import React, { useCallback, useEffect, useRef, useState } from "react";
import FilterSideBar from "./FilterSidebar";
import SearchArea from "./SearchArea";
import { debounce } from "lodash";
import { useSearchAndFilterBox } from "../../../context/SearchAndFilterContext";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";

const getFilterValues = (filterOptions = []) => {
  const returnValues = {};

  filterOptions.forEach((option) => {
    returnValues[option.title] = option.defaultChecked ? option.options : [];
  });

  return returnValues;
};

//TODO better handle column sizing
const SearchBox = ({
  filterOptions = [],
  sortByOptions = [], // [{key: 'createdAt', name: 'Created At'}]
  maxLimitOptions = [],
  onSearchOptionsChange,
  disabled,
  containerClass = "",
  recommendationGetter,
  showArchiveCheckbox = false,
  showMaxLimitOptions = false,
  showFilterOptions = false,
  showSortOptions = false,
  onChange,
}) => {
  const { setSearchBoxOptions, isClearingFilter, setIsClearingFilter } =
    useSearchAndFilterBox();
  const [filterBarVisible, setFilterBarVisible] = useState(false);
  const [maxLimit, setMaxLimit] = useState(maxLimitOptions[0]);
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState(sortByOptions[0]?.key);
  const [descSort, setDescSort] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  const [filters, setFilters] = useState(getFilterValues(filterOptions));
  const [recommendations, setRecommendations] = useState(["RAHUL", "EXAMPLE"]);

  const requestCounterRef = useRef(0);

  useEffect(() => {
    onSearchClick();
  }, [sortBy, maxLimit, descSort, filters, showArchived]);

  useDebouncedEffect(
    () => {
      onSearchClick();
    },
    [query],
    500
  );

  useEffect(() => {
    setQuery("");
    setIsClearingFilter(false);
  }, [isClearingFilter]);

  const onSearchClick = () => {
    setRecommendations([]);
    onSearchOptionsChange &&
      onSearchOptionsChange({
        sortBy,
        maxLimit,
        descSort,
        filters,
        query,
        showArchived,
      });
    setSearchBoxOptions &&
      setSearchBoxOptions({
        sortBy,
        maxLimit,
        descSort,
        filters,
        query,
        showArchived,
      });
  };

  const onFilterApply = (filter) => {
    setFilters(filter);
    setFilterBarVisible(false);
  };

  const fetchRecommendationsDebounced = useCallback(
    debounce(async (query) => {
      if (recommendationGetter && query.length > 2) {
        // Increment the request counter for each new request
        requestCounterRef.current += 1;
        const currentCounterValue = requestCounterRef.current;

        const recommendations = await recommendationGetter(query);

        // Only set the recommendations if the current counter matches the counter at the time of the request
        if (currentCounterValue === requestCounterRef.current) {
          setRecommendations(recommendations);
        }
      } else {
        setRecommendations([]); // Clear recommendations for short queries
      }
    }, 150),
    [recommendationGetter]
  );

  const onQueryChangeHandler = (newQuery) => {
    setQuery(newQuery);
    onChange && onChange(newQuery);
    fetchRecommendationsDebounced(newQuery);
  };

  const onRecommendationClick = (recommendation) => {
    setQuery(recommendation);
    onSearchClick();
  };

  return (
    <>
      <div className={`px-2 ${containerClass}`}>
        <SearchArea
          query={query}
          onQueryChange={onQueryChangeHandler}
          recommendations={recommendations}
          setRecommendations={setRecommendations}
          onRecommendationClick={onRecommendationClick}
          onSearchClick={onSearchClick}
          showSearchButton={false}
          disabled={disabled}
          maxLimit={maxLimit}
          showArchiveCheckbox={showArchiveCheckbox}
          showArchived={showArchived}
          onShowArchivedChange={setShowArchived}
          onMaxLimitOptionChange={setMaxLimit}
          maxLimitOptions={maxLimitOptions}
          sortBy={sortBy}
          onSortByOptionChange={setSortBy}
          sortByOptions={sortByOptions}
          descSort={descSort}
          onDescSortButtonClick={() => setDescSort(!descSort)}
          onFilterButtonClick={() => setFilterBarVisible(true)}
          showMaxLimitOptions={showMaxLimitOptions}
          showSortOptions={showSortOptions}
          showFilterOptions={showFilterOptions}
        />
      </div>
      {showFilterOptions && (
        <FilterSideBar
          show={filterBarVisible}
          initialFilterValues={filters}
          filterOptions={filterOptions}
          onFilterApply={onFilterApply}
          onSideBarClose={() => setFilterBarVisible(false)}
        />
      )}
    </>
  );
};

export default SearchBox;
