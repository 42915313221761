import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../../helpers/api";
import { DEFAULT_PAGE_SIZE } from "../../../helpers/constants";
import { getInboundTableColumns } from "../../../helpers/dataSheetConstants";
import { formatUrl } from "../../../helpers/global";
import useAppChoices from "../../../hooks/useAppChoices";
import useContactAndDealPopup from "../../../hooks/useContactAndDealPopup";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import useLocalization from "../../../hooks/useLocalization";
import { contactService } from "../../../services/contactService";
import { miscService } from "../../../services/miscService";
import DataTable from "../../common/data-table/DataTable";
import InboundDetailsModal from "../../contacts/inbounds/InboundDetailsModal";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const InboundTable = ({
  dateRange,
  users,
  leadSources,
  leadType,
  events,
  submissionType,
  count,
}) => {
  const { translate } = useLocalization();
  const abortControllerRef = useRef(null);
  const { openContactPopup } = useContactAndDealPopup();
  const eventOptions = useAppChoices("events");

  const [inboundSourceDetailsToShow, setInboundSourceDetailsToShow] =
    useState(null);
  const [contacts, setContacts] = useState([]);
  const onDetailsClick = (inbound) => {
    setInboundSourceDetailsToShow(inbound?.sourceDetails);
  };
  const [fetchingFirstPageContacts, setFetchingFirstPageContacts] =
    useState(false);
  const [fetchingMoreContacts, setFetchingMoreContacts] = useState(false);

  const [toLoadPageInfo, setToLoadPageInfo] = useState();
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const onCampaignClick = async (inbound) => {
    const campaignId = inbound?.sourceDetails?.campaignId;
    const adSetId = inbound?.sourceDetails?.adSetId;
    const adId = inbound?.sourceDetails?.adId;

    if (!campaignId || !adSetId || !adId) {
      return toast.error("No Ad Id found in the source details");
    }

    const { response: ads = [], error } = await miscService.getFacebookAds(
      null,
      {
        campaignId,
        adSetId,
      }
    );

    if (error) {
      return toast.error(error);
    }

    const adPreviewLink = ads.find(
      (a) => a.id === adId
    )?.preview_shareable_link;

    if (!adPreviewLink) {
      return toast.error("Preview link not found");
    }

    window.open(adPreviewLink, "_blank");
  };

  const tableColumns = useMemo(
    () =>
      getInboundTableColumns({
        onDetailsClick,
        onCampaignClick,
        openContactPopup,
      }),
    [onDetailsClick]
  );

  const fetchContacts = async () => {
    if (!count) {
      setContacts([]);
      return;
    }

    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const pageToFetch = toLoadPageInfo?.pageNumber;

    const loadingMoreContacts = pageToFetch > 1;

    if (loadingMoreContacts) {
      setFetchingMoreContacts(true);
    } else {
      setFetchingFirstPageContacts(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    try {
      let { startDate, endDate } = dateRange;

      const requestBody = {
        pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
        pageNumber: pageToFetch,
        sort: {
          key: "inbounds.date",
          order: "desc",
        },
        filter: [
          { key: "inboundDate", value: { start: startDate, end: endDate } },
          ...(leadSources?.length
            ? [
                {
                  key: "inbounds.leadSource",
                  value: leadSources,
                },
              ]
            : []),
          ...(leadType !== "all"
            ? [
                {
                  key: "inbounds.newHotLead",
                  value: leadType === "hot",
                },
              ]
            : []),
        ],
        assignedToFilter: users,
      };

      const { response, error } = await contactService.getContacts(
        requestBody,
        signal
      );
      if (error === ABORT_ERROR) return;

      if (error) {
        toast.error(error);
        return;
      }

      const {
        pageNumber: pgNumber,
        totalPages,
        resultCount,
        results,
      } = response;

      setLoadedPageInfo({
        totalPages,
        pageNumber: pgNumber,
        resultCount,
      });

      setContacts((prevContacts) => {
        const newArray = loadingMoreContacts
          ? [...prevContacts, ...results]
          : results;

        return newArray;
      });
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingMoreContacts(false);
      setFetchingFirstPageContacts(false);
    }
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageContacts || fetchingMoreContacts)
      return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
    });
  };

  useEffect(() => {
    setToLoadPageInfo({ ...initialPageInfo });
    setLoadedPageInfo();
  }, [dateRange, users, leadSources, leadType, events, submissionType, count]);

  useDebouncedEffect(
    () => {
      fetchContacts({});
    },
    [toLoadPageInfo],
    300
  );

  const inboundsTableData = useMemo(() => {
    const matchesDate = (inbound) => {
      const { startDate, endDate } = dateRange;
      if (!startDate || !endDate) return true;

      const inboundDate = new Date(inbound.date);
      return (
        inboundDate >= new Date(startDate) && inboundDate <= new Date(endDate)
      );
    };

    const matchesEvent = (inbound) => {
      const allEvents = events?.map((e) =>
        eventOptions.find((o) => o._id === e)
      );
      if (!allEvents?.length) return true;

      const { sourceDetails } = inbound;
      const campaignName = sourceDetails?.campaignName || null;
      const pageUrl = sourceDetails?.page_url
        ? formatUrl(sourceDetails.page_url)
        : null;

      const allCampaigns = allEvents.flatMap((e) =>
        e.facebookCampaigns.map((c) => c.name)
      );
      const allPageUrls = allEvents.flatMap((e) => e.urls);

      return (
        allCampaigns.includes(campaignName) || allPageUrls.includes(pageUrl)
      );
    };

    const filterInbound = ({ newHotLead, isNewSubmission, ...inbound }) => {
      const matchesLeadType =
        !leadType ||
        leadType === "all" ||
        (leadType === "hot" && newHotLead) ||
        (leadType === "cold" && !newHotLead);

      const matchesSubmissionType =
        !submissionType ||
        submissionType === "all" ||
        (submissionType === "new-submission" && isNewSubmission) ||
        (submissionType === "re-submission" && !isNewSubmission);

      const matchesLeadSource =
        !leadSources?.length || leadSources.includes(inbound.leadSource);

      return (
        matchesDate(inbound) &&
        matchesEvent(inbound) &&
        matchesLeadType &&
        matchesSubmissionType &&
        matchesLeadSource
      );
    };

    let contactsWithSubmissionType = contacts.map((c) => {
      const allInbounds = c.inbounds.map((inbound, index) => {
        return {
          ...inbound,
          isNewSubmission: index === 0,
        };
      });

      return {
        ...c,
        inbounds: allInbounds,
      };
    });

    let allInbounds = contactsWithSubmissionType.flatMap((contact) =>
      contact.inbounds.filter(filterInbound).map((inbound) => ({
        ...inbound,
        contact,
      }))
    );

    return allInbounds;
  }, [contacts]);

  return (
    <div className="custom-card h-100 p-2 mx-1 mt-2">
      {" "}
      <h6 className="xxlarge fw-bold mb-3">
        {translate("inbounds_no_count")} {`(${count})`}
      </h6>
      <DataTable
        maxTableHeight={`400px`}
        rowKey={"_id"}
        columns={tableColumns}
        data={inboundsTableData}
        bottomOffset={300}
        onBottomReached={loadMoreData}
        loadingMoreData={fetchingMoreContacts}
        loadingFirstPageData={fetchingFirstPageContacts}
        allowSort={false}
        allowFilter={false}
      />
      <InboundDetailsModal
        show={Boolean(inboundSourceDetailsToShow)}
        onHide={() => setInboundSourceDetailsToShow(null)}
        inbound={inboundSourceDetailsToShow}
      />
    </div>
  );
};

export default InboundTable;
