import { useEffect, useState } from "react";
import useAppChoices from "../../hooks/useAppChoices";
import useLocalization from "../../hooks/useLocalization";
import AppModal from "../common/AppModal";
import ContractSendForm from "../forms/ContractSendForm";

const CreateDealModal = ({ show, onHide, contact, showProgress, onSubmit }) => {
  const { translate } = useLocalization();

  const originalProducts = useAppChoices("products") ?? [];
  const SHOW_ARCHIVED_PRODUCTS = useAppChoices("SHOW_ARCHIVED_PRODUCTS");

  const [products, setProducts] = useState(originalProducts);

  useEffect(() => {
    if (SHOW_ARCHIVED_PRODUCTS?.[0]?.value === false) {
      setProducts(originalProducts.filter((product) => !product.archived));
    } else {
      setProducts(originalProducts);
    }
  }, [SHOW_ARCHIVED_PRODUCTS, originalProducts]);

  return (
    <AppModal
      show={show}
      onHide={onHide}
      title={translate(`create_new_deal`)}
      showCloseButton={!showProgress}
      modalBodyClassName="m-0 p-2"
      modalContentClassName="rounded"
      modalDialogClassName="p-5 bg-dark bg-opacity-50"
      size={"xl"}
      fullscreen={true}
    >
      <ContractSendForm
        products={products}
        contact={contact}
        showProgress={showProgress}
        onSubmit={onSubmit}
      />
    </AppModal>
  );
};

export default CreateDealModal;
