import React, { useCallback, useEffect, useMemo, useRef } from "react";
import RcTiptapEditor from "reactjs-tiptap-editor";
import {
  BaseKit,
  Blockquote,
  Bold,
  BulletList,
  Clear,
  Code,
  CodeBlock,
  Color,
  ColumnActionButton,
  Emoji,
  ExportPdf,
  ExportWord,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  History,
  HorizontalRule,
  Image,
  ImportWord,
  Indent,
  Italic,
  LineHeight,
  Link,
  Mention,
  MoreMark,
  OrderedList,
  SearchAndReplace,
  SlashCommand,
  Strike,
  Table,
  TaskList,
  TextAlign,
  TextDirection,
  Underline,
} from "reactjs-tiptap-editor/extension-bundle";
import { locale } from "reactjs-tiptap-editor/locale-bundle";
import "reactjs-tiptap-editor/style.css";
import "./editor.scss";

const getExtensions = ({ fileUploader }) => [
  BaseKit.configure({
    multiColumn: true,
    placeholder: { showOnlyCurrent: true },
    characterCount: false,
  }),
  History,
  SearchAndReplace,
  TextDirection,
  Clear,
  FontFamily,
  Heading.configure({ spacer: true }),
  FontSize,
  Bold,
  Italic,
  Underline,
  Strike,
  MoreMark,
  Emoji,
  Color.configure({ spacer: true }),
  Highlight,
  BulletList,
  OrderedList,
  TextAlign.configure({ types: ["heading", "paragraph"], spacer: true }),
  Indent,
  LineHeight,
  TaskList.configure({ spacer: true, taskItem: { nested: true } }),
  Link,
  ...(fileUploader
    ? [
        Image.configure({
          upload: fileUploader,
        }),
      ]
    : []),
  Blockquote.configure({ spacer: true }),
  SlashCommand,
  HorizontalRule,
  Code.configure({ toolbar: false }),
  CodeBlock.configure({ defaultTheme: "dracula" }),
  ColumnActionButton,
  Table,
  ExportPdf.configure({ spacer: true }),
  ImportWord.configure({
    upload: (files) =>
      Promise.resolve(
        files.map((file) => ({
          src: URL.createObjectURL(file),
          alt: file.name,
        }))
      ),
  }),
  ExportWord,
  Mention,
];

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

function Editor({ content, onContentChange, fileUploader }) {
  const refEditor = useRef(null);

  const onValueChange = useCallback(
    debounce((value) => onContentChange(value), 300),
    []
  );

  useEffect(() => {
    locale.setLang("he");
  }, []);

  const extensions = useMemo(
    () => getExtensions({ fileUploader }),
    [fileUploader]
  );

  return (
    <div className="tiptap-container">
      <RcTiptapEditor
        ref={refEditor}
        output="html"
        content={content}
        onChangeContent={onValueChange}
        extensions={extensions}
        dark={false}
      />
    </div>
  );
}

export default Editor;
