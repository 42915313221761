import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateItemsInArray } from "../../../helpers/global";
import useLocalization from "../../../hooks/useLocalization";
import { inboundSourceDefaultsStatusService } from "../../../services/inboundSourceDefaultsStatusService";
import EditDefaultStatusForm from "../../forms/EditDefaultStatusForm";
import Loader from "../../common/Loader";
import { INBOUNDS_TO_SHOW } from "../../../helpers/constants";
import useAuth from "../../../hooks/useAuth";
import { PERMISSIONS } from "../../../helpers/permissions";

const InboundSourceDefaultStatus = ({ whatsappTemplates }) => {
  const { checkNestedPermission } = useAuth();
  const { translate } = useLocalization();
  const [editingInboundSource, setEditingInboundSource] = useState(null);
  const [inboundSourceDefaults, setInboundSourceDefaults] = useState([]);
  const [fetchingInboundSourceDefaults, setFetchingInboundCourceDefaults] =
    useState(true);

  const fetchDefaultStatus = async () => {
    setFetchingInboundCourceDefaults(true);
    const { response, error } =
      await inboundSourceDefaultsStatusService.getInboundSourceDefaultsStatus();
    if (error) {
      toast.error(error);
      return;
    }
    setInboundSourceDefaults(response);
    setFetchingInboundCourceDefaults(false);
  };

  const onDefaultStatusSubmit = async (formData) => {
    const { __v, ...inboundSourceToUpdate } = formData;

    setEditingInboundSource(inboundSourceToUpdate);

    const requestBody = {
      ...inboundSourceToUpdate,
      exceptions: inboundSourceToUpdate.exceptions.map(
        ({ whatsappCampaigns, ...exception }) => exception
      ),
    };
    const { response, error } =
      await inboundSourceDefaultsStatusService.updateInboundSourceDefaultsStatus(
        requestBody
      );

    if (error) {
      toast.error(error);
      setEditingInboundSource(null);
      return;
    }
    const updatedInboundSources = updateItemsInArray(inboundSourceDefaults, {
      ...response,
      contactCount: formData?.contactCount,
    });
    setInboundSourceDefaults(updatedInboundSources);
    toast.success("Updated successfully");
    setEditingInboundSource(null);
  };

  useEffect(() => {
    fetchDefaultStatus();
  }, []);

  return (
    <Card className="m-2 my-4 pb-4">
      <Card.Header>{translate("inbound_source_defaults")}</Card.Header>
      <Card.Body className="py-1">
        {fetchingInboundSourceDefaults ? (
          <Loader />
        ) : (
          <Row>
            {inboundSourceDefaults
              ?.filter((ds) => INBOUNDS_TO_SHOW.includes(ds.key))
              .map((inboundSource) => {
                return (
                  <Col key={inboundSource?.key} className="p-1" sm={12}>
                    <div className="border">
                      <EditDefaultStatusForm
                        initialValues={inboundSource}
                        whatsappTemplates={whatsappTemplates}
                        showProgress={
                          editingInboundSource?._id === inboundSource?._id
                        }
                        onSubmit={onDefaultStatusSubmit}
                        allowEdit={checkNestedPermission([
                          PERMISSIONS.inbound_source_defaults
                            .UPDATE_ALL_INBOUND_SOURCE_DEFAULTS,
                        ])}
                        canViewWhatsappCampaigns={checkNestedPermission([
                          PERMISSIONS.whatsapp_campaigns.VIEW_ALL_CAMPAIGNS,
                        ])}
                      />
                    </div>
                  </Col>
                );
              })}
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default InboundSourceDefaultStatus;
