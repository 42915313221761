import { ErrorMessage, Field, Form, Formik } from "formik";
import { snakeCase } from "lodash";
import React from "react";
import {
  Badge,
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import * as Yup from "yup";
import { isValidProsperoUrl } from "../../helpers/global";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";
import ProductStructureTable from "../common/ProductStructureTable";
import { Eye, EyeSlash, Trash } from "react-bootstrap-icons";

// Yup validation schema
const validationSchema = Yup.object().shape({
  description: Yup.string().required("Please provide the contract description"),
  templateLink: Yup.string(),
});

const defaultValues = {
  description: "",
  price: "",
  total: "",
  discount: "",
  templateLink: "",
  units: [],
};
const AddEditContractForm = ({
  initialValues,
  showProgress,
  productOption,
  editMode,
  onSubmit,
  archiveContract,
  allowDelete,
  onDeleteContractClick,
}) => {
  const { translate } = useLocalization();

  return (
    <Formik
      initialValues={
        initialValues
          ? {
              _id: initialValues?._id,
              description: initialValues?.description,
              price: initialValues?.price,
              total: initialValues?.total,
              discount: initialValues?.discount,
              templateLink: initialValues?.templateLink,
              units: initialValues?.units || [],
              ...(initialValues.note && {
                note: initialValues.note,
              }),
            }
          : defaultValues
      }
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, { setFieldError }) => {
        // Destructure setFieldError from the second argument
        const { templateLink } = values;
        if (templateLink && !isValidProsperoUrl(templateLink)) {
          // Set an error message for templateLink field
          setFieldError(
            "templateLink",
            `A template link should be of the form "https://app.goprospero.com/t/<24_digit_template_id>"`
          );
          return; // Prevent the form from being submitted
        }

        onSubmit(values); // Proceed with the submission if the URL is valid
      }}
    >
      {({ values, setFieldValue, errors, submitForm }) => {
        return (
          <Form className="">
            <Row className="m-0">
              {[
                {
                  name: "templateLink",
                  type: "text",
                  colSize: 12,
                  label: "Template Link",
                },
                {
                  name: "description",
                  type: "text",
                  label: "Title in Prospero",
                  colSize: 12,
                  isTextArea: true,
                },
                {
                  name: "note",
                  type: "text",
                  label: "Name",
                  colSize: 12,
                  isTextArea: true,
                },
              ].map((field) => (
                <Col key={field.name} xs={12} md={field.colSize || 6}>
                  <FormGroup key={field.name} className="mb-2">
                    <FormLabel className="mid mb-1">
                      {translate(snakeCase(field.label))}
                    </FormLabel>
                    <Field
                      name={field.name}
                      type={field.type}
                      className="form-control form-control-sm"
                      rows={2}
                      as={field.isTextArea ? "textarea" : FormControl}
                      size="sm"
                    />
                    <ErrorMessage
                      name={field.name}
                      component="div"
                      className="text-danger mid"
                    />
                  </FormGroup>
                </Col>
              ))}
              <Col xs={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("products")}
                  </FormLabel>
                  <ProductStructureTable
                    productOptions={productOption}
                    errors={errors}
                    products={values?.units || []}
                    onProductChange={(units) => setFieldValue("units", units)}
                    discount={values?.discount}
                    onDiscountChange={(discount) =>
                      setFieldValue("discount", discount)
                    }
                    totalPrice={values?.price}
                    onTotalPriceChange={(price) => {
                      setFieldValue("price", price);
                      setFieldValue("total", price);
                    }}
                    initialValues={initialValues}
                  />
                  <ErrorMessage
                    name={"units"}
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <hr className="my-2" />
            <div className="d-flex align-items-center justify-content-between">
              {editMode && (
                <div className="d-flex justify-content-center align-items-center gap-2">
                  {initialValues?.archived ? (
                    <Badge
                      role="button"
                      bg="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        archiveContract({ ...initialValues, archived: false });
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <span>Unarchive</span>
                        <Eye size={15} />
                      </div>
                    </Badge>
                  ) : (
                    <Badge
                      role="button"
                      bg="purple"
                      onClick={(e) => {
                        e.preventDefault();
                        archiveContract({ ...initialValues, archived: true });
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <span>Archive</span>
                        <EyeSlash size={15} />
                      </div>
                    </Badge>
                  )}
                  {allowDelete && (
                    <Badge
                      role="button"
                      bg="danger"
                      onClick={(e) => {
                        onDeleteContractClick(initialValues);
                        e.stopPropagation();
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <span>Delete</span>
                        <Trash size={15} />
                      </div>
                    </Badge>
                  )}
                </div>
              )}
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate(!editMode ? "add" : "update")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditContractForm;
