import { isNumber } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../helpers/api";
import { DEFAULT_PAGE_SIZE } from "../../helpers/constants";
import { getLogTableColumns } from "../../helpers/dataSheetConstants";
import useAuth from "../../hooks/useAuth";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import useLocalization from "../../hooks/useLocalization";
import DataTable from "../common/data-table/DataTable";
import { logsService } from "../../services/logsService";
import useAppChoices from "../../hooks/useAppChoices";
import LogDetailsModal from "./LogDetailsModal";
import AlertModal from "../common/AlertModal";
import { updateItemsInArray } from "../../helpers/global";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const BrowseLogs = ({}) => {
  const abortControllerRef = useRef(null);

  const { translate } = useLocalization();
  const { user } = useAuth();
  const users = useAppChoices("users");
  const [tableFilterValues, setTableFilterValues] = useState([]);
  const [tableSortOption, setTableSortOption] = useState({
    key: "createdAt",
    order: "desc",
  });
  const [fetchingFirstPageLogs, setFetchingFirstPageLogs] = useState(false);
  const [fetchingMoreLogs, setFetchingMoreLogs] = useState(false);

  const [logs, setLogs] = useState([]);
  const [toLoadPageInfo, setToLoadPageInfo] = useState(initialPageInfo);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const [logDetailMeta, setLogDetailMeta] = useState(null);
  const [confirmationModalMeta, setConfirmationModalMeta] = useState(null);

  const onRowClick = useCallback((log) => {
    setLogDetailMeta({ log });
  }, []);

  const scrollToContactRow = (scrollHeight) => {
    const containerElement = document.getElementById("table-container");
    const offset = -80; // Adjust this value based on the height of your fixed element

    if (containerElement) {
      containerElement.scroll({
        top: scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const onActionClick = ({ logId, actionId, action, log }) => {
    setConfirmationModalMeta({ logId, actionId, action, log });
  };

  const tableColumns = useMemo(
    () =>
      getLogTableColumns({
        translate,
        users,
        onActionClick,
      }),
    [users]
  );

  const fetchLogs = async () => {
    if (!toLoadPageInfo) {
      return;
    }
    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const { pageNumber: pageToFetch, isNotInitialFetch = false } =
      toLoadPageInfo;
    const loadingMoreProducts = pageToFetch > 1;

    if (loadingMoreProducts) {
      setFetchingMoreLogs(true);
    } else {
      setFetchingFirstPageLogs(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
      pageNumber: pageToFetch,
      sort: tableSortOption,
      filter: [...tableFilterValues],
    };

    const { response, error } = await logsService.getLogs(requestBody, signal);
    if (error === ABORT_ERROR) return;

    setFetchingMoreLogs(false);
    setFetchingFirstPageLogs(false);

    if (error) {
      return toast.error(error);
    }

    if (isNumber(toLoadPageInfo?.scrollHeight)) {
      scrollToContactRow(toLoadPageInfo?.scrollHeight);
    }

    const { pageNumber, totalPages, results = [], resultCount } = response;

    setLoadedPageInfo({ totalPages, pageNumber, resultCount });

    setLogs((prevProducts) =>
      loadingMoreProducts ? [...prevProducts, ...results] : results
    );
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageLogs || fetchingMoreLogs) return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
      isNotInitialFetch: true,
    });
  };

  const handleAction = async () => {
    const { action, logId, actionId, log } = confirmationModalMeta;

    const reqBody = { logId, actionId };

    const isAtomicAction = Boolean(actionId);

    setConfirmationModalMeta({ ...confirmationModalMeta, showProgress: true });

    const { response, error } = await logsService.action(action, reqBody);

    if (error) {
      setConfirmationModalMeta({
        ...confirmationModalMeta,
        showProgress: false,
      });
      return toast.error(error);
    }

    if (!isAtomicAction) {
      const updatedLog = { ...log, undone: !Boolean(log.undone) };
      setLogs(updateItemsInArray(logs, updatedLog));
    } else {
      const updatedAtomicAction = log.atomicActions.find(
        (a) => a._id === actionId
      );

      updatedAtomicAction.undone = !Boolean(updatedAtomicAction.undone);

      const updatedLog = {
        ...log,
        atomicActions: updateItemsInArray(
          log.atomicActions,
          updatedAtomicAction
        ),
      };

      setLogDetailMeta({ log: updatedLog });
    }
    setConfirmationModalMeta(null);
  };

  useDebouncedEffect(
    () => {
      setToLoadPageInfo({ ...initialPageInfo, scrollHeight: 0 });
    },
    [tableFilterValues, tableSortOption],
    500
  );

  useEffect(() => {
    fetchLogs();
  }, [toLoadPageInfo]);

  return (
    <>
      <Container
        fluid
        className={`d-flex flex-column ${"px-2 pb-2"}`}
        style={{
          height: "calc(100vh - 50px)",
          overflow: "hidden",
        }}
      >
        {" "}
        <div className="mt-2 d-flex align-items-center justify-content-between">
          <h6 className="mb-0 ">
            {translate("logs")} ({loadedPageInfo?.resultCount || 0})
          </h6>
        </div>
        <div className={`flex-grow-1 pt-2 h-100`}>
          <DataTable
            maxTableHeight={`calc(100% - 40px)`}
            rowKey={"_id"}
            columns={tableColumns}
            data={logs}
            sortOptions={tableSortOption}
            onSortChange={setTableSortOption}
            filterValues={tableFilterValues}
            onFilterValuesChange={setTableFilterValues}
            onRowClick={onRowClick}
            onBottomReached={loadMoreData}
            loadingMoreData={fetchingMoreLogs}
            bottomOffset={300}
            loadingFirstPageData={fetchingFirstPageLogs}
          />
        </div>
        {Boolean(logDetailMeta) && (
          <LogDetailsModal
            show={Boolean(logDetailMeta)}
            onHide={() => setLogDetailMeta(null)}
            log={logDetailMeta?.log}
            onActionClick={onActionClick}
          />
        )}
        {Boolean(confirmationModalMeta) && (
          <AlertModal
            show={Boolean(confirmationModalMeta)}
            onHide={() => setConfirmationModalMeta(null)}
            alertText={`Are you sure you want to ${confirmationModalMeta.action} this log?`}
            onContinueClick={handleAction}
            showProgress={confirmationModalMeta?.showProgress}
          />
        )}
      </Container>
    </>
  );
};

export default BrowseLogs;
