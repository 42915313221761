import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
  ArrowUpLeftCircle,
  ArrowUpRightCircle,
  InfoCircleFill,
  Search,
  Stars,
  XCircle,
} from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { getPromptSummaryInfo, isNullOrUndefined } from "../../helpers/global";
import useLocalization from "../../hooks/useLocalization";
import { aiChatPromptServices } from "../../services/aiChatPromptService";
import AutoGrowTextArea from "./AutoGrowTextArea";
import Loader from "./Loader";
import CircularProgressBar from "./circular-progress";

const QueryInactiveIcon = () => {
  return (
    <div
      style={{
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        background: "linear-gradient(to bottom, skyblue, darkblue)",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        marginRight: "4px",
        flexShrink: 0,
        marginTop: "10px",
      }}
    />
  );
};

const getBorderColor = (confidence) => {
  return confidence > 8
    ? "#00d511" // Green for high confidence
    : confidence >= 6
    ? "#fff100" // Yellow for medium-high confidence
    : confidence >= 3
    ? "#ffb100" // Orange for medium-low confidence
    : "#FF0000"; // Red for low confidence
};

const ActiveQueryIcon = ({ aiQuery }) => {
  const color = getBorderColor(aiQuery.confidence);

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>Confidence: {aiQuery.confidence}</Tooltip>}
    >
      <div
        style={{
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          border: "1px solid",
          backgroundColor: color,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          marginRight: "4px",
          flexShrink: 0,
          marginTop: "10px",
        }}
      />
    </OverlayTrigger>
  );
};

const AiChatBotSideBar = ({
  onHide,
  show,
  activeAiQuery,
  onActiveAiQueryChange,
  width,
  height,
  modelName,
  recentSearchHeight = "620px",
  mobileView,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [recentSearches, setRecentSearches] = useState([]);
  const [recentSearchLoading, setRecentSearchLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const { isRTL, translate } = useLocalization();

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleRecentSearch = (item) => {
    onActiveAiQueryChange({ ...item });
    setSearchQuery(item.userQuery);
  };

  const handleCancelSearch = () => {
    setSearchQuery("");
    onActiveAiQueryChange(null);
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      setSubmitting(true);
      const { response, error } = await aiChatPromptServices.createAiChatPrompt(
        {
          userQuery: searchQuery,
          modelName: modelName,
        }
      );
      setSubmitting(false);
      if (error) {
        return toast.error("Failed to answer the query.");
      }

      onActiveAiQueryChange(response);
      setRecentSearches([response, ...recentSearches]);
    }
  };

  const getRecentSearches = async () => {
    setRecentSearchLoading(true);
    const { response, error } = await aiChatPromptServices.searchAiChatPrompt({
      modelNames: [modelName],
    });

    if (error) {
      setRecentSearchLoading(false);
      return toast.error("Failed to retrieve recent searches...");
    }
    setRecentSearches(response);
    setRecentSearchLoading(false);
  };

  useEffect(() => {
    getRecentSearches();
  }, []);

  return (
    <>
      {show && (
        <div
          style={{
            width: width,
            height,
            border: "1px solid #ddd",
            borderRadius: "4px",
            backgroundColor: "white",
          }}
          className=" px-2"
        >
          {/* Header */}
          <div className="d-flex justify-content-between align-items-center py-2 border-bottom">
            <div className="d-flex align-items-center">
              <Stars
                className="custom-shining-gold me-1"
                style={{ color: "#1ca9c9" }}
              />
              <h6 className="mid mb-0 fw-bold">{translate("ai_search")}</h6>
            </div>
            <div className="d-flex justify-content-end pe-3">
              {isRTL ? (
                <ArrowRightCircleFill
                  size={18}
                  onClick={onHide}
                  className="hover"
                />
              ) : (
                <ArrowLeftCircleFill
                  size={18}
                  onClick={onHide}
                  className="hover"
                />
              )}
            </div>
          </div>

          {/* Body */}
          <div className="py-2">
            <Form
              onSubmit={handleSearchSubmit}
              style={{ marginBottom: "1rem" }}
            >
              <InputGroup
                className="d-flex"
                style={{
                  position: "relative",
                  padding: "3px 10px",
                  border: "1px solid rgb(206, 204, 204)",
                  borderColor: isNullOrUndefined(activeAiQuery?.confidence)
                    ? ""
                    : getBorderColor(activeAiQuery?.confidence),
                  borderWidth: isNullOrUndefined(activeAiQuery?.confidence)
                    ? "1px"
                    : "2px",
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                  alignItems: "flex-start",
                }}
              >
                {!isNullOrUndefined(activeAiQuery?.confidence) ? (
                  <ActiveQueryIcon aiQuery={activeAiQuery} />
                ) : (
                  <QueryInactiveIcon />
                )}

                <AutoGrowTextArea
                  as="textarea"
                  placeholder={`${translate("ask_me_anything")}...`}
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      handleSearchSubmit(e);
                    }
                  }}
                  disabled={isSubmitting}
                  rows={1}
                  style={{
                    flex: 1,
                    border: "none",
                    outline: "none",
                    margin: 0,
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    fontSize: "13px",
                    resize: "none",
                  }}
                />

                {searchQuery && (
                  <Button variant="none" onClick={handleCancelSearch}>
                    {isSubmitting ? (
                      <CircularProgressBar size={13} />
                    ) : (
                      <XCircle
                        size={13}
                        style={{
                          color: "grey",
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.color = "crimson";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.color = "grey";
                        }}
                      />
                    )}
                  </Button>
                )}
              </InputGroup>
            </Form>

            {/* Recent Searches */}
            <div className="mb-0">
              <h6
                className="mt-3 smallFont fw-bold"
                style={{ marginBottom: "0.5rem" }}
              >
                {translate("recent_searches")}
              </h6>
              {recentSearchLoading ? (
                <Loader />
              ) : recentSearches.length > 0 ? (
                <div
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: "10px",
                    padding: "4px",
                    maxHeight: recentSearchHeight,
                    overflowY: "auto",
                    scrollBehavior: "smooth",
                    scrollbarWidth: "thin",
                  }}
                >
                  {recentSearches.map((item) => (
                    <div
                      key={item._id}
                      className="d-flex align-items-start"
                      style={{
                        padding: "10px",
                        borderRadius: "10px",
                        transition: "background-color 0.3s ease",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = " #dcdcdc")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                      onClick={() => handleRecentSearch(item)}
                    >
                      <div>
                        <Search
                          size={15}
                          style={{ marginRight: "8px", color: "#1ca9c9" }}
                        />
                      </div>
                      <div className="flex-grow-1 px-2">
                        <span
                          style={{
                            fontSize: "13px",
                            wordBreak: "break-word",
                            display: "block",
                          }}
                        >
                          {item.userQuery}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-center gap-2 pt-1">
                        {isRTL ? (
                          <ArrowUpRightCircle
                            size={18}
                            className="hover"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSearchQuery(item.userQuery);
                            }}
                          />
                        ) : (
                          <ArrowUpLeftCircle
                            size={18}
                            className="hover"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSearchQuery(item.userQuery);
                            }}
                          />
                        )}
                        <OverlayTrigger
                          onEntering={(e) =>
                            getPromptSummaryInfo(e, mobileView)
                          }
                          placement={`${isRTL ? "left" : "right"}`}
                          overlay={
                            <Tooltip id={`tooltip-${item._id}`}>
                              {item.summary || "No summary available."}
                            </Tooltip>
                          }
                        >
                          <InfoCircleFill
                            className="text-dark cursor-pointer"
                            size={16}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="tiny">{translate("no_any_recent_searches")}...</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AiChatBotSideBar;
