import React, { useEffect, useMemo, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../helpers/api";
import { DEFAULT_PAGE_SIZE } from "../../helpers/constants";
import { getEventMarketing } from "../../helpers/dataSheetConstants";
import { getDateWithTimezone, updateItemsInArray } from "../../helpers/global";
import useLocalization from "../../hooks/useLocalization";
import { eventService } from "../../services/eventService";
import AlertModal from "../common/AlertModal";
import DataTable from "../common/data-table/DataTable";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const MarketingTable = ({
  selectedEvent,
  setSelectedEvent,
  funnelTitle,
  setFunnelTitle,
  fetchEventFunnel,
}) => {
  const abortControllerRef = useRef(null);
  const { translate } = useLocalization();

  const [fetchingFirstPageEvents, setFetchingFirstPageEvents] = useState(false);
  const [fetchingMoreEvents, setFetchingMoreEvents] = useState(false);

  // original events array
  const [events, setEvents] = useState([]);

  const [toLoadPageInfo, setToLoadPageInfo] = useState(initialPageInfo);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const [eventModalMeta, setEventModalMeta] = useState(null);
  const [eventDeleteModalMeta, setEventDeleteModalMeta] = useState(null);

  const onShowEventFunnelClick = (event) => {
    setSelectedEvent(event);
    setFunnelTitle("complete_funnel");
    fetchEventFunnel(event._id);
  };

  const onShowEventPartialsFunnelClick = (event) => {
    setSelectedEvent(event);
    setFunnelTitle("fractional_funnel");
    fetchEventFunnel(event._id, true);
  };

  const tableColumns = useMemo(
    () =>
      getEventMarketing(
        onShowEventPartialsFunnelClick,
        onShowEventFunnelClick,
        selectedEvent,
        funnelTitle,
        translate
      ),
    [
      onShowEventFunnelClick,
      onShowEventPartialsFunnelClick,
      selectedEvent,
      funnelTitle,
    ]
  );

  const fetchEvents = async () => {
    if (!toLoadPageInfo) return;

    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const pageToFetch = toLoadPageInfo?.pageNumber;
    const loadingMoreEvents = pageToFetch > 1;

    if (loadingMoreEvents) {
      setFetchingMoreEvents(true);
    } else {
      setFetchingFirstPageEvents(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      pageSize: DEFAULT_PAGE_SIZE,
      pageNumber: pageToFetch,
      sort: {
        date: -1,
      },
    };

    const { response, error } = await eventService.getEvents(
      requestBody,
      signal
    );

    if (error === ABORT_ERROR) return;

    setFetchingMoreEvents(false);
    setFetchingFirstPageEvents(false);

    if (error) {
      return toast.error(error);
    }

    const { pageNumber, totalPages, results = [] } = response;
    setLoadedPageInfo({ totalPages, pageNumber });
    setEvents((prevEvents) =>
      loadingMoreEvents ? [...prevEvents, ...results] : results
    );
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageEvents || fetchingMoreEvents)
      return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
    });
  };

  useEffect(() => {
    fetchEvents();
  }, [toLoadPageInfo]);

  const onEventChange = (event) => {
    setEvents((prevEvents) => {
      const updatedEvents = updateItemsInArray(prevEvents, [event]);
      return updatedEvents;
    });
  };

  const onEventSubmit = async (event) => {
    const { editMode, editingEvent } = eventModalMeta;
    setEventModalMeta((meta) => ({ ...meta, showProgress: true }));

    const { response, error } = editMode
      ? await eventService.updateEvent(editingEvent._id, event)
      : await eventService.createEvent({
          ...event,
          date: getDateWithTimezone(event.date),
        });
    setEventModalMeta((meta) => ({ ...meta, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    setEventModalMeta(null);
    onEventChange(response);
    toast.success(
      editMode ? "Successfully updated event" : "Successfully added event"
    );
  };

  const deleteEvent = async () => {
    const { event } = eventDeleteModalMeta;
    setEventDeleteModalMeta((meta) => ({ ...meta, showProgress: true }));

    const { response, error } = await eventService.deleteEvent(event);
    setEventDeleteModalMeta((meta) => ({ ...meta, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    setEventDeleteModalMeta(null);
    setEvents((events) => events.filter((p) => p._id !== event._id));
    toast.success("Successfully deleted event");
  };

  return (
    <>
      <div className="custom-card m-0">
        <h6 className="mb-3 xlarge fw-bold">
          {translate("events")} ({events.length}):
        </h6>
        <DataTable
          rowKey={"_id"}
          columns={tableColumns}
          data={events}
          maxTableHeight="520px"
          onBottomReached={loadMoreData}
          loadingMoreData={fetchingMoreEvents}
          loadingFirstPageData={fetchingFirstPageEvents}
          allowFilter={false}
          allowSort={false}
        />
      </div>
      {events.length > 0 && (
        <AlertModal
          show={Boolean(eventDeleteModalMeta)}
          onHide={() => setEventDeleteModalMeta(false)}
          onDismissClick={() => setEventDeleteModalMeta(false)}
          alertText={translate("are_you_sure_you_want_to_delete_events", {
            eventName: eventDeleteModalMeta?.event?.name,
          })}
          showProgress={eventDeleteModalMeta?.showProgress}
          onContinueClick={deleteEvent}
        />
      )}
    </>
  );
};

export default MarketingTable;
