import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const dashboardService = {
  getStats: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_STATS,
      requestBody,
      signal,
    });

    return { response, error };
  },

  getMarketingStats: async (reqBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_MARKETING_STATS,
      method: "POST",
      requestBody: reqBody,
      signal,
    });

    return { response, error };
  },

  getProductionStats: async (reqBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_PRODUCTION_STATS,
      method: "POST",
      requestBody: reqBody,
      signal,
    });

    return { response, error };
  },
  getProductionProducts: async (reqBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_PRODUCTION_PRODUCTS,
      method: "POST",
      requestBody: reqBody,
      signal,
    });

    return { response, error };
  },
  getMarketingLeads: async (reqBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_MARKETING_LEADS,
      method: "POST",
      requestBody: reqBody,
      signal,
    });

    return { response, error };
  },
  getFunnelProgress: async (reqBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_FUNNEL_PROGRESS,
      method: "POST",
      requestBody: reqBody,
      signal,
    });

    return { response, error };
  },
};
