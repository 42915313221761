import React, { useEffect, useState } from "react";
import moment from "moment";
import { DateRangePicker as ReactDateRangePicker, createStaticRanges } from "react-date-range";

const getRangePresets = () => {
  return {
    Today: {
      startDate: moment().startOf("day").toDate(),
      endDate: moment().endOf("day").toDate(),
    },
    "This Week": {
      startDate: moment().startOf("week").toDate(),
      endDate: moment().endOf("week").toDate(),
    },
    "Last Week": {
      startDate: moment().subtract(1, "week").startOf("week").toDate(),
      endDate: moment().subtract(1, "week").endOf("week").toDate(),
    },
    "This Month": {
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),
    },
    "Last Month": {
      startDate: moment().subtract(1, "month").startOf("month").toDate(),
      endDate: moment().subtract(1, "month").endOf("month").toDate(),
    },
    "This Quarter": {
      startDate: moment().startOf("quarter").toDate(),
      endDate: moment().endOf("quarter").toDate(),
    },
    "Last Quarter": {
      startDate: moment().subtract(1, "quarter").startOf("quarter").toDate(),
      endDate: moment().subtract(1, "quarter").endOf("quarter").toDate(),
    },
    "This Year": {
      startDate: moment().startOf("year").toDate(),
      endDate: moment().endOf("year").toDate(),
    },
    "Last 3 Months": {
      startDate: moment().subtract(3, "month").startOf("day").toDate(),
      endDate: moment().endOf("day").toDate(),
    },
    "Last 12 Months": {
      startDate: moment().subtract(12, "month").startOf("day").toDate(),
      endDate: moment().endOf("day").toDate(),
    },
    "Last Year": {
      startDate: moment().subtract(1, "year").startOf("year").toDate(),
      endDate: moment().subtract(1, "year").endOf("year").toDate(),
    },
    "All Time": {
      startDate: null,
      endDate: null,
    },
  };
};

const staticRanges = createStaticRanges([
  ...Object.keys(getRangePresets()).map((key) => ({
    label: key,
    range: () => ({
      ...getRangePresets()[key],
      isPresetRange: true,
      rangeType: key,
    }),
    isSelected(range) {
      const definedRange = this.range();
      if (!definedRange.startDate || !definedRange.endDate) {
        return !range.startDate && !range.endDate;
      }
      return (
        moment(range.startDate).isSame(definedRange.startDate, "day") &&
        moment(range.endDate).isSame(definedRange.endDate, "day")
      );
    },
  })),
]);

const DateRangePicker = ({
  value,
  onChange,
  className = "",
  isRTL,
  customView,
  FooterContent,
  closeOnSelect = true,
}) => {
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dateRangePicker = document.getElementById(
        "custom-date-range-picker"
      );
      if (dateRangePicker && !dateRangePicker.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={className} style={{ position: "relative" }}>
      {customView ? (
        customView({ openCalendar: () => !showPicker && setShowPicker(true) })
      ) : (
        <div
          className="border hover d-flex justify-content-center align-items-center bg-white h-100 px-1 py-0"
          style={{
            borderRadius: 3,
            width: value.startDate && value.endDate ? 200 : 80,
          }}
          onClick={() => !showPicker && setShowPicker(true)}
        >
          <span className="smallFont py-1">
            {value.startDate && value.endDate
              ? `${moment(value.startDate).format("MMM D, YYYY")} - ${moment(
                  value.endDate
                ).format("MMM D, YYYY")}`
              : "All Time"}
          </span>
        </div>
      )}

      {showPicker && (
        <div
          className="border"
          id="custom-date-range-picker"
          style={{
            position: "absolute",
            top: "105%",
            right: isRTL ? -500 : 0,
            zIndex: 999,
          }}
        >
          <ReactDateRangePicker
            editableDateInputs={false}
            onChange={(range) => {
              onChange(range);
              if (range?.selection?.isPresetRange && closeOnSelect) {
                setShowPicker(false);
              }
            }}
            moveRangeOnFirstSelection={true}
            ranges={[value]}
            staticRanges={staticRanges}
            inputRanges={[]}
            showSelectionPreview={true}
            direction="vertical"
            footerContent={FooterContent && FooterContent({ setShowPicker })}
          />
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
