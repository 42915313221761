import React, { useState, useEffect } from "react";
import { FormCheck, FormLabel } from "react-bootstrap";
import useLocalization from "../../hooks/useLocalization";
const MultiCheckboxInput = ({ options, value, onChange, showAllOption }) => {
  const { translate, isRTL } = useLocalization();
  const [checkedOptions, setCheckedOptions] = useState([]);

  const handleCheckboxChange = (optionValue) => {
    const newCheckedOptions = checkedOptions.includes(optionValue)
      ? checkedOptions.filter((value) => value !== optionValue)
      : [...checkedOptions, optionValue];
    setCheckedOptions(newCheckedOptions);
    onChange(newCheckedOptions);
  };

  const handleAllCheckboxChange = () => {
    setCheckedOptions(
      checkedOptions.length === options.length
        ? []
        : options.map((option) => option.value)
    );
    onChange(
      checkedOptions.length === options.length
        ? []
        : options.map((option) => option.value)
    );
  };

  useEffect(() => {
    setCheckedOptions(value || []);
  }, [value]);

  return (
    <div className="d-flex flex-wrap gap-2">
      {options.map((option, index) => (
        <div key={option.value} className="d-flex align-items-center gap-2">
          {showAllOption && index === 0 && (
            <>
              <FormCheck
                id={option.value}
                reverse={isRTL}
                type="checkbox"
                checked={checkedOptions.length === options.length}
                style={{ width: 20, height: 20, fontSize: 20, marginBottom: 8 }}
                onChange={() => handleAllCheckboxChange()}
              />
              <FormLabel className="m-0 mt-1 p-0" htmlFor={option.value}>
                {translate("all") || option.label}
              </FormLabel>
            </>
          )}
          <FormCheck
            id={option.value}
            reverse={isRTL}
            type="checkbox"
            checked={checkedOptions.includes(option.value)}
            onChange={() => handleCheckboxChange(option.value)}
            style={{ width: 20, height: 20, fontSize: 20, marginBottom: 8 }}
          />
          <FormLabel className="m-0 mt-1 p-0" htmlFor={option.value}>
            {translate(option.label) || option.label}
          </FormLabel>
        </div>
      ))}
    </div>
  );
};
export default MultiCheckboxInput;
