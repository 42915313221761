import React, { useMemo, useRef, useState, useEffect } from "react";
import { getPhoneCallTableColumns } from "../../../helpers/dataSheetConstants";
import DataTable from "../../common/data-table/DataTable";
import CallDetailModal from "./CallDetailModal";
import { useScreenWidth } from "../../../hooks/useScreenWidth";
import { makeRESTApiRequests } from "../../../helpers/api";
import { toast } from "react-toastify";
import { ENDPOINTS } from "../../../helpers/constants";

const PhoneCalls = ({ contact = [], columnToHide }) => {
  const containerRef = useRef();
  const { screenWidth } = useScreenWidth();
  const { phoneCalls } = contact;
  const [callDetailModalMeta, setCallDetailModalMeta] = useState(null);
  const [containerPosition, setContainerPosition] = useState({ x: 0, y: 0 });

  const onPlayClick = (callInfo) => {
    setCallDetailModalMeta(callInfo);
  };

  const onDownloadClick = async (call) => {
    const audioLink = call?.recordingLink;

    if (!audioLink) {
      console.error("No audio link available");
      return;
    }

    const audioUrl = `${
      ENDPOINTS.DOWNLOAD_CALL_RECORDING
    }?audioLink=${encodeURIComponent(audioLink)}`;

    try {
      const blob = await makeRESTApiRequests({
        endpoint: audioUrl,
        method: "GET",
        downloadFile: true,
      });

      if (!blob || blob.error) {
        toast.error("Unable to download the call recording or file not found");
        throw new Error(blob?.error || "Download failed");
      }

      const blobURL = window.URL.createObjectURL(blob);

      // Create and trigger download
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = `call-recording-${audioLink.split("/").pop()}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Cleanup Blob URL to prevent memory leaks
      URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.error("Error downloading the call recording:", error);
    }
  };

  const tableColumns = useMemo(
    () => getPhoneCallTableColumns(onPlayClick, columnToHide, onDownloadClick),
    [onPlayClick, columnToHide]
  );

  useEffect(() => {
    if (containerRef.current) {
      const { x, y } = containerRef.current.getBoundingClientRect();
      setContainerPosition({ x, y });
    }
  }, [containerRef]);

  const offSet = useMemo(
    () => ({
      x: screenWidth < 800 ? 10 : screenWidth,
      y: containerPosition.y - 50,
      xOffSet: 10,
      yOffSet: 10,
    }),
    [screenWidth, containerPosition]
  );

  return (
    <div
      ref={containerRef}
      className=""
      style={{ maxHeight: "100vh", overflowY: "auto" }}
    >
      <DataTable
        maxTableHeight={`100vh`}
        rowKey={"_id"}
        columns={tableColumns}
        data={phoneCalls}
        allowSort={false}
        allowFilter={false}
      />
      {callDetailModalMeta && (
        <CallDetailModal
          show={Boolean(callDetailModalMeta)}
          onHide={() => setCallDetailModalMeta(null)}
          callInfo={callDetailModalMeta}
          offSet={offSet}
        />
      )}
    </div>
  );
};

export default PhoneCalls;
