import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { appConfigurationService } from "../../services/appConfigurationService";
import { filterServices } from "../../services/filterServices";
import { statusServices } from "../../services/statusService";
import { tagsServices } from "../../services/tagsServices";
import Loader from "../common/Loader";
import AutoMerge from "./auto-merge";
import CompoundFilter from "./compound-filter";
import Statuses from "./contact-status/Status";
import AppointmentDefaultType from "./event-type/AppointmentDefaultType";
import Tags from "./tags/Tags";
import WhatsappTemplates from "./whatsapp-message-template/WhatsappTemplates";
import EmailTemplates from "./email-templates/EmailTemplates";
import ReportTemplates from "./report-templates/ReportTemplates";
import { PERMISSIONS } from "../../helpers/permissions";
import useAuth from "../../hooks/useAuth";
const AppConfiguration = () => {
  const { checkNestedPermission } = useAuth();
  const { templateId } = useQueryParams();
  const { screenHeight } = useScreenWidth();
  const [shouldScroll, setShouldScroll] = useState(false);
  const [appointmentDefaultTypes, setAppointmentDefaultTypes] = useState([]);
  const [fetchingAppointmentTypes, setFetchingAppointmentTypes] =
    useState(false);
  const [tags, setTags] = useState([]);
  const [fetchingTags, setFetchingTags] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [fetchingStatuses, setFetchingStatuses] = useState(false);

  const [filterData, setFilterData] = useState([]);
  const [fetchingFilter, setFetchingFilter] = useState(false);

  const containerRef = useRef(null);

  const fetchAppointmentDefaultTypes = async () => {
    try {
      setFetchingAppointmentTypes(true);
      const { response, error } =
        await appConfigurationService.getAppointmentDefalutType();
      if (error) {
        return toast.error(error);
      }
      setAppointmentDefaultTypes(response);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingAppointmentTypes(false);
    }
  };

  const fetchTags = async () => {
    try {
      setFetchingTags(true);
      const { response, error } = await tagsServices.getTags();
      if (error) {
        return toast.error(error);
      }
      setTags(response);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingTags(false);
    }
  };

  const fetchStatuses = async () => {
    try {
      setFetchingStatuses(true);
      const { response, error } = await statusServices.getStatuses();
      if (error) {
        return toast.error(error);
      }
      setStatuses(response);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingStatuses(false);
    }
  };

  const fetchPresetFilters = async () => {
    try {
      setFetchingFilter(true);
      const { response, error } = await filterServices.getFilters();
      if (response) {
        setFilterData(response);
      }
      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingFilter(false);
    }
  };

  const canViewFilters = useMemo(
    () => checkNestedPermission([PERMISSIONS.filters.VIEW_ALL_FILTERS]),
    []
  );

  const canViewApointmentDefaults = useMemo(
    () =>
      checkNestedPermission([
        PERMISSIONS.appointment_defaults.VIEW_ALL_APPOINTMENT_DEFAULTS,
      ]),
    []
  );

  const canViewTags = useMemo(
    () => checkNestedPermission([PERMISSIONS.tags.VIEW_ALL_TAGS]),
    []
  );

  const canViewStatus = useMemo(
    () => checkNestedPermission([PERMISSIONS.status.VIEW_ALL_STATUS]),
    []
  );

  const canViewControlSettings = useMemo(
    () => checkNestedPermission([PERMISSIONS.app_choice.VIEW_ALL_APP_CHOICE]),
    []
  );

  useEffect(() => {
    if (canViewFilters) {
      fetchPresetFilters();
    }
    if (canViewApointmentDefaults) {
      fetchAppointmentDefaultTypes();
    }
    if (canViewTags) {
      fetchTags();
    }
    if (canViewStatus) {
      fetchStatuses();
    }
  }, [canViewFilters, canViewApointmentDefaults, canViewStatus, canViewTags]);

  useEffect(() => {
    if (templateId && shouldScroll) {
      const toBeScrolledTo = document.querySelector(
        `[data-template-id="${templateId}"]`
      );
      if (toBeScrolledTo) {
        setTimeout(() => {
          toBeScrolledTo.parentElement.parentElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });

          setShouldScroll(false);
        }, 200);
      }
    }
  }, [templateId, shouldScroll]);

  return (
    <div
      style={{
        height: screenHeight - 60,
      }}
      className="overflow-auto py-3"
      ref={containerRef}
    >
      {fetchingAppointmentTypes ||
      fetchingFilter ||
      fetchingTags ||
      fetchingStatuses ? (
        <Loader />
      ) : (
        <Row className="m-0">
          {canViewFilters && (
            <Col sm={12} md={6} lg={6} className="p-2">
              <CompoundFilter
                filterData={filterData}
                setFilterData={setFilterData}
                tags={tags}
              />
            </Col>
          )}
          {canViewTags && (
            <Col sm={12} md={6} lg={6} className="p-2">
              <Tags tags={tags} setTags={setTags} />
            </Col>
          )}
          {canViewApointmentDefaults && (
            <Col sm={6} className="p-2">
              <AppointmentDefaultType
                appointmentDefaultTypes={appointmentDefaultTypes}
                setAppointmentDefaultTypes={setAppointmentDefaultTypes}
              />
            </Col>
          )}
          {checkNestedPermission([
            PERMISSIONS.email_templates.VIEW_ALL_TEMPLATES,
          ]) && (
            <Col sm={6} className="p-2">
              <EmailTemplates className="" />
            </Col>
          )}
          {checkNestedPermission([
            PERMISSIONS.whatsapp_templates.VIEW_ALL_TEMPLATES,
          ]) && (
            <Col sm={12} className="p-2">
              <WhatsappTemplates setShouldScroll={setShouldScroll} />
            </Col>
          )}
          {checkNestedPermission([
            PERMISSIONS.report_templates.VIEW_ALL_TEMPLATES,
          ]) && (
            <Col sm={12} md={6} lg={6} className="p-2">
              <ReportTemplates />
            </Col>
          )}
          {canViewStatus && (
            <Col sm={12} md={6} lg={6} className="p-2">
              <Statuses
                className={"h-100"}
                statuses={statuses}
                setStatuses={setStatuses}
              />
            </Col>
          )}
          {canViewControlSettings && (
            <Col sm={12} md={6} lg={6} className="p-2">
              <AutoMerge />
            </Col>
          )}
        </Row>
      )}
    </div>
  );
};

export default AppConfiguration;
