import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { getInboundTableColumns } from "../../../helpers/dataSheetConstants";
import { miscService } from "../../../services/miscService";
import DataTable from "../../common/data-table/DataTable";
import InboundDetailsModal from "./InboundDetailsModal";
import { Button, Container } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";
import useLocalization from "../../../hooks/useLocalization";

const Inbounds = ({ contact, height = "150px", onSubmit }) => {
  const [inboundSourceDetailsToShow, setInboundSourceDetailsToShow] =
    useState(null);
  const { translate } = useLocalization();
  const [isAdd, setIsAdd] = useState(false);

  const onDetailsClick = (inbound) => {
    setInboundSourceDetailsToShow(inbound?.sourceDetails);
  };

  const onCampaignClick = async (inbound) => {
    const campaignId = inbound?.sourceDetails?.campaignId;
    const adSetId = inbound?.sourceDetails?.adSetId;
    const adId = inbound?.sourceDetails?.adId;

    if (!campaignId || !adSetId || !adId) {
      return toast.error("No Ad Id found in the source details");
    }

    const { response: ads = [], error } = await miscService.getFacebookAds(
      null,
      {
        campaignId,
        adSetId,
      }
    );

    if (error) {
      return toast.error(error);
    }

    const adPreviewLink = ads.find(
      (a) => a.id === adId
    )?.preview_shareable_link;

    if (!adPreviewLink) {
      return toast.error("Preview link not found");
    }

    window.open(adPreviewLink, "_blank");
  };

  const tableColumns = useMemo(
    () =>
      getInboundTableColumns({
        onDetailsClick,
        onCampaignClick,
        columnToHide: ["contact", "email"],
      }),
    [onDetailsClick]
  );

  const sortedInbounds = useMemo(
    () =>
      contact?.inbounds?.sort(
        (i1, i2) => new Date(i2.date) - new Date(i1.date)
      ) || [],
    [contact]
  );

  return (
    <>
      <Container fluid className={`d-flex flex-column p-0 m-0 h-100`}>
        <div className={`flex-grow-1`}>
          <DataTable
            maxTableHeight={height}
            rowKey={"_id"}
            columns={tableColumns}
            data={sortedInbounds}
            allowSort={false}
            allowFilter={false}
          />
        </div>

        <div
          className="d-flex align-items-center py-1 w-100 bg-white border-top"
          style={{ position: "absolute", bottom: 0, left: 0 }}
        >
          <Button
            size="sm"
            onClick={() => setIsAdd(true)}
            variant="success"
            className="align-items-center d-flex text-white py-0 mx-1"
          >
            <PlusCircle size={10} className="mx-1" />
            {translate("add_new_inbound")}
          </Button>
        </div>
      </Container>

      {sortedInbounds.length > 0 && (
        <InboundDetailsModal
          show={Boolean(inboundSourceDetailsToShow) || isAdd}
          onHide={() =>
            isAdd ? setIsAdd(false) : setInboundSourceDetailsToShow(null)
          }
          inbound={inboundSourceDetailsToShow}
          isAdd={isAdd}
          onSubmit={(contact) => {
            onSubmit(contact);
            setIsAdd(false);
          }}
        />
      )}
    </>
  );
};

export default Inbounds;
