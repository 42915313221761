import classnames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Plus, Trash } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { DEFAULT_DATE_TIME_FORMAT_NON_MOMENT } from "../../helpers/constants";
import useLocalization from "../../hooks/useLocalization";
import UnderlineButton from "../common/UnderlineButton";
import Switch from "react-switch";

const StepCard = React.memo(
  ({
    isStart,
    isEnd,
    stage,
    accomplished,
    onProductionStageChange,
    productionStageHistory,
    setFormValues,
  }) => {
    const initialHistoryUpdateDate = useMemo(() => {
      const filteredStage = productionStageHistory?.find(
        (s) => s?.stage === stage?._id
      );

      return filteredStage?.updateDate
        ? new Date(filteredStage.updateDate)
        : null;
    }, [stage, productionStageHistory]);

    const [selectedDate, setSelectedDate] = useState(initialHistoryUpdateDate);

    const handleDateChange = (date) => {
      setSelectedDate(date);
      setFormValues((prev) => {
        const stageExists = prev.productionStageHistory.some(
          (p) => p.stage === stage?._id
        );
        if (stageExists) {
          return {
            ...prev,
            productionStageHistory: prev.productionStageHistory.map((p) => {
              if (p.stage === stage?._id) {
                return { ...p, updateDate: date };
              }
              return p;
            }),
          };
        } else {
          return {
            ...prev,
            productionStageHistory: [
              ...prev.productionStageHistory,
              { stage: stage?._id, updateDate: date.toISOString() },
            ],
          };
        }
      });
    };

    const handleAddNewDate = () => {
      setSelectedDate(new Date());
      handleDateChange(new Date());
    };

    const handleRemoveDate = () => {
      setSelectedDate(null);
      setFormValues((prev) => ({
        ...prev,
        productionStageHistory: prev.productionStageHistory.map((p) => {
          if (p.stage === stage?._id) {
            return { ...p, updateDate: null };
          }
          return p;
        }),
      }));
    };

    const stepClass = classnames({
      "bg-dark-green text-white": accomplished,
      "bg-gray border-dark-green": !accomplished,
      "p-relative border border-light hover": true,
    });

    return (
      <OverlayTrigger
        delay={{ hide: 250, show: 300 }}
        overlay={(props) => (
          <Tooltip {...props} width={1000}>
            <h6 className="mb-0 smallFont">{stage?.step}</h6>
          </Tooltip>
        )}
        placement={"top"}
      >
        <div
          onClick={() => onProductionStageChange(stage?._id)}
          className={stepClass}
          style={{
            height: 22,
            width: 22,
            borderRadius: "50%",
            marginLeft: isStart ? 8 : -10,
          }}
        >
          <div
            className="text-dark"
            style={{
              position: "absolute",
              maxWidth: "120px",
              minWidth: 50,
              right: isEnd ? 0 : undefined,
              left: isStart ? 8 : undefined,
              marginTop: 20,
            }}
          >
            <h6
              style={{ marginBottom: 2 }}
              className="smallFont truncate fw-bold"
            >
              {stage?.step || ""}
            </h6>
            {accomplished && (
              <div
                style={{ height: 15 }}
                className="tiny d-flex gap-1 align-items-center"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {selectedDate ? (
                  <>
                    <DatePicker
                      className={`relative m-0 text-dark tiny truncate`}
                      selected={selectedDate}
                      dateFormat={DEFAULT_DATE_TIME_FORMAT_NON_MOMENT}
                      onChange={handleDateChange}
                      showYearDropdown
                      showMonthDropdown
                      showTimeInput
                      timeIntervals={15}
                      dropdownMode="scroll"
                      timeFormat="HH:mm"
                      clearButtonClassName="text-dark"
                      portalId="root"
                      popperClassName="datepickerPopperClassName"
                      ref={(ref) => {
                        if (ref) {
                          const input = ref.input;
                          if (input) {
                            input.style.setProperty(
                              "background",
                              "transparent",
                              "important"
                            );
                            input.style.setProperty(
                              "width",
                              "85px",
                              "important"
                            );
                            input.style.setProperty(
                              "border",
                              "none",
                              "important"
                            );
                            input.style.setProperty(
                              "color",
                              "#000",
                              "important"
                            );
                            input.style.setProperty(
                              "outline",
                              "none",
                              "important"
                            );
                            input.style.setProperty(
                              "box-shadow",
                              "none",
                              "important"
                            );
                          }
                        }
                      }}
                    />
                    {selectedDate ? (
                      <Trash
                        className="text-danger hover-light rounded"
                        size={10}
                        onClick={handleRemoveDate}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <UnderlineButton
                    Icon={Plus}
                    text={"Date"}
                    variant="success"
                    onClick={handleAddNewDate}
                    className="px-0"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </OverlayTrigger>
    );
  }
);

const ProductionStageProgress = ({
  className,
  label,
  productionStages,
  productionStageHistory,
  currentProductionStage,
  initialProductionStage,
  onProductionStageChange,
  disabled,
  updatingHistoryDate,
  onUpdateDate,
  unit,
  setFormValues,
  products,
  updatingContractUnit,
  index,
}) => {
  const [stepPositions, setStepPositions] = useState([]);

  const { translate, isRTL } = useLocalization();

  const [showActionButtons, setShowActionButtons] = useState({});
  const [isDatePickerOpen, setIsDatePickerOpen] = useState({});
  const initialQuittedValue = useMemo(() => {
    return unit.quittedDate ? new Date(unit.quittedDate) : new Date();
  }, [unit.quittedDate]);
  const initialFollowUpValue = useMemo(() => {
    const currentDate = new Date();
    const oneMonthAfter = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate()
    );

    if (oneMonthAfter.getMonth() === currentDate.getMonth()) {
      oneMonthAfter.setDate(0);
    }

    return unit.followupDate ? new Date(unit.followupDate) : oneMonthAfter;
  }, [unit.followupDate, unit.isFloating]);

  const [isClear, setIsClear] = useState(false);

  function calculatePositions(productionStages) {
    const numStages = productionStages?.length;
    const step = 100 / (numStages - 1);
    const positions = [];
    for (let i = 0; i < numStages; i++) {
      positions.push(i === 0 ? 0.1 : step * i);
    }
    return positions;
  }

  const percent = useMemo(() => {
    const valuePosition = productionStages?.findIndex(
      (s) => s?._id === currentProductionStage
    );
    return stepPositions[valuePosition] ?? 0;
  }, [currentProductionStage, productionStages, stepPositions]);

  useEffect(() => {
    if (productionStages?.length > 0) {
      setStepPositions(calculatePositions(productionStages));
    } else {
      setStepPositions([]);
    }
  }, [productionStages]);

  const sortedProductionStageHistory = useMemo(() => {
    return [...(productionStageHistory || [])].sort(
      (a, b) => new Date(a.updateDate) - new Date(b.updateDate)
    );
  }, [productionStageHistory]);

  const handleProductChange = (field, value) => {
    const updatedValues = products.map((item, idx) => {
      if (idx !== index) return item;

      let updatedProduct = { ...item, [field]: value };

      return updatedProduct;
    });

    setFormValues((prev) => ({
      ...prev,
      units: updatedValues,
    }));
  };

  const handleDateChange = (dateField, field, date) => {
    setShowActionButtons({ ...showActionButtons, [field]: true });
    handleProductChange(dateField, date?.toISOString());
    setIsDatePickerOpen({ ...isDatePickerOpen, [field]: false });
    setIsClear({ ...isClear, [field]: false });
  };

  const handleAddNewDate = (dateField, field) => {
    setShowActionButtons({ ...showActionButtons, [field]: true });
    if (dateField === "quittedDate") {
      handleProductChange(dateField, initialQuittedValue);
    } else if (dateField === "followupDate") {
      handleProductChange(dateField, initialFollowUpValue);
    }
    setIsDatePickerOpen({ ...isDatePickerOpen, [field]: true });
  };

  const handleRemoveDate = (dateField, field) => {
    handleProductChange(dateField, null);
    setIsDatePickerOpen({ ...isDatePickerOpen, [field]: false });
    setShowActionButtons({ ...showActionButtons, [field]: false });
    setIsClear({ ...isClear, [field]: true });
  };

  const handleSwitchChange = (dateField, field, checked) => {
    if (checked) {
      setShowActionButtons({ ...showActionButtons, [field]: true });
      const dateValue =
        dateField === "quittedDate"
          ? initialQuittedValue
          : initialFollowUpValue;
      handleProductChange(dateField, dateValue.toISOString());
      handleProductChange(field, true);
      setIsDatePickerOpen({ ...isDatePickerOpen, [field]: true });
    } else {
      handleProductChange(dateField, null);
      handleProductChange(field, false);
      setShowActionButtons({ ...showActionButtons, [field]: false });
      setIsDatePickerOpen({ ...isDatePickerOpen, [field]: false });
    }
  };

  const renderSwitchAndDatePicker = ({
    field,
    dateField,
    label,
    value,
    datePickerValue,
    datePickerLabel,
  }) => {
    const shouldShowDatePicker = datePickerValue || isClear[field];

    return (
      <>
        <Col
          className="m-0 d-flex align-items-end justify-content-start gap-1"
          style={{
            transition: "transform 1s ease, opacity 1s ease",
            transform: value
              ? "translateX(0)"
              : datePickerValue
              ? `translateX(${isRTL ? "-120%" : "120%"})`
              : `translateX(${isRTL ? "-80%" : "80%"})`,
            whiteSpace: "nowrap",
          }}
        >
          <h3 className="smallFont fw-bold mb-0">{translate(label)}</h3>
          <Switch
            onChange={(checked) => {
              handleSwitchChange(dateField, field, checked);
            }}
            checked={value}
            height={15}
            disabled={disabled}
            width={40}
            offHandleColor="#d1e6cc"
            onHandleColor="#d1e6cc"
            className="mt-1"
          />
        </Col>
        <Col
          className={`m-0 d-flex align-items-end justify-content-center gap-2 py-1 ${
            isDatePickerOpen[field] ? "px-4" : ""
          }`}
          style={{
            transition: "transform 1s ease, opacity 1s ease",
            transform: value
              ? "translateX(0)"
              : `translateX(${isRTL ? "-100%" : "100%"})`,
            opacity: value ? 1 : 0,
            pointerEvents: value ? "auto" : "none",
            whiteSpace: "nowrap", // To avoid line breaking during transition
          }}
        >
          <div
            className="tiny d-flex gap-1 align-items-center"
            style={{
              height: 15,
              transition: "transform 1s ease, opacity 1s ease",
              transform: value
                ? "translateX(0)"
                : `translateX(${isRTL ? "-100%" : "100%"})`,
              opacity: value ? 1 : 0,
              pointerEvents: value ? "auto" : "none",
              whiteSpace: "nowrap",
            }}
          >
            {shouldShowDatePicker ? (
              <div className="mx-1 d-flex flex-row justify-content-between align-items-center">
                <div className="relative">
                  <label style={{ position: "absolute", top: -20 }}>
                    {translate(datePickerLabel)}
                  </label>

                  <DatePicker
                    className={`relative m-0 text-dark smallFont truncate pt-1`}
                    selected={datePickerValue}
                    dateFormat={"dd/MM/yyyy"}
                    onChange={(date) =>
                      handleDateChange(dateField, field, date)
                    }
                    onInputClick={() =>
                      setIsDatePickerOpen({
                        ...isDatePickerOpen,
                        [field]: true,
                      })
                    }
                    showYearDropdown
                    showMonthDropdown
                    showTimeInput={false}
                    timeIntervals={15}
                    dropdownMode="scroll"
                    clearButtonClassName="text-danger"
                    portalId="root"
                    popperClassName="datepickerPopperClassName"
                    placeholderText={"Select Date..."}
                    ref={(ref) => {
                      if (ref) {
                        const input = ref.input;
                        if (input) {
                          input.style.setProperty(
                            "background",
                            "transparent",
                            "important"
                          );
                          input.style.setProperty(
                            "width",
                            showActionButtons[field] ? "75px" : "90px",
                            "important"
                          );
                          input.style.setProperty(
                            "border",
                            "none",
                            "important"
                          );
                          input.style.setProperty("color", "#000", "important");
                          input.style.setProperty(
                            "outline",
                            "none",
                            "important"
                          );
                          input.style.setProperty(
                            "box-shadow",
                            "none",
                            "important"
                          );
                        }
                      }
                    }}
                  />
                </div>

                {datePickerValue && (
                  <div className="d-flex gap-1 align-items-center">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className="tooltip-danger" id="edit-tooltip">
                          {translate("delete")}
                        </Tooltip>
                      }
                    >
                      <Trash
                        className="text-danger hover-light rounded"
                        size={12}
                        onClick={() => handleRemoveDate(dateField, field)}
                      />
                    </OverlayTrigger>
                  </div>
                )}
              </div>
            ) : (
              <UnderlineButton
                Icon={Plus}
                text={unit?.[dateField] ? "" : translate(datePickerLabel)}
                variant="dark"
                onClick={() => handleAddNewDate(dateField, field)}
                className="px-0"
                fontSize="smallFont"
              />
            )}
          </div>
        </Col>
      </>
    );
  };

  useEffect(() => {
    if (unit.isFloating) {
      handleProductChange("followupDate", initialFollowUpValue);
    }
  }, [unit.isFloating]);

  useEffect(() => {
    if (unit.isQuitted) {
      onProductionStageChange(currentProductionStage);
    }
    if (unit.isQuitted && !unit.hasOwnProperty("quittedDate")) {
      handleProductChange("quittedDate", initialQuittedValue);
    }
  }, [unit.isQuitted]);

  return (
    <div className={`${className}`} style={{ height: "70px" }}>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h6 className="mid">{label}</h6>
        <div
          className="d-flex align-items-center flex-end gap-2"
          style={{ position: "relative" }}
        >
          {renderSwitchAndDatePicker({
            field: "isQuitted",
            dateField: "quittedDate",
            label: "quitted",
            datePickerLabel: "quit_date",
            value: unit?.isQuitted,
            datePickerValue: unit?.quittedDate
              ? new Date(unit?.quittedDate)
              : null,
          })}

          {renderSwitchAndDatePicker({
            field: "isFloating",
            dateField: "followupDate",
            datePickerLabel: "unfloating_date",
            label: "show_floating",
            value: unit?.isFloating,
            datePickerValue: unit?.followupDate
              ? new Date(unit?.followupDate)
              : null,
          })}
        </div>
      </div>
      <ProgressBar
        percent={percent}
        filledBackground="#28a745"
        stepPositions={stepPositions}
        height={20}
        disabled={disabled}
      >
        {stepPositions?.map((_, index) => {
          let stage = productionStages?.[index];
          return (
            <Step key={index}>
              {({ accomplished }) => (
                <StepCard
                  isStart={index === 0}
                  isEnd={stepPositions?.length - 1 === index}
                  stage={stage}
                  productionStageHistory={sortedProductionStageHistory}
                  accomplished={accomplished}
                  onProductionStageChange={(change) => {
                    if (!disabled) onProductionStageChange(change);
                  }}
                  setFormValues={setFormValues}
                />
              )}
            </Step>
          );
        })}
      </ProgressBar>
    </div>
  );
};

export default ProductionStageProgress;
