import { snakeCase } from "lodash";
import React, { useMemo } from "react";
import useAppChoices from "../../hooks/useAppChoices";
import useLocalization from "../../hooks/useLocalization";
import AppTable from "../common/app-table/AppTable";
import moment from "moment";
import { DEFAULT_DATE_TIME_FORMAT } from "../../helpers/constants";
import { Badge, Button } from "react-bootstrap";
import { Arrow90degLeft, Arrow90degRight } from "react-bootstrap-icons";

// Helper function to filter out specific fields like "_id", "createdAt", "__v"

const typeToValue = ({ users = [], groups = [] }) => ({
  phoneNumbers: (value) => value.phoneNumbers?.join(", "),
  emails: (value) => value.emails?.join(", "),

  spousePhoneNumbers: (value) => value.spousePhoneNumbers?.join(", "),
  spouseEmails: (value) => value.spouseEmails?.join(", "),
  inbounds: (value) => value.inbounds?.length,

  surveySent: (value) => (value.surveySent ? "Yes" : "No"),
  surveyCompleted: (value) => (value.surveyCompleted ? "Yes" : "No"),
  followUpDate: (value) =>
    moment(value.followUpDate).format(DEFAULT_DATE_TIME_FORMAT),
  comments: (value) => value.comments?.length,
  phoneCalls: (value) => value.phoneCalls?.length,
  tags: (value) => value.tags?.join(", "),
  salesperson: (value) =>
    value.salesperson
      ?.map((s) => users.find((u) => u._id === s)?.name)
      .join(", "),
  financialStrategist: (value) =>
    value.financialStrategist
      ?.map((s) => users.find((u) => u._id === s)?.name)
      .join(", "),
  realEstateAnalyst: (value) =>
    value.realEstateAnalyst
      ?.map((s) => users.find((u) => u._id === s)?.name)
      .join(", "),
  assignedTo: (value) =>
    value.assignedTo
      ?.map((s) => users.find((u) => u._id === s)?.name)
      .join(", "),
  groups: (value) =>
    value.groups?.map((s) => (
      <Badge bg="dark" className="mx-1">
        {groups.find((u) => u._id === s)?.name}
      </Badge>
    )),
  contracts: (value) => value.contracts.length,
  archived: (value) => (value.archived ? "Yes" : "No"),
});

const filterData = (data) => {
  const { _id, createdAt, __v, statusCount, updatedAt, ...filteredData } =
    data || {};
  return filteredData;
};

// Helper function to render the object as table rows
const renderTableRows = ({
  translate,
  users,
  groups,
  beforeData,
  afterData,
}) => {
  const beforeFiltered = filterData(beforeData);
  const afterFiltered = filterData(afterData);

  const uniqueKeys = new Set([
    ...Object.keys(beforeFiltered),
    ...Object.keys(afterFiltered),
  ]);

  return Array.from(uniqueKeys).map((key) => {
    const beforeValue = typeToValue({ users, groups })[key]
      ? typeToValue({ users, groups })[key](beforeData)
      : beforeData[key];
    const afterValue = typeToValue({ users, groups })[key]
      ? typeToValue({ users, groups })[key](afterData)
      : afterData[key];

    return (
      <tr key={key}>
        <td className="p-1">
          {translate(snakeCase(key))?.replace("({count})", "") || key}
        </td>{" "}
        <td className="p-1">{beforeValue}</td> {/* Before value */}
        <td className="p-1">{afterValue}</td> {/* After value */}
      </tr>
    );
  });
};

const AtomicAction = ({ action, onActionClick, log }) => {
  const { translate } = useLocalization();
  const users = useAppChoices("users");
  const products = useAppChoices("products");
  const groups = useAppChoices("groups");

  const { before, after, type, collection, documentId } = useMemo(
    () => action,
    [action]
  );

  const documentName = useMemo(() => {
    if (collection === "Contact") {
      const { firstName = "", lastName = "" } =
        type === "DELETE" ? before || {} : documentId || {};
      return `${firstName} ${lastName}`.trim();
    }

    if (collection === "Contract") {
      const product = products?.find((p) => p._id === documentId?.product);
      const description = product?.description || "";
      const proposalQuote = documentId?.proposalQuote || "";

      return `${description}, ${proposalQuote}`.trim();
    }

    return "";
  }, [collection, products, type, before, documentId]);

  return (
    <div className="border rounded p-1 mb-1">
      <div className="w-100 d-flex align-items-center justify-content-between p-1">
        <h6 className="mb-0 ">
          <span className="fw-bold">{documentName}</span> was{" "}
          <span className="fw-bold">{type.toLowerCase()}d</span>
        </h6>
        {Boolean(action.undone) ? (
          <Button
            className="d-flex align-items-center gap-2 justify-content-center"
            size="sm"
            variant="outline-primary"
            onClick={(e) => {
              e.stopPropagation();
              onActionClick({
                actionId: action._id,
                logId: log._id,
                action: "redo",
                log,
              });
            }}
          >
            <Arrow90degRight className="" size={10} />{" "}
            <h6 className="mb-0 tiny">Redo</h6>
          </Button>
        ) : (
          <Button
            className="d-flex align-items-center gap-2 justify-content-center"
            size="sm"
            variant="outline-primary"
            onClick={(e) => {
              e.stopPropagation();
              onActionClick({
                actionId: action._id,
                logId: log._id,
                action: "undo",
                log,
              });
            }}
          >
            <Arrow90degLeft className="" size={10} />{" "}
            <h6 className="mb-0 tiny">{translate("undo")}</h6>
          </Button>
        )}
      </div>
      {type !== "DELETE" && (
        <AppTable className={"smallFont mt-0 mb-2"}>
          <thead>
            <tr>
              <th style={{ width: 150 }} className="bg-dark text-white p-1">
                {translate("key")}
              </th>
              <th style={{ width: 200 }} className="bg-dark text-white p-1">
                {translate("before")}
              </th>
              <th style={{ width: 200 }} className="bg-dark text-white p-1">
                {translate("after")}
              </th>
            </tr>
          </thead>
          <tbody>
            {renderTableRows({
              translate,
              users,
              groups,
              beforeData: before,
              afterData: after,
            })}
          </tbody>
        </AppTable>
      )}
    </div>
  );
};

export default AtomicAction;
