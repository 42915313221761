import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { capitalize, snakeCase } from "lodash";
import React, { useMemo, useRef } from "react";
import { Bar, getDatasetAtEvent, getElementAtEvent } from "react-chartjs-2";
import useLocalization from "../../../hooks/useLocalization";
import { commonChartConfig } from "../../../helpers/constants";
import { getDataFromChartDatasetIndex } from "../../../helpers/global";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Barchart = ({
  chartName,
  data,
  isACard = true,
  renderExtraInfo,
  marketingStatsData,
  onClick,
}) => {
  const { translate, isRTL } = useLocalization();
  const chartRef = useRef(null);

  const commonConfig = useMemo(() => commonChartConfig(isRTL), [isRTL]);

  const onBarClick = (event) => {
    const dataset = getElementAtEvent(chartRef.current, event);
    if (dataset.length === 0) return;
    const value = getDataFromChartDatasetIndex(
      marketingStatsData,
      dataset[0].index
    );
    onClick && onClick(value);
  };

  return (
    <div
      style={{ height: 392, width: "100%" }}
      className={isACard ? "custom-card" : ""}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="xxlarge fw-bold flex-grow-1">
          {typeof chartName === "string"
            ? translate(snakeCase(chartName)) || chartName
            : chartName?.map((name) => translate(snakeCase(name)))?.join(", ")}
        </h6>
        {renderExtraInfo && renderExtraInfo()}
      </div>
      <Bar
        height={320}
        className="w-100 pb-5"
        ref={chartRef}
        onClick={onBarClick}
        options={{
          ...commonConfig,
          plugins: {
            ...commonConfig.plugins,
            datalabels: {
              display: false,
            },
            legend: {
              display: false,
            },
          },
        }}
        data={data}
      />
    </div>
  );
};

export default Barchart;
