import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { PERMISSIONS } from "../helpers/permissions";
import { whatsappCampaignService } from "../services/whatsappCampaignService";
import useAuth from "./useAuth";

const useWhatsappCampaigns = ({ source, sourceId, exceptionId }) => {
  const { checkNestedPermission } = useAuth();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoadingWhatsappCampaign, setIsLoadingWhatsappCampaign] =
    useState(false);
  const [campaigns, setCampaigns] = useState([]);

  const refetchCampaignCount = useRef(0);

  const refetchCampaigns = () => {
    refetchCampaignCount.current++;
  };

  const fetchCampaigns = async () => {
    try {
      const requestBody = {
        filter: [
          {
            key: "sourceType",
            value: source,
          },
          {
            key: "sourceId",
            value: sourceId,
          },
          {
            key: "exceptionId",
            value: exceptionId || null,
          },
        ],
      };

      setIsLoadingWhatsappCampaign(true);
      const { response, error } =
        await whatsappCampaignService.getWhatsappCampaigns(requestBody);

      if (error) {
        toast.error(error);
        return;
      }

      const { results } = response;
      setCampaigns(
        results.flatMap((r) =>
          r.schedules.map((schedule) => ({
            ...schedule,
            campaignId: r._id,
            createdAt: r.createdAt,
          }))
        )
      );
    } catch (error) {
      console.error(error);
      toast.error(
        "An unexpected error occurred while fetching WhatsApp campaigns."
      );
    } finally {
      setIsLoadingWhatsappCampaign(false);
      setIsInitialLoad(false);
    }
  };

  useEffect(() => {
    if (
      checkNestedPermission([
        PERMISSIONS.whatsapp_campaigns.VIEW_ALL_CAMPAIGNS,
      ]) &&
      sourceId
    ) {
      fetchCampaigns();
    }
  }, [source, sourceId, exceptionId, refetchCampaignCount.current]);

  return {
    campaigns,
    isLoadingWhatsappCampaign,
    isInitialLoad,
    refetchCampaigns,
  };
};

export default useWhatsappCampaigns;
