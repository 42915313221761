import { useEffect, useMemo, useState } from "react";
import { Button, FormControl, FormGroup } from "react-bootstrap";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";
import QueryBuilder from "../common/query-builder";
import useAuth from "../../hooks/useAuth";
import { PERMISSIONS } from "../../helpers/permissions";

function convertToValidFormatWithId(query) {
  // Check if the query is an object and has a rules property
  if (typeof query === "object" && query.hasOwnProperty("rules")) {
    // Iterate through each rule in the rules array
    query.rules.forEach((rule) => {
      // Check if the rule has an _id property
      if (rule.hasOwnProperty("_id")) {
        // Rename _id to id
        rule.id = rule._id;
        delete rule._id;
      }
      // If the rule has nested rules, recursively update them
      if (rule.hasOwnProperty("rules")) {
        convertToValidFormatWithId(rule);
      }
    });
  }
  return query;
}

const defaultValue = {
  filterParsed: {},
  filter: {
    combinator: "and",
    rules: [],
  },
};

const AddEditFilterForm = ({
  fields,
  disabled,
  initialValues,
  showProgress,
  onSubmit,
  isEditmode,
}) => {
  const { checkNestedPermission } = useAuth();
  const [filterData, setFilterData] = useState(defaultValue);
  const [errorMsg, setErrorMsg] = useState("");
  const { translate } = useLocalization();

  const submitForm = (type) => {
    if (type === "submit" && !filterData?.name) {
      setErrorMsg("Filter Name is required");
      return;
    }
    if (type === "submit" && onSubmit) {
      onSubmit(filterData);
    }
  };

  useEffect(() => {
    setFilterData(initialValues ? initialValues : defaultValue);
    setErrorMsg("");
  }, [initialValues]);

  const hasCreateOrUpdatePermission = useMemo(() => {
    return checkNestedPermission(
      isEditmode
        ? [PERMISSIONS.filters.UPDATE_ALL_FILTERS]
        : [PERMISSIONS.filters.CREATE_ALL_FILTERS]
    );
  }, [isEditmode]);

  return (
    <div>
      <div className="d-flex justify-content-start mt-2 mb-2">
        <FormGroup className="me-2">
          <FormControl
            className={`form-control-sm smallFont ${
              errorMsg && "border-danger"
            }`}
            disabled={disabled || !hasCreateOrUpdatePermission}
            value={filterData.name}
            placeholder={translate("filter name")}
            onChange={(e) =>
              setFilterData({ ...filterData, name: e.target.value })
            }
          />
        </FormGroup>
      </div>
      <QueryBuilder
        disabled={disabled || !hasCreateOrUpdatePermission}
        fields={fields}
        query={convertToValidFormatWithId(filterData?.filter)}
        onChange={(query) => setFilterData({ ...filterData, ...query })}
      />
      {showProgress && (
        <HorizontalProgress text={`${translate("please_wait")}`} />
      )}
      {errorMsg && (
        <h6 className="smallFont text-end my-1 text-danger">- {errorMsg}</h6>
      )}

      <div className="d-flex justify-content-end mt-2">
        {hasCreateOrUpdatePermission && (
          <div className="d-flex align-items-center">
            <Button
              className={`text-white  ${errorMsg && "border-danger"}`}
              variant="primary"
              onClick={() => submitForm("submit")}
              size="sm"
              disabled={showProgress || disabled}
            >
              {translate("save")}
            </Button>{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddEditFilterForm;
