import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { Button, FormGroup, Table } from "react-bootstrap";
import { Person, Plus, Trash } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import {
  commissionTypes,
  DEFAULT_DATE_TIME_FORMAT_NON_MOMENT,
} from "../../helpers/constants";
import useAppChoices from "../../hooks/useAppChoices";
import useLocalization from "../../hooks/useLocalization";
import CurrencyInput from "../common/CurrencyInput";
import CustomMultiSelect from "../common/CustomMultiSelect";
// Yup validation schema
const validationSchema = Yup.object().shape({
  commissions: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Type is required"),
      assignedTo: Yup.string().required("Assigned to is required"),
      commission: Yup.number().required("Commission is required"),
      commissionPaidDate: Yup.date().nullable(),
    })
  ),
});

const DealComissionForm = ({ contract, onChange, disabled, referredBy }) => {
  const { translate, isRTL } = useLocalization();

  const users = useAppChoices("users");

  return (
    <Formik
      initialValues={{
        commissions: contract?.commissions || [],
      }}
      enableReinitialize
      validateOnChange
      validationSchema={validationSchema}
      validateOnBlur
      validateOnMount
    >
      {({ submitForm, values, setFieldValue, setFieldError }) => {
        useEffect(() => {
          onChange(values);
        }, [values]);

        return (
          <Form noValidate className="">
            <FormGroup className="">
              <Table bordered striped className="mb-0 w-100">
                <thead>
                  <tr className="mid">
                    <th>{translate("type")}</th>
                    <th>{translate("assigned_to")}</th>
                    <th style={{ width: 100 }}>{translate("amount")}</th>
                    <th>{translate("paid_date")}</th>
                    {!disabled && <th>{translate("action")}</th>}
                  </tr>
                </thead>
                <tbody>
                  <FieldArray
                    name="commissions"
                    render={(arrayHelpers) => (
                      <>
                        {values.commissions?.length > 0 ? (
                          values.commissions?.map((e, index) =>
                            Boolean(referredBy) &&
                            values?.commissions?.[index]?.type !==
                              "Referral" ? null : (
                              <tr key={`${index}`}>
                                <td>
                                  {" "}
                                  <CustomMultiSelect
                                    items={commissionTypes
                                      ?.filter((c) =>
                                        Boolean(referredBy)
                                          ? c === "Referral"
                                          : true
                                      )
                                      ?.map((v) => ({
                                        label: v,
                                        value: v,
                                      }))}
                                    selectedItems={
                                      values?.commissions?.[index]?.type || ""
                                    }
                                    onChange={(value) => {
                                      setFieldValue(
                                        `commissions.${index}.type`,
                                        value
                                      );
                                    }}
                                    disabled={disabled}
                                    isMulti={false}
                                    closeMenuOnSelect
                                  />
                                  <ErrorMessage
                                    name={`commissions.${index}.type`}
                                    component="div"
                                    className="text-danger smallFont"
                                  />
                                </td>

                                <td align="center">
                                  {values?.commissions?.[index]?.type ===
                                  commissionTypes[1] ? (
                                    <div
                                      className="d-flex align-items-center border bg-white px-1"
                                      style={{ height: 30, borderRadius: 5 }}
                                    >
                                      {contract?.contact?.referredBy && (
                                        <>
                                          {" "}
                                          <div className="d-flex gap-1 align-items-center mx-2 p-1">
                                            <Person size={15} />
                                            <h6 className="fw-bold smallFont mb-0">
                                              {
                                                contract?.contact?.referredBy
                                                  ?.name
                                              }
                                            </h6>
                                            {/* <h6 className="tiny mb-0">
                                        {contract?.contact?.referredBy?.email ||
                                          contract?.contact?.referredBy?.emails?.join(
                                            ", "
                                          ) ||
                                          contract?.contact?.referredBy
                                            ?.phone ||
                                          contract?.contact?.referredBy?.phoneNumbers?.join(
                                            ", "
                                          )}
                                      </h6> */}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  ) : (
                                    <CustomMultiSelect
                                      items={users?.map((u) => ({
                                        label: u?.name,
                                        value: u?._id,
                                      }))}
                                      selectedItems={
                                        values?.commissions?.[index]
                                          ?.assignedTo || ""
                                      }
                                      onChange={(value) => {
                                        setFieldValue(
                                          `commissions.${index}.assignedTo`,
                                          value
                                        );
                                      }}
                                      disabled={disabled}
                                      isMulti={false}
                                      closeMenuOnSelect
                                      isClearable
                                    />
                                  )}

                                  <ErrorMessage
                                    name={`commissions.${index}.assignedTo`}
                                    component="div"
                                    className="text-danger smallFont"
                                  />
                                </td>

                                <td>
                                  <CurrencyInput
                                    value={
                                      values?.commissions?.[index]?.commission
                                    }
                                    onChange={(value) =>
                                      setFieldValue(
                                        `commissions.${index}.commission`,
                                        value
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name={`commissions.${index}.commission`}
                                    component="div"
                                    className="text-danger smallFont"
                                  />
                                </td>
                                <td>
                                  <DatePicker
                                    disabled={disabled}
                                    className={`${
                                      isRTL ? "px-4" : ""
                                    }   mb-0 text-dark px-2 w-100 py-1 form-control form-control-sm`}
                                    selected={
                                      values?.commissions?.[index]
                                        ?.commissionPaidDate
                                        ? new Date(
                                            values?.commissions?.[
                                              index
                                            ]?.commissionPaidDate
                                          )
                                        : null
                                    }
                                    dateFormat={
                                      DEFAULT_DATE_TIME_FORMAT_NON_MOMENT
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        `commissions.${index}.commissionPaidDate`,
                                        e?.toISOString() || null
                                      );
                                    }}
                                    showMonthDropdown
                                    showYearDropdown
                                    showTimeInput
                                    timeFormat="HH:mm" // 24-hour format
                                    dropdownMode="scroll"
                                    clearButtonClassName="text-dark"
                                    portalId="root"
                                    popperClassName="datepickerPopperClassName"
                                    isClearable
                                  />
                                  <ErrorMessage
                                    name={`commissions.${index}.commissionPaidDate`}
                                    component="div"
                                    className="text-danger smallFont"
                                  />
                                </td>
                                {!disabled && (
                                  <td align="center">
                                    <Trash
                                      className="hover"
                                      size={12}
                                      onClick={() =>
                                        !disabled && arrayHelpers.remove(index)
                                      }
                                    />
                                  </td>
                                )}
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-center">
                              {translate("nothing_to_show")}
                            </td>
                          </tr>
                        )}
                        {!disabled && (
                          <tr className="">
                            <td
                              className="bg-white p-1"
                              colSpan={5}
                              align="right"
                            >
                              <Button
                                className="px-1 py-0 text-white"
                                variant="success"
                                disabled={disabled}
                                onClick={() =>
                                  arrayHelpers.push({
                                    commissionPaidDate: null,
                                    assignedTo: null,
                                    commission: 0,
                                    type: referredBy ? "Referral" : "Sales",
                                  })
                                }
                                size="sm"
                              >
                                <Plus />{" "}
                                <span className="smallFont">
                                  {translate("add")}
                                </span>
                              </Button>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  />
                </tbody>
              </Table>
            </FormGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DealComissionForm;
