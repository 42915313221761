import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, FormCheck, Row } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { useSearchAndFilterBox } from "../../context/SearchAndFilterContext";
import { ABORT_ERROR } from "../../helpers/api";
import { ALL_ROLES, DEFAULT_PAGE_SIZE } from "../../helpers/constants";
import { getUserTableColumns } from "../../helpers/dataSheetConstants";
import {
  getDateObjectFromInputString,
  getFormattedDate,
  updateItemsInArray,
} from "../../helpers/global";
import useLocalization from "../../hooks/useLocalization";
import { userService } from "../../services/userService";
import FloatingButton from "../common/FloatingButton";
import DataTable from "../common/data-table/DataTable";
import SearchBox from "../common/searchbox";
import AddEditUserModal from "./AddEditUserModal";
import DeleteUserModal from "./DeleteUserModal";
import AddEditUserPermissionModal from "./AddEditUserPermissionModal";
import ArchiveUser from "../app-configuration/archive-user";
import AlertModal from "../common/AlertModal";
import useAuth from "../../hooks/useAuth";
import { PERMISSIONS } from "../../helpers/permissions";

import Accordion from "react-bootstrap/Accordion";
import { map } from "lodash";
import { BROADCAST_TYPES, useWebSocket } from "../../context/WebSocketContext";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const Users = () => {
  const { message } = useWebSocket();
  const abortControllerRef = useRef(null);
  const { checkNestedPermission } = useAuth();
  const { translate, isRTL } = useLocalization();
  const { searchBoxOptions, setIsSearchBoxDisabled, isSearchBoxDisabled } =
    useSearchAndFilterBox();

  const [fetchingFirstPageUsers, setFetchingFirstPageUsers] = useState(false);
  const [fetchingMoreUsers, setFetchingMoreUsers] = useState(false);

  //original users array

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [toLoadPageInfo, setToLoadPageInfo] = useState(initialPageInfo);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const [userAddEditModalMeta, setUserAddEditModalMeta] = useState(null);
  const [userDeleteModalMeta, setUserDeleteModalMeta] = useState(null);
  const [userPermissionAddEditModalMeta, setUserPermissionAddEditModalMeta] =
    useState(null);
  const [activeRoleFilter, setActiveRoleFilter] = useState(null);
  const [showArchivedUser, setShowArchivedUser] = useState(false);
  const [showOtherUser, setShowOtherUser] = useState(false);

  const [revertModalMeta, setRevertModalMeta] = useState(null);
  const [userType, setUserType] = useState("active_users");
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [archiveUserCount, setArchiveUserCount] = useState(0);
  const [otherUserCount, setOtherUserCount] = useState(0);
  const [lastUpdatedUser, setLastUpdatedUser] = useState(null);

  const fetchUsersAfterUpdate = () => {
    setToLoadPageInfo({
      ...loadedPageInfo,
      pageSize: loadedPageInfo?.pageNumber * DEFAULT_PAGE_SIZE,
      pageNumber: 1,
    });
  };

  useDebouncedEffect(
    () => {
      if (message) {
        let { type, payload } = message;
        switch (type) {
          case BROADCAST_TYPES.USER_UPDATE:
            fetchUsersAfterUpdate();
            setLastUpdatedUser(payload._id);
            break;

          case BROADCAST_TYPES.USER_CREATE:
            fetchUsersAfterUpdate();
            break;
        }
      }
    },
    [message],
    500
  );

  useEffect(() => {
    if (message) {
      let { type, payload } = message;
      switch (type) {
        case BROADCAST_TYPES.USER_DELETE:
          const updatedUsers = users.filter((u) => u._id !== payload?._id);
          setUsers(updatedUsers);
          break;
      }
    }
  }, [message]);

  useEffect(() => {
    if (!lastUpdatedUser) return;
    setTimeout(() => {
      setLastUpdatedUser(null);
    }, 500);
  }, [lastUpdatedUser]);

  useEffect(() => {
    const resultUsers = [];
    let archiveCount = 0;
    let otherCount = 0;
    let activeCount = 0;

    users.forEach((u) => {
      const matchesRole = !activeRoleFilter || u.role === activeRoleFilter;
      if (matchesRole && u.archived) {
        if (userType == "archived_user") resultUsers.push(u);
        archiveCount += 1;
      } else if (matchesRole && u.showInOthersInCalendar) {
        if (userType == "others_user") resultUsers.push(u);
        otherCount += 1;
      }

      // Default case: if neither showArchivedUser nor showOtherUser is active
      else if (matchesRole && !u.archived && !u.showInOthersInCalendar) {
        if (userType == "active_users") resultUsers.push(u);
        activeCount += 1;
      }
      return matchesRole && !u.archived && !u.showInOthersInCalendar;
    });

    setFilteredUsers(resultUsers);
    setArchiveUserCount(archiveCount);
    setOtherUserCount(otherCount);
    setActiveUserCount(activeCount);
  }, [users, activeRoleFilter, userType]);

  const onAddNewUserClick = () => {
    setUserAddEditModalMeta({});
  };

  const onAddEditPermissionButtonClick = (user) => {
    setUserPermissionAddEditModalMeta({ user });
  };

  const refreshUser = async (user) => {
    try {
      let updatedUser = {
        _id: user?._id,
      };
      toast.info("Fetching zoom details of this user...");
      const { response, error } = await userService.updateUser(updatedUser);
      if (error) {
        toast.error(error);
      }
      if (response) {
        if (response?.zoomUserId) {
          toast.success("User connected successfully");
        } else {
          toast.info("User not found on Zoom");
        }
        onUserChange(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onEditUserClick = (user) => {
    if (checkNestedPermission([PERMISSIONS.users.UPDATE_ALL_USERS])) {
      setUserAddEditModalMeta({
        initialValues: {
          ...user,
          birthDate: user.birthDate
            ? getFormattedDate(new Date(user.birthDate))
            : "",
        },
        editMode: true,
        editingUser: user,
      });
    }
  };

  const onUserDeleteClick = async (user) => {
    setUserDeleteModalMeta({ user, anotherUserToAssign: null });
  };

  const sendPasswordResetLink = async (user) => {
    if (!user?.email) return toast.error("This user donot have an email");
    let reqBody = { email: user?.email };
    toast.info("Sending password reset link...");
    try {
      const { response, error } = await userService.forgotPassword(reqBody);
      if (response) {
        toast.success("Password reset link has been sent");
      }
      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onArchiveUserClick = async (user) => {
    let reqBody = { _id: user._id, archived: !user.archived };
    toast.info(showArchivedUser ? "Unarchiving..." : "Archiving");
    try {
      const { response, error } = await userService.updateUser(reqBody);
      if (error) {
        toast.error(error);
        return;
      }
      if (response) {
        let updatedUsers = updateItemsInArray(users, response);
        setUsers(updatedUsers);
        toast.success(
          showArchivedUser ? "Succesfully unarchived" : "Succesfully Archived"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tableColumns = useMemo(
    () =>
      getUserTableColumns({
        translate,
        onUserDeleteClick,
        sendPasswordResetLink,
        refreshUser,
        onAddEditPermissionButtonClick,
        archiveFilter: showArchivedUser,
        onArchiveUserClick,
        allowEdit: checkNestedPermission([PERMISSIONS.users.UPDATE_ALL_USERS]),
        allowDelete: checkNestedPermission([
          PERMISSIONS.users.DELETE_ALL_USERS,
        ]),
      }),
    [
      onUserDeleteClick,
      sendPasswordResetLink,
      showArchivedUser,
      onArchiveUserClick,
      onAddEditPermissionButtonClick,
    ]
  );

  const fetchUsers = async () => {
    if (!searchBoxOptions || !toLoadPageInfo) return;

    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const { query } = searchBoxOptions;

    const pageToFetch = toLoadPageInfo?.pageNumber;
    const loadingMoreEvents = pageToFetch > 1;

    if (loadingMoreEvents) {
      setFetchingMoreUsers(true);
    } else {
      setFetchingFirstPageUsers(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      query,
      pageSize: 100,
      pageNumber: pageToFetch,
      sort: { name: 1 },
    };

    const { response, error } = await userService.getUsers(requestBody, signal);

    if (error === ABORT_ERROR) return;

    setFetchingMoreUsers(false);
    setFetchingFirstPageUsers(false);

    if (error) {
      return toast.error(error);
    }

    const { pageNumber, totalPages, results = [] } = response;
    setLoadedPageInfo({ totalPages, pageNumber });
    setUsers((prevEvents) =>
      loadingMoreEvents ? [...prevEvents, ...results] : results
    );
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageUsers || fetchingMoreUsers) return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
    });
  };

  useEffect(() => {
    if (searchBoxOptions) setToLoadPageInfo({ ...initialPageInfo });
  }, [searchBoxOptions]);

  useEffect(() => {
    fetchUsers();
  }, [toLoadPageInfo]);

  const onUserChange = (user) => {
    setUsers((prevUsers) => {
      const updatedUsers = updateItemsInArray(prevUsers, [user]);
      return updatedUsers;
    });
  };

  const onUserSubmit = async (user) => {
    const { editMode } = userAddEditModalMeta;
    setUserAddEditModalMeta((meta) => ({ ...meta, showProgress: true }));

    let updatedUser = {
      _id: user._id,
      name: user?.name,
      email: user?.email,
      phone: user?.phone,
      role: user?.role,
      isEnabled: user?.isEnabled,
      specialRoles: user?.specialRoles,
      color: user?.color,
      secondaryEmails: user?.secondaryEmails,
      driveFolder: user?.driveFolder,
      sip: user?.sip,
      birthDate: user.birthDate
        ? getDateObjectFromInputString(user.birthDate).toISOString()
        : null,
      secondaryPhone: user?.secondaryPhone,
    };
    const { response, error } = editMode
      ? await userService.updateUser(updatedUser)
      : await userService.createUser(user);
    setUserAddEditModalMeta((meta) => ({ ...meta, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    setUserAddEditModalMeta(null);
    onUserChange(response);
    toast.success(
      editMode ? "Successfully updated user" : "Successfully added user"
    );
  };

  const onUserPermissionsSubmit = async (updates) => {
    const { user } = userPermissionAddEditModalMeta;
    setUserPermissionAddEditModalMeta((meta) => ({
      ...meta,
      showProgress: true,
    }));

    let updatedUser = {
      _id: user._id,
      ...updates,
    };
    const { response, error } = await userService.updateUser(updatedUser);
    setUserPermissionAddEditModalMeta((meta) => ({
      ...meta,
      showProgress: false,
    }));

    if (error) {
      return toast.error(error);
    }

    //setUserPermissionAddEditModalMeta(null);
    onUserChange(response);
    toast.success("Successfully updated user permissions");
  };

  const onRevertPermissionClick = async () => {
    setRevertModalMeta({});
  };

  const revertPermission = async () => {
    const { user } = userPermissionAddEditModalMeta;
    setRevertModalMeta((meta) => ({
      ...meta,
      showProgress: true,
    }));

    const { response, error } = await userService.resetUserPermissions(user);
    setRevertModalMeta((meta) => ({
      ...meta,
      showProgress: false,
    }));

    if (error) {
      return toast.error(error);
    }

    setRevertModalMeta(null);
    onUserChange(response);
    setUserPermissionAddEditModalMeta((meta) => ({ ...meta, user: response }));
    toast.success("Successfully updated user permissions");
  };

  const deleteUser = async (deleteInfo) => {
    const { user, anotherUserToAssign } = deleteInfo;
    try {
      setUserDeleteModalMeta((meta) => ({ ...meta, showProgress: true }));
      const { error } = await userService.deleteUser({
        email: user?.email,
        anotherUserToAssign,
      });
      setUserDeleteModalMeta((meta) => ({ ...meta, showProgress: false }));
      if (error) {
        return toast.error(error);
      }

      setUsers((users) => users.filter((p) => p._id !== user._id));
      toast.success("Successfully deleted user");
    } catch (error) {
      console.log(error);
    } finally {
      setUserDeleteModalMeta(null);
    }
  };

  const handleUser = (type) => {
    setShowArchivedUser(type === "archived_user" ? true : false);
    setUserType(type);
  };

  return (
    <>
      <Container fluid className={"px-2 py-3"}>
        <div className="border rounded p-2 bg-white">
          <div className="d-flex justify-content-between align-items-center pb-2 mb-3">
            <div className="d-flex align-items-center gap-3  flex-grow-1">
              <div className="flex-grow-1" style={{ maxWidth: "50%" }}>
                <SearchBox
                  containerClass="bg-white rounded"
                  disabled={isSearchBoxDisabled}
                />
              </div>

              <div className="d-flex flex-wrap gap-2 ps-4">
                <Button
                  variant={activeRoleFilter === null ? "dark" : "outline-dark"}
                  size="sm"
                  onClick={() => setActiveRoleFilter(null)}
                >
                  <h6 className="mb-0 smallFont">{translate("all_roles")}</h6>
                </Button>
                {ALL_ROLES.map((r, i) => (
                  <Button
                    variant={activeRoleFilter === r ? "dark" : "outline-dark"}
                    size="sm"
                    key={i}
                    onClick={() => setActiveRoleFilter(r)}
                  >
                    <h6 className="mb-0 smallFont">{r}</h6>
                  </Button>
                ))}
              </div>
            </div>
          </div>

          <Accordion
            onSelect={(eventKey) => handleUser(eventKey)}
            defaultActiveKey="active_users"
          >
            <Accordion.Item eventKey="active_users">
              <Accordion.Header className="custom-header">
                {translate("active_users")} ({activeUserCount})
              </Accordion.Header>
              <Accordion.Body>
                <DataTable
                  maxTableHeight={`calc(100vh - 195px)`}
                  rowKey={"_id"}
                  columns={tableColumns}
                  data={filteredUsers}
                  onBottomReached={loadMoreData}
                  loadingMoreData={fetchingMoreUsers}
                  onRowClick={onEditUserClick}
                  loadingFirstPageData={fetchingFirstPageUsers}
                  allowFilter={false}
                  allowSort={false}
                  rowToHighlight={lastUpdatedUser}
                  striped
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="archived_user">
              <Accordion.Header className="custom-header">
                {translate("archived_user")} ({archiveUserCount})
              </Accordion.Header>
              <Accordion.Body>
                <DataTable
                  maxTableHeight={`calc(100vh - 195px)`}
                  rowKey={"_id"}
                  columns={tableColumns}
                  data={filteredUsers}
                  onBottomReached={loadMoreData}
                  loadingMoreData={fetchingMoreUsers}
                  onRowClick={onEditUserClick}
                  loadingFirstPageData={fetchingFirstPageUsers}
                  allowFilter={false}
                  allowSort={false}
                  rowToHighlight={lastUpdatedUser}
                  striped
                />
                <hr className="my-2" />
                <div className="d-flex">
                  <ArchiveUser />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="others_user">
              <Accordion.Header>
                {translate("others_user")} ({otherUserCount})
              </Accordion.Header>
              <Accordion.Body>
                <DataTable
                  maxTableHeight={`calc(100vh - 195px)`}
                  rowKey={"_id"}
                  columns={tableColumns}
                  data={filteredUsers}
                  onBottomReached={loadMoreData}
                  loadingMoreData={fetchingMoreUsers}
                  onRowClick={onEditUserClick}
                  loadingFirstPageData={fetchingFirstPageUsers}
                  allowFilter={false}
                  allowSort={false}
                  rowToHighlight={lastUpdatedUser}
                  striped
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Container>
      {checkNestedPermission([PERMISSIONS.users.CREATE_ALL_USERS]) && (
        <FloatingButton
          className="text-white"
          Icon={PlusCircleFill}
          variant="success"
          text={translate("add_new_user")}
          onClick={onAddNewUserClick}
        />
      )}
      <AddEditUserModal
        show={Boolean(userAddEditModalMeta)}
        initialValues={userAddEditModalMeta?.initialValues}
        editMode={userAddEditModalMeta?.editMode}
        onHide={() => setUserAddEditModalMeta(null)}
        showProgress={userAddEditModalMeta?.showProgress}
        onSubmit={onUserSubmit}
        onUserDeleteClick={onUserDeleteClick}
        sendPasswordResetLink={sendPasswordResetLink}
        refreshUser={refreshUser}
        onAddEditPermissionButtonClick={onAddEditPermissionButtonClick}
        archiveFilter={showArchivedUser}
        onArchiveUserClick={onArchiveUserClick}
        allowEdit={checkNestedPermission([PERMISSIONS.users.UPDATE_ALL_USERS])}
        allowDelete={checkNestedPermission([
          PERMISSIONS.users.DELETE_ALL_USERS,
        ])}
      />
      <DeleteUserModal
        users={users}
        userDeleteModalMeta={userDeleteModalMeta}
        setUserDeleteModalMeta={setUserDeleteModalMeta}
        onHide={() => setUserDeleteModalMeta(false)}
        onContinueClick={deleteUser}
        showProgress={userDeleteModalMeta?.showProgress}
      />
      <AddEditUserPermissionModal
        user={userPermissionAddEditModalMeta?.user}
        show={Boolean(userPermissionAddEditModalMeta)}
        onHide={() => setUserPermissionAddEditModalMeta(null)}
        showProgress={userPermissionAddEditModalMeta?.showProgress}
        onSubmit={onUserPermissionsSubmit}
        onRevertPermissionClick={onRevertPermissionClick}
      />
      <AlertModal
        show={revertModalMeta}
        onContinueClick={revertPermission}
        showProgress={revertModalMeta?.showProgress}
        onDismissClick={() => setRevertModalMeta(null)}
        onHide={() => setRevertModalMeta(null)}
        alertText={translate("sure_to_revert_permissions_to_defaults")}
      />
    </>
  );
};

export default Users;
