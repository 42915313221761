import React, { useEffect } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import useLocalization from "./hooks/useLocalization";
import AppRoutes from "./routes";
import usePreventClickOnSelection from "./hooks/usePreventClickOnSelection";

const App = () => {
  const { langCode } = useLocalization();
  
  // Prevent click event on row text selection
  usePreventClickOnSelection(document.body);

  useEffect(() => {
    document.dir = langCode === "Hebrew" ? "rtl" : "ltr";
  }, [langCode]);

  return <AppRoutes />;
};

export default App;
