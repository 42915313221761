import React from "react";
import CustomMultiSelect from "./CustomMultiSelect";
import useAppChoices from "../../hooks/useAppChoices";
import { SALES_ROLE } from "../../helpers/constants";
import { GROUPPED_USER_BASED_ON_ROLE } from "../../helpers/global";
import useLocalization from "../../hooks/useLocalization";

const UserDropdown = ({
  value,
  onChange,
  priotize = SALES_ROLE,
  height = "30px",
  maxToShow,
  maxItemCustomMessage,
  isMultiSelect = true,
  closeMenuOnSelect = false,
  disabled,
  roles,
}) => {
  let users = useAppChoices("users").filter((user) =>
    roles ? roles.includes(user.role) : true
  );
  const { translate } = useLocalization();
  return (
    <CustomMultiSelect
      items={GROUPPED_USER_BASED_ON_ROLE({ users, priotize, translate })}
      selectedItems={value}
      isMulti={isMultiSelect}
      onChange={onChange}
      isGroupped
      placeholder={translate("select_user")}
      height={height}
      closeMenuOnSelect={closeMenuOnSelect}
      maxToShow={maxToShow}
      maxItemCustomMessage={maxItemCustomMessage}
      disabled={disabled}
    />
  );
};

export default UserDropdown;
