import React, { useMemo } from "react";
import useLocalization from "../../../hooks/useLocalization";
import { snakeCase } from "lodash";
import DataTable from "../../common/data-table/DataTable";
import ContentLoader from "react-content-loader";

const DashboardTable = ({
  tableName,
  loading,
  tableColumns,
  tableData,
  onRowClick,
  maxTableHeight,
  totalCount,
}) => {
  let columns = useMemo(() => tableColumns, [tableColumns]);
  let { translate } = useLocalization();
  return (
    <div className="custom-card h-100 p-2">
      {loading ? (
        <ContentLoader width={"100%"}>
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="15" />
          <rect x="0" y="18" rx="4" ry="4" width="100%" height="100%" />
        </ContentLoader>
      ) : (
        <>
          <h6 className="xxlarge fw-bold mb-3">
            {translate(snakeCase(tableName))} {`(${totalCount})`}
          </h6>
          <DataTable
            loadingFirstPageData={loading}
            columns={columns}
            data={tableData}
            allowFilter={false}
            allowSort={false}
            onRowClick={onRowClick}
            rowKey={"_id"}
            maxTableHeight={maxTableHeight}
            headerHorizontalAlign="left"
            dataHorizontalAlign="start"
          />{" "}
        </>
      )}
    </div>
  );
};

export default DashboardTable;
