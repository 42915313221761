import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const reportTemplateService = {
  getReportTemplates: async (reqBody = {}, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_REPORT_TEMPLATES,
      requestBody: reqBody,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  createReportTemplate: async (reqBody = {}) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_REPORT_TEMPLATE,
      requestBody: reqBody,
      method: "POST",
    });

    return { response, error };
  },
  updateReportTemplate: async (templateID, reqBody = {}) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.REPORT_TEMPLATE_WITH_ID(templateID),
      requestBody: reqBody,
      method: "PUT",
    });

    return { response, error };
  },
  deleteReportTemplate: async (templateID) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.REPORT_TEMPLATE_WITH_ID(templateID),
      method: "DELETE",
    });

    return { response, error };
  },
};
