import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";
export const fileService = {
  uploadFile: async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const { error, response } = await makeRESTApiRequests({
      requestBody: formData,
      endpoint: ENDPOINTS.FILE,
      contentType: "multipart/form-data",
      stringify: false,
    });
    return { error, response };
  },
  uploadBase64File: async (file) => {
    const base64String = await fileToBase64(file);

    const requestBody = {
      fileName: file.name,
      mimeType: file.type,
      data: base64String,
    };

    const { error, response } = await makeRESTApiRequests({
      requestBody: requestBody,
      endpoint: ENDPOINTS.FILE_BASE64,
    });
    return { error, response };
  },

  streamBase64File: async (fileId) => {
    const { error, response } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.FILE_BASE64_WITH_ID(fileId),
      stringify: false,
      method: "GET",
    });
    return { error, response };
  },
  streamFile: async (fileId) => {
    const { error, response } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.FILE_STREAM_IMAGE_WITH_ID(fileId),
      stringify: false,
      method: "GET",
      stream: true,
    });
    return { error, response };
  },
  uploadFileFromLink: async (url) => {
    const { error, response } = await makeRESTApiRequests({
      requestBody: { url },
      endpoint: ENDPOINTS.FILE_LINK,
    });
    return { error, response };
  },
};

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result.split(",")[1]; // Remove metadata prefix
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
};
