import { useEffect, useRef } from "react";

function usePreventClickOnSelection(element) {
  // Ref to store whether the mouseup event had a text selection
  const hadSelection = useRef(false);

  useEffect(() => {
    const handleMouseUp = () => {
      const selection = window.getSelection();
      // Check if there is a selection and it's not just a click without a drag
      if (selection && selection.toString().length > 0) {
        hadSelection.current = true;
      }
    };

    const handleMouseDown = () => {
      const selection = window.getSelection();
      // Clear the selection if it exists
      if (selection.type === "Range") {
        selection.removeAllRanges();
      }
      hadSelection.current = false;
    };

    const handleClick = (event) => {
      // If the last mouse up event had a selection, prevent the click
      if (hadSelection.current) {
        event.stopImmediatePropagation();
        event.preventDefault();
        console.log("Click after selection prevented");
        hadSelection.current = false; // Reset the flag after handling
      }
    };

    if (element) {
      // Listen for mouse up to check for selection
      element.addEventListener("mouseup", handleMouseUp);
      // Listen for mouse down to clear selection
      element.addEventListener("mousedown", handleMouseDown);
      // Adding click listener in the capturing phase
      element.addEventListener("click", handleClick, true);
    }

    return () => {
      if (element) {
        element.removeEventListener("mouseup", handleMouseUp);
        element.removeEventListener("mousedown", handleMouseDown);
        element.removeEventListener("click", handleClick, true);
      }
    };
  }, [element]);
}

export default usePreventClickOnSelection;
