import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
  FilterCircleFill,
} from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { useAppointment } from "../../context/AppointmentContext";
import { useFollowUpDate } from "../../context/FollowUpDateContext";
import { BROADCAST_TYPES, useWebSocket } from "../../context/WebSocketContext";
import { ABORT_ERROR } from "../../helpers/api";
import {
  createAppointments,
  formatGoogleAppointment,
  getDateXMonthBefore,
  getMonthRange,
  mergeAppAndGoogleAppointments,
  updateItemsInArray,
} from "../../helpers/global";
import { PERMISSIONS } from "../../helpers/permissions";
import { isAdminOrManager } from "../../helpers/session";
import useAppChoices from "../../hooks/useAppChoices";
import useAuth from "../../hooks/useAuth";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import useLocalization from "../../hooks/useLocalization";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { availabilityService } from "../../services/availabilityService";
import AlertModal from "../common/AlertModal";
import AddEditAppointmentModal from "./AddEditAppointmentModal";
import AppointmentCalendar from "./common/AppointmentCalendar";
import AppointmentSidebar from "./common/AppointmentSidebar";
import SearchAppointment from "./common/SearchAppointment";
import DeleteAppointmentModal from "./DeleteAppointmentModal";
import UntrackedAppointments from "./untracked-meetings/UntrackedAppointments";

const getSearchInfoAccordingToMonth = (endDateTime = new Date(), month) => ({
  startDateTime: getDateXMonthBefore(endDateTime, month),
  endDateTime,
});

const Appointment = ({
  contact,
  editable,
  specificContactMode,
  appointmentModalMeta,
  setAppointmentModalMeta,
  importUntrackedMeetMode,
  setImportUntrackedMeetMode,
  apointmentToOpen,
}) => {
  const { screenWidth, screenHeight } = useScreenWidth();

  const mobileViewCalendar = useMemo(() => screenWidth <= 950, [screenWidth]);

  const users = useAppChoices("users");
  const { user, checkNestedPermission } = useAuth();
  const { message } = useWebSocket();

  const appointmentDefaults = useAppChoices("appointmentDefaults");

  const [calendarDate, setCalendarDate] = useState(new Date());
  const { langCode, isRTL, translate } = useLocalization();
  const {
    appointments,
    setAppointments,
    isLoading: fetchingAppointments,
    setToFetchAppointmentInfo,
    fetchedAppointmentInfo,
    checkedParticipants,
    setCheckedParticipants,
    appointmentTypeFilter,
    setAppointmentTypeFilter,
  } = useAppointment();

  const [openedFilters, setOpenedFilters] = useState(["users", "others"]);
  const [openAppointmentSideBar, setOpenAppointmentSideBar] = useState(true);
  const { setFollowupDateChangeInfo } = useFollowUpDate();
  const [updatingAppointment, setUpdatingAppointment] = useState(false);
  const [deleteAppointment, setDeleteAppointment] = useState(null);
  const [showMoreAppointmentMeta, setShowMoreAppointmentMeta] = useState(null);
  const [
    sendUpdateAppointmentNotificationsMeta,
    setSendUpdateAppointmentNotificationsMeta,
  ] = useState(null);
  const [
    showPastEventCreationWarningMeta,
    setShowPastEventCreationWarningMeta,
  ] = useState(null);

  const [noParticipantModalMeta, setNoParticipantModalMeta] = useState(null);

  const abortControllerRef = useRef(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchedAppointments, setSearchedAppointments] = useState([]);
  const [searchingForTheFirstTime, setSearchingAppointmentsForTheFirstTime] =
    useState(true);
  const [searchingMoreAppointments, setSearchingMoreAppointments] =
    useState(true);
  const [toSearchAppointmentInfo, setToSearchAppointmentInfo] = useState(null);
  const [searchedAppointmentInfo, setSearchedAppointmentInfo] = useState(null);

  const [importUntrackedMeetSearchQuery, setImportUntrackedMeetSearchQuery] =
    useState("");
  const [
    importUntrackedMeetCheckedParticipants,
    setImportUntrackedMeetCheckedParticipants,
  ] = useState([]);
  const [
    importUntrackedMeetAppointmentTypeFilter,
    setImportUntrackedMeetAppointmentTypeFilter,
  ] = useState([]);

  const SHOW_ARCHIVE_USERS = useAppChoices("SHOW_ARCHIVE_USERS");
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    if (SHOW_ARCHIVE_USERS?.[0]?.value === false) {
      setFilteredUsers(users.filter((u) => !u.archived));
    } else {
      setFilteredUsers(users);
    }
  }, [SHOW_ARCHIVE_USERS, users]);

  useEffect(() => {
    setImportUntrackedMeetAppointmentTypeFilter(
      appointmentDefaults.map((a) => a.key)
    );
  }, [appointmentDefaults]);

  const searchAppointments = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;
    if (searchedAppointmentInfo) {
      setSearchingMoreAppointments(true);
    }
    try {
      const reqBody = {
        query: searchQuery,
        filter: {
          ...toSearchAppointmentInfo,
          participants: checkedParticipants,
          appointmentType: appointmentTypeFilter,
        },
      };
      const { response, error } = await availabilityService.search(
        reqBody,
        controller.signal
      );
      if (error === ABORT_ERROR) return;

      const { results } = response;
      setSearchedAppointments((prevAppointments) =>
        searchedAppointmentInfo
          ? [...prevAppointments, ...mergeAppAndGoogleAppointments(results)]
          : mergeAppAndGoogleAppointments(results)
      );
      setSearchedAppointmentInfo(toSearchAppointmentInfo);
    } catch (e) {
      console.log(e);
    } finally {
      if (controller === abortControllerRef.current) {
        setSearchingMoreAppointments(false);
        setSearchingAppointmentsForTheFirstTime(false);
        abortControllerRef.current = null;
      }
    }
  };

  const handleShowMoreClick = (appointments, boxInfo) => {
    setAppointmentModalMeta(null);
    setShowMoreAppointmentMeta({
      mode: "showMore",
      appointments,
      popupOffset: boxInfo,
    });
  };

  const handleOnSlotSelected = (slot, boxInfo) => {
    if (
      checkNestedPermission([PERMISSIONS.appointments.CREATE_ALL_APPOINTMENTS])
    ) {
      setShowMoreAppointmentMeta(null);
      setAppointmentModalMeta({
        mode: "add",
        appointment: {
          date: moment(slot?.start).format("yyyy-MM-DD"),
          endDate: moment(slot?.start).format("yyyy-MM-DD"),
        },
        popupOffset: boxInfo,
      });
    }
  };

  const handleAppointmentClick = async (appointment, boxInfo) => {
    let formattedAppointmentModalMeta = {
      appointment: {
        ...appointment,
        date: moment(appointment.startDateTime).format("YYYY-MM-DD"),
        endDate: moment(appointment.endDateTime).format("YYYY-MM-DD"),
        start: moment(appointment.startDateTime).format("HH:mm"),
        end: moment(appointment.endDateTime).format("HH:mm"),
      },
      popupOffset: boxInfo,
      loading: false,
      mode: "edit",
    };

    const parentGoogleCalendarEventId =
      formattedAppointmentModalMeta?.appointment?.googleCalendarEventId?.split(
        "_"
      ).length > 1
        ? formattedAppointmentModalMeta?.appointment?.googleCalendarEventId?.split(
            "_"
          )[0]
        : null;

    // Optimized update to appointmentModalMeta state
    setAppointmentModalMeta(formattedAppointmentModalMeta);

    if (parentGoogleCalendarEventId) {
      setAppointmentModalMeta((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const { response, error } = await availabilityService.search({
        filter: {
          googleCalendarEventId: parentGoogleCalendarEventId,
        },
      });

      if (error) {
        setAppointmentModalMeta((prevState) => ({
          ...prevState,
          loading: false,
        }));
        return;
      }

      const appEvent = response.results.appEvents[0];
      formattedAppointmentModalMeta = {
        ...formattedAppointmentModalMeta,
        appointment: {
          ...appEvent,
          isAppEvent: true,
          date: moment(appEvent.startDateTime).format("YYYY-MM-DD"),
          endDate: moment(appEvent.endDateTime).format("YYYY-MM-DD"),
          start: moment(appEvent.startDateTime).format("HH:mm"),
          end: moment(appEvent.endDateTime).format("HH:mm"),
        },
        loading: false,
      };

      setAppointmentModalMeta(formattedAppointmentModalMeta);
    }
  };

  const handleSearchedAppointmentClick = (appointment) => {
    const formattedAppointment = {
      ...appointment,
      date: moment(appointment.startDateTime).format("YYYY-MM-DD"),
      endDate: moment(appointment.endDateTime).format("YYYY-MM-DD"),
      start: moment(appointment.startDateTime).format("HH:mm"),
      end: moment(appointment.endDateTime).format("HH:mm"),
    };

    setAppointmentModalMeta({
      mode: "edit",
      appointment: formattedAppointment,
    });
  };

  const handleDeleteAppointment = async (sendNotification) => {
    const toBeDeletedAppointment = { ...deleteAppointment };

    const isRecurrentEvent = Boolean(toBeDeletedAppointment?.recurrenceInfo);

    setDeleteAppointment((prevAppointment) => ({
      ...prevAppointment,
      showProgress: true,
    }));

    try {
      const { error } = await availabilityService.delete({
        ...toBeDeletedAppointment,
        sendNotification,
        optionalMessage: toBeDeletedAppointment.customMessage,
      });
      if (error) return toast.error(error);

      setAppointments((prevAppointments) =>
        prevAppointments.filter((a) => {
          if (isRecurrentEvent) {
            return !a.googleCalendarEventId?.includes(
              toBeDeletedAppointment?.googleCalendarEventId
            );
          }
          return a._id !== toBeDeletedAppointment._id;
        })
      );

      if (searchQuery) {
        setSearchedAppointments((prevAppointments) =>
          prevAppointments.filter((a) => a._id !== toBeDeletedAppointment._id)
        );
      }
    } catch (e) {
      toast.error(e);
    } finally {
      setDeleteAppointment(null);
      setAppointmentModalMeta(null);
    }
  };

  const getRecurrentApointmentFromGoogle = async (
    googleCalendarEventId = null
  ) => {
    const { fetchedMonths } = fetchedAppointmentInfo;

    const dateTimeBoundry = fetchedMonths.reduce(
      (acc, curr) => {
        return {
          startDateTime:
            curr.startDateTime < acc.startDateTime
              ? curr.startDateTime
              : acc.startDateTime,
          endDateTime:
            curr.endDateTime > acc.endDateTime
              ? curr.endDateTime
              : acc.endDateTime,
        };
      },
      {
        startDateTime: fetchedMonths[0].startDateTime,
        endDateTime: fetchedMonths[0].endDateTime,
      }
    ); // find the lowest startDateTime and highest endDateTime

    const reqBody = {
      filter: {
        googleCalendarEventId,
        ...dateTimeBoundry,
      },
    };

    const { response, error } = await availabilityService.search(reqBody);

    if (error) {
      toast.error(error);
      return;
    }

    const { results } = response;
    const formatedGoogleApointments = results?.googleCalendarEvents?.flatMap(
      formatGoogleAppointment
    );

    return formatedGoogleApointments;
  };

  const handleAddUpdateAppointment = async (appointment) => {
    const toBeEditedAppointment = { ...appointment };
    const isEditing = Boolean(toBeEditedAppointment?._id);
    const originalApointment = appointmentModalMeta.appointment;
    const currentDateTime = moment();
    const createdAppointment = createAppointments(appointment)[0];
    const isPastEvent = moment(createdAppointment.startDateTime).isBefore(
      currentDateTime
    );
    const isParticipantsEmpty = appointment?.participants?.length === 0;

    if (isParticipantsEmpty && !noParticipantModalMeta) {
      return setNoParticipantModalMeta({
        appointment: toBeEditedAppointment,
      });
    }

    // Handle past event warning or update appointment notification
    if (isPastEvent && !showPastEventCreationWarningMeta) {
      return setShowPastEventCreationWarningMeta({
        appointment: toBeEditedAppointment,
      });
    }

    if (isEditing && !sendUpdateAppointmentNotificationsMeta) {
      return setSendUpdateAppointmentNotificationsMeta({
        appointment: toBeEditedAppointment,
      });
    }

    setNoParticipantModalMeta(null);
    setShowPastEventCreationWarningMeta(null);
    setSendUpdateAppointmentNotificationsMeta(null);

    createdAppointment.endDateTime = new Date(
      appointment.endDate + "T" + appointment.end
    ).toISOString();
    delete createdAppointment.endDate;

    try {
      setUpdatingAppointment(true);

      const appointmentAction = isEditing
        ? availabilityService.update(createdAppointment)
        : availabilityService.create(createdAppointment);

      const { response, error } = await appointmentAction;

      if (error) return toast.error(error);

      const updatedAppointment = {
        ...response,
        start: new Date(response?.startDateTime),
        end: new Date(response?.endDateTime),
        isAppEvent: true,
      };

      let googleEvents = [];

      const isRecurrentEvent =
        Boolean(updatedAppointment?.recurrenceInfo) ||
        Boolean(originalApointment?.recurrenceInfo);

      googleEvents = isRecurrentEvent
        ? await getRecurrentApointmentFromGoogle(
            updatedAppointment?.googleCalendarEventId
          )
        : [];

      const allAppointments = isRecurrentEvent
        ? appointments.filter(
            (a) =>
              !a?.googleCalendarEventId?.includes(
                originalApointment?.googleCalendarEventId ||
                  updatedAppointment?.googleCalendarEventId
              )
          )
        : [...appointments];

      if (isEditing) {
        setAppointments(
          updateItemsInArray(allAppointments, [
            updatedAppointment,
            ...googleEvents,
          ])
        );

        if (showMoreAppointmentMeta) {
          setShowMoreAppointmentMeta({
            ...showMoreAppointmentMeta,
            appointments: updateItemsInArray(
              showMoreAppointmentMeta?.appointments,
              updatedAppointment
            ),
          });
        }

        if (searchQuery) {
          setSearchedAppointments(
            updateItemsInArray(searchedAppointments, updatedAppointment)
          );
        }
      } else {
        if (response?.contact) {
          setFollowupDateChangeInfo({
            contact: response?.contact,
            followupDate: response?.startDateTime,
          });
        }
        setAppointments(
          updateItemsInArray(allAppointments, [
            updatedAppointment,
            ...googleEvents,
          ])
        );
      }

      toast.success(
        isEditing ? "Successfully Updated!" : "Successfully Created!"
      );

      setAppointmentModalMeta(null);
    } catch (e) {
      toast.error(e);
    } finally {
      setUpdatingAppointment(false);
    }
  };

  const handleCalendarDateChange = (date) => {
    const { startDateTime, endDateTime } = getMonthRange(date);
    const isAlreadyFetched = fetchedAppointmentInfo?.fetchedMonths?.some(
      (d) =>
        new Date(d.startDateTime).getTime() ===
        new Date(startDateTime).getTime()
    );

    if (!isAlreadyFetched) {
      setToFetchAppointmentInfo({ startDateTime, endDateTime });
    }
  };

  const navigateToSpecificDate = (date) => {
    setSearchQuery("");
    setImportUntrackedMeetMode(false);
    setCalendarDate(date);
    const { startDateTime, endDateTime } = getMonthRange(date);
    const isAlreadyFetched = fetchedAppointmentInfo?.fetchedMonths?.some(
      (d) =>
        new Date(d.startDateTime).getTime() ===
        new Date(startDateTime).getTime()
    );

    if (!isAlreadyFetched) {
      setToFetchAppointmentInfo({ startDateTime, endDateTime });
    }
  };

  const collapseSidebar = useCallback(() => {
    if (!openAppointmentSideBar) {
      return (
        <FilterCircleFill
          size={20}
          className="hover"
          onClick={() => setOpenAppointmentSideBar(!openAppointmentSideBar)}
        />
      );
    }

    const toggleIcon = isRTL ? (
      <ArrowRightCircleFill
        size={20}
        className="hover"
        onClick={() => setOpenAppointmentSideBar(!openAppointmentSideBar)}
      />
    ) : (
      <ArrowLeftCircleFill
        size={20}
        className="hover"
        onClick={() => setOpenAppointmentSideBar(!openAppointmentSideBar)}
      />
    );

    return (
      <div className="d-flex gap-2 flex-wrap align-items-center">
        {toggleIcon}
      </div>
    );
  }, [isRTL, openAppointmentSideBar]);

  const filterAppointmentsByParticipantsAndType = useCallback(
    (apps, performFilter = true) => {
      return performFilter
        ? apps.filter((appointment) => {
            //for holidays no need to check participants
            const { participants, appointmentType } = appointment;
            if (
              appointmentType === "holiday" &&
              appointmentTypeFilter.includes(appointmentType)
            ) {
              return true;
            }

            return (
              participants?.some((participant) =>
                checkedParticipants?.includes(participant._id)
              ) && appointmentTypeFilter?.includes(appointmentType)
            );
          })
        : apps;
    },
    [checkedParticipants, appointmentTypeFilter]
  );

  const handleSearchMore = useCallback(() => {
    const LOWER_CAP = 5 * 365 * 24 * 60 * 60 * 1000; // 5 years in milliseconds
    const previousStartDT = searchedAppointmentInfo?.startDateTime;
    const lowerCapTime = Date.now() - LOWER_CAP;

    if (previousStartDT <= lowerCapTime) return; // Stop fetching if the start date is 5 years ago

    let { endDateTime, startDateTime } = getSearchInfoAccordingToMonth(
      previousStartDT,
      24
    );
    if (startDateTime < lowerCapTime) startDateTime = new Date(lowerCapTime); // Adjust the start date

    if (searchingForTheFirstTime || searchingMoreAppointments) return;

    setToSearchAppointmentInfo({ endDateTime, startDateTime });
  }, [
    searchedAppointmentInfo,
    searchingForTheFirstTime,
    searchingMoreAppointments,
  ]);

  useEffect(() => {
    if (filteredUsers?.length > 0 && isAdminOrManager(user?.role)) {
      setImportUntrackedMeetCheckedParticipants(
        filteredUsers?.map((u) => u?._id)
      );
    }
  }, [filteredUsers, user]);

  useEffect(() => {
    if (toSearchAppointmentInfo) {
      searchAppointments();
    }
  }, [toSearchAppointmentInfo]);

  useDebouncedEffect(
    () => {
      if (searchQuery) {
        setToSearchAppointmentInfo({
          startDateTime: getDateXMonthBefore(new Date(), 24),
        });
      }
    },
    [searchQuery, checkedParticipants, appointmentTypeFilter],
    300
  );

  const onCloseSearch = () => {
    setSearchingMoreAppointments(false);
    setToSearchAppointmentInfo(null);
    setSearchedAppointmentInfo(null);
    setSearchedAppointments([]);
    setSearchQuery("");
  };

  const onCloseImportMode = () => {
    setImportUntrackedMeetMode(false);
    setImportUntrackedMeetSearchQuery("");
  };

  useEffect(() => {
    if (!searchQuery) {
      onCloseSearch();
    } else {
      setSearchingAppointmentsForTheFirstTime(true);
      setSearchingMoreAppointments(false);
      setSearchedAppointments([]);
    }
  }, [searchQuery, checkedParticipants, appointmentTypeFilter]);

  useEffect(() => {
    if (mobileViewCalendar) {
      setOpenAppointmentSideBar(false);
    }
  }, [mobileViewCalendar]);

  useEffect(() => {
    if (apointmentToOpen) {
      setAppointmentModalMeta({
        mode: "edit",
        appointment: apointmentToOpen,
      });
      navigateToSpecificDate(new Date(apointmentToOpen.startDateTime));
    }
  }, [apointmentToOpen]);

  useEffect(() => {
    if (message) {
      let { type, payload } = message;
      switch (type) {
        case BROADCAST_TYPES.CONTACT_UPDATE:
          const results = appointments.map((a) =>
            a?.contact?._id === payload._id ? { ...a, contact: payload } : a
          );
          setAppointments(results);
          break;

        case BROADCAST_TYPES.APPOINTMENT_UPDATE:
          const updatedAppointments = appointments.map((a) =>
            a._id === payload._id
              ? {
                  ...payload,
                  date: new Date(payload?.startDateTime),
                  start: new Date(payload?.startDateTime),
                  end: new Date(payload?.endDateTime),
                  isAppEvent: true,
                }
              : a
          );
          setAppointments(updatedAppointments);
          break;
        case BROADCAST_TYPES.APPOINTMENT_DELETE:
          setAppointments([
            ...appointments.filter((a) => a._id !== payload._id),
          ]);
          break;
        case BROADCAST_TYPES.APPOINTMENT_CREATE:
          setAppointments([
            ...appointments,
            {
              ...payload,
              date: new Date(payload?.startDateTime),
              start: new Date(payload?.startDateTime),
              end: new Date(payload?.endDateTime),
              isAppEvent: true,
            },
          ]);
          break;
        default:
          console.log("Unknown message type");
          break;
      }
    }
  }, [message]);

  const bodyHeight = useMemo(
    () =>
      mobileViewCalendar || !openAppointmentSideBar
        ? `${screenHeight - 75}px` // Use viewHeight instead of 100vh
        : `${screenHeight - 35}px`,
    [screenHeight, mobileViewCalendar, openAppointmentSideBar]
  );

  return (
    <>
      <div className="d-flex p-0 m-0">
        {openAppointmentSideBar && (
          <div
            className="p-0 m-0 border-start border-end overflow-auto"
            style={{
              position: mobileViewCalendar ? "absolute" : "relative",
              background: mobileViewCalendar ? "#F5F5F5" : "white",
              minWidth: 250,
              maxWidth: 250,
              zIndex: 100,
              height: `calc(100vh - 35px)`,
              scrollbarWidth: "none",
            }}
          >
            <AppointmentSidebar
              className="w-100 px-2"
              mobileViewCalendar={mobileViewCalendar}
              collapseSidebar={collapseSidebar}
              checkedParticipants={
                importUntrackedMeetMode
                  ? importUntrackedMeetCheckedParticipants
                  : checkedParticipants
              }
              setCheckedParticipants={
                importUntrackedMeetMode
                  ? setImportUntrackedMeetCheckedParticipants
                  : setCheckedParticipants
              }
              searchQuery={
                importUntrackedMeetMode
                  ? importUntrackedMeetSearchQuery
                  : searchQuery
              }
              setSearchQuery={
                importUntrackedMeetMode
                  ? setImportUntrackedMeetSearchQuery
                  : setSearchQuery
              }
              appointmentTypeFilter={
                importUntrackedMeetMode
                  ? importUntrackedMeetAppointmentTypeFilter
                  : appointmentTypeFilter
              }
              setAppointmentTypeFilter={
                importUntrackedMeetMode
                  ? setImportUntrackedMeetAppointmentTypeFilter
                  : setAppointmentTypeFilter
              }
              hideHolidayCheckbox={importUntrackedMeetMode}
              openedFilters={openedFilters}
              setOpenedFilters={setOpenedFilters}
            />
          </div>
        )}

        <div
          className="px-0 m-0 w-100"
          style={{
            height: bodyHeight,
            width:
              mobileViewCalendar || !openAppointmentSideBar
                ? "100vw"
                : `calc(100vw - 250px)`,
          }}
        >
          {importUntrackedMeetMode &&
          checkNestedPermission([
            PERMISSIONS.appointments.CREATE_ALL_APPOINTMENTS,
            PERMISSIONS.appointments.UPDATE_ALL_APPOINTMENTS,
          ]) ? (
            <UntrackedAppointments
              bodyHeight={bodyHeight}
              collapseSidebar={collapseSidebar}
              closeImportMode={onCloseImportMode}
              searchQuery={importUntrackedMeetSearchQuery}
              checkedParticipants={importUntrackedMeetCheckedParticipants}
              appointmentTypeFilter={importUntrackedMeetAppointmentTypeFilter}
              onImportUntrackedZoomMeet={(untrackedZoomMeet) =>
                setAppointments((prevAppointments) => [
                  untrackedZoomMeet,
                  ...prevAppointments,
                ])
              }
              onAssignContactToAppointment={(updatedAppointment) => {
                const updatedAppointments = appointments.map((a) =>
                  a._id === updatedAppointment._id ? updatedAppointment : a
                );
                setAppointments(updatedAppointments);
              }}
              onNavigateToDate={navigateToSpecificDate}
              onDeleteApointment={(deletedAppointment) => {
                const updatedAppointments = appointments.filter(
                  (a) => a._id !== deletedAppointment._id
                );
                setAppointments(updatedAppointments);
              }}
              handleAppointmentClick={handleAppointmentClick}
            />
          ) : searchQuery ? (
            <SearchAppointment
              bodyHeight={bodyHeight}
              collapseSidebar={collapseSidebar}
              closeSearch={onCloseSearch}
              searchingForTheFirstTime={searchingForTheFirstTime}
              searchingMoreAppointments={searchingMoreAppointments}
              onAppointmentClick={handleSearchedAppointmentClick}
              appointments={filterAppointmentsByParticipantsAndType(
                searchedAppointments,
                false
              )}
              onNavigateToDate={navigateToSpecificDate}
              onBottomReached={handleSearchMore}
            />
          ) : (
            <AppointmentCalendar
              calendarStyles={{
                height: bodyHeight,
                width:
                  mobileViewCalendar || !openAppointmentSideBar
                    ? "100vw"
                    : `calc(100vw - 250px)`,
              }}
              onAppointmentClick={handleAppointmentClick}
              events={filterAppointmentsByParticipantsAndType(appointments)}
              calendarDate={calendarDate}
              setCalendarDate={setCalendarDate}
              customStep={30}
              activeEvent={appointmentModalMeta?.appointment}
              onSlotSelect={handleOnSlotSelected}
              onShowMoreClick={handleShowMoreClick}
              allowEditing={true}
              compactMode={mobileViewCalendar}
              initialSmallScreenView={"month"}
              initialLargeScreenView={"month"}
              locale={langCode === "Hebrew" ? "he" : "en"}
              renderMoreInToolbar={collapseSidebar}
              onDateChange={handleCalendarDateChange}
              fetchingEvents={fetchingAppointments}
            />
          )}
        </div>
      </div>

      <AddEditAppointmentModal
        show={Boolean(showMoreAppointmentMeta) || Boolean(appointmentModalMeta)}
        onHide={() => {
          setAppointmentModalMeta(null);
          setShowMoreAppointmentMeta(null);
        }}
        showMoreMode={
          Boolean(showMoreAppointmentMeta) && !Boolean(appointmentModalMeta)
        }
        showBackToShowMoreButton={
          Boolean(showMoreAppointmentMeta) && Boolean(appointmentModalMeta)
        }
        editMode={Boolean(appointmentModalMeta?.mode === "edit")}
        onBackToShowMore={() => setAppointmentModalMeta(null)}
        onAppointmentClick={(appointment) =>
          handleAppointmentClick(
            appointment,
            showMoreAppointmentMeta?.popupOffset
          )
        }
        onSubmit={(formValues) => handleAddUpdateAppointment(formValues)}
        onAppointmentDelete={(appointment) => setDeleteAppointment(appointment)}
        allAppointments={showMoreAppointmentMeta?.appointments}
        contact={contact}
        appointment={{
          ...appointmentModalMeta?.appointment,
        }}
        disabled={
          !editable ||
          (appointmentModalMeta?.appointment?.hasOwnProperty("isAppEvent") &&
            !appointmentModalMeta?.appointment?.isAppEvent)
        }
        defaultInviteesEmail={contact?.emails ?? []}
        appointmentMetaDataByType={appointmentDefaults}
        showProgress={updatingAppointment}
        specificContactMode={specificContactMode}
        setAppointmentMetadata={setAppointmentModalMeta}
        popupOffset={
          showMoreAppointmentMeta?.popupOffset ||
          appointmentModalMeta?.popupOffset
        }
        loading={Boolean(
          appointmentModalMeta?.mode === "edit" && appointmentModalMeta?.loading
        )}
      />

      <AlertModal
        show={noParticipantModalMeta}
        onHide={() => setNoParticipantModalMeta(null)}
        alertText={translate("no_participants_in_appointment")}
        onContinueClick={() =>
          handleAddUpdateAppointment(noParticipantModalMeta?.appointment)
        }
        continueButtonVariant="danger"
        continueButtonText={translate("continue")}
        onDismissClick={() => setNoParticipantModalMeta(null)}
      />

      <AlertModal
        show={showPastEventCreationWarningMeta}
        onHide={() => setShowPastEventCreationWarningMeta(null)}
        alertText={translate("past_event_creation_warning")}
        onDismissClick={() => setShowPastEventCreationWarningMeta(null)}
        onContinueClick={() =>
          handleAddUpdateAppointment({
            ...showPastEventCreationWarningMeta.appointment,
          })
        }
        continueButtonVariant="danger"
      />
      <AlertModal
        show={sendUpdateAppointmentNotificationsMeta}
        onHide={() => setSendUpdateAppointmentNotificationsMeta(null)}
        alertText={translate("send_update_emails_to_guests")}
        onDismissClick={() =>
          handleAddUpdateAppointment({
            ...sendUpdateAppointmentNotificationsMeta.appointment,
            inviteUser: false,
          })
        }
        onContinueClick={() =>
          handleAddUpdateAppointment({
            ...sendUpdateAppointmentNotificationsMeta.appointment,
            inviteUser: true,
          })
        }
      />

      <DeleteAppointmentModal
        appointmentToDelete={deleteAppointment}
        onHide={() => setDeleteAppointment(null)}
        onDeleteClick={handleDeleteAppointment}
        customMessage={deleteAppointment?.customMessage || ""}
        onCustomMessageChange={(customMessage) =>
          setDeleteAppointment((prevAppointment) => ({
            ...prevAppointment,
            customMessage,
          }))
        }
        showProgress={deleteAppointment?.showProgress}
      />
    </>
  );
};

export default Appointment;
