import { snakeCase } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Badge, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import {
  DEAL_STATUSES,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_DATE_TIME_FORMAT_NON_MOMENT,
} from "../../helpers/constants";
import { formatCurrency } from "../../helpers/global";
import { PERMISSIONS } from "../../helpers/permissions";
import useAppChoices from "../../hooks/useAppChoices";
import useAuth from "../../hooks/useAuth";
import useLocalization from "../../hooks/useLocalization";
import CustomMultiSelect from "../common/CustomMultiSelect";
import ProductStructureTable from "../common/ProductStructureTable";
import UserDropdown from "../common/UserDropdown";
import ProductionStageProgress from "../deals/ProductionStageProgress";
import ViewContactButton from "../deals/ViewContactButton";

function findUnitPosition(array, index) {
  const { unit } = array[index]; // Get the unit of the object at the given index
  let count = 0;

  // Loop through the array from the start up to the given index
  for (let i = 0; i <= index; i++) {
    if (array[i].unit === unit) {
      count++; // Increment the count each time the unit matches
    }
  }

  return count;
}

// Yup validation schema
const validationSchema = Yup.object().shape({
  status: Yup.string().required("Status is required"),
  product: Yup.string().required("Product is required"),
  proposalQuote: Yup.string().required("Deal value is required"),
});

const DealDetailForm = ({
  disabled,
  originalContract,
  contract,
  products,
  onChange,
  onUpdateDate,
  updatingHistoryDate,
  updatingContractUnit,
  onContractUnitUpdate,
}) => {
  const { translate, isRTL } = useLocalization();
  const { user, checkNestedPermission } = useAuth();
  const findPaymentDue = (contract) => {
    let dealValue = contract?.proposalQuote;
    let totalPayment = contract?.payments?.reduce((a, b) => a + b.amount, 0);
    return dealValue - totalPayment;
  };

  const tags = useAppChoices("tags");
  const productOptions = useAppChoices("units");

  const [formValues, setFormValues] = useState({
    ...contract,
    product: contract?.product?._id || contract?.product,
    units:
      contract?.units?.map((u) => ({
        ...u,
        unit: u?.unit?._id || u?.unit,
      })) || [],
    assignedTo: contract?.assignedTo?.map((u) => u?._id || u) || [],
    // isFloating: Boolean(contract?.isFloating),
    status: contract?.status,
    proposalQuote: contract?.proposalQuote,
    signedOn: contract?.signedOn,
    tags: contract?.tags || [],
    discount: contract?.discount || 0,
  });

  useEffect(() => {
    onChange(formValues);
  }, [formValues]);

  const handleFieldChange = (field, value) => {
    if (field === "units") {
      setFormValues((prevValues) => {
        const existingUnits = value?.filter((u) => u._id) || [];
        const newUnits =
          value?.filter(
            (u) => !u._id || u._id === null || u._id === undefined
          ) || [];

        const newlyAddedAssignees = existingUnits
          .flatMap((unit) => {
            const originalUnit = originalContract.units.find(
              (orig) => orig._id === unit._id
            );

            if (originalUnit) {
              return (unit.assignedTo || []).filter(
                (user) => !(originalUnit.assignedTo || []).includes(user)
              );
            }

            return [];
          })
          .filter(Boolean);

        const newAssignees = newUnits
          .flatMap((unit) => unit.assignedTo || [])
          .filter(Boolean);

        const updatedAssignedTo = [
          ...new Set([
            ...originalContract.assignedTo.map((user) => user._id),
            ...newlyAddedAssignees,
            ...newAssignees,
          ]),
        ];

        return {
          ...prevValues,
          assignedTo: updatedAssignedTo,
          [field]: value,
        };
      });
    } else {
      setFormValues((prevValues) => ({ ...prevValues, [field]: value }));
    }
  };

  const getLastProductionStage = (unitId) => {
    const unit = productOptions?.find((p) => p._id === unitId);
    return unit?.productionStages?.[unit?.productionStages?.length - 1]?._id;
  };

  const populatedProductOptions = useMemo(() => {
    return productOptions?.map((u) => {
      const newUnit = formValues?.units.find((unt) => unt.unit === u._id);
      return {
        ...u,
        assignedTo: newUnit?.assignedTo || [],
      };
    });
  }, [productOptions]);

  return (
    <Row className="m-0 p-0">
      {checkNestedPermission([
        PERMISSIONS.contract_advanced_info.VIEW_ALL_CONTRACT_ADVANCED_INFO,
      ]) ? (
        <>
          <Col xs={12} md={12} lg={12}>
            {" "}
            <Row className="mb-2 align-items-center">
              {contract?.contact?._id && (
                <Col md={6} xs={12}>
                  <ViewContactButton
                    contact={contract?.contact}
                    onContactChange={(contact) => {
                      onChange({ contact });
                    }}
                  />
                </Col>
              )}
              <Col md={3} xs={12}>
                <FormLabel className="mid mb-2">
                  {translate("contract")}
                </FormLabel>
                <CustomMultiSelect
                  height="30px"
                  isMulti={false}
                  items={products?.map((product) => ({
                    label: (
                      <div>
                        <span>{product?.description}</span>
                        {product?.archived && (
                          <Badge variant="warning" className="mx-2">
                            Archived
                          </Badge>
                        )}
                      </div>
                    ),
                    value: product?._id,
                  }))}
                  selectedItems={formValues?.product}
                  onChange={(product) => {
                    handleFieldChange("product", product);
                  }}
                  placeholder="Select contract"
                  disabled={
                    disabled ||
                    !checkNestedPermission([
                      PERMISSIONS.contract_advanced_info
                        .UPDATE_ALL_CONTRACT_ADVANCED_INFO,
                    ])
                  }
                  closeMenuOnSelect
                />
              </Col>
              <Col md={3} xs={12}>
                <FormLabel className="mid mb-2">
                  {translate("status")}
                </FormLabel>
                <CustomMultiSelect
                  height="30px"
                  isMulti={false}
                  items={DEAL_STATUSES?.map((status) => ({
                    label: translate(snakeCase(status)),
                    value: status,
                  }))}
                  selectedItems={formValues?.status}
                  onChange={(status) => {
                    let updatedUnits = formValues.units.map((u) => {
                      let dealWonId = productOptions?.find(
                        (p) => p._id === u?.unit
                      )?.productionStages?.[0]?._id;

                      return {
                        ...u,
                        productionStage:
                          status === "Signed"
                            ? u?.productionStage?._id ||
                              u?.productionStage ||
                              dealWonId
                            : u?.productionStage,
                      };
                    });
                    handleFieldChange("units", updatedUnits);
                    handleFieldChange("status", status);
                  }}
                  closeMenuOnSelect
                  disabled={
                    disabled ||
                    !checkNestedPermission([
                      PERMISSIONS.contract_advanced_info
                        .UPDATE_ALL_CONTRACT_ADVANCED_INFO,
                    ])
                  }
                />
              </Col>
            </Row>
          </Col>{" "}
          <Col xs={12} md={12} lg={12}>
            {" "}
            <FormGroup className="mb-2">
              <FormLabel className="mid mb-2">
                {translate("products")}
              </FormLabel>
              <ProductStructureTable
                editable={
                  !disabled &&
                  checkNestedPermission([
                    PERMISSIONS.contract_advanced_info
                      .UPDATE_ALL_CONTRACT_ADVANCED_INFO,
                  ])
                }
                discount={formValues?.discount}
                productOptions={populatedProductOptions}
                products={formValues?.units || []}
                onProductChange={(value) => {
                  const productMap = new Map(
                    productOptions?.map((p) => [p._id, p]) ?? []
                  );

                  let updatedUnits = value.map((u, index) => {
                    const currentProduct = productMap.get(u.unit);

                    const currentProductionStages =
                      currentProduct?.productionStages || [];

                    const dealWonId = currentProductionStages?.[0]?._id;

                    const currentProductionStageId = u.productionStage || null;

                    const isNewProduct = !currentProductionStages.find(
                      (p) =>
                        p._id === currentProductionStageId?._id ||
                        p._id === currentProductionStageId
                    );

                    const shouldUseDealWonId =
                      isNewProduct &&
                      formValues?.status === "Signed" &&
                      dealWonId;

                    return {
                      ...u,
                      productionStage: shouldUseDealWonId
                        ? dealWonId
                        : currentProductionStageId,
                    };
                  });

                  handleFieldChange("units", updatedUnits);
                }}
                totalPrice={formValues?.proposalQuote}
                onTotalPriceChange={(proposalQuote) =>
                  handleFieldChange("proposalQuote", proposalQuote)
                }
                onDiscountChange={(discount) =>
                  handleFieldChange("discount", discount)
                }
                initialValues={originalContract}
                isAssignedTo={true}
              />
            </FormGroup>
          </Col>
        </>
      ) : (
        <>
          <Row>
            {contract?.contact?._id && (
              <Col md={6} xs={12}>
                <ViewContactButton
                  contact={contract?.contact}
                  onContactChange={(contact) => {
                    onChange({ contact });
                  }}
                />
              </Col>
            )}
          </Row>
          <Col xs={4} md={2} lg={2}>
            <FormGroup className="mb-2">
              <FormLabel className="mid mb-2">
                {translate("contract")}
              </FormLabel>
              <div
                className="form-control d-flex align-items-center "
                style={{ height: "30px" }}
              >
                <span className="smaller">
                  {products?.find(
                    (product) => product?._id === formValues?.product
                  )?.description || "N/A"}
                </span>
              </div>
            </FormGroup>
          </Col>
          <Col xs={8} md={4} lg={4}>
            <FormGroup className="mb-2">
              <FormLabel className="mid mb-2">
                {translate("products")}
              </FormLabel>
              <div
                className="form-control d-flex flex-wrap align-items-center"
                style={{ minHeight: "20px", padding: "2px" }}
              >
                {formValues?.units?.map((u, index) => {
                  const product = productOptions?.find(
                    (p) => p?._id === (u?.unit?._id || u?.unit)
                  );
                  return product ? (
                    <span
                      key={index}
                      className="product-tag d-flex align-items-center smallFont"
                      style={{
                        background: "#e6e6e6",
                        padding: "2px 8px",
                        margin: "3px",
                        borderRadius: "2px",
                        border: "1px solid #ccc",
                      }}
                    >
                      {product?.description}
                    </span>
                  ) : (
                    <span
                      key={index}
                      className="product-tag"
                      style={{ margin: "3px" }}
                    >
                      N/A
                    </span>
                  );
                })}
              </div>
            </FormGroup>
          </Col>

          <Col xs={4} md={2} lg={2}>
            <FormGroup className="mb-2">
              <FormLabel className="mid mb-2">{translate("status")}</FormLabel>
              <div
                className="form-control d-flex align-items-center "
                style={{ height: "30px" }}
              >
                <span className="smallFont">{formValues?.status}</span>
              </div>
            </FormGroup>
          </Col>
        </>
      )}
      {/* Production stages */}{" "}
      <Col
        xs={12}
        className={`p-0 mb-1 ${
          !checkNestedPermission([
            PERMISSIONS.contract_advanced_info.VIEW_ALL_CONTRACT_ADVANCED_INFO,
          ]) && "border-top border-2 border-secondary border-bottom"
        }}`}
      >
        <h6 className="mid mb-0 mx-2 mt-1 mb-2">
          {translate("production_stage")}
        </h6>
        {formValues?.units?.length === 0 ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: 50 }}
          >
            {" "}
            <h6 className="mb-0 mid">No any product selected</h6>
          </div>
        ) : (
          formValues?.units?.map((product, index) => {
            const currentProduct = productOptions?.find(
              (pO) => pO?._id === product?.unit
            );

            const savedProductionStageOfThisProduct =
              originalContract?.units?.find(
                (u) => u.unit?._id === product?.unit
              )?.productionStage?._id;

            const currentProductProductionStages =
              currentProduct?.productionStages;

            const selectedProductionStage =
              product?.productionStage?._id || product?.productionStage;

            const unitPosition = findUnitPosition(formValues.units, index);

            const label = `${currentProduct?.description} ${
              unitPosition > 1 ? `(${unitPosition})` : ""
            }`;

            return (
              <div
                key={`${currentProduct?._id}-${index}`}
                className="border rounded mx-2 my-1 pb-2 pt-3"
              >
                {currentProductProductionStages?.length === 0 ? (
                  <div
                    className={`px-2 py-1 d-flex align-items-center justify-content-center `}
                    style={{ height: "80px" }}
                  >
                    {translate("this_product_has_no_producation_stage")}
                  </div>
                ) : (
                  <ProductionStageProgress
                    label={label}
                    className={"m-2 px-2"}
                    productionStages={currentProductProductionStages}
                    currentProductionStage={selectedProductionStage}
                    initialProductionStage={savedProductionStageOfThisProduct}
                    onProductionStageChange={(newProductionStageId) => {
                      const updatedUnits = formValues.units?.map((u, i) => {
                        const unitLastStageId = getLastProductionStage(u.unit);

                        const isLastStage = (stageId) =>
                          unitLastStageId === stageId;

                        if (u.unit === product.unit && i === index) {
                          return {
                            ...u,
                            productionStage: newProductionStageId,
                            isLastStage: isLastStage(newProductionStageId),
                          };
                        }

                        return {
                          ...u,
                          isLastStage: isLastStage(
                            u.productionStage?._id ?? u.productionStage
                          ),
                        };
                      });

                      if (
                        updatedUnits.every((u) => u.isLastStage || u.isQuitted)
                      ) {
                        handleFieldChange("status", "Deal Finished");
                      }

                      handleFieldChange(
                        "units",
                        updatedUnits.map((u) => {
                          const { isLastStage, ...rest } = u;
                          return rest;
                        })
                      );
                    }}
                    productionStageHistory={contract?.productionStageHistory}
                    disabled={disabled}
                    onUpdateDate={onUpdateDate}
                    updatingHistoryDate={updatingHistoryDate}
                    unit={product}
                    setFormValues={setFormValues}
                    products={formValues?.units || []}
                    updatingContractUnit={updatingContractUnit}
                    onContractUnitUpdate={onContractUnitUpdate}
                    index={index}
                  />
                )}
              </div>
            );
          })
        )}
      </Col>
      {checkNestedPermission([
        PERMISSIONS.contract_advanced_info.VIEW_ALL_CONTRACT_ADVANCED_INFO,
      ]) && (
        <>
          <Col xs={6} md={2} lg={1}>
            {" "}
            <FormGroup className="mb-2">
              <FormLabel className="mid mb-1">
                {translate("payment_due")}
              </FormLabel>
              <h6
                style={{ background: "#f4f499" }}
                className="smallFont rounded fw-bold py-2 px-3"
                dir="ltr"
              >
                {formatCurrency(findPaymentDue(contract))}
              </h6>
            </FormGroup>
          </Col>
          <Col xs={6} md={3} lg={3}>
            {" "}
            <FormGroup className="mb-2">
              <FormLabel className="mid mb-1">
                {translate("assigned_to")}
              </FormLabel>
              <UserDropdown
                value={formValues?.assignedTo}
                onChange={(users) => {
                  handleFieldChange("assignedTo", users);
                }}
                maxItemCustomMessage={(length) => `+ ${length} more`}
                height="26px"
                maxToShow={5}
                isMultiSelect
                disabled={
                  disabled ||
                  !checkNestedPermission([
                    PERMISSIONS.contract_advanced_info
                      .UPDATE_ALL_CONTRACT_ADVANCED_INFO,
                  ])
                }
                closeMenuOnSelect={false}
              />
            </FormGroup>
          </Col>
          <Col xs={6} md={2} lg={2}>
            {" "}
            <FormGroup className="mb-2">
              <FormLabel className="mid mb-1">{translate("tags")}</FormLabel>

              <CustomMultiSelect
                items={tags.map((tag) => ({
                  label: tag.name,
                  value: tag.name,
                }))}
                selectedItems={formValues?.tags}
                onChange={(tag) => {
                  handleFieldChange("tags", tag);
                }}
                disabled={
                  disabled ||
                  !checkNestedPermission([
                    PERMISSIONS.contract_advanced_info
                      .UPDATE_ALL_CONTRACT_ADVANCED_INFO,
                  ])
                }
              />
            </FormGroup>
          </Col>
          <Col xs={6} md={2} lg={2}>
            {" "}
            <FormGroup className="mb-2">
              <FormLabel className="mid mb-1">
                {translate("contract_signed_on")}
              </FormLabel>
              <br />
              <DatePicker
                className={`${
                  isRTL ? "px-4" : ""
                }   mb-0 text-dark px-2 py-1 form-control form-control-sm`}
                selected={
                  formValues?.signedOn ? new Date(formValues?.signedOn) : null
                }
                dateFormat={DEFAULT_DATE_TIME_FORMAT_NON_MOMENT}
                onChange={(e) => {
                  handleFieldChange(`signedOn`, e?.toISOString() || null);
                }}
                showYearDropdown
                showMonthDropdown
                showTimeInput
                timeIntervals={15}
                dropdownMode="scroll"
                timeFormat="HH:mm" // 24-hour format
                clearButtonClassName="text-dark"
                portalId="root"
                popperClassName="datepickerPopperClassName"
                disabled={
                  disabled ||
                  !checkNestedPermission([
                    PERMISSIONS.contract_advanced_info
                      .UPDATE_ALL_CONTRACT_ADVANCED_INFO,
                  ])
                }
              />
            </FormGroup>
          </Col>{" "}
          <Col xs={6} md={2} lg={2}>
            {" "}
            <FormGroup className="mb-2">
              <FormLabel className="mid mb-1">
                {translate("contract_sent_on")}
              </FormLabel>
              <h6 className="smallFont fw-bold form-control form-control-sm">
                {contract?.createdAt
                  ? moment(contract?.createdAt).format(DEFAULT_DATE_TIME_FORMAT)
                  : "-"}
              </h6>
            </FormGroup>
          </Col>
        </>
      )}
    </Row>
  );
};

export default DealDetailForm;
