import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const aiChatPromptServices = {
  searchAiChatPrompt: async (reqBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_AI_CHAT_PROMPT,
      requestBody: reqBody,
      method: "POSt",
      signal,
    });

    return { response, error };
  },
  createAiChatPrompt: async (userQuestion, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_AI_CHAT_PROMPT,
      requestBody: userQuestion,
      method: "POST",
      signal,
    });

    return { response, error };
  },
};
