import useLocalization from "../../../hooks/useLocalization";
import SlidingSideBar from "../../common/SlidingSideBar/SlidingSideBar";
import AddEditFilterForm from "../../forms/AddEditFilterForm";

const AddEditFilterModal = ({
  show,
  initialValues,
  onHide,
  fields,
  showProgress,
  onSubmit,
  editMode,
}) => {
  const { translate } = useLocalization();

  return (
    <SlidingSideBar
      visible={show}
      onClose={onHide}
      title={translate("filters")}
    >
      <AddEditFilterForm
        isEditmode={editMode}
        fields={fields}
        initialValues={initialValues}
        showProgress={showProgress}
        onSubmit={onSubmit}
      />
    </SlidingSideBar>
  );
};

export default AddEditFilterModal;
