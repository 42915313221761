import React, { useEffect, useMemo, useRef, useState } from "react";
import CircularProgressBar from "../../common/circular-progress";
import useLocalization from "../../../hooks/useLocalization";
import OverViewColumns from "../../common/overview-columns/OverViewColumns";
import { Check, XLg } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import useAppChoices from "../../../hooks/useAppChoices";
import { newContactField } from "../../../helpers/constants";
import { ABORT_ERROR } from "../../../helpers/api";
import { referralService } from "../../../services/referralServices";
import useAuth from "../../../hooks/useAuth";
import useContactStatus from "../../../hooks/useContactStatus";
import { toast } from "react-toastify";
import { contactService } from "../../../services/contactService";
import { camelCase } from "lodash";

const NewContactCard = ({
  tags,
  contact,
  onContactChange,
  onHideNewContact,
  addingNewContact,
  onSaveNewContact,
  onCreateNewReferralClick,
  referrals = [],
  createdReferral,
  openFoundContactPopup,
}) => {
  const { GROUPPED_STATUS } = useContactStatus();
  const users = useAppChoices("users");
  const { translate } = useLocalization();
  const abortControllerRef = useRef(null);
  const { user } = useAuth();

  const [warningMessage, setWarningMessage] = useState({
    fields: {
      "Phone Numbers": null,
      Emails: null,
    },
  });

  const ifContactExists = async (key, values) => {
    const message =
      key === "Phone Numbers"
        ? translate("contact_phone_already_exists")
        : translate("contact_email_already_exists");
    try {
      const requestBody = {
        filter: [
          {
            key: camelCase(key),
            value: values,
          },
        ],
        archived: false,
      };
      const { response, error } = await contactService.getContacts(requestBody);
      if (error) {
        toast.error(error);
        return;
      }

      setWarningMessage((warningMessage) => ({
        fields: {
          ...warningMessage.fields,
          [key]: {
            message: response.results.length ? message : null,
            onClick: () =>
              openFoundContactPopup(response.results[0]?._id || null),
          },
        },
      }));

      return {
        contactExists: response.results.length > 0,
        results: response.results,
        error,
      };
    } catch (error) {
      setLoadingContacts(false);
      console.error(error);
      toast.error("An error occurred while fetching contacts.");
    }
  };

  const validateContactLength = (contacts, key, minLength = 8) => {
    if (contacts.length >= 1) {
      return contacts.filter((c) => c.length >= minLength).length > 0;
    }

    return false;
  };

  useEffect(() => {
    if (validateContactLength(contact.emails, "Emails", 5)) {
      ifContactExists("Emails", contact.emails);
    } else {
      setWarningMessage((warningMessage) => ({
        fields: {
          ...warningMessage.fields,
          Emails: null,
        },
      }));
    }
  }, [contact?.emails]);

  useEffect(() => {
    if (validateContactLength(contact.phoneNumbers, "Phone Numbers")) {
      ifContactExists("Phone Numbers", contact.phoneNumbers);
    } else {
      setWarningMessage((warningMessage) => ({
        fields: {
          ...warningMessage.fields,
          "Phone Numbers": null,
        },
      }));
    }
  }, [contact?.phoneNumbers]);

  const searchReferral = async (query) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    let reqBody = {
      query,
    };

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    let { response, error } = await referralService.getReferrals(
      reqBody,
      signal
    );

    if (error === ABORT_ERROR) return;

    let { results = [] } = response;
    return results?.map((r) => ({ label: r?.name, value: r?._id, ...r }));
  };

  const newContactFields = useMemo(
    () =>
      newContactField({
        users,
        tags,
        translate,
        referrals,
        onCreateNewReferralClick,
        searchReferral,
        user,
        GROUPPED_STATUS,
      }),
    [users, tags, translate, referrals, user, GROUPPED_STATUS]
  );

  useEffect(() => {
    if (createdReferral) {
      onContactChange({ ...contact, referredBy: createdReferral });
    }
  }, [createdReferral]);

  return (
    <div className={`border m-1`}>
      <OverViewColumns
        parentContainerClassName="mid m-0 p-2 bg-light-gray"
        inputCellStyle={{ fontSize: 14 }}
        cellSizeMD={2}
        data={contact}
        onDataChange={(newData) => {
          onContactChange({ ...contact, ...newData });
        }}
        fields={newContactFields}
        isUpdating={addingNewContact}
        alwaysInEditMode={false}
        editable={true}
        warningMessage={warningMessage}
        cellContainerClassName="mb-3"
      />
      <div className="d-flex justify-content-end align-items-center bg-gray border-top border-secondary-dark px-1 py-2">
        <div className="d-flex">
          <Button
            variant="danger"
            className="text-white px-2 py-0 mx-1"
            size="sm"
            disabled={addingNewContact}
            onClick={onHideNewContact}
          >
            <span className="">
              {translate("cancel")} <XLg size={12} />
            </span>
          </Button>
          <Button
            variant="success"
            className="text-white px-2 py-0 mx-1"
            size="sm"
            disabled={addingNewContact}
            onClick={onSaveNewContact}
          >
            {addingNewContact ? (
              <span className="mx-1 px-1 text-white">
                <CircularProgressBar size={12} />
                <span className="mx-1 smallFont">
                  {translate("please_wait")}
                </span>
              </span>
            ) : (
              <span className="">
                {translate("save")} <Check size={15} />
              </span>
            )}
          </Button>{" "}
        </div>
      </div>
    </div>
  );
};

export default NewContactCard;
