import Board from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import "./styles.scss";

import { cloneDeep, snakeCase, uniqueId } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { CalendarDateFill, PersonCircle } from "react-bootstrap-icons";
import ContentLoader from "react-content-loader";
import { toast } from "react-toastify";
import { dealStatusToColor } from "../../../helpers/constants";
import {
  formatCurrency,
  getDateDifferenceInHumanReadableFormat,
  getTextColor,
} from "../../../helpers/global";
import { PERMISSIONS } from "../../../helpers/permissions";
import useAuth from "../../../hooks/useAuth";
import useLocalization from "../../../hooks/useLocalization";
import CircularProgressBar from "../../common/circular-progress";
import ShowAppointmentModal from "./ShowAppointmentModal";

const DealsBoardView = ({
  boardDeals,
  fetchingBoardDeals,
  products,
  boardViewFilters,
  maxHeight,
  updatingProductionStage,
  onProductionStageDragEnd,
  onDealClick,
  showLastAndFirstStage,
}) => {
  let { translate } = useLocalization();
  const [boardData, setBoardData] = useState([]);
  const { checkNestedPermission } = useAuth();
  const [showDealAppointmentMeta, setShowDealAppointmentMeta] = useState(null);
  const [filtering, setFiltering] = useState(false);

  //this triggers an api call, so setting filtering to true
  useEffect(() => {
    setFiltering(true);
  }, [boardViewFilters]);

  //this marks completion of api call, so setting filtering to false
  useEffect(() => {
    setFiltering(false);
  }, [boardData]);

  const handleShowMoreClick = (deal) => {
    setShowDealAppointmentMeta({
      deal,
      appointments: deal?.contactAppointments,
    });
  };

  const getContractCountByProductionStage = (columns) => {
    return columns
      ?.flatMap((col) => col.cards)
      ?.filter((card) => card?.type !== "no-deals").length;
  };

  const getDealsBoardViewData = (
    deals,
    selectedProducts,
    showLastAndFirstStage,
    isFloating,
    isQuitted
  ) => {
    const clonedDeal = cloneDeep(deals);
    const result = selectedProducts?.map((product) => {
      const columns = product?.productionStages.map((stage) => {
        let filteredDeals = clonedDeal
          .filter((deal) => {
            return deal?.units?.some((u) => {
              const isUnitProductionStageAndProductStageMatched =
                u?.productionStage?._id === stage?._id ||
                u?.productionStage === stage?._id;
              const isFloatingMatched = u.isFloating === isFloating;
              const isQuittedMatched = u.isQuitted === isQuitted;
              const isProductEqualToColumnCardsUnit =
                product?._id === u?.unit?._id;

              return (
                isUnitProductionStageAndProductStageMatched &&
                isFloatingMatched &&
                isQuittedMatched &&
                isProductEqualToColumnCardsUnit
              );
            });
          })
          .flatMap((deal) => {
            const allUnitsInThisStage = deal.units.filter(
              (u) =>
                u?.productionStage?._id === stage?._id ||
                u?.productionStage === stage?._id
            );

            return allUnitsInThisStage.map((u) => ({ ...deal, unitId: u._id }));
          });

        let cards =
          filteredDeals?.length < 1
            ? [
                {
                  id: uniqueId(),
                  type: "no-deals",
                },
              ]
            : filteredDeals.map((d) => ({
                id: d?._id + "_" + d.unitId,
                ...d,
              }));

        return {
          id: stage?._id,
          title: `${stage?.step} ${
            cards.length > 0 && cards.some((c) => c.type !== "no-deals")
              ? `(${cards?.length})`
              : ""
          }`,
          cards,
        };
      });
      return {
        product,
        columns,
      };
    });

    const filteredResult = showLastAndFirstStage
      ? result
      : result.map((data) => {
          const { columns, product } = data;
          const filteredCols = columns.slice(1, columns.length - 1);
          const { productionStages } = product;
          const filteredPS = productionStages.slice(
            1,
            productionStages.length - 1
          );
          return {
            columns: filteredCols,
            product: {
              ...product,
              productionStages: filteredPS,
            },
          };
        });

    setBoardData(filteredResult || []);
  };

  const CustomCard = ({ deal = {}, cardBags, board }) => {
    const { status, type, assignedTo } = deal;
    const statusColor = dealStatusToColor[status] || "primary";

    const timeStayedInCurrentProductionStage = useMemo(() => {
      let productionStage = deal?.units?.find(
        (u) => u?.unit?._id === board?.product?._id
      )?.productionStage;

      if (!productionStage?._id) return null;

      const productionStageInDescOrder = deal.productionStageHistory
        ?.filter((pS) => productionStage?._id === pS?.stage)
        .sort((a, b) => new Date(b.updateDate) - new Date(a.updateDate));

      return productionStageInDescOrder?.[0]
        ? getDateDifferenceInHumanReadableFormat(
            productionStageInDescOrder[0].updateDate
          )
        : null;
    }, [deal, board]);

    const priceOfThisUnitCard = useMemo(
      () => deal.units?.find((u) => u._id === deal.unitId)?.total || 0,
      [deal]
    );

    return type === "no-deals" ? (
      <span className="mid text-secondary">No any deals</span>
    ) : (
      <div
        className={`mt-2 h-100 ${
          cardBags?.dragging ? "bg-light border" : "bg-white"
        }`}
        style={{
          width: "210px",
          cursor: "default",
        }}
        onClick={() => onDealClick(deal)}
      >
        <div
          className={`d-flex justify-content-between align-items-center bg-${statusColor}`}
          style={{ padding: 2 }}
        >
          <h6 className="smallFont mx-1 text-white mb-0">
            {translate(snakeCase(status))}
          </h6>{" "}
          <div className="d-flex justify-content-between align-items-center">
            {deal?.contactAppointments?.length > 0 && (
              <div className="d-flex align-items-center text-dark border border-info bg-white rounded-circle p-1 mx-1">
                <CalendarDateFill
                  className="text-dark hover"
                  size={10}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowMoreClick(deal);
                  }}
                />
              </div>
            )}

            {timeStayedInCurrentProductionStage && (
              <div className="d-flex align-items-center text-dark border border-info bg-white rounded p-1">
                <h6 className="mb-0 text-dark fw-bold xs">
                  {timeStayedInCurrentProductionStage?.toUpperCase()}
                </h6>
              </div>
            )}
          </div>
        </div>
        <div className="p-1">
          <div className="d-flex align-items-center mb-1">
            <h6 className="flex-grow-1 text-dark fw-bold mid truncate mb-0">
              {deal?.contact?.firstName} {deal?.contact?.lastName}
            </h6>
            {checkNestedPermission([
              PERMISSIONS.contract_advanced_info
                .VIEW_ALL_CONTRACT_ADVANCED_INFO,
            ]) && (
              <h6 className="mid text-secondary mb-0">
                {formatCurrency(priceOfThisUnitCard)}
              </h6>
            )}
          </div>
          {assignedTo?.length > 0 && (
            <div className="d-flex align-items-center flex-wrap">
              <PersonCircle /> <span className="ms-1">|</span>
              {assignedTo?.map((user) => {
                let userTextColor = getTextColor(user?.color) || "white";
                return (
                  <h6
                    key={user?._id}
                    className="ms-1 tiny px-1 mb-0"
                    style={{
                      background: user?.color,
                      color: userTextColor,
                      paddingTop: 2,
                      paddingBottom: 2,
                      borderRadius: 3,
                    }}
                  >
                    {user?.name}
                  </h6>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  };

  const CustomColumnHeader = (column) => {
    return (
      <div className="truncate mid fw-bold border-bottom">{column?.title}</div>
    );
  };

  const onCardDragEnd = (deal, source, destination) => {
    if (deal?.type === "no-deals") return;
    if (!deal?.editable) {
      toast.error("You are not permitted to edit this deal!");
      return;
    }

    const unitToUpdate = deal?.units?.find((u) => u._id === deal.unitId);

    onProductionStageDragEnd({
      _id: deal?._id,
      unit: {
        _id: unitToUpdate?._id,
        productionStage: destination?.toColumnId,
      },
    });
  };

  useEffect(() => {
    getDealsBoardViewData(
      boardDeals,
      products?.filter((product) =>
        boardViewFilters?.product?.length > 0
          ? boardViewFilters?.product?.includes(product?._id)
          : true
      ),
      showLastAndFirstStage,
      boardViewFilters?.isFloating,
      boardViewFilters?.isQuitted
    );
  }, [boardDeals, products, showLastAndFirstStage]);

  const showLoader = useMemo(() => {
    return filtering || fetchingBoardDeals;
  }, [filtering, fetchingBoardDeals]);

  useEffect(() => {
    console.log("filtering in filtering", filtering);
  }, [filtering]);

  return boardDeals?.length === 0 && showLoader ? (
    <ContentLoader width={"100%"}>
      <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
    </ContentLoader>
  ) : (
    <div
      className="overflow-auto pb-4 px-1 position-relative w-100"
      style={{ height: maxHeight }}
    >
      {showLoader && (
        <div
          className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-50"
          style={{ zIndex: 10 }}
        >
          <CircularProgressBar size={20} />
        </div>
      )}
      {boardData?.map((board, index) => {
        if (getContractCountByProductionStage(board?.columns) === 0) {
          return null;
        }

        return (
          <div key={index} className="bg-white border p-0 mb-2">
            <div className="bg-gray p-1 d-flex align-items-center justify-content-between">
              <h6 className="mid mb-0 fw-bold">
                {board?.product?.description}
                {getContractCountByProductionStage(board?.columns) > 0 &&
                  `(${getContractCountByProductionStage(board?.columns)})`}
              </h6>
            </div>

            <hr className="my-0 border-secondary" />
            {board?.columns?.length === 0 ? (
              <div className="react-kanban-column bg-light-gray d-flex align-items-center p-2 m-2">
                <span className="mid">No any steps to show!</span>
              </div>
            ) : (
              <Board
                renderCard={(deal, cardBags) => (
                  <CustomCard deal={deal} cardBags={cardBags} board={board} />
                )}
                renderColumnHeader={CustomColumnHeader}
                disableColumnDrag
                disableCardDrag={
                  board?.columns?.cards?.[0]?.type === "no-deals" ||
                  updatingProductionStage
                }
                onCardDragEnd={onCardDragEnd}
              >
                {board}
              </Board>
            )}
          </div>
        );
      })}
      <ShowAppointmentModal
        show={Boolean(showDealAppointmentMeta)}
        onHide={() => setShowDealAppointmentMeta(null)}
        deal={showDealAppointmentMeta?.deal}
        showDealAppointmentMeta={showDealAppointmentMeta}
        onCloseAppointmentDetail={() =>
          setShowDealAppointmentMeta({
            ...showDealAppointmentMeta,
            activeAppointment: null,
          })
        }
        onAppointmentClick={(appointment) =>
          setShowDealAppointmentMeta({
            ...showDealAppointmentMeta,
            activeAppointment: appointment,
          })
        }
      />
    </div>
  );
};

export default DealsBoardView;
