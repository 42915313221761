import React, { useEffect, useRef, useMemo } from "react";
import { Chart, LinearScale, CategoryScale } from "chart.js";
import { FunnelController, TrapezoidElement } from "chartjs-chart-funnel";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Loader from "../../../common/Loader";
import { Bar } from "react-chartjs-2";
import { commonChartConfig } from "../../../../helpers/constants";
import useLocalization from "../../../../hooks/useLocalization";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { QuestionCircle } from "react-bootstrap-icons";

Chart.register(
  FunnelController,
  TrapezoidElement,
  LinearScale,
  CategoryScale,
  ChartDataLabels
);

const FunnelChart = ({
  data,
  title,
  aspectRatio,
  loading,
  containerStyle = {},
  barChartData,
  dateRangeLabel,
  tooltipText,
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (loading) return;
    const ctx = canvasRef.current.getContext("2d");

    const datasets = data.datasets;
    const labels = datasets.map((dataset) => dataset.label);
    const values = datasets.map((dataset) => dataset.data[0]);
    const backgroundColors = datasets.map((dataset) => dataset.backgroundColor);

    const chartData = {
      labels,
      datasets: [
        {
          data: values,
          backgroundColor: backgroundColors,
        },
      ],
    };

    const chart = new Chart(ctx, {
      type: "funnel",
      data: chartData,
      options: {
        indexAxis: "y",
        aspectRatio,
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            labels: {
              generateLabels: (chart) => {
                return chartData.labels.map((label, index) => ({
                  text: label,
                  fillStyle: chartData.datasets[0].backgroundColor[index],
                  hidden: false,
                  index: index,
                }));
              },
            },
          },
          datalabels: {
            display: true,
            color: "#fff",
            font: {
              size: 12,
            },
            formatter: (value, ctx) => {
              return `${values[ctx.dataIndex]}`;
            },
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        scales: {
          y: {
            display: false,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data, loading]);

  return (
    <div className="custom-card">
      {loading ? (
        <Loader />
      ) : (
        <>
          <h6 className="large fw-bold mb-2 d-flex align-items-center gap-2">
            {title}
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>{tooltipText}</Tooltip>}
            >
              <QuestionCircle size={14} className="text-secondary" />
            </OverlayTrigger>
          </h6>
          <p className="text-muted mb-2 smallFont">
            Showing data from: {dateRangeLabel}
          </p>
          <div style={containerStyle} className="text-center">
            <div
              style={{
                ...containerStyle,
                height: containerStyle?.height - 200,
              }}
              className="text-center"
            >
              <canvas className="m-auto" ref={canvasRef} />
            </div>
            <FunnelBarChart data={barChartData} />
          </div>
        </>
      )}
    </div>
  );
};

export default FunnelChart;

const FunnelBarChart = ({ data }) => {
  const { isRTL } = useLocalization();

  const commonConfig = useMemo(() => commonChartConfig(isRTL), [isRTL]);

  return (
    <div style={{ height: 180, width: "100%", paddingTop: 10 }}>
      <Bar
        height={170}
        className="w-100 pb-3"
        options={{
          ...commonConfig,

          plugins: {
            ...commonConfig.plugins,
            datalabels: {
              anchor: "end",
              align: "end",
              color: "black",
              font: {
                size: 14,
                weight: "bold",
              },
              offset: 1,
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem, chartData) {
                  const label = data?.labels[tooltipItem.dataIndex];
                  const value = tooltipItem?.parsed.y;
                  return `${label}: ${value}`;
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "black",
                font: {
                  size: 14,
                  family: "Poppins, sans-serif",
                },
              },
              border: {
                display: false,
              },
            },
            y: {
              display: false,
            },
          },
          layout: {
            padding: {
              top: 20,
              bottom: 5,
            },
          },
        }}
        data={data}
      />
    </div>
  );
};
