import React, { useEffect, useState } from "react";
import AppModal from "../../common/AppModal";
import html2pdf from "html-to-pdf-js";
import edjsHTML from "editorjs-html";
import { Button } from "react-bootstrap";

const ViewTemplateModal = ({ show, onHide, template = null }) => {
  const [html, setHtml] = useState("");

  const handleEdjsToHTML = (data) => {
    const edjsParser = edjsHTML();
    let html = edjsParser.parse(data);
    setHtml(html);
  };

  const downloadTemplate = async () => {
    const htmlWithBase64Images = await convertHtmlImagesToBase64(html);
    const options = {
      margin: 10,
      filename: "document.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 2,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    await html2pdf(htmlWithBase64Images, options).save();
  };

  const convertHtmlImagesToBase64 = async (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const images = doc.querySelectorAll("img");

    for (let img of images) {
      if (!img.src.startsWith("data:image")) {
        // Skip if already Base64
        const base64 = await convertImageToBase64(img.src);
        if (base64) img.src = base64; // Replace image URL with Base64
      }
    }

    return doc.documentElement.outerHTML; // Return updated HTML as string
  };
  const convertImageToBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl, {
        method: "GET",
        credentials: "include", // ✅ Ensures authentication cookies are sent
      });

      console.log("response", response);

      if (!response.ok) throw new Error("Failed to fetch image");

      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
      });
    } catch (error) {
      console.error("Error converting image to Base64:", error);
      return null;
    }
  };

  useEffect(() => {
    if (template) {
      handleEdjsToHTML(template?.body);
    }
  }, [template]);
  return (
    <AppModal
      show={show}
      onHide={onHide}
      title={template?.name}
      size={"lg"}
      modalBodyClassName="py-2"
    >
      <div>
        <h6 style={{ fontSize: 24, fontWeight: 500 }} className="mb-4">
          {template?.name}
        </h6>
        //Todo: Add viewer
        <Button onClick={downloadTemplate}>Download</Button>
      </div>
    </AppModal>
  );
};

export default ViewTemplateModal;
