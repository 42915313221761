import { isNumber } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Send, Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { useSearchAndFilterBox } from "../../context/SearchAndFilterContext";
import { BROADCAST_TYPES, useWebSocket } from "../../context/WebSocketContext";
import { ABORT_ERROR, makeRESTApiRequests } from "../../helpers/api";
import {
  DEAL_VIEWS,
  DEFAULT_PAGE_SIZE,
  ENDPOINTS,
} from "../../helpers/constants";
import {
  getDealsTableColumns,
  getProductsOnDealsTableColumns,
} from "../../helpers/dataSheetConstants";
import {
  downloadFileFromString,
  updateItemsInArray,
} from "../../helpers/global";
import { PERMISSIONS } from "../../helpers/permissions";
import { isAdminOrManager } from "../../helpers/session";
import useAppChoices from "../../hooks/useAppChoices";
import useAuth from "../../hooks/useAuth";
import useContactAndDealPopup from "../../hooks/useContactAndDealPopup";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import useLocalization from "../../hooks/useLocalization";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import useSelection from "../../hooks/useSelection";
import { contractService } from "../../services/contractService";
import AiChatBotSideBar from "../common/AiChatBotSideBar";
import AlertModal from "../common/AlertModal";
import DataTable from "../common/data-table/DataTable";
import DealsBoardView from "./board-view/DealsBoardView";
import ShowAppointmentModal from "./board-view/ShowAppointmentModal";
import CreateDealModal from "./CreateDealModal";
import DealsTabAndFilter from "./DealsTabAndFilter";
import ProsperoContractEditAndSendSidebar from "./ProsperoContractEditSidebar";

const checkIfDealHasRemainingPayment = (deal) => {
  const { payments = [], proposalQuote } = deal;
  const totalPayment = payments?.reduce((a, c) => a + c.amount, 0);

  return Math.round(totalPayment) !== Math.round(proposalQuote);
};

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const BrowseDeals = () => {
  const abortControllerRef = useRef(null);
  const { message } = useWebSocket();
  const users = useAppChoices("users");

  const products = useAppChoices("products");
  const units = useAppChoices("units");
  const productProductionStages = useAppChoices("productProductionStages");

  const { translate } = useLocalization();
  const { user, checkNestedPermission } = useAuth();
  const { searchBoxOptions } = useSearchAndFilterBox();

  const { openDealPopup, updatedDeal, updatedContact } =
    useContactAndDealPopup();

  const [activeView, setActiveView] = useState(DEAL_VIEWS[0].name);
  const [tableFilterValues, setTableFilterValues] = useState([
    { key: "status", value: ["Signed"] },
  ]);
  const [tableSortOption, setTableSortOption] = useState({
    key: "signedOn",
    order: "desc",
  });
  const [fetchingFirstPageDeals, setFetchingFirstPageDeals] = useState(false);
  const [fetchingMoreDeals, setFetchingMoreDeals] = useState(false);
  const [deleteContractModalMeta, setDeleteContractModalMeta] = useState(null);
  const [boardViewFilters, setBoardViewFilters] = useState({
    status: ["Signed"],
  });

  // board view deal dragging state
  const [updatingProductionStage, setUpdatingProductionStage] = useState(false);

  //original products array
  const [tableDeals, setTableDeals] = useState([]);
  const [toLoadPageInfo, setToLoadPageInfo] = useState(null);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  // new deal
  const [newDealModalMeta, setNewDealModalMeta] = useState(null);
  const [prosperoContractEditMeta, setProsperoContractEditMeta] =
    useState(null);

  // board deals
  const boardDealabortControllerRef = useRef(null);

  const [boardDeals, setBoardDeals] = useState([]);
  const [fetchingBoardDeals, setFetchingBoardDeals] = useState(false);
  const [showFloatingContract, setShowFloatingContract] = useState(false);
  const [showDealsWithRemainingPayment, setShowDealsWithRemainingPayment] =
    useState(false);
  const [tableScrollTop, setTableScrollTop] = useState();
  const [lastUpdatedContract, setLastUpdatedContract] = useState(null);
  const [isDownloadingDeals, setIsDownloadingDeals] = useState(false);
  const [isDownloadingProducts, setIsDownloadingProducts] = useState(false);

  //products view
  const isProductView = useMemo(() => activeView === "product", [activeView]);

  const [
    fetchingFirstPageProductsOnDeals,
    setFetchingFirstPageProductsOnDeals,
  ] = useState(false);
  const [fetchingMoreProductsOnDeals, setFetchingMoreProductsOnDeals] =
    useState(false);
  const [tableProductsOnDeals, setTableProductsOnDeals] = useState([]);
  const [toLoadPageProductsOnDealsInfo, setToLoadPageProductsOnDealsInfo] =
    useState(null);
  const [loadedPageProductsOnDealsInfo, setLoadedPageProductsOnDealsInfo] =
    useState();
  const abortControllerRefForProducts = useRef(null);
  const [showAllFields, setShowAllFields] = useState(false);
  const [showLastAndFirstStage, setShowLastAndFirstStage] = useState(false);
  const { screenWidth } = useScreenWidth();
  const mobileView = useMemo(() => screenWidth <= 992, [screenWidth]);
  const [showAiChatBot, setShowAiChatBot] = useState(false);
  const [aiQueryMetaData, setAiQueryMetaData] = useState(null);
  const [showQuittedContract, setShowQuittedContract] = useState(false);

  const {
    selectAllMode,
    selectedItems,
    unselectedItems,
    isItemSelected,
    onToggleSelectItem,
    onToggleSelectAll,
    clearSelection,
  } = useSelection(tableDeals);

  const {
    selectAllMode: selectAllModeForProducts,
    selectedItems: selectedItemsForProducts,
    unselectedItems: unselectedItemsForProducts,
    isItemSelected: isItemSelectedForProducts,
    onToggleSelectItem: onToggleSelectItemForProducts,
    onToggleSelectAll: onToggleSelectAllForProducts,
  } = useSelection(tableProductsOnDeals);

  const [showDealAppointmentMeta, setShowDealAppointmentMeta] = useState(null);

  const handleShowMoreClick = (deal) => {
    setShowDealAppointmentMeta({
      deal,
      appointments: deal?.contactAppointments,
    });
  };

  const onBoardFiltersChange = (filters) => {
    const { product = [], assignedTo = [], status = [] } = filters;

    const productsToSearch = units
      .filter((u) => product.includes(u._id))
      .map((u) => u._id);

    const newFilters = [
      { key: "units.unit", value: productsToSearch },
      { key: "assignedTo", value: assignedTo },
      { key: "status", value: status },
    ];
    const updatedTableFilters = updateItemsInArray(
      tableFilterValues,
      newFilters,
      "key"
    );
    setTableFilterValues(updatedTableFilters);
    setBoardViewFilters(filters);
  };

  const onTableFilterValueChange = (filters) => {
    const status = filters.find((f) => f.key === "status")?.value;
    const description = filters.find((f) => f.key === "description")?.value;
    setBoardViewFilters({
      ...boardViewFilters,
      status: status || [],
      description: description || [],
    });
    setTableFilterValues(filters);
  };

  const tableColumns = useMemo(
    () =>
      getDealsTableColumns({
        user,
        columnToHide: [],
        users,
        products,
        onToggleSelectAll,
        onToggleSelectItem,
        isItemSelected,
        selectAllMode,
        translate,
        handleShowMoreClick,
      }),
    [
      user,
      users,
      products,
      onToggleSelectAll,
      isItemSelected,
      onToggleSelectItem,
      selectAllMode,
      handleShowMoreClick,
    ]
  );

  const tableColumnsForProducts = useMemo(
    () =>
      getProductsOnDealsTableColumns({
        user,
        columnToHide: [],
        users,
        products,
        units,
        showAllFields,
        translate,
        onToggleSelectAll: onToggleSelectAllForProducts,
        onToggleSelectItem: onToggleSelectItemForProducts,
        isItemSelected: isItemSelectedForProducts,
        selectAllMode: selectAllModeForProducts,
        productProductionStages,
      }),
    [
      user,
      users,
      tableProductsOnDeals,
      products,
      onToggleSelectAllForProducts,
      onToggleSelectItemForProducts,
      isItemSelectedForProducts,
      selectAllModeForProducts,
      productProductionStages,
    ]
  );

  const scrollToContactRow = (scrollTop) => {
    const containerElement = document.getElementById("table-container");
    const offset = -80; // Adjust this value based on the height of your fixed element

    if (containerElement) {
      containerElement.scroll({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const fetchDeals = async () => {
    if (
      !searchBoxOptions ||
      !toLoadPageInfo ||
      (searchBoxOptions?.query && searchBoxOptions?.query.length < 2)
    ) {
      return;
    }

    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const { pageNumber: pageToFetch, isNotInitialFetch = false } =
      toLoadPageInfo;

    const loadingMoreProducts = pageToFetch > 1;

    if (loadingMoreProducts) {
      setFetchingMoreDeals(true);
    } else if (!isNotInitialFetch) {
      setFetchingFirstPageDeals(true);
    }

    const { query } = searchBoxOptions;
    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    //on show deals with remaining payment, add status filter to make sure only finished deals with remaining payment are fetched
    let updatedFilters = showDealsWithRemainingPayment
      ? [
          ...tableFilterValues.filter((filter) => filter.key !== "status"),
          { key: "status", value: "Deal Finished" },
        ]
      : tableFilterValues;

    //add isQuitted and isFloating filters
    updatedFilters = [
      ...updatedFilters,
      { key: "units.isFloating", value: showFloatingContract },
      { key: "units.isQuitted", value: showQuittedContract },
    ];

    const requestBody = {
      query,
      pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
      pageNumber: pageToFetch,
      sort: tableSortOption,
      filter: updatedFilters,
      aiChatPromptId: aiQueryMetaData ? aiQueryMetaData._id : undefined,
    };

    const { response, error } = await contractService.getContracts(
      requestBody,
      signal
    );

    if (error === ABORT_ERROR) return;

    setFetchingMoreDeals(false);
    setFetchingFirstPageDeals(false);

    if (!isNotInitialFetch) {
      clearSelection();
    }

    if (error) {
      return toast.error(error);
    }

    if (isNumber(toLoadPageInfo?.scrollTop)) {
      scrollToContactRow(toLoadPageInfo?.scrollTop);
    }

    let { pageNumber, totalPages, results = [], resultCount } = response;

    results = showDealsWithRemainingPayment
      ? results.filter(checkIfDealHasRemainingPayment)
      : results;

    setLoadedPageInfo({ totalPages, pageNumber, resultCount });

    setTableDeals((prevProducts) => {
      if (loadingMoreProducts) {
        const existingIds = new Set(prevProducts.map((deal) => deal._id));
        const uniqueResults = results.filter(
          (deal) => !existingIds.has(deal._id)
        );
        return [...prevProducts, ...uniqueResults];
      } else {
        const seenIds = new Set();
        return results.filter((deal) => {
          const isUnique = !seenIds.has(deal._id);
          if (isUnique) seenIds.add(deal._id);
          return isUnique;
        });
      }
    });
  };

  const fetchBoardDeals = async () => {
    if (
      !searchBoxOptions ||
      !toLoadPageInfo ||
      (searchBoxOptions?.query && searchBoxOptions?.query.length < 2)
    ) {
      return;
    }

    // If an old API call is in progress, abort it
    try {
      if (boardDealabortControllerRef.current) {
        boardDealabortControllerRef.current.abort();
      }

      const { isNotInitialFetch = false } = toLoadPageInfo;
      if (!isNotInitialFetch) {
        setFetchingBoardDeals(true);
      }

      const controller = new AbortController();
      const { signal } = controller;

      boardDealabortControllerRef.current = controller;
      let { query } = searchBoxOptions;
      const requestBody = {
        query,
        filter: [
          { key: "units.unit", value: boardViewFilters?.product },
          { key: "assignedTo", value: boardViewFilters?.assignedTo },
          { key: "status", value: boardViewFilters?.status },
          { key: "units.isFloating", value: showFloatingContract },
          { key: "units.isQuitted", value: showQuittedContract },
        ],
        attachContactAppointments: true,
        aiChatPromptId: aiQueryMetaData ? aiQueryMetaData._id : undefined,
      };

      const { response, error } = await contractService.getContracts(
        requestBody,
        signal
      );

      if (error === ABORT_ERROR) return;

      setFetchingBoardDeals(false);

      if (error) {
        return toast.error(error);
      }
      const { results = [] } = response;
      setBoardDeals(results);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProductsOnDeals = async () => {
    if (
      !searchBoxOptions ||
      !toLoadPageProductsOnDealsInfo ||
      !isProductView ||
      (searchBoxOptions?.query && searchBoxOptions?.query.length < 2)
    ) {
      return;
    }

    // If an old API call is in progress, abort it
    if (abortControllerRefForProducts.current) {
      abortControllerRefForProducts.current.abort();
    }

    const { pageNumber: pageToFetch, isNotInitialFetch = false } =
      toLoadPageProductsOnDealsInfo;

    if (!isNotInitialFetch) {
      setFetchingFirstPageProductsOnDeals(true);
    }

    const { query } = searchBoxOptions;
    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRefForProducts.current = controller;

    const requestBody = {
      query,
      sort: tableSortOption,
      filter: [
        ...tableFilterValues,
        { key: "isFloating", value: showFloatingContract },
        { key: "isQuitted", value: showQuittedContract },
      ],
      aiChatPromptId: aiQueryMetaData ? aiQueryMetaData._id : undefined,
    };

    const { response, error } = await contractService.getProductsOnContracts(
      requestBody,
      signal
    );

    if (error === ABORT_ERROR) {
      setFetchingMoreProductsOnDeals(false);
      setFetchingFirstPageProductsOnDeals(false);
      return;
    }

    if (response) {
      setFetchingMoreProductsOnDeals(false);
    }

    if (!isNotInitialFetch) {
      setFetchingFirstPageProductsOnDeals(false);
    }

    if (error) {
      setFetchingMoreProductsOnDeals(false);
      return;
    }

    let products = response || [];

    if (showDealsWithRemainingPayment) {
      products = products.filter(
        (item) => item.contract && checkIfDealHasRemainingPayment(item.contract)
      );
    }

    // Only append data if loading more products (pagination), otherwise replace the existing data
    setTableProductsOnDeals(products);

    const totalItems = products.length;
    const pageSize =
      toLoadPageProductsOnDealsInfo?.pageSize || DEFAULT_PAGE_SIZE;
    const totalPages = Math.ceil(totalItems / pageSize);

    setLoadedPageProductsOnDealsInfo({
      totalPages,
      pageNumber: pageToFetch,
      resultCount: totalItems,
    });
  };

  const fetchDealsAfterUpdate = (scrollTop) => {
    // fetches contacts and scrolls back to previous position
    setToLoadPageInfo({
      ...loadedPageInfo,
      pageSize: loadedPageInfo?.pageNumber * DEFAULT_PAGE_SIZE,
      pageNumber: 1,
      scrollTop,
      isNotInitialFetch: true,
    });

    setToLoadPageProductsOnDealsInfo({
      ...loadedPageProductsOnDealsInfo,
      scrollTop,
      isNotInitialFetch: true,
    });
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageDeals || fetchingMoreDeals) return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
      scrollTop: undefined,
      isNotInitialFetch: true,
    });
  };

  const loadMoreProductsOnDealsData = () => {
    if (
      !loadedPageProductsOnDealsInfo ||
      fetchingFirstPageProductsOnDeals ||
      fetchingMoreProductsOnDeals
    )
      return;

    if (
      loadedPageProductsOnDealsInfo.totalPages <
      loadedPageProductsOnDealsInfo.pageNumber
    )
      return;

    setToLoadPageProductsOnDealsInfo({
      ...loadedPageProductsOnDealsInfo,
      pageNumber: loadedPageProductsOnDealsInfo.pageNumber + 1,
      scrollTop: undefined,
      isNotInitialFetch: true,
    });
  };

  const onDealClick = useCallback((contract) => {
    openDealPopup(contract?._id);
  }, []);

  const onProductDealClick = useCallback((row) => {
    openDealPopup(row?.contract?._id);
  }, []);

  const onDeleteSelectedContractClick = () => {
    setDeleteContractModalMeta({
      contractToBeDeleted: selectedItems,
      showProgress: false,
    });
  };

  const deleteSelectedDeals = async () => {
    const { contractToBeDeleted } = deleteContractModalMeta;

    setDeleteContractModalMeta((deleteModalMeta) => ({
      ...deleteModalMeta,
      showProgress: true,
    }));

    try {
      const { response, error } = await contractService.deleteSelectedContract({
        ids: contractToBeDeleted?.map((deal) => deal._id),
      });
      if (response) {
        let updatedDeals = tableDeals.filter(
          (deal) => !contractToBeDeleted.find((c) => c._id === deal?._id)
        );
        setTableDeals(updatedDeals);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteContractModalMeta(null);
    }
  };

  const onCreateNewDealClick = () => {
    setNewDealModalMeta({});
  };

  const onNewDealSubmit = async (contractDetails) => {
    setNewDealModalMeta({ showProgress: true });
    const { sendImmediately } = contractDetails;

    const { response, error } = await contractService.createContract(
      contractDetails
    );

    if (error) {
      setNewDealModalMeta({ showProgress: false });
      return toast.error(error);
    }

    setNewDealModalMeta(null);
    if (response) {
      let updatedDeals = [response, ...tableDeals];
      setTableDeals(updatedDeals);
      setLoadedPageInfo({
        ...loadedPageInfo,
        resultCount: (loadedPageInfo?.resultCount || 0) + 1,
      });
    }

    if (sendImmediately) {
      toast.success("Deal Sent Successfully");
      return;
    }

    setProsperoContractEditMeta({ contract: response });
  };

  const onContractEditAndSendClick = async () => {
    const { contract } = prosperoContractEditMeta;
    setProsperoContractEditMeta({
      ...prosperoContractEditMeta,
      showProgress: true,
    });

    const { response, error } = await contractService.sendContractViaEmail({
      contractId: contract._id,
    });

    setProsperoContractEditMeta({
      ...prosperoContractEditMeta,
      showProgress: false,
    });

    if (error) {
      return toast.error(error);
    }

    toast.success("Deal Sent Successfully");

    setProsperoContractEditMeta(null);
  };

  const onProductionStageDragEnd = async (reqBody) => {
    try {
      setUpdatingProductionStage(true);
      const contractUpdatePayload = reqBody;

      let originalTableDeals = [...tableDeals];
      let originalBoardDeals = [...boardDeals];

      let updatedDeal = boardDeals?.find(
        (d) => contractUpdatePayload?._id === d?._id
      );

      updatedDeal.units = updatedDeal?.units?.map((u) =>
        u._id === contractUpdatePayload?.unit?._id
          ? {
              ...u,
              productionStage: contractUpdatePayload?.unit?.productionStage,
            }
          : u
      );

      const updatedTableDeals = [
        ...updateItemsInArray(originalTableDeals, updatedDeal),
      ];
      const updatedBoardDeals = [
        ...updateItemsInArray(boardDeals, updatedDeal),
      ];

      setTableDeals(updatedTableDeals);
      setBoardDeals(updatedBoardDeals);

      const { response, error } = await contractService.updateContractUnit(
        contractUpdatePayload._id,
        contractUpdatePayload.unit
      );

      if (error) {
        toast.error(error);
        setBoardDeals(originalBoardDeals);
        setTableDeals(originalTableDeals);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatingProductionStage(false);
    }
  };

  const toggleRemainingPaymentDealFilter = (checked) => {
    setShowDealsWithRemainingPayment(checked);
    clearSelection();
  };

  const downloadContractedBuyersCSV = async () => {
    //on show deals with remaining payment, add status filter to make sure only finished deals with remaining payment are fetched
    let updatedFilters = showDealsWithRemainingPayment
      ? [
          ...tableFilterValues.filter((filter) => filter.key !== "status"),
          { key: "status", value: "Deal Finished" },
        ]
      : tableFilterValues;

    //add isQuitted and isFloating filters
    updatedFilters = [
      ...updatedFilters,
      { key: "units.isFloating", value: showFloatingContract },
      { key: "units.isQuitted", value: showQuittedContract },
    ];

    const { query } = searchBoxOptions;

    const requestBody = {
      query,
      sort: tableSortOption,
      filter: updatedFilters,
      aiChatPromptId: aiQueryMetaData ? aiQueryMetaData._id : undefined,
      timezoneOffset: new Date().getTimezoneOffset(),
    };

    toast.info("File will be downloaded shortly...");
    setIsDownloadingDeals(true);
    const { response, error } = await makeRESTApiRequests({
      requestBody,
      endpoint: ENDPOINTS.CONTRACTS_DOWNLOAD_CSV,
      method: "POST",
    });
    setIsDownloadingDeals(false);

    if (error) {
      toast.error(error);
      console.log("error:", error);
      return;
    }

    const { csv } = response;
    downloadFileFromString({ data: csv, fileName: "Contracts.csv" });
  };

  const downloadContractProductsCSV = async () => {
    const { query } = searchBoxOptions;

    const requestBody = {
      query,
      sort: tableSortOption,
      filter: [
        ...tableFilterValues,
        { key: "isFloating", value: showFloatingContract },
        { key: "isQuitted", value: showQuittedContract },
      ],
      aiChatPromptId: aiQueryMetaData ? aiQueryMetaData._id : undefined,
      timezoneOffset: new Date().getTimezoneOffset(),
    };

    toast.info("File will be downloaded shortly...");
    setIsDownloadingProducts(true);
    const { response, error } = await makeRESTApiRequests({
      requestBody,
      endpoint: ENDPOINTS.CONTRACTS_PRODUCTS_DOWNLOAD_CSV,
      method: "POST",
    });
    setIsDownloadingProducts(false);

    if (error) {
      toast.error(error);
      console.log("error:", error);
      return;
    }

    const { csv } = response;
    downloadFileFromString({ data: csv, fileName: "Products.csv" });
  };

  const toggleAiChatBot = () => {
    setShowAiChatBot((prev) => !prev);
  };

  useEffect(() => {
    if (activeView === "table") {
      fetchDeals();
    }
  }, [toLoadPageInfo]);

  useEffect(() => {
    if (activeView === "board") {
      fetchBoardDeals();
    }
  }, [toLoadPageInfo]);

  useEffect(() => {
    if (isProductView) {
      fetchProductsOnDeals();
    }
  }, [toLoadPageProductsOnDealsInfo]);

  //reset to load page info when filters change to make fresh api call
  useDebouncedEffect(
    () => {
      if (activeView === "table" || activeView === "board") {
        setToLoadPageInfo({
          ...initialPageInfo,
        });
      }
      if (isProductView) {
        setToLoadPageProductsOnDealsInfo({
          ...initialPageInfo,
        });
      }
    },
    [
      activeView,
      tableFilterValues,
      tableSortOption,
      searchBoxOptions,
      showDealsWithRemainingPayment,
      isProductView,
      boardViewFilters,
      showQuittedContract,
      showFloatingContract,
      aiQueryMetaData,
    ],
    100
  );

  useEffect(() => {
    // update deals if popup deal changes
    if (Boolean(updatedDeal)) {
      let { action, deal } = updatedDeal;

      if (action === "delete" || action === "update") {
        fetchDealsAfterUpdate(tableScrollTop);
      }
    }
  }, [updatedDeal, boardViewFilters]);

  useEffect(() => {
    // update deals if contact changes to show in table
    if (Boolean(updatedContact)) {
      let { action, contact } = updatedContact;
      if (action === "delete") {
        let updatedTableDeals = tableDeals?.filter(
          (d) => d?.contact?._id !== contact?._id
        );
        let updatedBoardDeals = boardDeals?.filter(
          (d) => d?.contact?._id !== contact?._id
        );
        setTableDeals(updatedTableDeals);
        setBoardDeals(updatedBoardDeals);
      }
      if (action === "update") {
        let updatedTableDeals = tableDeals.map((deal) => {
          if (deal?.contact?._id === contact?._id) {
            return { ...deal, contact };
          }
          return deal;
        });
        let updatedBoardDeals = boardDeals.map((deal) => {
          if (deal?.contact?._id === contact?._id) {
            return { ...deal, contact };
          }
          return deal;
        });
        setTableDeals(updatedTableDeals);
        setBoardDeals(updatedBoardDeals);
      }
    }
  }, [updatedContact]);

  useDebouncedEffect(
    () => {
      if (!message) return;

      const { type, payload } = message;

      switch (type) {
        case BROADCAST_TYPES.CONTRACT_UPDATE:
          fetchDealsAfterUpdate(tableScrollTop);
          setLastUpdatedContract(payload._id);
          break;

        case BROADCAST_TYPES.CONTRACT_CREATE:
          fetchDealsAfterUpdate(tableScrollTop);
          break;
      }
    },
    [message],
    500
  );

  useEffect(() => {
    if (!message) return;

    const { type, payload } = message;

    const updateDeals = (dealsList, updateFn) => dealsList.map(updateFn);

    const filterDeals = (dealsList, filterFn) => dealsList.filter(filterFn);

    switch (type) {
      case BROADCAST_TYPES.CONTACT_UPDATE:
        if (payload?._id) {
          const updateContact = (deal) =>
            deal?.contact?._id === payload._id
              ? { ...deal, contact: payload }
              : deal;

          setTableDeals(updateDeals(tableDeals, updateContact));
          setBoardDeals(updateDeals(boardDeals, updateContact));
        }
        break;

      case BROADCAST_TYPES.CONTACT_DELETE:
        if (payload?._id) {
          const filterContact = (deal) => deal?.contact?._id !== payload._id;

          setTableDeals(filterDeals(tableDeals, filterContact));
          setBoardDeals(filterDeals(boardDeals, filterContact));
        }
        break;

      case BROADCAST_TYPES.CONTRACT_DELETE:
        const filterContract = (deal) => deal._id !== payload._id;

        setTableDeals(filterDeals(tableDeals, filterContract));
        setBoardDeals(filterDeals(boardDeals, filterContract));
        break;
    }
  }, [message]);

  useEffect(() => {
    setActiveView(DEAL_VIEWS[0].name);
  }, []);

  useEffect(() => {
    if (!isAdminOrManager(user.role)) {
      setBoardViewFilters((prevFilter) => ({
        ...prevFilter,
        assignedTo: [user._id],
      }));
    }
  }, [user]);

  useEffect(() => {
    if (!lastUpdatedContract) return;
    setTimeout(() => {
      setLastUpdatedContract(null);
    }, 500);
  }, [lastUpdatedContract]);

  const totalPageDate = useMemo(
    () => loadedPageInfo?.resultCount,
    [loadedPageInfo]
  );

  const selectionMetaData = useMemo(() => {
    if (activeView === "product") {
      return {
        selectedItems: selectAllModeForProducts
          ? tableProductsOnDeals?.length - unselectedItemsForProducts?.length
          : selectedItemsForProducts?.length,
        totalItems: tableProductsOnDeals?.length,
      };
    } else {
      return {
        selectedItems: selectAllMode
          ? totalPageDate - unselectedItems?.length
          : selectedItems?.length,
        totalItems: totalPageDate,
      };
    }
  }, [
    selectAllMode,
    selectAllModeForProducts,
    tableDeals,
    tableProductsOnDeals,
    unselectedItems,
    unselectedItemsForProducts,
    totalPageDate,
    selectedItems,
    selectedItemsForProducts,
    activeView,
  ]);

  const boardViewFilterToPass = useMemo(() => {
    return {
      ...boardViewFilters,
      isFloating: showFloatingContract,
      isQuitted: showQuittedContract,
    };
  }, [boardViewFilters, showFloatingContract, showQuittedContract]);

  return (
    <>
      <Container
        fluid
        className={`d-flex flex-column px-2 pb-2`}
        style={{
          height: "calc(100vh - 78px)",
          overflow: "hidden",
        }}
      >
        <div className={`flex-grow-1 mb-4 h-100`}>
          <DealsTabAndFilter
            activeView={activeView}
            setActiveView={setActiveView}
            products={units}
            users={users}
            deals={tableDeals}
            boardViewFilters={boardViewFilters}
            onBoardFiltersChange={onBoardFiltersChange}
            showFloatingContract={showFloatingContract}
            setShowFloatingContract={setShowFloatingContract}
            showDealsWithRemainingPayment={showDealsWithRemainingPayment}
            toggleRemainingPaymentDealFilter={toggleRemainingPaymentDealFilter}
            isDownloadingDeals={isDownloadingDeals}
            downloadContractedBuyersCSV={downloadContractedBuyersCSV}
            isDownloadingProducts={isDownloadingProducts}
            downloadContractProductsCSV={downloadContractProductsCSV}
            showProductsOnDeals={isProductView}
            showAllFields={showAllFields}
            setShowAllFields={setShowAllFields}
            showLastAndFirstStage={showLastAndFirstStage}
            setShowLastAndFirstStage={setShowLastAndFirstStage}
            toggleAiChatBot={toggleAiChatBot}
            showQuittedContract={showQuittedContract}
            setShowQuittedContract={setShowQuittedContract}
          />

          <div className="d-flex w-100 h-100">
            <AiChatBotSideBar
              onHide={() => setShowAiChatBot(false)}
              show={showAiChatBot}
              activeAiQuery={aiQueryMetaData}
              onActiveAiQueryChange={setAiQueryMetaData}
              width={showAiChatBot ? (mobileView ? "100%" : "20%") : "0%"}
              height={`calc(100% - ${mobileView ? "148px" : "82px"})`}
              modelName={"Contract"}
              recentSearchHeight="calc(100vh * 0.6)"
              mobileView={mobileView}
            />

            <div
              style={{
                width: showAiChatBot ? (mobileView ? "0%" : "80%") : "100%",
                margin: showAiChatBot ? "0px 5px" : "0px",
                height: "100%",
              }}
            >
              {Boolean(activeView === "table" || activeView === "product") && (
                <DataTable
                  maxTableHeight={`calc(100% - ${
                    mobileView ? "148px" : "82px"
                  })`}
                  rowKey={"_id"}
                  columns={
                    isProductView ? tableColumnsForProducts : tableColumns
                  }
                  data={isProductView ? tableProductsOnDeals : tableDeals}
                  rowToHighlight={lastUpdatedContract}
                  sortOptions={tableSortOption}
                  onTableScrollTopChange={setTableScrollTop}
                  onSortChange={setTableSortOption}
                  filterValues={tableFilterValues}
                  onFilterValuesChange={onTableFilterValueChange}
                  onRowClick={isProductView ? onProductDealClick : onDealClick}
                  onBottomReached={
                    isProductView ? loadMoreProductsOnDealsData : loadMoreData
                  }
                  loadingMoreData={
                    isProductView
                      ? fetchingMoreProductsOnDeals
                      : fetchingMoreDeals
                  }
                  bottomOffset={50}
                  loadingFirstPageData={
                    isProductView
                      ? fetchingFirstPageProductsOnDeals
                      : fetchingFirstPageDeals
                  }
                  allowFilter={true}
                  allowSort={true}
                />
              )}
              {activeView === "board" && (
                <DealsBoardView
                  fetchingBoardDeals={fetchingBoardDeals}
                  maxHeight={"calc(100vh - 135px)"}
                  boardDeals={boardDeals}
                  boardViewFilters={boardViewFilterToPass}
                  products={units}
                  onDealClick={onDealClick}
                  updatingProductionStage={updatingProductionStage}
                  onProductionStageDragEnd={onProductionStageDragEnd}
                  showLastAndFirstStage={showLastAndFirstStage}
                />
              )}
            </div>
          </div>
        </div>

        <div
          className="d-flex align-items-center py-1 w-100 bg-white border-top"
          style={{ position: "fixed", bottom: 0, left: 0 }}
        >
          <div className="flex-grow-1 d-flex align-items-center">
            {/* Show selected contact count */}
            {checkNestedPermission([
              PERMISSIONS.contracts.CREATE_ALL_CONTRACTS,
            ]) && (
              <Button
                size="sm"
                onClick={onCreateNewDealClick}
                variant="success"
                className="align-items-center d-flex text-white py-0 mx-1"
              >
                <Send size={10} className="mx-1" />
                {translate("create_new_deal")}
              </Button>
            )}

            {(activeView === "table" || activeView === "product") && (
              <>
                <Button
                  className="smallFont py-0 mx-1"
                  variant="dark"
                  size="sm"
                >
                  <span className="text-white smallFont mx-1">
                    {selectionMetaData.selectedItems} /{" "}
                    {selectionMetaData.totalItems ?? 0} Selected
                  </span>
                </Button>
                {/* Delete selected contact button */}
                {isAdminOrManager(user?.role) &&
                  !selectAllMode &&
                  selectedItems?.length > 0 &&
                  activeView === "table" && (
                    <Button
                      variant="outline-dark"
                      size="sm"
                      className="px-1 py-0 mx-1"
                      onClick={onDeleteSelectedContractClick}
                    >
                      <Trash className="mb-1" />
                    </Button>
                  )}
                {/* Edit selected contact button */}
              </>
            )}
          </div>
        </div>
      </Container>
      {selectedItems.length > 0 && (
        <AlertModal
          show={Boolean(deleteContractModalMeta)}
          onHide={() => setDeleteContractModalMeta(false)}
          onDismissClick={() => setDeleteContractModalMeta(false)}
          alertText={translate("are_you_sure_you_want_to_delete_contracts")}
          showProgress={deleteContractModalMeta?.showProgress}
          onContinueClick={deleteSelectedDeals}
        />
      )}

      <CreateDealModal
        show={Boolean(newDealModalMeta)}
        showProgress={newDealModalMeta?.showProgress}
        onHide={() => setNewDealModalMeta(null)}
        onSubmit={onNewDealSubmit}
      />
      <ProsperoContractEditAndSendSidebar
        contract={prosperoContractEditMeta?.contract}
        show={Boolean(prosperoContractEditMeta)}
        onHide={() => setProsperoContractEditMeta(false)}
        onContractEditAndSendClick={onContractEditAndSendClick}
        showProgress={prosperoContractEditMeta?.showProgress}
      />
      <ShowAppointmentModal
        show={Boolean(showDealAppointmentMeta)}
        onHide={() => setShowDealAppointmentMeta(null)}
        deal={showDealAppointmentMeta?.deal}
        showDealAppointmentMeta={showDealAppointmentMeta}
        onCloseAppointmentDetail={() =>
          setShowDealAppointmentMeta({
            ...showDealAppointmentMeta,
            activeAppointment: null,
          })
        }
        onAppointmentClick={(appointment) =>
          setShowDealAppointmentMeta({
            ...showDealAppointmentMeta,
            activeAppointment: appointment,
          })
        }
      />
    </>
  );
};

export default BrowseDeals;
