import { startCase } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
  BarChart,
  CalendarEventFill,
  Diagram2Fill,
  Funnel,
  GraphUpArrow,
} from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../../../helpers/api";
import { getLeadsTableColumn } from "../../../../helpers/dataSheetConstants";
import {
  convertToFunnelChartData,
  getBarChartData,
  getDashboardLineCartData,
} from "../../../../helpers/global";
import useLocalization from "../../../../hooks/useLocalization";
import { dashboardService } from "../../../../services/dashboardService";
import DashboardDataNavigator from "../../../common/DashboardDataNavigator";
import DataTable from "../../../common/data-table/DataTable";
import LineChart from "../../../common/graph/LineChart";
import Barchart from "../../../common/graph/Barchart";
import Loader from "../../../common/Loader";
import MarketingTable from "../../MarketingTable";
import StatCard from "../../StatCard";
import InboundTable from "../InboundTable";
import FunnelChart from "./FunnelChart";
import { DEFAULT_PAGE_SIZE } from "../../../../helpers/constants";
import useContactAndDealPopup from "../../../../hooks/useContactAndDealPopup";
import DashBoardFilterOptions from "./DashBoardFilterOptions";
import moment from "moment";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const MarketingStatBoard = ({
  rangeType,
  dateRangeLabel,
  handleNavigateDate,
  dateRange,
  selectedUser,
}) => {
  const abortControllerRef = useRef(null);
  const leadsAbortControllerRef = useRef(null);
  const funnelProgressAbortControllerRef = useRef(null);
  const { openContactPopup } = useContactAndDealPopup();
  const { translate } = useLocalization();
  const [activeStat, setActiveStat] = useState(null);
  const [activeSecondaryStat, setActiveSecondaryStat] = useState([
    "webinar",
    "direct",
    "website",
    "facebook",
    "other",
  ]);
  const [dashboardChartType, setDashboardChartType] = useState("bar");
  const [leadsData, setLeadsData] = useState([]);
  const [marketingStatsData, setMarketingStats] = useState([]);
  const [funnelProgressData, setFunnelProgressData] = useState([]);
  const [initialFetching, setInitialFetching] = useState(false);
  const [navigatingData, setNavigatingData] = useState(false);
  const [isNavigation, setIsNavigation] = useState(false);
  const [submissionType, setSubmissionType] = useState("all");
  const [leadType, setLeadType] = useState("all");
  const [events, setEvents] = useState([]);
  const [selectedBar, setSelectedBar] = useState(null);

  const [fetchingFirstPageLeads, setFetchingFirstPageLeads] = useState(false);
  const [fetchingMoreLeads, setFetchingMoreLeads] = useState(false);

  const [toLoadPageInfo, setToLoadPageInfo] = useState(null);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const [eventFunnelProgressData, setEventFunnelProgressData] = useState(null);
  const [funnelLoading, setFunnelLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [funnelTitle, setFunnelTitle] = useState("progress");

  const fetchMarketingStats = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;
    if (isNavigation) {
      setNavigatingData(true);
    } else {
      setInitialFetching(true);
    }

    try {
      const { startDate, endDate } = dateRange;
      const reqBody = {
        startDate,
        endDate,
        assignedTo: selectedUser,
        leadSources: activeSecondaryStat,
        submissionType,
        leadType,
        events,
      };
      let { response, error } = await dashboardService.getMarketingStats(
        reqBody,
        signal
      );
      if (error === ABORT_ERROR) return;
      if (response) {
        setMarketingStats(response);
      }
      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.error("Error fetching marketing stats:", error);
    } finally {
      if (controller === abortControllerRef.current) {
        setInitialFetching(false);
        setNavigatingData(false);
        setIsNavigation(false);
        abortControllerRef.current = null;
      }
    }
  };

  const fetchMarketingLeadsStats = async () => {
    if (!toLoadPageInfo) {
      return;
    }
    // If an old API call is in progress, abort it
    if (leadsAbortControllerRef.current) {
      leadsAbortControllerRef.current.abort();
    }

    const { pageNumber: pageToFetch } = toLoadPageInfo;
    const loadingMoreApps = pageToFetch > 1;

    if (loadingMoreApps) {
      setFetchingMoreLeads(true);
    } else {
      setFetchingFirstPageLeads(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    leadsAbortControllerRef.current = controller;
    const { startDate, endDate } = dateRange;
    try {
      const reqBody = {
        startDate,
        endDate,
        leadSources: activeSecondaryStat,
        submissionType,
        leadType,
        events,
        assignedTo: selectedUser,
        pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
        pageNumber: pageToFetch,
      };
      let { response, error } = await dashboardService.getMarketingLeads(
        reqBody,
        signal
      );
      if (error === ABORT_ERROR) return;

      setFetchingMoreLeads(false);
      setFetchingFirstPageLeads(false);

      if (error) {
        return toast.error(error);
      }

      const { pageNumber, totalPages, results = [], resultCount } = response;

      setLoadedPageInfo({ totalPages, pageNumber, resultCount });

      setLeadsData((prev) => {
        const newArray = loadingMoreApps ? [...prev, ...results] : results;

        return newArray;
      });
    } catch (error) {
      console.error("Error fetching marketing stats:", error);
    }
  };

  const fetchFunnelProgress = async (from, to) => {
    if (funnelProgressAbortControllerRef.current) {
      funnelProgressAbortControllerRef.current.abort();
    }

    const controller = new AbortController();
    const { signal } = controller;

    funnelProgressAbortControllerRef.current = controller;
    try {
      const { startDate, endDate } = dateRange;
      const reqBody = {
        startDate: from || startDate,
        endDate: to || endDate,
        assignedTo: selectedUser,
        events,
      };
      let { response, error } = await dashboardService.getFunnelProgress(
        reqBody,
        signal
      );
      if (error === ABORT_ERROR) return;
      if (response) {
        setFunnelProgressData(response);
      }
      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.error("Error fetching funnel progress:", error);
    } finally {
      if (controller === funnelProgressAbortControllerRef.current) {
        funnelProgressAbortControllerRef.current = null;
      }
    }
  };

  useEffect(() => {
    fetchFunnelProgress();
  }, [dateRange, selectedUser, events, activeStat]);

  useEffect(() => {
    if (toLoadPageInfo) {
      fetchMarketingLeadsStats();
    }
  }, [toLoadPageInfo]);

  useEffect(() => {
    if (activeStat?.name === "leads") {
      setToLoadPageInfo({ ...initialPageInfo });
    } else {
      setToLoadPageInfo(null);
    }
  }, [
    activeStat,
    activeSecondaryStat,
    dateRange,
    selectedUser,
    submissionType,
    leadType,
    events,
  ]);

  useEffect(() => {
    fetchMarketingStats();
  }, [
    dateRange,
    selectedUser,
    activeSecondaryStat,
    submissionType,
    leadType,
    events,
  ]);

  const loadMoreLeadsData = () => {
    if (
      !loadedPageInfo ||
      fetchingFirstPageLeads ||
      fetchingMoreLeads ||
      loadedPageInfo.pageNumber >= loadedPageInfo.totalPages
    ) {
      return;
    }

    setToLoadPageInfo((prev) => ({
      ...prev,
      pageNumber: loadedPageInfo.pageNumber + 1,
    }));
  };

  const navigateDates = (type, dir) => {
    setIsNavigation(true);
    handleNavigateDate(type, dir);
    setSelectedBar(null);
  };

  const marketingStats = useMemo(() => {
    return [
      {
        key: "leads",
        name: "Leads Inbounds",
        variant: "primary",
        icon: Diagram2Fill,
        totalCount: marketingStatsData?.data?.leads?.totalCount,
      },
      {
        key: "events",
        label: "Events",
        name: "events",
        totalCount: marketingStatsData?.data?.events?.totalCount,
        variant: "info",
        icon: CalendarEventFill,
      },
    ];
  }, [marketingStatsData]);

  const onDashboardFilterChange = (values) => {
    setIsNavigation(true);
    let { viewBy, leadSource, submissionType, leadType, events } = values;

    const activeStat = {
      ...marketingStats[0],
      name: viewBy,
    };
    setSubmissionType(submissionType);
    setLeadType(leadType);
    setActiveStat(activeStat);
    setActiveSecondaryStat(leadSource);
    setEvents(events);
  };

  const activeTabData = useMemo(() => {
    const data = marketingStatsData?.data?.[activeStat?.name];
    if (!data) return null;

    const chartInterval =
      marketingStatsData?.data?.[activeStat?.name]?.chartInterval;

    const getChartData = (chartType, chartData) => {
      return chartType === "line"
        ? getDashboardLineCartData(chartData, "", chartInterval)
        : getBarChartData(chartData, "", chartInterval);
    };

    return getChartData(dashboardChartType, data.chartData);
  }, [marketingStatsData, activeStat, dashboardChartType, activeSecondaryStat]);

  const leadsTableColumns = useMemo(
    () => getLeadsTableColumn({ openContactPopup }),
    [openContactPopup]
  );

  const fetchEventFunnel = async (eventId, fractionalMode = false) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    setFunnelLoading(true);
    try {
      const reqBody = {
        assignedTo: selectedUser,
        events: [eventId],
        fractionalMode,
      };
      let { response, error } = await dashboardService.getFunnelProgress(
        reqBody,
        signal
      );
      if (error === ABORT_ERROR) return;
      if (response) {
        setEventFunnelProgressData(response);
      }
    } catch (error) {
      console.error("Error fetching marketing funnel:", error);
    } finally {
      setFunnelLoading(false);
    }
  };

  const formatFunnelBarChartData = (data) => {
    if (!data) return null;
    const labels = data?.map((dataset) => dataset.label);

    const datasets = [
      {
        label: "Funnel conversion",
        data: data?.map((dataset) => dataset.data[0]),
        backgroundColor: data?.map((dataset) => dataset.backgroundColor),
        borderWidth: data?.map((dataset) => dataset.borderWidth),
        maxBarThickness: data?.map((dataset) => dataset.maxBarThickness),
      },
    ];

    return {
      labels,
      datasets,
    };
  };

  const chartData = useMemo(() => {
    const funnelProgress =
      activeStat?.name !== "events"
        ? funnelProgressData?.funnelProgress
        : eventFunnelProgressData?.funnelProgress;
    if (!funnelProgress) return null;

    const allData = convertToFunnelChartData(
      funnelProgress,
      "Conversion ratio"
    );

    // Split datasets into bar and funnel data
    const barChartData = formatFunnelBarChartData(
      allData?.datasets?.slice(0, 2) // First 2 datasets for bar chart
    );

    const funnelChartData = {
      labels: allData.labels,
      datasets: allData.datasets.slice(2), // Remaining datasets for funnel chart
    };

    return {
      barChartData,
      funnelChartData,
    };
  }, [funnelProgressData, eventFunnelProgressData]);

  useEffect(() => {
    if (selectedEvent) {
      fetchEventFunnel(selectedEvent._id);
    }
  }, [selectedEvent, selectedUser]);

  useEffect(() => {
    setActiveStat({ ...marketingStats?.[0], name: "leads" });
  }, []);

  const handleCardClick = (stat) => {
    if (stat?.name === "Leads Inbounds") {
      setActiveStat({ ...stat, name: "leads" });
      setEventFunnelProgressData(null);
      setSelectedEvent(null);
      setFunnelTitle("progress");
    } else {
      setActiveStat(stat);
      setFunnelProgressData(null);
    }
  };

  const handleBarClick = (value) => {
    const date = moment(value?.date);
    let startDate = null;
    let endDate = null;
    let dateRangeLabel = null;
    if (["Year", "Last 12 Months"].includes(rangeType)) {
      startDate = new Date(date.startOf("month"));
      endDate = new Date(date.endOf("month"));
      dateRangeLabel = `${moment(startDate).format("MMM D, YYYY")} - ${moment(
        endDate
      ).format("MMM D, YYYY")}`;
    } else if (["Quarter", "Last 3 Months"].includes(rangeType)) {
      startDate = new Date(value?.date);
      endDate = new Date(date.clone().add(3, "month"));
      dateRangeLabel = `${moment(startDate).format("MMM D, YYYY")} - ${moment(
        endDate
      ).format("MMM D, YYYY")}`;
    } else {
      startDate = new Date(date.startOf("day"));
      endDate = new Date(date.endOf("day"));
      dateRangeLabel = `${moment(startDate).format("MMM D, YYYY")}`;
    }

    setSelectedBar({
      startDate: startDate,
      endDate: endDate,
      bar: value,
      dateRangeLabel: dateRangeLabel,
    });

    fetchFunnelProgress(startDate, endDate);
  };

  const renderChartControls = () => (
    <div className={`d-flex align-items-center gap-2`}>
      <DashboardDataNavigator
        rangeType={rangeType}
        dateRangeLabel={dateRangeLabel}
        handleNavigateDate={navigateDates}
      />
      <div className="d-flex justify-content-end rounded overflow-hidden border border-dark">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{translate("bar_chart")}</Tooltip>}
        >
          <Button
            className="p-1 d-flex justify-content-center align-items-center"
            style={{ width: "50px", borderRadius: "0px", border: "none" }}
            variant={
              dashboardChartType === "bar" ? "dark text-white" : "outline-dark"
            }
            onClick={() => setDashboardChartType("bar")}
          >
            <BarChart size={14} />
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{translate("line_chart")}</Tooltip>}
        >
          <Button
            className="p-1 d-flex justify-content-center align-items-center"
            style={{ width: "50px", borderRadius: "0px", border: "none" }}
            variant={
              dashboardChartType === "line" ? "dark text-white" : "outline-dark"
            }
            onClick={() => setDashboardChartType("line")}
          >
            <GraphUpArrow size={14} />
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  );

  const eventFunnelTooltipText = useMemo(() => {
    if (funnelTitle === "complete_funnel") {
      return translate("complete_funnel_info");
    }
    return translate("fractional_funnel_info");
  }, [funnelTitle]);

  return (
    <div className="d-flex flex-column h-100">
      <Row className="my-2 mx-1">
        {marketingStats?.map((stat, index) => (
          <Col className="p-1" key={index} lg={3} md={3} sm={12} xs={12}>
            <StatCard
              stat={stat}
              active={activeStat?.key === stat?.key}
              fetching={initialFetching}
              onClick={handleCardClick}
            />
          </Col>
        ))}
      </Row>

      <Row className="pt-2 m-0">
        <Col lg={9} md={9} sm={12} xs={12} className="p-2">
          {" "}
          {initialFetching ? (
            <div className="custom-card">
              <Loader />
            </div>
          ) : (
            <div className="d-flex flex-column gap-2">
              {activeStat?.name !== "events" && activeTabData ? (
                <>
                  <div className="custom-card mb-2 p-4">
                    <DashBoardFilterOptions
                      activeStat={activeStat}
                      onChange={onDashboardFilterChange}
                    />
                  </div>
                  <div
                    className={`custom-card ${
                      dashboardChartType === "line" ? "pb-5" : ""
                    }`}
                  >
                    {dashboardChartType === "line" ? (
                      <LineChart
                        chartData={activeTabData}
                        chartName={activeSecondaryStat}
                        loading={initialFetching}
                        randerExtraInfo={renderChartControls}
                        hideContiniousFalsyDataFromLast
                        isACard={false}
                      />
                    ) : (
                      <Barchart
                        chartName={activeSecondaryStat}
                        data={activeTabData}
                        isACard={false}
                        renderExtraInfo={renderChartControls}
                        marketingStatsData={
                          marketingStatsData?.data?.[activeStat?.name]
                        }
                        onClick={handleBarClick}
                      />
                    )}
                  </div>
                </>
              ) : (
                <MarketingTable
                  selectedEvent={selectedEvent}
                  setSelectedEvent={setSelectedEvent}
                  assignedTo={selectedUser}
                  funnelTitle={funnelTitle}
                  setFunnelTitle={setFunnelTitle}
                  fetchEventFunnel={fetchEventFunnel}
                />
              )}
            </div>
          )}
        </Col>
        <Col lg={3} md={3} sm={12} xs={12} className="p-2">
          {chartData ? (
            <FunnelChart
              data={chartData.funnelChartData}
              title={translate(funnelTitle)}
              aspectRatio={1}
              loading={initialFetching || funnelLoading}
              containerStyle={{ height: 520, width: "100%" }}
              barChartData={chartData.barChartData}
              dateRangeLabel={selectedBar?.dateRangeLabel || dateRangeLabel}
              tooltipText={
                activeTabData
                  ? translate("funnel_chart_info")
                  : eventFunnelTooltipText
              }
            />
          ) : (
            <div className="custom-card p-2 d-flex h-100 flex-column align-items-center justify-content-center">
              <Funnel className="text-muted mb-3" size={40} />
              <p className="text-muted mb-0">
                {translate("select_an_event_to_view_funnel") ||
                  "Select an event to view its funnel"}
              </p>
            </div>
          )}
        </Col>
        <Col lg={12} md={12} sm={12} xs={12} className="p-2 mt-3">
          <div>
            {activeStat?.name === "leads" && (
              <div className="custom-card">
                <h6 className="xxlarge fw-bold">
                  {" "}
                  {startCase("leads")}{" "}
                  {`(${marketingStatsData?.data?.leads?.totalCount})`}
                </h6>
                <DataTable
                  columns={leadsTableColumns}
                  data={leadsData}
                  maxTableHeight={`500px`}
                  onRowClick={(contact) => openContactPopup(contact?._id)}
                  rowKey={"_id"}
                  loadingFirstPageData={fetchingFirstPageLeads}
                  loadingMoreData={fetchingMoreLeads}
                  onBottomReached={loadMoreLeadsData}
                  allowSort={false}
                  allowFilter={false}
                />
              </div>
            )}{" "}
            {activeStat?.name === "inbounds" && (
              <InboundTable
                dateRange={dateRange}
                leadSources={activeSecondaryStat}
                users={selectedUser}
                leadType={leadType}
                submissionType={submissionType}
                events={events}
                count={marketingStatsData?.data?.inbounds?.totalCount}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MarketingStatBoard;
