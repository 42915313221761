import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Clipboard2Pulse,
  CurrencyExchange,
  PersonVideo3,
  TelephonePlus,
} from "react-bootstrap-icons";
import {
  getDashboardSalesWonColumns,
  getDashboardTotalCommissionsFields,
} from "../../../helpers/dataSheetConstants";
import { getDashboardLineCartData } from "../../../helpers/global";
import useLocalization from "../../../hooks/useLocalization";
import DashboardDataNavigator from "../../common/DashboardDataNavigator";
import LineChart from "../../common/graph/LineChart";
import StatCard from "../StatCard";
import DashboardTable from "./DashboardTable";

const SalesStatBoard = ({
  fetching,
  data,
  onDealClick,
  rangeType,
  dateRangeLabel,
  handleNavigateDate,
  navigating,
}) => {
  const { translate } = useLocalization();

  const [activeStat, setActiveStat] = useState(null);
  const salesWonColumns = useMemo(() => getDashboardSalesWonColumns({}), []);

  const commissionsFields = useMemo(
    () => getDashboardTotalCommissionsFields({}),
    []
  );
  const salesStat = useMemo(() => {
    return [
      {
        name: "total sales",
        data: data?.sales,
        variant: "dark",
        tableColumns: salesWonColumns,
        tableData: data?.sales?.tableData,
        chartData: getDashboardLineCartData(
          data?.sales?.chartData,
          "Inbound",
          data?.sales?.chartInterval
        ),
        icon: CurrencyExchange,
      },
      {
        name: "total commissions",
        data: data?.commissions,
        variant: "dark",
        icon: CurrencyExchange,
        tableColumns: commissionsFields,
        chartData: getDashboardLineCartData(
          data?.commissions?.chartData,
          "Inbound",
          data?.commissions?.chartInterval
        ),
        tableData: data?.commissions?.tableData,
      },
      {
        name: "conversion rate from lead to phone call",
        data: 642,
        variant: "primary",
        icon: Clipboard2Pulse,
      },
      {
        name: "conversion rate from phone call to meeting",
        data: 732,
        variant: "secondary",
        icon: Clipboard2Pulse,
      },
      {
        name: "conversion rate from meeting to signed deal",
        data: 322,
        variant: "info",
        icon: PersonVideo3,
      },

      {
        name: "average time from first phone call to deal closed",
        data: 124,
        variant: "danger",
        icon: TelephonePlus,
      },
    ];
  }, [data, commissionsFields]);

  const onTableRowClick = (row) => {
    if (activeStat?.name === "total commissions") {
      onDealClick(row?.contract);
    }
  };

  useEffect(() => {
    setActiveStat(
      !activeStat
        ? salesStat?.[0]
        : salesStat?.find((s) => s?.name === activeStat?.name)
    );
  }, [salesStat]);

  const handleCardClick = (stat) => {
    setActiveStat(stat);
  };

  return (
    <div className="d-flex flex-column h-10">
      <Row className="my-2 mx-1">
        {salesStat?.map((stat, index) => (
          <Col
            className="p-1"
            key={`${index}-${stat?.name}`}
            lg={4}
            md={4}
            sm={4}
            xs={6}
          >
            <StatCard
              active={activeStat?.name === stat?.name}
              stat={stat}
              fetching={fetching}
              onClick={handleCardClick}
            />
          </Col>
        ))}
      </Row>
      <Row className="my-2 mx-1 flex-grow-1">
        {activeStat?.chartData && (
          <Col className="p-1" lg={12} md={12} sm={12} xs={12}>
            {" "}
            <LineChart
              chartData={activeStat?.chartData}
              chartName={activeStat?.name}
              loading={fetching}
              randerExtraInfo={() => (
                <DashboardDataNavigator
                  rangeType={rangeType}
                  dateRangeLabel={dateRangeLabel}
                  handleNavigateDate={handleNavigateDate}
                  loading={navigating}
                />
              )}
              hideContiniousFalsyDataFromLast
            />
          </Col>
        )}
        {activeStat?.tableData && (
          <Col className="p-1" lg={12} md={12} sm={12} xs={12}>
            <DashboardTable
              loading={fetching}
              tableColumns={activeStat?.tableColumns}
              tableData={activeStat?.tableData}
              tableName={activeStat?.name}
              onRowClick={onTableRowClick}
              maxTableHeight="calc(100vh - 200px)"
              totalCount={activeStat?.tableData?.length || 0}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default SalesStatBoard;
