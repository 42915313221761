import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const whatsappTemplateService = {
  getWhatsappTemplates: async (reqBody = {}, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_WHATSAPP_TEMPLATES,
      requestBody: reqBody,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  createWhatsappTemplate: async (reqBody = {}) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_WHATSAPP_TEMPLATE,
      requestBody: reqBody,
      method: "POST",
    });

    return { response, error };
  },
  updateWhatsappTemplate: async (templateID, reqBody = {}) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.WHATSAPP_TEMPLATE_WITH_ID(templateID),
      requestBody: reqBody,
      method: "PUT",
    });

    return { response, error };
  },
  archiveWhatsappTemplateById: async (templateID) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.ARCHIVE_WHATSAPP_TEMPLATE_BY_ID(templateID),
      method: "PUT",
    });

    return { response, error };
  },
};
