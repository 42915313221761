import React, { useEffect, useMemo, useState } from "react";
import {
  Col,
  FormControl,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import MultiCheckboxInput from "../../../common/MultiCheckboxInput";
import useLocalization from "../../../../hooks/useLocalization";
import {
  BinocularsFill,
  EyeFill,
  FilterCircleFill,
  Fire,
  HandIndexThumbFill,
  InfoCircle,
  QuestionCircle,
} from "react-bootstrap-icons";
import { snakeCase } from "lodash";
import CustomMultiSelect from "../../../common/CustomMultiSelect";
import useAppChoices from "../../../../hooks/useAppChoices";

const DashBoardFilterOptions = ({ onChange }) => {
  const { translate } = useLocalization();
  const [filterValues, setFilterValues] = useState({});

  const events = useAppChoices("events");

  const getDashboardMarketingFilterOptions = useMemo(
    () => [
      {
        label: "View By",
        key: "viewBy",
        type: "radio",
        icon: EyeFill,
        cellSize: 7,
        defaultValue: "leads",
        options: [
          {
            label: "leads",
            value: "leads",
          },
          {
            label: "inbounds",
            value: "inbounds",
          },
        ],
        className: "d-flex gap-2 align-items-center",
        info: "view_by_info",
      },
      {
        label: "Filter by Event",
        key: "events",
        type: "multi-select",
        defaultValue: [],
        icon: FilterCircleFill,
        cellSize: 5,
        className: "d-flex gap-2 align-items-center",
        options: events.map((e) => ({ label: e.name, value: e._id })),
        info: "filter_by_event_info",
      },
      {
        label: "Submissions",
        key: "submissionType",
        type: "radio",
        cellSize: 7,
        icon: HandIndexThumbFill,
        defaultValue: "all",
        options: [
          {
            label: "all",
            value: "all",
          },
          {
            label: "new submission",
            value: "new-submission",
          },
          {
            label: "re submission",
            value: "re-submission",
          },
        ],
        info: "submission_type_info",
      },

      {
        label: "Inbound Type",
        key: "leadType",
        type: "radio",
        cellSize: 5,
        icon: Fire,
        defaultValue: "all",
        options: [
          {
            label: "all",
            value: "all",
          },
          {
            label: "hot",
            value: "hot",
          },
          {
            label: "cold",
            value: "cold",
          },
        ],
        info: "lead_type_info",
      },
      {
        label: "Source",
        key: "leadSource",
        type: "multi-checkbox",
        cellSize: 12,
        cellSizeLg: 12,
        icon: BinocularsFill,
        defaultValue: ["webinar", "direct", "website", "facebook", "other"],
        options: [
          {
            label: "webinar",
            value: "webinar",
          },
          {
            label: "direct",
            value: "direct",
          },
          {
            label: "website",
            value: "website",
          },
          {
            label: "facebook",
            value: "facebook",
          },
          {
            label: "other",
            value: "other",
          },
        ],
        className: "d-flex gap-2 mt-4 align-items-center",
        info: "source_info",
      },
    ],
    [events]
  );

  const internalOnChange = (key, value, type) => {
    let values = filterValues;
    if (type === "radio") {
      values[key] = value;
    } else if (type === "multi-checkbox" || type === "multi-select") {
      values[key] = value || [];
    } else {
      values[key] = value;
    }
    setFilterValues(values);
    onChange(values);
  };

  useEffect(() => {
    setFilterValues(
      getDashboardMarketingFilterOptions?.reduce((acc, option) => {
        acc[option.key] = option.defaultValue;
        return acc;
      }, {})
    );
  }, []);

  const renderLabel = ({ label, icon: Icon }) => {
    return (
      <p
        style={{ textWrap: "nowrap" }}
        className="fw-bold d-flex align-items-center m-0 gap-2"
      >
        {<Icon size={18} className="text-secondary-info" />}{" "}
        {translate(snakeCase(label)) || label}:
      </p>
    );
  };

  return (
    <Row className="">
      {getDashboardMarketingFilterOptions?.map((option) => (
        <Col
          md={12}
          lg={option.cellSize}
          className={option.className || "d-flex gap-2 mt-4 align-items-center"}
          key={option.key}
        >
          {option.type === "radio" ? (
            <div key={option.key} className="d-flex align-items-center gap-2">
              {renderLabel(option)}
              {option.options.map((opt) => (
                <div className="form-check align-items-center">
                  <input
                    type="radio"
                    className="form-check-input mt-0"
                    id={opt.value}
                    name={option.key}
                    style={{ width: "20px", height: "20px" }}
                    checked={filterValues[option.key] === opt.value}
                    onChange={(e) =>
                      internalOnChange(option.key, opt.value, "radio")
                    }
                  />
                  <label className="ms-2" htmlFor={opt.value}>
                    {translate(snakeCase(opt.label)) || opt.label}
                  </label>
                </div>
              ))}
            </div>
          ) : option.type === "multi-checkbox" ? (
            <div className="d-flex gap-2">
              {renderLabel(option)}
              <MultiCheckboxInput
                showAllOption={true}
                options={option.options}
                value={filterValues[option.key]}
                onChange={(value) =>
                  internalOnChange(option.key, value, "multi-checkbox")
                }
              />
            </div>
          ) : option.type === "text" ? (
            <div className="d-flex align-items-center gap-2 w-100">
              {renderLabel(option)}
              <FormControl
                size="sm"
                type="text"
                className="w-100"
                placeholder={option.label}
                value={filterValues[option.key]}
                onChange={(e) =>
                  internalOnChange(option.key, e.target.value, "text")
                }
              />
            </div>
          ) : option.type === "divider" ? (
            <div
              className="w-100 mt-3 mb-4"
              style={{ height: 1, backgroundColor: "#e5e7eb" }}
            />
          ) : option.type === "multi-select" ? (
            <div className="d-flex w-100 gap-2">
              {renderLabel(option)}
              <div className="w-100">
                <CustomMultiSelect
                  items={option.options}
                  closeMenuOnSelect
                  isMulti
                  selectedItems={filterValues[option.key] || ""}
                  onChange={(value) => {
                    internalOnChange(option.key, value, "multi-select");
                  }}
                  height="26px"
                  placeholder={translate(snakeCase(option.label))}
                />
              </div>
            </div>
          ) : null}
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{translate(option.info)}</Tooltip>}
          >
            <QuestionCircle size={14} className="text-secondary" />
          </OverlayTrigger>
        </Col>
      ))}
    </Row>
  );
};

export default DashBoardFilterOptions;
