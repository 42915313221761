import React, { useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import "react-quill/dist/quill.snow.css";
import SlidingSideBar from "../../common/SlidingSideBar/SlidingSideBar";
import TiptapEditor from "../../common/tiptap-editor/Editor";
import { fileService } from "../../../services/fileService";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import "./styles.scss";
import CircularProgressBar from "../../common/circular-progress";
import useLocalization from "../../../hooks/useLocalization";

export default function AddEditTemplateModal({
  visible,
  onHide,
  showProgress,
  onSubmit,
  initialValues,
}) {
  const [template, setTemplate] = useState({
    name: initialValues?.name || "Untitled Template",
    body: initialValues?.body || "",
  });

  const handleTitleChange = (newTitle) => {
    const updatedTemplate = { ...template, name: newTitle };
    setTemplate(updatedTemplate);
  };

  const handleEditorChange = (newBody) => {
    const updatedTemplate = { ...template, body: newBody };
    setTemplate(updatedTemplate);
  };

  useDebouncedEffect(
    () => {
      onSubmit({ ...initialValues, ...template });
    },
    [template],
    1000
  );

  const fileUploader = useCallback(async (files) => {
    const { response, error } = await fileService.uploadFile(files);
    if (error) {
      return null;
    }

    const fileStreamUri = `${process.env.REACT_APP_API_URL}/files/stream/image/${response.filename}`;
    return fileStreamUri;
  }, []);

  return (
    <SlidingSideBar
      visible={visible}
      onClose={onHide}
      fullScreen
      showCloseButton={false}
    >
      <TemplateHeader
        name={template.name}
        handleTitleChange={handleTitleChange}
        onHide={onHide}
        isSaving={showProgress}
      />
      <TiptapEditor
        content={template.body}
        onContentChange={handleEditorChange}
        fileUploader={fileUploader}
      />
    </SlidingSideBar>
  );
}

const TemplateHeader = ({ name, handleTitleChange, onHide, isSaving }) => {
  const { translate } = useLocalization();

  return (
    <div className="d-flex align-items-center justify-content-between px-2 -mt-2">
      <div
        className="d-flex align-items-center"
        style={{ minHeight: "40px", width: "300px" }}
      >
        <input
          type="text"
          value={name}
          onChange={(e) => handleTitleChange(e.target.value)}
          className="h5 m-0 w-100 px-2 rounded report-template-title truncate"
        />
      </div>
      <div className="d-flex align-items-center gap-2">
        {isSaving && (
          <div className={"d-flex align-items-center gap-2"}>
            <CircularProgressBar size={14} />
            <span className="smallFont">{`${translate("saving")}...`}</span>
          </div>
        )}
        <Button variant="link" className="p-1 text-secondary" onClick={onHide}>
          <X size={32} />
        </Button>
      </div>
    </div>
  );
};
