import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  CalendarDate,
  Cash,
  Chat,
  ChatQuote,
  Check,
  FileEarmarkText,
  Trash,
  XLg,
} from "react-bootstrap-icons";
import GoogleMailIcon from "../../assets/GoogleMailIcon.png";
import PhoneCallIcon from "../../assets/PhoneCallIcon.png";
import RujumAppIcon from "../../assets/RujumAppIcon.png";
import WhatsAppIcon from "../../assets/WhatsAppIcon.png";
import { isAdminOrManager } from "../../helpers/session";
import useAuth from "../../hooks/useAuth";
import useLocalization from "../../hooks/useLocalization";
import AlertModal from "../common/AlertModal";
import AppModal from "../common/AppModal";
import Comments from "../common/Comments";
import FormSection from "../common/FormSection";
import CircularProgressBar from "../common/circular-progress";
import AppointmentModal from "../contacts/contact-details/AppointmentModal";
import DealComissionForm from "../forms/DealCommissionForm";
import ContractDetailForm from "../forms/DealDetailForm";
import DealPaymentForm from "../forms/DealPaymentForm";

import ContentLoader from "react-content-loader";
import { commissionTypes } from "../../helpers/constants";
import { BROADCAST_TYPES, useWebSocket } from "../../context/WebSocketContext";
import { toast } from "react-toastify";
import useWhatsappConversation from "../../hooks/useWhatsappConversation";
import { PERMISSIONS } from "../../helpers/permissions";

const UpdateDealModal = ({
  show,
  onHide,
  products,
  originalContract,
  contract,
  onDeleteDealClick,
  onContractDetailSubmit,
  newCommentText,
  commentsUpdating,
  deletingComment,
  editingComment,
  setNewCommentText,
  setDeletingComment,
  setEditingComment,
  deleteComment,
  onNewCommentSubmit,
  onEditingCommentSubmit,
  updatingContract,
  onDealChange,
  makePhoneCall,
  sendToRujum,
  editable,
  deletable,
  fetching = false,
  referrals = [],
  onUpdateDate,
  updatingHistoryDate,
  updatingContractUnit,
  onContractUnitUpdate,
}) => {
  if (!Boolean(show)) return null;
  const { translate } = useLocalization();
  const { user, checkNestedPermission } = useAuth();
  const { message } = useWebSocket();
  const [appointmentModalMeta, setAppointmentModalMeta] = useState(null);
  const { openWhatsappChatScreen } = useWhatsappConversation();

  const isContractModified = useMemo(
    () => !_.isEqual(contract, originalContract) || newCommentText?.trim(),
    [contract, originalContract, newCommentText]
  );

  useEffect(() => {
    if (message && Boolean(show)) {
      let { type, payload } = message;
      switch (type) {
        case BROADCAST_TYPES.CONTACT_UPDATE:
          if (contract?.contact?._id === payload?._id) {
            onDealChange({ contact: payload });
          }
          break;

        case BROADCAST_TYPES.CONTACT_DELETE:
          if (payload?._id === contract?.contact?._id) {
            toast.info(
              "The contact associated to this contract was deleted by someone."
            );
            onHide();
          }
          break;

        case BROADCAST_TYPES.CONTRACT_UPDATE:
          if (contract?._id === payload?._id) {
            toast.info(
              "This contract was updated by someone. You are viewing latest changes."
            );
            onDealChange(payload);
          }
          break;

        case BROADCAST_TYPES.CONTRACT_DELETE:
          if (contract?._id === payload?._id) {
            toast.info("This contract has been deleted by someone else.");
            onHide();
          }
          break;

        default:
          console.log("Unknown message type");
          break;
      }
    }
  }, [message, show]);

  return (
    <AppModal show={show} fullscreen hideHeader modalBodyClassName="p-0">
      <div className="d-flex flex-column">
        <div
          className="bg-white px-1"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 10000,
          }}
        >
          {" "}
          <div className="border-bottom py-1 d-flex justify-content-end">
            <Button
              size="sm"
              className="px-1 py-0 text-white mx-1"
              variant="danger"
              disabled={commentsUpdating || updatingContract || fetching}
              onClick={onHide}
            >
              <span className="py-1">
                {translate("close")} <XLg size={12} />
              </span>
            </Button>
            {editable && !fetching && Boolean(contract) && (
              <Button
                size="sm"
                className="px-1 py-0 text-white mx-1"
                variant={isContractModified ? "success" : "secondary"}
                disabled={
                  commentsUpdating ||
                  updatingContract ||
                  fetching ||
                  !isContractModified
                }
                onClick={onContractDetailSubmit}
              >
                {updatingContract ? (
                  <span className="mx-1 px-1 text-white">
                    <CircularProgressBar size={12} />
                    <span className="mx-1 smallFont">
                      {translate("please_wait")}
                    </span>
                  </span>
                ) : (
                  <span className="py-1">
                    {translate("save")} <Check size={15} />
                  </span>
                )}
              </Button>
            )}
          </div>
          {!fetching && Boolean(contract) && (
            <div className="d-flex justify-content-between align-items-center border-bottom py-1 bg-white">
              <div className="d-flex">
                {deletable && (
                  <Button
                    className="px-1 py-0 d-flex justify-content-between align-items-center mx-1"
                    variant={"danger"}
                    size="sm"
                    onClick={() => onDeleteDealClick(contract)}
                  >
                    <Trash size={12} className={`text-white`} />{" "}
                    <span className="smallFont mx-1">
                      {translate("delete")}{" "}
                    </span>
                  </Button>
                )}{" "}
                {contract?.prosperoProposalMeta?.link && (
                  <Button
                    className="px-1 py-0 d-flex justify-content-between align-items-center mx-1"
                    variant={"info"}
                    href={contract?.prosperoProposalMeta?.link}
                    target={"_blank"}
                  >
                    <FileEarmarkText size={12} className={`text-white`} />{" "}
                    <span className="smallFont mx-1">
                      {translate("contract_link")}{" "}
                    </span>
                  </Button>
                )}
              </div>
              <div className="d-flex flex-wrap gap-1 px-2">
                {[
                  {
                    label: "Chat",
                    iconVariant: "success",
                    Icon: Chat,
                    onClick: () => openWhatsappChatScreen(contract?.contact),
                    disabled: false,
                  },
                  {
                    label: "call",
                    iconVariant: "success",
                    imgSrc: PhoneCallIcon,
                    onClick: () =>
                      makePhoneCall(contract?.contact?.phoneNumbers?.[0]),
                    disabled: false,
                  },
                  {
                    label: "whatsapp",
                    url: `https://wa.me/+972${contract?.contact?.phoneNumbers?.[0]}`,
                    iconVariant: "success",
                    imgSrc: WhatsAppIcon,
                  },
                  {
                    label: "email",
                    url: `https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=${contract?.contact?.emails?.[0]}`,
                    iconVariant: "primary",
                    imgSrc: GoogleMailIcon,
                  },
                  {
                    label: "set_appointment",
                    iconVariant: "info",
                    Icon: CalendarDate,
                    onClick: () => setAppointmentModalMeta({}),
                    disabled: false,
                    hide: !checkNestedPermission([
                      PERMISSIONS.appointments.VIEW_ALL_APPOINTMENTS,
                    ]),
                  },
                  {
                    label: "send_to_rujum",
                    iconVariant: "purple",
                    imgSrc: RujumAppIcon,
                    onClick: () => sendToRujum(contract?.contact),
                    disabled: false,
                  },
                ].map(
                  ({
                    label,
                    url,
                    iconVariant,
                    Icon,
                    imgSrc,
                    onClick,
                    disabled,
                    hide = false,
                  }) =>
                    !hide ? (
                      <Button
                        className="px-1 py-1 d-flex justify-content-between border-light align-items-center"
                        key={`${label}-${url}`}
                        variant={"light-gray"}
                        href={url}
                        target="_blank"
                        onClick={onClick}
                        disabled={disabled}
                      >
                        {imgSrc ? (
                          <img
                            size={13}
                            src={imgSrc}
                            height={20}
                            width={20}
                            alt="icon"
                          />
                        ) : (
                          <Icon className={`text-${iconVariant}`} />
                        )}

                        <span className="smallFont mx-1">
                          {translate(label) || label}{" "}
                        </span>
                      </Button>
                    ) : null
                )}
              </div>
            </div>
          )}
        </div>
        {fetching || !Boolean(contract) ? (
          <ContentLoader className="p-2 w-50">
            {" "}
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="15px" />
            <rect x="0" y="20" rx="4" ry="4" width="90%" height="15px" />
            <rect x="0" y="40" rx="4" ry="4" width="80%" height="15px" />
          </ContentLoader>
        ) : (
          <div className="flex-grow-1 bg-light p-1">
            <ContractDetailForm
              disabled={updatingContract || !editable}
              originalContract={originalContract}
              onChange={onDealChange}
              contract={contract}
              products={products}
              user={user}
              onUpdateDate={onUpdateDate}
              updatingHistoryDate={updatingHistoryDate}
              updatingContractUnit={updatingContractUnit}
              onContractUnitUpdate={onContractUnitUpdate}
            />
            <div className="my-2 bg-secondary w-100" style={{ height: 2 }} />

            <Row className="m-0 h-100">
              {checkNestedPermission([
                PERMISSIONS.contract_advanced_info
                  .VIEW_ALL_CONTRACT_ADVANCED_INFO,
              ]) && (
                <Col
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  className="border-end border-2 border-secondary"
                >
                  <FormSection
                    Icon={ChatQuote}
                    title={translate("payments")}
                    bodyClassName="p-1"
                  >
                    <DealPaymentForm
                      disabled={
                        updatingContract ||
                        !editable ||
                        !checkNestedPermission([
                          PERMISSIONS.contract_advanced_info
                            .UPDATE_ALL_CONTRACT_ADVANCED_INFO,
                        ])
                      }
                      contract={contract}
                      onChange={onDealChange}
                      onAddNewPayment={() => {
                        let refferedBy =
                          contract?.contact?.referredBy?._id ||
                          contract?.contact?.referredBy;

                        let newCommission;

                        if (refferedBy) {
                          newCommission = {
                            type: commissionTypes[1],
                            assignedTo: null,
                            commission: 0,
                          };
                          onDealChange({
                            commissions: [
                              ...contract?.commissions,
                              newCommission,
                            ],
                          });
                        }
                      }}
                    />
                  </FormSection>

                  <FormSection
                    Icon={Cash}
                    title={translate("commissions")}
                    bodyClassName="p-1"
                    className="mt-2"
                  >
                    <DealComissionForm
                      disabled={
                        updatingContract ||
                        !editable ||
                        !checkNestedPermission([
                          PERMISSIONS.contract_advanced_info
                            .UPDATE_ALL_CONTRACT_ADVANCED_INFO,
                        ])
                      }
                      contract={contract}
                      onChange={onDealChange}
                      referrals={referrals}
                    />
                  </FormSection>
                </Col>
              )}

              <Col xs={12} sm={12} md={5} lg={5} className="">
                <FormSection
                  Icon={ChatQuote}
                  title={translate("comments_no_count")}
                  bodyClassName="p-1"
                >
                  <Comments
                    editable={editable}
                    comments={contract?.comments}
                    text={newCommentText}
                    onTextChange={setNewCommentText}
                    onCommentSubmit={onNewCommentSubmit}
                    commentsUpdating={commentsUpdating}
                    editingComment={editingComment}
                    onEditingCommentUpdate={(text) =>
                      setEditingComment({ ...editingComment, text })
                    }
                    onCommentEditClick={(comment) =>
                      setEditingComment({ ...comment })
                    }
                    onCommentEditCancelClick={() => setEditingComment(null)}
                    onCommentEditSaveClick={onEditingCommentSubmit}
                    onCommentDeleteClick={(comment) =>
                      setDeletingComment({ ...comment })
                    }
                  />
                </FormSection>
              </Col>
            </Row>
          </div>
        )}
      </div>

      {contract?.comments?.length > 0 && (
        <AlertModal
          show={Boolean(deletingComment)}
          onHide={() => setDeletingComment(null)}
          onContinueClick={deleteComment}
          onDismissClick={() => setDeletingComment(null)}
          alertText={translate("are_you_sure_delete_comment")}
          showProgress={commentsUpdating}
        />
      )}
      <AppointmentModal
        show={Boolean(appointmentModalMeta)}
        onHide={() => setAppointmentModalMeta(null)}
        contact={contract?.contact}
        specificContactMode
      />
    </AppModal>
  );
};

export default UpdateDealModal;
