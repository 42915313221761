export const PERMISSIONS = {
  appointments: {
    VIEW_ALL_APPOINTMENTS: "view-all-appointments",
    /* VIEW_ASSIGNED_APPOINTMENTS: "view-assigned-appointments", */

    CREATE_ALL_APPOINTMENTS: "create-all-appointments",

    UPDATE_ALL_APPOINTMENTS: "update-all-appointments",
    /* UPDATE_ASSIGNED_APPOINTMENTS: "update-assigned-appointments", */

    DELETE_ALL_APPOINTMENTS: "delete-all-appointments",
    /*     DELETE_ASSIGNED_APPOINTMENTS: "delete-assigned-appointments",
     */
  },
  dashboard: {
    VIEW_ALL_DASHBOARD: "view-all-dashboard",
    VIEW_ASSIGNED_DASHBOARD: "view-assigned-dashboard",
  },
  referrers: {
    VIEW_ALL_REFERRERS: "view-all-referrers",
    CREATE_ALL_REFERRERS: "create-all-referrers",
    UPDATE_ALL_REFERRERS: "update-all-referrers",
    DELETE_ALL_REFERRERS: "delete-all-referrers",
  },
  contracts: {
    VIEW_ALL_CONTRACTS: "view-all-contracts",
    VIEW_ASSIGNED_CONTRACTS: "view-assigned-contracts",

    CREATE_ALL_CONTRACTS: "create-all-contracts",

    UPDATE_ALL_CONTRACTS: "update-all-contracts",
    UPDATE_ASSIGNED_CONTRACTS: "update-assigned-contracts",

    DELETE_ALL_CONTRACTS: "delete-all-contracts",
    DELETE_ASSIGNED_CONTRACTS: "delete-assigned-contracts",
  },
  contract_advanced_info: {
    VIEW_ALL_CONTRACT_ADVANCED_INFO: "view-all-contract-advanced-info",
    UPDATE_ALL_CONTRACT_ADVANCED_INFO: "update-all-contract-advanced-info",
  },
  contacts: {
    VIEW_ALL_CONTACTS: "view-all-contacts",
    VIEW_ASSIGNED_CONTACTS: "view-assigned-contacts",

    CREATE_ALL_CONTACTS: "create-all-contacts",

    UPDATE_ALL_CONTACTS: "update-all-contacts",
    UPDATE_ASSIGNED_CONTACTS: "update-assigned-contacts",

    DELETE_ALL_CONTACTS: "delete-all-contacts",
    DELETE_ASSIGNED_CONTACTS: "delete-assigned-contacts",
  },
  users: {
    VIEW_ALL_USERS: "view-all-users",
    CREATE_ALL_USERS: "create-all-users",
    UPDATE_ALL_USERS: "update-all-users",
    DELETE_ALL_USERS: "delete-all-users",
  },
  products: {
    VIEW_ALL_PRODUCTS: "view-all-products",
    CREATE_ALL_PRODUCTS: "create-all-products",
    UPDATE_ALL_PRODUCTS: "update-all-products",
    DELETE_ALL_PRODUCTS: "delete-all-products",
  },
  logs: {
    VIEW_ALL_LOGS: "view-all-logs",
  },
  whatsapp_campaigns: {
    VIEW_ALL_CAMPAIGNS: "view-all-whatsapp-campaigns",
    CREATE_ALL_CAMPAIGNS: "create-all-whatsapp-campaigns",
    UPDATE_ALL_CAMPAIGNS: "update-all-whatsapp-campaigns",
    DELETE_ALL_CAMPAIGNS: "delete-all-whatsapp-campaigns",
  },
  filters: {
    VIEW_ALL_FILTERS: "view-all-filters",
    CREATE_ALL_FILTERS: "create-all-filters",
    UPDATE_ALL_FILTERS: "update-all-filters",
    DELETE_ALL_FILTERS: "delete-all-filters",
  },
  tags: {
    VIEW_ALL_TAGS: "view-all-tags",
    CREATE_ALL_TAGS: "create-all-tags",
    UPDATE_ALL_TAGS: "update-all-tags",
    DELETE_ALL_TAGS: "delete-all-tags",
  },
  appointment_defaults: {
    VIEW_ALL_APPOINTMENT_DEFAULTS: "view-all-appointment-defaults",
    UPDATE_ALL_APPOINTMENT_DEFAULTS: "update-all-appointment-defaults",
  },
  email_templates: {
    VIEW_ALL_TEMPLATES: "view-all-email-templates",
    CREATE_ALL_TEMPLATES: "create-all-email-templates",
    UPDATE_ALL_TEMPLATES: "update-all-email-templates",
    DELETE_ALL_TEMPLATES: "delete-all-email-templates",
  },
  whatsapp_templates: {
    VIEW_ALL_TEMPLATES: "view-all-whatsapp-templates",
    CREATE_ALL_TEMPLATES: "create-all-whatsapp-templates",
    UPDATE_ALL_TEMPLATES: "update-all-whatsapp-templates",
    DELETE_ALL_TEMPLATES: "delete-all-whatsapp-templates",
  },
  status: {
    VIEW_ALL_STATUS: "view-all-status",
    CREATE_ALL_STATUS: "create-all-status",
    UPDATE_ALL_STATUS: "update-all-status",
    DELETE_ALL_STATUS: "delete-all-status",
  },
  report_templates: {
    VIEW_ALL_TEMPLATES: "view-all-report-templates",
    CREATE_ALL_TEMPLATES: "create-all-report-templates",
    UPDATE_ALL_TEMPLATES: "update-all-report-templates",
    DELETE_ALL_TEMPLATES: "delete-all-report-templates",
  },
  app_choice: {
    VIEW_ALL_APP_CHOICE: "view-all-app-choice",
    UPDATE_ALL_APP_CHOICE: "update-all-app-choice",
  },

  automations: {
    VIEW_ALL_AUTOMATIONS: "view-all-automations",
    CREATE_ALL_AUTOMATIONS: "create-all-automations",
    UPDATE_ALL_AUTOMATIONS: "update-all-automations",
    DELETE_ALL_AUTOMATIONS: "delete-all-automations",
  },
  events: {
    VIEW_ALL_EVENTS: "view-all-events",
    CREATE_ALL_EVENTS: "create-all-events",
    UPDATE_ALL_EVENTS: "update-all-events",
    DELETE_ALL_EVENTS: "delete-all-events",
  },
  inbound_source_defaults: {
    VIEW_ALL_INBOUND_SOURCE_DEFAULTS: "view-all-inbound-source-defaults",
    UPDATE_ALL_INBOUND_SOURCE_DEFAULTS: "update-all-inbound-source-defaults",
  },
};

export const PATH_PERMITTED_PERMISSIONS = {
  "/contacts": {
    permissions: [
      PERMISSIONS.contacts.VIEW_ALL_CONTACTS,
      PERMISSIONS.contacts.VIEW_ASSIGNED_CONTACTS,
    ],
  },
  "/commissions": {
    permissions: {
      orPermissionsToCheck: [
        PERMISSIONS.contracts.VIEW_ALL_CONTRACTS,
        PERMISSIONS.contracts.VIEW_ASSIGNED_CONTRACTS,
      ],
      andPermissionsToCheck: [
        PERMISSIONS.contract_advanced_info.VIEW_ALL_CONTRACT_ADVANCED_INFO,
      ],
    },
  },
  "/deals": {
    permissions: [
      PERMISSIONS.contracts.VIEW_ALL_CONTRACTS,
      PERMISSIONS.contracts.VIEW_ASSIGNED_CONTRACTS,
    ],
  },
  "/whatsapp-logs": {
    permissions: [PERMISSIONS.whatsapp_campaigns.VIEW_ALL_CAMPAIGNS],
  },
  "/logs": {
    permissions: [PERMISSIONS.logs.VIEW_ALL_LOGS],
  },
  "/dashboard": {
    permissions: [
      PERMISSIONS.dashboard.VIEW_ALL_DASHBOARD,
      PERMISSIONS.dashboard.VIEW_ASSIGNED_DASHBOARD,
    ],
  },
  "/products": {
    permissions: [PERMISSIONS.products.VIEW_ALL_PRODUCTS],
  },
  "/automation-events": {
    permissions: [
      PERMISSIONS.automations.VIEW_ALL_AUTOMATIONS,
      PERMISSIONS.events.VIEW_ALL_EVENTS,
      PERMISSIONS.inbound_source_defaults.VIEW_ALL_INBOUND_SOURCE_DEFAULTS,
    ],
  },
  "/app-configuration": {
    permissions: [
      PERMISSIONS.filters.VIEW_ALL_FILTERS,
      PERMISSIONS.tags.VIEW_ALL_TAGS,
      PERMISSIONS.appointment_defaults.VIEW_ALL_APPOINTMENT_DEFAULTS,
      PERMISSIONS.email_templates.VIEW_ALL_TEMPLATES,
      PERMISSIONS.whatsapp_templates.VIEW_ALL_TEMPLATES,
      PERMISSIONS.status.VIEW_ALL_STATUS,
      PERMISSIONS.app_choice.VIEW_ALL_APP_CHOICE,
    ],
  },
  "/referrers": {
    permissions: [PERMISSIONS.referrers.VIEW_ALL_REFERRERS],
  },
  "/users": {
    permissions: [PERMISSIONS.users.VIEW_ALL_USERS],
  },
  "/meetings": {
    permissions: [PERMISSIONS.appointments.VIEW_ALL_APPOINTMENTS],
  },
};

export const getPermissionKey = ({ operation, ownership, mod }) =>
  `${operation}-${ownership}-${mod.replaceAll("_", "-")}`;

export const checkPermission = ({ permissions, operation, ownership, mod }) => {
  if (ownership === "none") {
    const relatedPermissions = permissions.filter(
      (p) => p.permission.includes(mod) && p.permission.includes(operation)
    );
    return relatedPermissions.length === 0;
  }
  const permissionKey = getPermissionKey({ operation, ownership, mod });
  return permissions.map((p) => p.permission).includes(permissionKey);
};
