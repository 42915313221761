import { useContext } from "react";
import { WhatsappConversationContext } from "../context/WhatsappConversationContext";

const useWhatsappConversation = () => {
  const { openWhatsappChatScreen, conversationResolvedRef } = useContext(
    WhatsappConversationContext
  );

  return {
    openWhatsappChatScreen,
    conversationResolvedRef,
  };
};

export default useWhatsappConversation;
