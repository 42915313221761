import React from "react";
import AppModal from "../../common/AppModal";
import useLocalization from "../../../hooks/useLocalization";
import ObjectDetails from "../../common/ObjectDetails";
import AddEditContactInboundForm from "../../forms/AddEditContactInboundForm";

const InboundDetailsModal = ({ show, inbound, onHide, isAdd, onSubmit }) => {
  const { translate } = useLocalization();

  return (
    <AppModal
      show={show}
      title={translate(isAdd ? "add_new_inbound" : "inbound_source_details")}
      onHide={onHide}
      modalBodyClassName="p-2"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      {isAdd ? (
        <AddEditContactInboundForm onSubmit={onSubmit} />
      ) : (
        inbound && <ObjectDetails object={inbound} />
      )}
    </AppModal>
  );
};

export default InboundDetailsModal;
