import React, { useEffect, useMemo, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { Plus, Trash } from "react-bootstrap-icons";
import PercentInput from "./PercentInput";
import AppTable from "./app-table/AppTable";
import CurrencyInput from "./CurrencyInput";
import CustomMultiSelect from "./CustomMultiSelect";
import useAppChoices from "../../hooks/useAppChoices";
import UserDropdown from "./UserDropdown";
import useLocalization from "../../hooks/useLocalization";
import { SALES_ROLE } from "../../helpers/constants";
import { camelCase, snakeCase } from "lodash";

const findTotalAfterDiscount = (price = 0, discount = 0) =>
  price - (price * discount) / 100;

const findDiscount = (price, total) =>
  price === 0 ? 0 : ((price - total) / price) * 100;

const ProductStructureTable = ({
  products = [],
  errors = [],
  onProductChange,
  productOptions = [],
  editable = true,
  discount,
  totalPrice,
  onDiscountChange,
  onTotalPriceChange,
  initialValues,
  isAssignedTo = false,
}) => {
  const [filteredProductOptions, setFilteredProductOptions] =
    useState(productOptions);
  const SHOW_ARCHIVED_UNITS = useAppChoices("SHOW_ARCHIVED_UNITS");

  useEffect(() => {
    if (SHOW_ARCHIVED_UNITS?.[0]?.value === false) {
      const units = initialValues?.units || initialValues?.product?.units || [];
      setFilteredProductOptions(
        productOptions.filter(
          (product) =>
            !product.archived ||
            units.some(
              (unit) =>
                (typeof unit?.unit === "object"
                  ? unit?.unit?._id
                  : unit?.unit) === product._id
            )
        )
      );
    } else {
      setFilteredProductOptions(productOptions);
    }
  }, [SHOW_ARCHIVED_UNITS, productOptions, initialValues]);

  const { translate } = useLocalization();
  const newProductData = useMemo(() => {
    const defaultUnit = productOptions?.[0];
    return {
      unit: defaultUnit?._id,
      price: defaultUnit?.price || 0,
      discount: 0,
      total: defaultUnit?.price || 0,
      assignedTo: defaultUnit?.assignedTo || [],
    };
  }, [productOptions]);

  const calculateTotals = (updatedProducts) => {
    const totalPriceAfterDiscount = updatedProducts.reduce(
      (a, b) => a + b.total,
      0
    );
    const totalPrice = updatedProducts.reduce((a, b) => a + b.price, 0);
    const totalDiscount = findDiscount(totalPrice, totalPriceAfterDiscount);

    onDiscountChange(totalDiscount);
    onTotalPriceChange(totalPriceAfterDiscount);
    onProductChange(updatedProducts);
  };

  const handleProductChange = (index, field, value) => {
    const updatedValues = products.map((item, i) => {
      if (i !== index) return item;

      let updatedProduct = { ...item, [field]: value };

      if (field === "unit") {
        const price = productOptions.find((p) => p._id === value)?.price || 0;
        const assignedTo =
          productOptions.find((p) => p._id === value)?.assignedTo || [];
        updatedProduct = {
          ...updatedProduct,
          price,
          total: findTotalAfterDiscount(price, updatedProduct.discount),
          assignedTo,
        };
      } else if (field === "total") {
        const newDiscount = findDiscount(
          updatedProduct.price,
          updatedProduct.total
        );
        updatedProduct.discount = newDiscount;
      } else if (["discount", "price"].includes(field)) {
        const newTotal = findTotalAfterDiscount(
          updatedProduct.price,
          updatedProduct.discount
        );
        updatedProduct.total = newTotal;
      } else if (
        field === "assignedTo" ||
        field === "salesperson" ||
        field === "financialStrategist" ||
        field === "realEstateAnalyst" ||
        field === "mortgageAdvisor"
      ) {
        let salesperson = updatedProduct?.salesperson || [];
        let financialStrategist = updatedProduct?.financialStrategist || [];
        let realEstateAnalyst = updatedProduct?.realEstateAnalyst || [];
        let mortgageAdvisor = updatedProduct?.mortgageAdvisor || [];
        updatedProduct.assignedTo = [
          ...salesperson,
          ...financialStrategist,
          ...realEstateAnalyst,
          ...mortgageAdvisor,
          ...value,
        ];
      }

      return updatedProduct;
    });
    calculateTotals(updatedValues);
  };

  const handleRemoveProduct = (index) => {
    const updatedValues = products.filter((_, i) => i !== index);

    calculateTotals(updatedValues);
  };

  const handleTotalDiscount = (discount) => {
    const updatedValues = products.map((product) => ({
      ...product,
      total: findTotalAfterDiscount(product.price, discount),
      discount,
    }));

    calculateTotals(updatedValues);
  };

  const handleTotalPriceChange = (totalPrice) => {
    const oldTotalPrice = products.reduce((acc, p) => acc + (p.price || 0), 0);
    const updatedValues = products.map((product) => {
      const oldPrice = product.price || 0;
      const newPrice = !oldTotalPrice
        ? 0
        : (oldPrice * totalPrice) / oldTotalPrice;
      return {
        ...product,
        price: newPrice,
        total: findTotalAfterDiscount(newPrice, discount),
      };
    });

    calculateTotals(updatedValues);
  };

  const addNewProductClick = () => {
    const updatedValues = [...products, newProductData];

    calculateTotals(updatedValues);
  };

  const handleTotal = (totalPriceAfterDisc) => {
    const totalPrice = products.reduce((a, b) => a + b.price, 0);
    const totalDiscount = findDiscount(totalPrice, totalPriceAfterDisc);
    handleTotalDiscount(totalDiscount);
  };

  const assignedToRoles = useMemo(() => {
    return [
      "Sales Person",
      "Financial Strategist",
      "Real Estate Analyst",
      "Mortgage Advisor",
    ].map((role) => ({
      label: snakeCase(role),
      value:
        role === "Sales Person" ? SALES_ROLE.toLowerCase() : camelCase(role),
    }));
  }, []);

  return (
    <div className=" w-100 overflow-auto">
      <AppTable hover={false}>
        <thead>
          <tr>
            <th
              style={{ width: 150 }}
              className="bg-dark text-center text-white p-2"
            >
              {translate("product")}
            </th>
            <th
              style={{ width: 110 }}
              className="bg-dark text-center text-white p-2"
            >
              {translate("price")}
            </th>
            <th
              style={{ width: 80 }}
              className="bg-dark text-center text-white p-2"
            >
              {translate("discount")}
            </th>

            <th
              style={{ width: 110 }}
              className="bg-dark text-center text-white p-2"
            >
              {translate("total")}
            </th>
            {isAssignedTo && (
              <>
                {assignedToRoles.map((role) => (
                  <th
                    key={role.value}
                    style={{ width: 120 }}
                    className="bg-dark text-center text-white p-2"
                  >
                    {translate(role.label)}
                  </th>
                ))}
              </>
            )}
            {editable && (
              <th
                style={{ width: 70 }}
                className="bg-dark text-center text-white p-2"
              >
                {translate("action")}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={`${product.unit}-${index}`}>
              <td align="center" className="p-1 bg-white">
                <CustomMultiSelect
                  disabled={!editable}
                  items={filteredProductOptions.map((u) => ({
                    label: (
                      <div>
                        <span>{u?.description}</span>
                        {u?.archived && (
                          <Badge variant="warning" className="mx-2">
                            Archived
                          </Badge>
                        )}
                      </div>
                    ),
                    value: u._id,
                  }))}
                  selectedItems={
                    Array.isArray(product?.unit)
                      ? product?.unit
                      : [product?.unit]
                  }
                  isMulti={false}
                  closeMenuOnSelect
                  onChange={(v) => handleProductChange(index, "unit", v)}
                />
              </td>
              <td align="center" className="p-1">
                <CurrencyInput
                  disabled={!editable}
                  value={product?.price}
                  onChange={(price) =>
                    handleProductChange(index, "price", price)
                  }
                />
              </td>
              <td align="center" className="p-1">
                <PercentInput
                  disabled={!editable}
                  value={product?.discount}
                  onChange={(discount) =>
                    handleProductChange(index, "discount", discount)
                  }
                />
              </td>

              <td align="center" className="p-1">
                <CurrencyInput
                  disabled={!editable}
                  value={product?.total}
                  onChange={(total) =>
                    handleProductChange(index, "total", total)
                  }
                />
              </td>
              {isAssignedTo && (
                <>
                  {assignedToRoles?.map((role) => (
                    <td key={role.value} align="center" className="p-1">
                      <UserDropdown
                        key={role.value}
                        value={product?.[role.value]}
                        onChange={(value) =>
                          handleProductChange(index, role.value, value)
                        }
                        maxItemCustomMessage={(length) => `+ ${length} more`}
                        height="26px"
                        maxToShow={2}
                        isMultiSelect
                        closeMenuOnSelect={false}
                      />
                    </td>
                  ))}
                </>
              )}
              {editable && (
                <td align="center" className="p-1">
                  <Button
                    size="sm"
                    variant="outline-danger"
                    className="d-flex align-items-center mt-1"
                    onClick={() => handleRemoveProduct(index)}
                  >
                    <Trash />
                  </Button>
                </td>
              )}
            </tr>
          ))}
          <tr className="border-secondary-dak border-2" />
          {editable && (
            <tr className="border-secondary-dark border-2">
              <td align="center" className="bg-gray">
                <h6 className="mb-0 text-dark large mt-1 fw-bold">Total</h6>
              </td>
              <td align="center" className="p-1 bg-gray">
                <CurrencyInput
                  size="sm"
                  className="fw-bold bg-white"
                  value={products.reduce((a, b) => a + b.price, 0)}
                  onChange={handleTotalPriceChange}
                />
              </td>
              <td align="center" className="p-1 bg-gray ">
                <PercentInput
                  className="fw-bold"
                  disabled={!editable}
                  value={discount}
                  onChange={handleTotalDiscount}
                />
              </td>

              <td align="center" className="p-1 bg-gray">
                <CurrencyInput
                  className="fw-bold bg-primary-light"
                  disabled={!editable}
                  value={totalPrice}
                  onChange={handleTotal}
                />
              </td>
              {isAssignedTo && (
                <td align="center" colSpan={4} className="p-1 bg-gray">
                  {" "}
                </td>
              )}
              <td
                align="center"
                className="p-0 bg-gray align-middle text-center"
              >
                <Button
                  size="sm"
                  variant="success"
                  className="text-white px-2 py-0"
                  onClick={addNewProductClick}
                >
                  <Plus size={14} />{" "}
                  <span className="smallFont">{translate("new")}</span>
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </AppTable>
    </div>
  );
};

export default ProductStructureTable;
