import { snakeCase, startCase } from "lodash";
import moment from "moment";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  Arrow90degLeft,
  Arrow90degRight,
  CalendarDateFill,
  ChatFill,
  EnvelopeAt,
  Eye,
  EyeSlash,
  Funnel,
  FunnelFill,
  ListTask,
  Pencil,
  PersonGear,
  PlayCircleFill,
  Plus,
  TelephoneFill,
  TelephoneInboundFill,
  TelephoneOutboundFill,
  Trash,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import RujumIcon from "../assets/RujumAppIcon.png";
import CopyLinkField from "../components/common/CopyLinkField";
import OverviewWhatsappTemplate from "../components/common/OverviewWhatsappTemplate";
import TextHighlighter from "../components/common/TextHiglighter";
import UnderlineButton from "../components/common/UnderlineButton";
import WhatsappTemplateDropdownOption from "../components/common/WhatsappTemplateDropdownOption";
import {
  CRM_APP_URL,
  DEAL_STATUSES,
  DEFAULT_DATE_TIME_FORMAT,
  FINANCIAL_STRATEGIST_ROLE,
  INBOUNDS_TO_SHOW,
  INBOUND_SOURCE,
  LEAD_SOURCE_OPTIONS,
  LOG_COLLECTIONS,
  LOG_TYPE,
  MORTGAGE_ADVISOR_ROLE,
  PRODUCTION_STAGE_STATUSES,
  REAL_ESTATE_ANALYST_ROLE,
  RUJUM_APP_URL,
  SALES_ROLE,
  SENDER_STATUS_OPTIONS,
  WHATSAPP_SOURCE_AUTOMATIONS,
  WHATSAPP_SOURCE_CONTACT_TABLE,
  WHATSAPP_SOURCE_EVENTS,
  WHATSAPP_SOURCE_INBOUNDS,
  WHATSAPP_SOURCE_QUICK_SEND,
  dealStatusToColor,
  role_to_color_map,
} from "./constants";
import {
  CAMPAIGN_TRIGGER_OPTIONS,
  formatCurrency,
  formatDateTime,
  getAppointmentVideo,
  getTextColor,
  getWhatsappTemplateStyles,
  hasHttpOrHttps,
  removeParamsFromUrl,
  templateUrl,
  truncateAndAddEllipsis,
} from "./global";
import { isAdmin, isAdminOrManager } from "./session";
import { PERMISSIONS } from "./permissions";
import ViewActions from "../components/common/data-table/ViewActions";
import { DownCircleFilled, DownloadOutlined } from "@ant-design/icons";
// Function to check if a date is in the past
const isDatePast = (dateToCheck) => {
  if (!dateToCheck) return false;

  const currentDate = new Date();
  const dateToCompare = new Date(dateToCheck);
  return dateToCompare < currentDate;
};

export const getContactTableColumns = ({
  selectAllMode,
  onToggleSelectAll,
  onToggleSelectItem,
  isItemSelected,
  inboundCampaignUrlOptions,
  textToHighlight,
  translate,
  showAllFields,
  users,
  groups,
  GROUPPED_STATUS,
  getContactStatus,
  openWhatsappChatScreen,
  isRTL,
  user,
}) => {
  const allLabels = [
    {
      key: "view",
      labelRenderer: (field) => (
        <input
          type="checkbox"
          className="hover"
          checked={selectAllMode}
          onChange={(e) => {
            e.stopPropagation();
            onToggleSelectAll(e.target.checked);
          }}
        />
      ),
      cellRenderer: (contact) => (
        <div
          className={`${
            contact?.duplicates?.filter((d) => d !== contact?._id)?.length >
              0 && "bg-primary-light rounded"
          } `}
          style={{ height: "100%" }}
        >
          <input
            type="checkbox"
            className="hover"
            checked={isItemSelected(contact)}
            onClick={(e) => {
              e.stopPropagation();
              onToggleSelectItem(contact);
            }}
          />
        </div>
      ),
      onCellClick: (contact) => onToggleSelectItem(contact),
      disableSort: true,
      disableSearch: true,
      noInternalCellPadding: true,
      width: 50,
    },
    {
      key: "status",
      label: "Status",
      width: 200,
      cellRenderer: (contact) => {
        const status = getContactStatus(contact?.status);

        return <Badge bg={status.color}>{status.label}</Badge>;
      },
      searchOptions: GROUPPED_STATUS,
      isGroupedDropdown: true,
    },
    {
      key: "followupDate",
      label: "Follow up Date",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      width: 160,
      cellColor: (contact) =>
        isDatePast(contact.followupDate) ? "danger-light" : "white",
    },
    {
      key: "leadSource",
      label: "lead source",
      cellRenderer: (contact) => translate(contact?.leadSource),
      width: 100,
      searchOptions: LEAD_SOURCE_OPTIONS.map((ls) => ({
        label: translate(ls),
        value: ls,
      })),
    },
    {
      key: "inboundSource",
      label: "Last Inbound Source",
      cellRenderer: (contact) => {
        const latestInbound = contact.inbounds?.sort(
          (i1, i2) => new Date(i2.date) - new Date(i1.date)
        )[0];
        if (latestInbound?.source === "Facebook Leadform")
          return <span>{latestInbound?.sourceDetails?.campaignName}</span>;
        else if (latestInbound?.source === "Website") {
          const urlToShow = truncateAndAddEllipsis(
            removeParamsFromUrl(latestInbound?.sourceDetails?.page_url) ?? "",
            30
          );
          return urlToShow;
        } else
          return (
            translate(snakeCase(latestInbound?.source)) || latestInbound?.source
          );
      },
      width: 200,
      disableSort: true,
      searchOptions: [
        ...inboundCampaignUrlOptions?.map((iS) => ({ label: iS, value: iS })),
        ...INBOUND_SOURCE.map((s) => ({
          label: translate(snakeCase(s)) || s,
          value: s,
        })),
      ],
      isCreatableDropdown: true,
      isMultiSelect: false,
      hide: !showAllFields,
    },
    {
      key: "lastInboundDate",
      label: "Last Inbound Date",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      valueSelector: (contact) => {
        const latestInbound = contact.inbounds?.sort(
          (i1, i2) => new Date(i2.date) - new Date(i1.date)
        )[0];

        return latestInbound?.date;
      },
      width: 160,
    },
    {
      key: "firstName",
      label: "First Name",
      type: "text",
      width: 120,
      highlight: true,
      highlightText: textToHighlight,
      cellRenderer: (contact) => (
        <div>
          <h6 style={{ fontSize: 16 }} className="mb-0">
            <TextHighlighter
              queries={textToHighlight}
              text={contact.firstName || "-"}
            />
          </h6>
          {contact.spouseFirstName && (
            <h6 style={{ fontSize: 16 }} className="mb-0 mt-1">
              <TextHighlighter
                queries={textToHighlight}
                text={contact.spouseFirstName || "-"}
              />
            </h6>
          )}
        </div>
      ),
    },
    {
      key: "lastName",
      label: "Last Name",
      type: "text",
      width: 120,
      highlight: true,
      highlightText: textToHighlight,
      cellRenderer: (contact) => (
        <div>
          <h6 style={{ fontSize: 16 }} className="mb-0">
            <TextHighlighter
              queries={textToHighlight}
              text={contact.lastName || "-"}
            />
          </h6>
          {contact.spouseLastName && (
            <h6 style={{ fontSize: 16 }} className="mb-0 mt-1">
              <TextHighlighter
                queries={textToHighlight}
                text={contact.spouseLastName || "-"}
              />
            </h6>
          )}
        </div>
      ),
    },

    {
      key: "phoneNumbers",
      label: "Phone Number",
      type: "phone",
      cellRenderer: (contact) =>
        contact.phoneNumbers.length ? (
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-center flex-grow-1">
              {contact.phoneNumbers?.[0]}
            </div>{" "}
            {contact?.messages?.length && !contact.messages[0].seen ? (
              <OverlayTrigger
                placement={isRTL ? "right" : "left"}
                onEnter={getWhatsappTemplateStyles}
                overlay={
                  <Tooltip aria-label="tooltip">
                    {contact?.messages.map((m, i) => {
                      return (
                        <div
                          key={m.sid}
                          className={`text-left ${
                            i < contact.messages.length - 1
                              ? "border-bottom"
                              : ""
                          } py-1`}
                        >
                          <b>{m.phone} :</b> <br />{" "}
                          <span
                            style={{ textAlign: "left" }}
                            className="text-left"
                          >
                            {m.message}
                          </span>
                        </div>
                      );
                    })}
                  </Tooltip>
                }
              >
                <Button
                  className="p-0"
                  variant="transparent"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    openWhatsappChatScreen(contact);
                  }}
                >
                  <ChatFill className="text-success hover" />
                </Button>
              </OverlayTrigger>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        ),
      width: 140,
      disableSort: true,
      highlight: true,
      highlightText: textToHighlight,
    },
    {
      key: "spousePhoneNumbers",
      label: "Spouse Phone Number",
      type: "phone",
      valueSelector: (contact) => contact.spousePhoneNumbers?.[0],
      width: 140,
      disableSort: true,
      highlight: true,
      highlightText: textToHighlight,
      hide: !showAllFields,
    },
    {
      key: "description",
      label: "description",
      type: "text",
      width: 160,
      disableSort: true,
      valueSelector: (contact) => {
        return contact?.description;
      },
      truncate: true,
      highlight: true,
      highlightText: textToHighlight,
    },
    {
      key: "comments",
      label: "Comment",
      type: "text",
      width: 160,
      disableSort: true,
      valueSelector: (contact) => {
        contact.comments.sort(
          (a, b) => new Date(b["date"]) - new Date(a["date"])
        );
        return contact.comments?.[0]?.text;
      },
      truncate: true,
      highlight: true,
      highlightText: textToHighlight,
      hide: !showAllFields,
    },
    {
      key: "tags",
      label: "Tags",
      width: 160,
      disableSort: true,
      cellRenderer: (contact) => (
        <div>
          {contact?.tags?.map((tag) => (
            <Badge className="ms-1" key={tag}>
              {tag}
            </Badge>
          ))}
        </div>
      ),
      hide: !showAllFields,
    },
    {
      key: "emails",
      label: "Emails",
      type: "email",
      valueSelector: (contact) => contact.emails?.[0],
      width: 140,
      disableSort: true,
      highlight: true,
      highlightText: textToHighlight,
      hide: !showAllFields,
    },
    {
      key: "assignedTo",
      label: "assigned to",
      cellRenderer: (contact) => {
        const { assignedTo = [] } = contact;
        return (
          <div className="d-flex gap-1 justify-content-center align-items-center flex-wrap">
            {[...new Map(assignedTo?.map((f) => [f?._id, f])).values()]?.map(
              (f) => {
                let backgroundColor = f?.color || "#007f7f";
                let textColor = getTextColor(backgroundColor);
                return (
                  <div
                    style={{ background: f?.color, color: textColor }}
                    className="rounded p-1"
                    key={f?._id}
                  >
                    <h6 className="tiny mb-0">{f?.name}</h6>
                  </div>
                );
              }
            )}
          </div>
        );
      },
      width: 200,
      disableSort: true,
      type: "user-dropdown",
      hide: !showAllFields,
    },

    {
      key: "groups",
      label: "groups",
      width: 320,
      cellRenderer: (contact) => {
        return (
          <div className="d-flex align-items-center gap-1 justify-content-center  flex-wrap">
            {groups
              .filter((group) => contact?.groups?.includes(group._id))
              .map((g) => (
                <Badge key={g._id} bg={"dark"} style={{ fontSize: 10 }}>
                  {g.name}
                </Badge>
              ))}
          </div>
        );
      },
      searchOptions: groups.map((g) => ({ label: g.name, value: g._id })),
      hide: !showAllFields,
    },
    {
      key: "referredBy",
      label: "referrer",
      cellRenderer: (contact) => {
        const { referredBy } = contact;
        return (
          referredBy && (
            <div className="rounded bg-primary p-1">
              <h6 className="tiny text-white mb-0">{referredBy?.name}</h6>
            </div>
          )
        );
      },
      width: 100,
      disableSort: true,
      hide: !showAllFields,
    },
    {
      key: "surveySent",
      label: "survey_sent",
      cellRenderer: (contact) => (
        <h6 style={{ font: 14 }} className="mb-0">
          {contact?.surveySent ? "Yes" : "No"}
        </h6>
      ),
      disableSort: true,
      disableSearch: true,
      hide: !showAllFields,
      width: 120,
    },
    {
      key: "surveyCompleted",
      label: "survey_completed",
      cellRenderer: (contact) => (
        <h6 style={{ font: 14 }} className="mb-0">
          {contact?.surveyCompleted ? "Yes" : "No"}
        </h6>
      ),
      disableSort: true,
      disableSearch: true,
      hide: !showAllFields,
      width: 120,
    },
    {
      key: "whatsappSenderStatus",
      label: "whatsapp_sender_status",
      disableSort: true,
      hide: !showAllFields || !isAdmin(user?.role),
      searchOptions: SENDER_STATUS_OPTIONS?.map((u) => ({
        label: u,
        value: u,
      })),
      width: 120,
    },
    {
      key: "emailSenderStatus",
      label: "email_sender_status",
      disableSort: true,
      hide: !showAllFields || !isAdmin(user?.role),
      searchOptions: SENDER_STATUS_OPTIONS?.map((u) => ({
        label: u,
        value: u,
      })),
      width: 120,
    },
    {
      key: "age",
      label: "Age",
      width: 100,
      cellRenderer: (contact) => (
        <p style={{ font: 10 }} className="mb-0">
          {contact?.age}
        </p>
      ),
      disableSort: true,
      disableSearch: true,
      hide: !showAllFields,
    },
    {
      key: "work",
      label: "Work",
      width: 100,
      cellRenderer: (contact) => (
        <p style={{ font: 10 }} className="mb-0">
          {contact?.work}
        </p>
      ),
      disableSort: true,
      disableSearch: true,
      hide: !showAllFields,
    },
    {
      key: "monthlySaving",
      label: "Monthly Saving",
      width: 100,
      cellRenderer: (contact) => (
        <p style={{ font: 10 }} className="mb-0">
          {contact?.monthlySaving}
        </p>
      ),
      disableSort: true,
      disableSearch: true,
      hide: !showAllFields,
    },
    {
      key: "availableMoney",
      label: "Available Money",
      cellRenderer: (contact) => (
        <p style={{ font: 10 }} className="mb-0">
          {contact?.availableMoney}
        </p>
      ),
      disableSort: true,
      disableSearch: true,
      hide: !showAllFields,
    },
    {
      key: "whatApartmentWantToBuy",
      label: "What Apartment Want To Buy",
      width: 160,
      cellRenderer: (contact) => (
        <p style={{ font: 10 }} className="mb-0">
          {contact?.whatApartmentWantToBuy}
        </p>
      ),
      disableSort: true,
      disableSearch: true,
      hide: !showAllFields,
    },
    {
      key: "haveApartment",
      label: "Have Apartment",
      cellRenderer: (contact) => (
        <p style={{ font: 10 }} className="mb-0">
          {contact?.haveApartment}
        </p>
      ),
      disableSort: true,
      disableSearch: true,
      hide: !showAllFields,
    },
    {
      key: "stageInSearch",
      label: "Stage In Search",
      type: "text",
      width: 160,
      disableSort: true,
      valueSelector: (contact) => {
        return contact.stageInSearch;
      },
      truncate: true,
      highlight: true,
      highlightText: textToHighlight,
      hide: !showAllFields,
    },
    {
      key: "whatService",
      label: "What Service",
      width: 160,
      disableSort: true,
      valueSelector: (contact) => {
        return contact.whatService;
      },
      truncate: true,
      highlight: true,
      highlightText: textToHighlight,
      hide: !showAllFields,
    },
    {
      key: "howDoYouKnowUs",
      label: "How Do You Know Us",
      width: 140,
      cellRenderer: (contact) => (
        <p style={{ font: 10 }} className="mb-0">
          {contact?.howDoYouKnowUs}
        </p>
      ),
      disableSort: true,
      disableSearch: true,
      hide: !showAllFields,
    },
  ];

  return allLabels.filter((l) => !l?.hide);
};

export const getEventTableColumns = ({
  onEditEventClick,
  onDeleteEventClick,
  users,
  translate,
  getContactStatus,
  allowEdit,
  allowDelete,
}) => {
  const allLabels = [
    {
      key: "name",
      label: "Name",
      type: "text",
      width: 100,
    },
    {
      key: "date",
      label: "Event Date",
      type: "date",
      width: 140,
    },
    {
      key: "contactCount",
      label: "Contacts",
      width: 50,
      disableSearch: true,
      disableSort: true,
    },
    {
      key: "facebookCampaigns",
      label: "Facebook Campaigns",
      cellRenderer: (event) => (
        <div className="d-flex align-items-center justify-content-center gap-1">
          {event.facebookCampaigns.map((u) => (
            <Badge key={u?.name} bg="dark" className="">
              {u?.name}
            </Badge>
          ))}
        </div>
      ),
      type: "text",
      width: 200,
    },
    {
      key: "urls",
      label: "Urls",
      cellRenderer: (event) =>
        event.urls.map((u) => (
          <Badge bg="dark" className="mx-1">
            {u}
          </Badge>
        )),
      type: "text",
      width: 200,
    },
    {
      key: "activeTraillistName",
      label: "Active Trail Groups",
      width: 160,
      cellRenderer: (event) => (
        <div className="d-flex align-items-center justify-content-center gap-1">
          {event?.activeTrailLists?.map((a) => (
            <Badge key={a.name} bg="dark" className="">
              {a.name}
            </Badge>
          ))}
        </div>
      ),
    },
    {
      key: "leadSource",
      label: "lead source",
      cellRenderer: (event) => translate(event?.leadSource),
      width: 100,
    },
    {
      key: "status",
      label: "Status",
      cellRenderer: (event) => {
        let status = getContactStatus(event.status);
        return (
          <div className={`rounded p-1 text-white bg-${status.color}`}>
            <h6 className="tiny mb-0"> {status.label}</h6>
          </div>
        );
      },
    },
    {
      key: "assignedTo",
      label: "assigned_to",
      cellRenderer: (event) => {
        const assignedTo = users?.filter((user) =>
          event?.assignedTo?.includes(user?._id)
        );
        return (
          <div className="d-flex justify-content-center align-items-center flex-wrap gap-1">
            {assignedTo?.map((u) => {
              let backgroundColor = u?.color || "#007f7f";
              let textColor = getTextColor(backgroundColor);
              return (
                <div
                  style={{ color: textColor, backgroundColor: backgroundColor }}
                  className="w-auto rounded p-1"
                >
                  <h6 className="tiny mb-0"> {u.name}</h6>
                </div>
              );
            })}
          </div>
        );
      },
    },

    {
      label: "Actions",
      key: "view",
      hide: !allowDelete && !allowEdit,
      cellRenderer: (event) => (
        <>
          {allowEdit && (
            <Pencil
              className="text-success hover-light mx-2"
              onClick={() => onEditEventClick(event)}
            />
          )}
          {allowDelete && (
            <Trash
              className="text-danger hover-light mx-1"
              onClick={() => onDeleteEventClick(event)}
            />
          )}
        </>
      ),
    },
  ];

  return allLabels.filter((l) => !l.hide);
};

export const getAutomationsTableColumns = ({
  onEditAutomationClick,
  onDeleteAutomationClick,
  users,
  translate,
  getContactStatus,
  allowEdit,
  allowDelete,
}) => {
  const allLabels = [
    {
      key: "name",
      label: "Name",
      type: "text",
      width: 100,
    },
    {
      key: "contactCount",
      label: "Contacts",
      width: 50,
      disableSearch: true,
      disableSort: true,
    },
    {
      key: "facebookCampaigns",
      label: "Facebook Campaigns",
      cellRenderer: (automation) => (
        <div className="d-flex align-items-center justify-content-center gap-1">
          {automation.facebookCampaigns.map((u) => (
            <Badge key={u?.name} bg="dark" className="">
              {u?.name}
            </Badge>
          ))}
        </div>
      ),
      type: "text",
      width: 200,
    },
    {
      key: "urls",
      label: "Urls",
      cellRenderer: (automation) =>
        automation.urls.map((u) => (
          <Badge bg="dark" className="mx-1">
            {u}
          </Badge>
        )),
      type: "text",
      width: 200,
    },
    {
      key: "activeTraillistName",
      label: "Active Trail Groups",
      width: 160,
      cellRenderer: (automation) => (
        <div className="d-flex align-items-center justify-content-center gap-1">
          {automation?.activeTrailLists?.map((a) => (
            <Badge key={a.name} bg="dark" className="">
              {a.name}
            </Badge>
          ))}
        </div>
      ),
    },
    {
      key: "leadSource",
      label: "lead source",
      cellRenderer: (automation) => translate(automation?.leadSource),
      width: 100,
    },
    {
      key: "status",
      label: "Status",
      cellRenderer: (automation) => {
        const status = getContactStatus(automation?.status);
        return (
          <div className={`rounded p-1 text-white bg-${status.color}`}>
            <h6 className="tiny mb-0">{status.label}</h6>
          </div>
        );
      },
    },
    {
      key: "assignedTo",
      label: "assigned_to",
      cellRenderer: (automation) => {
        const assignedTo = users?.filter((user) =>
          automation?.assignedTo?.includes(user?._id)
        );
        return (
          <div className="d-flex justify-content-center align-items-center flex-wrap gap-1">
            {assignedTo?.map((u) => {
              let backgroundColor = u?.color || "#007f7f";
              let textColor = getTextColor(backgroundColor);
              return (
                <div
                  style={{ color: textColor, backgroundColor: backgroundColor }}
                  className="w-auto rounded p-1"
                >
                  <h6 className="tiny mb-0"> {u?.name || "ssss"}</h6>
                </div>
              );
            })}
          </div>
        );
      },
    },

    {
      label: "Actions",
      key: "view",
      hide: !allowEdit && !allowDelete,
      cellRenderer: (automation) => (
        <>
          {allowEdit && (
            <Pencil
              className="text-success hover-light mx-2"
              onClick={() => onEditAutomationClick(automation)}
            />
          )}
          {allowDelete && (
            <Trash
              className="text-danger hover-light mx-1"
              onClick={() => onDeleteAutomationClick(automation)}
            />
          )}
        </>
      ),
    },
  ];

  return allLabels.filter((l) => !l.hide);
};

export const getProductTableColumns = ({
  onEditProductClick,
  onDeleteProductClick,
  onEditProductStageClick,
  allowEdit,
  allowDelete,
  users,
}) => {
  const allLabels = [
    {
      key: "description",
      label: "Contract title",
      type: "text",
      width: 160,
    },
    {
      key: "price",
      label: "Price",
      valueSelector: (product) => formatCurrency(product.price),
      width: 160,
    },
    {
      key: "view",
      label: "Action",
      hide: !allowDelete && !allowEdit,
      cellRenderer: (product) => (
        <>
          {allowEdit && (
            <>
              <ListTask
                className="text-info hover-light mx-2"
                onClick={() => onEditProductStageClick(product)}
              />
              <Pencil
                className="text-success hover-light mx-2"
                onClick={() => onEditProductClick(product)}
              />
            </>
          )}
        </>
      ),
    },
  ];

  return allLabels.filter((l) => !l.hide);
};

export const getContractsTableColumns = ({
  onEditContractClick,
  products,
  allowEdit,
  allowDelete,
}) => {
  const allLabels = [
    {
      key: "note",
      label: "Name",
      type: "text",
      valueSelector: (contract) => {
        return contract?.note;
      },
      truncate: true,
    },
    {
      key: "total",
      label: "Price",
      valueSelector: (product) => formatCurrency(product.total),
      width: 100,
    },
    {
      key: "templateLink",
      label: "Template Link",
      type: "text",
      width: 200,
    },
    {
      key: "products",
      label: "Products",
      cellRenderer: (contract) => (
        <div className="d-flex align-items-center justify-content-center flex-wrap">
          {contract?.units?.map((unit) => {
            const product = products?.find((pr) => pr?._id === unit?.unit);
            return (
              <>
                <Badge bg={"dark"} className="mx-1">
                  {product?.description}
                  {product?.archived === true && (
                    <Badge variant="warning" className="mx-1">
                      Archived
                    </Badge>
                  )}
                </Badge>
              </>
            );
          })}
        </div>
      ),
      width: 300,
    },
    {
      key: "description",
      label: "Title in Prospero",
      cellRenderer: (contract) => (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <h6 style={{ width: 200 }} className="mb-0 smallFont truncate">
            {contract?.description}
          </h6>
        </div>
      ),
      width: 200,
    },
    {
      key: "view",
      label: "Action",
      width: 100,
      hide: !allowDelete && !allowEdit,
      cellRenderer: (contract) => (
        <>
          {allowEdit && (
            <Badge
              role="button"
              bg="success"
              onClick={(e) => {
                e.preventDefault();
                onEditContractClick(contract);
              }}
            >
              <div className="d-flex gap-2 align-items-center">
                <span>Edit</span>
                <Pencil size={12} />
              </div>
            </Badge>
          )}
        </>
      ),
    },
  ];

  return allLabels.filter((l) => !l.hide);
};
const generateSearchOrderForProductionStage = (products) => {
  let result = [];

  products.forEach((product) => {
    let options = [];
    options = product?.productionStages?.map((pS) => ({
      label: pS?.step,
      value: pS?._id,
    }));

    result.push({
      label: product?.description,
      key: product?.description,
      options,
    });
  });
  return result;
};

export const getDealsTableColumns = ({
  user,
  columnToHide = [],
  users,
  products,
  onToggleSelectAll,
  onToggleSelectItem,
  isItemSelected,
  selectAllMode,
  translate,
  handleShowMoreClick,
}) => {
  const allLabels = [
    {
      key: "view",
      labelRenderer: (field) => (
        <input
          type="checkbox"
          className="hover"
          checked={selectAllMode}
          onChange={(e) => {
            e.stopPropagation();
            onToggleSelectAll(e.target.checked);
          }}
        />
      ),
      cellRenderer: (contract) => (
        <input
          type="checkbox"
          className="hover"
          checked={isItemSelected(contract)}
          onChange={(e) => {
            e.stopPropagation();
            onToggleSelectItem(contract);
          }}
        />
      ),
      onCellClick: (contact) => onToggleSelectItem(contact),
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "status",
      label: "Status",
      cellRenderer: (contract) => {
        const { status } = contract;
        const color = dealStatusToColor[status] || "primary";
        return <Badge bg={color}>{translate(snakeCase(status))}</Badge>;
      },
      searchOptions: DEAL_STATUSES?.map((s) => ({
        label: translate(snakeCase(s)) || s,
        value: s,
      })),
      width: 150,
      disableSearch: true,
      isMultiSelect: true,
    },
    {
      key: "product",
      label: "Contract",
      cellRenderer: (contract) => {
        const { product } = contract;

        if (!product) return "N/A";
        return contract?.prosperoProposalMeta?.link ? (
          <a
            href={contract?.prosperoProposalMeta?.link}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {product?.description}{" "}
          </a>
        ) : (
          <span className="truncate">{product?.description}</span>
        );
      },
      disableSort: true,
      searchOptions: products.map((product) => ({
        label: product?.description,
        value: product?._id,
      })),
      width: 80,
    },
    {
      key: "client",
      label: "Client",
      width: 80,
      cellRenderer: (contract) => {
        const { contact } = contract;
        if (!contact) return "N/A";
        return <span>{`${contact?.firstName} ${contact?.lastName}`} </span>;
      },
      disableSort: true,
    },
    {
      key: "contactApointemnts",
      label: "",
      width: 50,
      cellRenderer: (contract) =>
        contract?.contactAppointments?.length > 0 && (
          <CalendarDateFill
            className="text-info hover"
            size={15}
            onClick={(e) => {
              e.stopPropagation();
              handleShowMoreClick(contract);
            }}
          />
        ),
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "proposalQuote",
      label: "Deal",
      type: "number",
      hide: !isAdminOrManager(user?.role),
      width: 60,
      valueSelector: (deal) => formatCurrency(deal?.proposalQuote),
      disableSearch: true,
    },
    {
      key: "payment",
      label: "Payment",
      hide: !isAdminOrManager(user?.role),
      width: 80,
      cellRenderer: (contract) => {
        const { payments } = contract;
        return (
          <span dir="ltr">
            {formatCurrency(payments?.reduce((a, c) => a + c.amount, 0))}
          </span>
        );
      },
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "assignedTo",
      label: "Assigned To",
      cellRenderer: (contract) => {
        return contract?.assignedTo?.map((user) => (
          <Badge className="me-1 px-1 py-1">{user?.name}</Badge>
        ));
      },
      searchOptions: users
        .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
        .map((user) => ({
          label: user.name,
          value: user._id,
        })),
      disableSort: true,
      disableSearch: true,
      isMultiSelect: true,
    },
    {
      key: "tags",
      label: "Tags",
      width: 160,
      disableSort: true,
      cellRenderer: (contract) => (
        <div>
          {contract?.tags?.map((tag) => (
            <Badge className="ms-1" key={tag}>
              {tag}
            </Badge>
          ))}
        </div>
      ),
    },
    {
      key: "signedOn",
      label: "Contract Signed On",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
    },
    {
      key: "createdAt",
      label: "Contract Sent On",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
    },
  ].filter((f) => !f?.hide);

  return allLabels.filter((label) => !columnToHide.includes(label?.key));
};

export const getCommissionsTableColumns = ({
  columnToHide,
  users,
  products,
  onToggleSelectAll,
  onToggleSelectItem,
  isItemSelected,
  selectAllMode,
  translate,
  referredBy = null,
}) => {
  const referredByMode = Boolean(referredBy);
  const allLabels = [
    {
      key: "view",
      labelRenderer: (field) => (
        <input
          type="checkbox"
          className="hover"
          checked={selectAllMode}
          onChange={(e) => {
            e.stopPropagation();
            onToggleSelectAll(e.target.checked);
          }}
        />
      ),
      cellRenderer: (contract) => (
        <input
          type="checkbox"
          className="hover"
          checked={isItemSelected(contract)}
          onClick={(e) => {
            e.stopPropagation();
            onToggleSelectItem(contract);
          }}
        />
      ),
      onCellClick: (contract) => onToggleSelectItem(contract),
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "status",
      label: "Status",
      cellRenderer: (contract) => {
        const { status } = contract;
        const color = dealStatusToColor[status] || "primary";
        return <Badge bg={color}>{translate(snakeCase(status))}</Badge>;
      },
      searchOptions: DEAL_STATUSES?.map((s) => ({ label: s, value: s })),
      width: 40,
    },
    {
      key: "product",
      label: "Contract",
      cellRenderer: (contract) => {
        const { product } = contract;

        if (!product) return "N/A";
        return contract?.prosperoProposalMeta?.link ? (
          <a
            href={contract?.prosperoProposalMeta?.link}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {product?.description}{" "}
          </a>
        ) : (
          <span className="truncate">{product?.description}</span>
        );
      },
      disableSort: true,
      searchOptions: products.map((product) => ({
        label: product?.description,
        value: product?._id,
      })),
      width: 80,
    },
    {
      key: "client",
      label: "Client",
      width: 80,
      cellRenderer: (contract) => {
        const { contact } = contract;

        return contact ? (
          <a
            href={
              process.env.PUBLIC_URL +
              `/contacts?contact=${contact?._id}&view=contact`
            }
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {`${contact?.firstName} ${contact?.lastName}`}
          </a>
        ) : (
          <span className="truncate">N/A</span>
        );
      },
      disableSort: true,
    },
    {
      key: "referredBy",
      label: "Referred by",
      width: 60,
      hide: referredByMode,
      valueSelector: (deal) => deal?.contact?.referredBy?.name,
    },
    {
      key: "proposalQuote",
      label: "Deal",
      type: "number",
      width: 60,
      valueSelector: (deal) => formatCurrency(deal?.proposalQuote),
      disableSearch: true,
    },
    {
      key: "payment",
      label: "Payment",
      width: 80,
      cellRenderer: (contract) => {
        const { payments } = contract;
        return (
          <span dir="ltr">
            {formatCurrency(payments?.reduce((a, c) => a + c.amount, 0))}
          </span>
        );
      },
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "referredPaidAmount",
      label: "Referrer paid amount",
      width: 80,
      cellRenderer: (contract) => {
        const { commissions } = contract;

        return (
          <span dir="ltr">
            {formatCurrency(
              commissions?.reduce(
                (a, c) =>
                  a +
                  (c?.type === "Referral" && c?.commissionPaidDate
                    ? c.commission
                    : 0),
                0
              )
            )}
          </span>
        );
      },
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "assignedTo",
      label: "Assigned To",
      cellRenderer: (contact) => {
        return contact?.assignedTo?.map((user) => (
          <Badge className="me-1 px-1 py-1">{user?.name}</Badge>
        ));
      },
      searchOptions: users
        .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
        .map((user) => ({
          label: user.name,
          value: user._id,
        })),
      disableSort: true,
    },
    {
      key: "signedOn",
      label: "Contract Signed On",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
    },
    {
      key: "createdAt",
      label: "Contract Sent On",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
    },
  ];

  return allLabels.filter(
    (label) => !label?.hide && !columnToHide.includes(label?.key)
  );
};

export const getLogTableColumns = ({
  user,
  translate,
  users,
  onActionClick,
}) => {
  const logTypeToColor = {
    multiUpdate: "primary",
  };
  const allLabels = [
    {
      key: "type",
      label: "type",
      cellRenderer: (log) => {
        const { type } = log;
        const color = logTypeToColor[type] || "primary";
        return <Badge bg={color}>{translate(snakeCase(type)) || type}</Badge>;
      },
      searchOptions: LOG_TYPE?.map((s) => ({ label: s, value: s })),
      width: 40,
    },
    {
      key: "user",
      label: "affected_documents",
      disableSearch: true,
      disableSort: true,
      cellRenderer: (log) => {
        const { atomicActions } = log;

        const collectionCounts = atomicActions.reduce((acc, action) => {
          if (LOG_COLLECTIONS.includes(action.collection)) {
            acc[action.collection] = (acc[action.collection] || 0) + 1;
          }
          return acc;
        }, {});

        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            {Object.keys(collectionCounts).map((c) => (
              <h6 key={c} className="mb-0 smallFont">
                {collectionCounts[c]} {c}
              </h6>
            ))}
          </div>
        );
      },
    },
    {
      key: "createdAt",
      label: "Date Time",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
    },
    {
      key: "user",
      label: "user",
      cellRenderer: (log) => {
        const user = users.find((u) => u._id === log.user);
        let backgroundColor = user?.color || "#007f7f";
        let textColor = getTextColor(backgroundColor);
        return (
          <div
            style={{
              color: textColor,
              backgroundColor: backgroundColor,
              width: "fit-content",
            }}
            className="mx-auto rounded p-1"
          >
            <h6 className="tiny mb-0"> {user?.name}</h6>
          </div>
        );
      },
    },
    {
      key: "action",
      label: "Action",
      disableSearch: true,
      disableSort: true,
      cellRenderer: (log) => (
        <div className="d-flex justify-content-center align-items-center gap-2">
          {log.undone ? (
            <Button
              className="d-flex align-items-center gap-2 justify-content-center"
              size="sm"
              variant="outline-primary"
              disabled={!log.undone}
              onClick={(e) => {
                e.stopPropagation();
                onActionClick({
                  logId: log._id,
                  action: "redo",
                  log,
                });
              }}
            >
              <Arrow90degRight className="" size={10} />{" "}
              <h6 className="mb-0 tiny">Redo</h6>
            </Button>
          ) : (
            <Button
              className="d-flex align-items-center gap-2 justify-content-center"
              size="sm"
              variant="outline-primary"
              disabled={log.undone}
              onClick={(e) => {
                e.stopPropagation();
                onActionClick({
                  logId: log._id,
                  action: "undo",
                  log,
                });
              }}
            >
              <Arrow90degLeft className="" size={10} />{" "}
              <h6 className="mb-0 tiny">{translate("undo")}</h6>
            </Button>
          )}
        </div>
      ),
      width: 160,
    },
  ];

  return allLabels.filter((label) => !label?.hide);
};

const grouppedEventAutomationAndInbounds = (events, automations, inbounds) => {
  const eventGroup = {
    label: "Events",
    key: "events",
    options: events
      .sort((e1, e2) => new Date(e2.date) - new Date(e1.date))
      .map((e) => ({ label: e.name, value: e._id })),
  };
  const automationGroup = {
    label: "Automations",
    key: "automations",
    options: automations
      .sort((e1, e2) => new Date(e2.createdAt) - new Date(e1.createdAt))
      .map((e) => ({ label: e.name, value: e._id })),
  };
  const inboundGroup = {
    label: "Inbounds",
    key: "inbounds",
    options: inbounds
      ?.filter((ds) => INBOUNDS_TO_SHOW.includes(ds.key))
      .map((e) => ({ label: e.key, value: e._id })),
  };
  return [eventGroup, automationGroup, inboundGroup];
};

export const getWhatsappCampaignsTableColumns = ({
  whatsappTemplates,
  onDeleteCampaignClick,
  onEditCampaignClick,
  onViewLogClick,
  translate,
  events = [],
  automations = [],
  inbounds = [],
  facebookCampaigns = [],
  allowEdit,
  allowDelete,
}) => {
  const allLabels = [
    {
      key: "sourceType",
      label: "Source",
      searchOptions: [
        WHATSAPP_SOURCE_CONTACT_TABLE,
        WHATSAPP_SOURCE_QUICK_SEND,
        WHATSAPP_SOURCE_EVENTS,
        WHATSAPP_SOURCE_AUTOMATIONS,
        WHATSAPP_SOURCE_INBOUNDS,
      ].map((s) => ({ label: translate(snakeCase(s)), value: s })),
      valueSelector: (log) => translate(snakeCase(log?.sourceType)),
      width: 100,
    },
    {
      key: "sourceId",
      label: "name",
      valueSelector: ({ sourceType = "", sourceId = "", exceptionId = "" }) => {
        if (exceptionId) {
          const inbound = inbounds.find((c) => c._id === sourceId);
          const exception = inbound?.exceptions?.find(
            (e) => e._id === exceptionId
          );

          if (exception) {
            return (
              facebookCampaigns.find((c) => c.id === exception.value)?.name ||
              exception.value
            );
          }
        }

        const sourceMapping = {
          [WHATSAPP_SOURCE_EVENTS]: () =>
            events.find((c) => c._id === sourceId)?.name,
          [WHATSAPP_SOURCE_AUTOMATIONS]: () =>
            automations.find((c) => c._id === sourceId)?.name,
          [WHATSAPP_SOURCE_INBOUNDS]: () =>
            inbounds.find((c) => c._id === sourceId)?.key,
        };

        return sourceMapping[sourceType]?.() || "-";
      },
      searchOptions: grouppedEventAutomationAndInbounds(
        events,
        automations,
        inbounds
      ),
      isGroupedDropdown: true,
      disableSort: true,
      width: 150,
    },
    {
      key: "schedules.template",
      label: "template",
      cellRenderer: (log) => {
        const template = whatsappTemplates.find(
          (t) => t._id === log?.schedules?.[0]?.template
        );
        return (
          <div className="d-flex justify-content-center align-items-center gap-2">
            <a target="_blank" href={templateUrl(template)}>
              {template?.templateName}
            </a>
            <OverviewWhatsappTemplate message={template?.messageBody} />
          </div>
        );
      },
      searchOptions: whatsappTemplates?.map((t) => ({
        label: t.templateName,
        value: t._id,
      })),
      width: 150,
      CustomSingleValueComponent: (props) => (
        <WhatsappTemplateDropdownOption
          {...props}
          whatsappTemplates={whatsappTemplates}
        />
      ),
      CustomOption: (props) => (
        <WhatsappTemplateDropdownOption
          {...props}
          isOption={true}
          whatsappTemplates={whatsappTemplates}
        />
      ),
    },
    {
      key: "schedules.trigger",
      label: "trigger",
      valueSelector: (log) => {
        return log.schedules
          .map((s) =>
            s.trigger === "date_time"
              ? `Scheduled for ${moment(s.dateTime).format(
                  DEFAULT_DATE_TIME_FORMAT
                )} `
              : translate(s.trigger)
          )
          .join(", ");
      },
      searchOptions: [
        ...Object.values(CAMPAIGN_TRIGGER_OPTIONS),
        "on_signup",
      ]?.map((c) => ({
        label: translate(c),
        value: c,
      })),
      width: 150,
    },
    {
      key: "schedules.sentAt",
      label: "sent_at",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      valueSelector: (log) => log?.schedules?.[0]?.sentAt,
    },
    {
      key: "createdBy",
      label: "created_by",
      valueSelector: (log) => log?.createdBy?.name,
    },
    {
      label: "Action",
      disableSort: true,
      disableSearch: true,
      cellRenderer: (log) => {
        const scheduledCampaign = log?.schedules?.find(
          ({ trigger }) => trigger === CAMPAIGN_TRIGGER_OPTIONS.DATE_TIME
        );

        const scheduledInFuture = scheduledCampaign
          ? new Date(scheduledCampaign.dateTime).getTime() > Date.now()
          : false;
        const editable =
          scheduledInFuture ||
          log?.schedules.find(({ trigger }) => trigger === "on_signup");
        return (
          <div className="d-flex align-items-center justify-content-center gap-2">
            {log?.logs?.length > 0 && (
              <Button
                size="sm"
                variant="outline-primary"
                className="d-flex align-items-center gap-1"
                onClick={(e) => {
                  e.stopPropagation();
                  onViewLogClick(log);
                }}
              >
                {" "}
                <h6 className="mb-0 smallFont">
                  {log.logs.length} {translate("logs")}
                </h6>
              </Button>
            )}
            {editable && (
              <>
                {" "}
                {allowEdit && (
                  <Button
                    size="sm"
                    variant="outline-success"
                    className="d-flex align-items-center gap-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditCampaignClick(log);
                    }}
                  >
                    {" "}
                    <Pencil />
                  </Button>
                )}
                {allowDelete && (
                  <Button
                    size="sm"
                    variant="outline-danger"
                    className="d-flex align-items-center gap-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteCampaignClick(log);
                    }}
                  >
                    {" "}
                    <Trash />
                  </Button>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  return allLabels.filter((label) => !label?.hide);
};

export const getUserTableColumns = ({
  translate,
  onUserDeleteClick,
  sendPasswordResetLink,
  refreshUser,
  onAddEditPermissionButtonClick,
  archiveFilter,
  onArchiveUserClick,
  allowEdit,
  allowDelete,
  showEye = false,
}) => {
  const allLabels = [
    {
      key: "color",
      label: "Color",
      cellRenderer: (user) => (
        <div
          className="mx-auto"
          style={{
            background: user?.color ?? "#ffffff",
            height: 20,
            width: 25,
            border: 5,
          }}
        />
      ),
      width: 40,
    },
    {
      key: "name",
      label: "Name",
      type: "text",
      width: 150,
    },

    {
      key: "email",
      label: "Email",
      type: "text",
      width: 100,
    },
    {
      key: "emails",
      label: "Secondary Emails",
      cellRenderer: (user) => user?.secondaryEmails?.join(", "),
      width: 100,
    },
    {
      key: "phone",
      label: "Phone Number",
      type: "text",
      width: 100,
    },
    {
      key: "secondaryPhone",
      label: "Secondary Phone Number",
      type: "text",
      cellRenderer: (user) => user?.secondaryPhone?.join(", "),
      width: 100,
    },
    {
      key: "role",
      label: "Role",
      cellRenderer: (user) => (
        <Badge bg={role_to_color_map[user.role]} className="mx-1">
          {user.role}
        </Badge>
      ),
      type: "text",
      width: 100,
    },

    {
      key: "zoom",
      label: "Zoom",
      cellRenderer: (user) =>
        user?.zoomUserId ? (
          <Badge bg={"success"} className="mx-1">
            {translate("connected")}
          </Badge>
        ) : (
          allowEdit && (
            <UnderlineButton
              onClick={() => refreshUser(user)}
              variant="dark"
              text={translate("connect")}
            />
          )
        ),
      type: "text",
      width: 80,
    },
    {
      key: "birthDate",
      label: "DOB",
      type: "date",
      width: 80,
    },
    {
      key: "view",
      label: "Action",
      cellRenderer: (user) => (
        <>
          <ViewActions
            allowEdit={allowEdit}
            allowDelete={false}
            sendPasswordResetLink={sendPasswordResetLink}
            onAddEditPermissionButtonClick={onAddEditPermissionButtonClick}
            archiveFilter={true}
            onArchiveUserClick={onArchiveUserClick}
            onUserDeleteClick={onUserDeleteClick}
            user={user}
            showPerson={true}
            showEye={showEye}
          />
        </>
      ),
      width: 140,
    },
  ];
  return allLabels;
};

export const getProductionDashboardProductsTable = ({
  translate,
  onContactClick,
  activeStat,
}) => {
  const allLabels = [
    {
      key: "contact",
      label: "Contact",
      cellRenderer: (p) => {
        const contact = p?.contract?.contact;
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onContactClick(contact);
            }}
          >
            <u className="hover-light">
              {contact?.firstName} {contact?.lastName}
            </u>
          </div>
        );
      },
      width: 100,
    },
    {
      key: "status",
      label: "Deal status",
      cellRenderer: (p) => {
        const { status } = p?.contract;
        const color = dealStatusToColor[status] || "primary";
        return <Badge bg={color}>{translate(snakeCase(status))}</Badge>;
      },
      width: 100,
    },
    {
      key: "product",
      label: "Product name",

      valueSelector: (p) => p?.unit?.description,
      width: 100,
    },
    {
      key: "productionStage",
      label: "Production stage",
      valueSelector: (p) => p?.productionStage?.step,
      width: 100,
    },
    {
      key: "currentProductionStageDate",
      label:
        activeStat?.name === "Product Finished Production"
          ? "Finished On"
          : "In this stage since",
      valueSelector: (p) => {
        const sortedHistory = [
          ...(p.contract?.productionStageHistory || []),
        ].sort((a, b) => new Date(a.updateDate) - new Date(b.updateDate));

        const stageDate = sortedHistory.find(
          (h) => h.stage === p?.productionStage?._id
        )?.updateDate;

        return stageDate
          ? moment(stageDate).format(DEFAULT_DATE_TIME_FORMAT)
          : "";
      },
      width: 100,
    },
    {
      key: "time",
      label:
        activeStat?.name === "Product Finished Production"
          ? "Total production time"
          : "Time since deal won",
      valueSelector: (p) => {
        if (activeStat?.name !== "Product Finished Production") {
          return `${moment().diff(p?.contract?.signedOn, "days")} days`;
        }

        const sortedHistory = [
          ...(p.contract?.productionStageHistory || []),
        ].sort((a, b) => new Date(a.updateDate) - new Date(b.updateDate));

        const finalStageDate = sortedHistory.find(
          (h) => h.stage === p?.productionStage?._id
        )?.updateDate;

        const startDate = p?.contract?.signedOn;
        const endDate = finalStageDate;

        if (!endDate || !startDate) return "-";

        return `${moment(endDate).diff(startDate, "days")} days`;
      },

      width: 100,
    },
    {
      key: "productValue",
      label: "Product value",
      valueSelector: (p) => formatCurrency(p?.total),
      width: 100,
    },
  ];
  return allLabels;
};

export const getReferrerTableColumns = ({
  onReferrerDeleteClick,
  onAddEditReferralCodeClick,
  allowDelete,
  allowEdit,
}) => {
  const allLabels = [
    {
      key: "name",
      label: "Name",
      type: "text",
      width: 100,
    },
    {
      key: "email",
      label: "Email",
      type: "text",
      width: 100,
    },
    {
      key: "phone",
      label: "Phone Number",
      type: "text",
      width: 100,
    },
    {
      key: "notes",
      label: "Notes",
      type: "text",
      width: 160,
    },
    {
      key: "referralCode",
      label: "Referral link",
      onCellClick: () => {},
      cellRenderer: (referral) => (
        <div className="d-flex justify-content-center align-items-center">
          {referral?.referralCode ? (
            <div className="d-flex   gap-2">
              <CopyLinkField
                link={`https://ethica.finance/meeting?referrer=${referral.referralCode}`}
              />
              {allowEdit && (
                <Button
                  size="sm"
                  variant="outline-success"
                  className="d-flex align-items-center gap-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    onAddEditReferralCodeClick(referral, "edit");
                  }}
                >
                  {" "}
                  <Pencil />
                </Button>
              )}
            </div>
          ) : allowEdit ? (
            <Button
              size="sm"
              variant="outline-dark"
              className="d-flex align-items-center gap-1"
              onClick={(e) => {
                e.stopPropagation();
                onAddEditReferralCodeClick(referral, "add");
              }}
            >
              {" "}
              <Plus />
              <h6 className="tiny mb-0">Referral code</h6>
            </Button>
          ) : (
            "-"
          )}
        </div>
      ),
      width: 100,
    },
    {
      key: "view",
      label: "Action",
      hide: !allowDelete,
      cellRenderer: (user) => (
        <div className="d-flex justify-content-center align-items-center">
          <Trash
            className="text-dark hover hover-light mx-1"
            size={12}
            onClick={(e) => {
              onReferrerDeleteClick(user);
              e.stopPropagation();
            }}
          />
        </div>
      ),
      width: 60,
    },
  ];
  return allLabels.filter((l) => !l.hide);
};

export const getInboundTableColumns = ({
  onDetailsClick,
  onCampaignClick,
  columnToHide = [],
  openContactPopup,
}) => {
  const allLabels = [
    {
      key: "contact",
      label: "Contact",
      cellRenderer: (inbound) => (
        <div
          className="underline hover-light"
          onClick={() => openContactPopup(inbound?.contact?._id)}
        >
          {inbound.contact
            ? inbound?.contact?.firstName + " " + inbound?.contact?.lastName
            : ""}
        </div>
      ),
      width: 70,
    },
    {
      key: "email",
      label: "Email",
      valueSelector: (inbound) => inbound?.contact?.emails?.[0],
      width: 70,
    },
    {
      key: "source",
      label: "Platform",
      width: 70,
    },
    {
      key: "leadSource",
      label: "Inbound Source",
      valueSelector: (inbound) => startCase(inbound?.leadSource),
      width: 70,
    },
    {
      key: "campaign",
      label: "Campaign/url",
      cellRenderer: (inbound) => {
        return (
          <>
            {inbound?.source === "Website" ? (
              <a
                href={
                  hasHttpOrHttps(inbound?.sourceDetails?.page_url)
                    ? inbound?.sourceDetails?.page_url
                    : `//${inbound?.sourceDetails?.page_url}`
                }
                target="_blank"
              >
                {truncateAndAddEllipsis(
                  removeParamsFromUrl(inbound?.sourceDetails?.page_url) ?? "",
                  30
                )}
              </a>
            ) : inbound?.source === "Manually Added" ? (
              <span className="">
                {inbound?.sourceDetails?.added_by || "-"}
              </span>
            ) : (
              <span
                className="a text-info"
                onClick={() => onCampaignClick(inbound)}
              >
                {inbound?.sourceDetails?.campaignName || "-"}
              </span>
            )}
          </>
        );
      },
      width: 100,
    },
    {
      key: "date",
      label: "Date",
      cellRenderer: (inbound) =>
        moment(inbound?.date).format(DEFAULT_DATE_TIME_FORMAT),
      width: 100,
    },
    {
      key: "newHotLead",
      label: "Hot",
      cellRenderer: (inbound) => {
        return (
          <Badge bg={inbound?.newHotLead ? "success" : "secondary"}>
            {inbound?.newHotLead ? "Yes" : "No"}
          </Badge>
        );
      },
    },
    {
      key: "details",
      label: "Details",
      cellRenderer: (inbound) => {
        if (
          !inbound?.sourceDetails ||
          !Object.keys(inbound?.sourceDetails).length
        ) {
          return "-";
        }

        return (
          <Button
            className="px-1 py-0"
            variant="info"
            size="sm"
            onClick={() => onDetailsClick(inbound)}
          >
            <span className="smallFont">View</span>
          </Button>
        );
      },
      width: 100,
    },
  ];
  return allLabels?.filter((f) => !columnToHide.includes(f?.key));
};

export const getLeadsTableColumn = ({
  columnToHide = [],
  openContactPopup,
}) => {
  const allLabels = [
    {
      key: "contact",
      label: "Contact",
      valueSelector: (lead) => (
        <div
          className="underline hover-light"
          onClick={(e) => {
            e.stopPropagation();
            openContactPopup(lead?._id);
          }}
        >
          {lead?.firstName + " " + lead?.lastName}
        </div>
      ),
      width: 70,
    },
    {
      key: "email",
      label: "Email",
      valueSelector: (lead) => lead?.emails?.[0],
      width: 70,
    },
    {
      key: "phone",
      label: "Phone number",
      valueSelector: (lead) => lead?.phoneNumbers?.[0],
      width: 70,
    },
    {
      key: "leadSource",
      label: "First Lead Source",
      valueSelector: (lead) => lead?.inbounds?.[0]?.leadSource,
      width: 70,
    },
    {
      key: "date",
      label: "First Inbound Date",
      cellRenderer: (lead) =>
        moment(lead?.inbounds?.[0]?.date).format(DEFAULT_DATE_TIME_FORMAT),
      width: 100,
    },
  ];
  return allLabels?.filter((f) => !columnToHide.includes(f?.key));
};

export const getPhoneCallTableColumns = (
  onPlayClick,
  columnToHide = [],
  onDownloadClick
) => {
  const allLabels = [
    {
      key: "play",
      label: "Play",
      width: 50,
      cellRenderer: (call) => (
        <div className="d-flex justify-content-center align-items-center gap-3">
          <PlayCircleFill
            size={18}
            className="text-primary"
            onClick={() => onPlayClick(call)}
          />
          <DownloadOutlined
            size={20}
            className="text-secondary"
            onClick={() => onDownloadClick(call)}
          />
        </div>
      ),
    },
    {
      key: "createdAt",
      label: "time of the call",
      cellRenderer: (call) =>
        moment(call.callDateTime || call.createdAt).format(
          DEFAULT_DATE_TIME_FORMAT
        ),
      width: 100,
    },
    {
      key: "callType",
      label: "Call type",
      cellRenderer: (call) => {
        let { callType = null, duration = 0, status } = call;

        let isMissedCall = status ? status === "unanswered" : duration === 0;
        if (callType?.toLowerCase().includes("outgoing")) {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <TelephoneOutboundFill className="text-info" />{" "}
              <span className="mx-2">
                Outgoing {isMissedCall && "- Unanswered"}{" "}
              </span>
            </div>
          );
        }

        if (callType?.toLowerCase().includes("incoming")) {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <TelephoneInboundFill className="text-primary" />{" "}
              <span className="mx-2">
                Incoming {isMissedCall && "- Unanswered"}
              </span>
            </div>
          );
        }

        return (
          <div className="d-flex justify-content-center align-items-center">
            <TelephoneFill className="text-primary" />{" "}
            <span className="mx-2">{callType}</span>
          </div>
        );
      },
      width: 100,
    },
    {
      key: "receiverNumber",
      label: "receiver",
      cellRenderer: (call) => call?.receiver?.name || call?.receiverNumber,
      width: 100,
    },
    {
      key: "caller",
      label: "caller",
      cellRenderer: (call) => call?.caller?.name || call?.callerNumber,
      width: 100,
    },
    {
      key: "duration",
      label: "call length",
      width: 100,
      cellRenderer: (call) => {
        let duration = moment.duration(Number(call?.duration || 0) * 1000);

        const minutes = duration.minutes();
        const seconds = duration.seconds();

        return `${minutes > 0 ? `${minutes}m ` : ""}${seconds}s`;
      },
    },
  ];
  return allLabels?.filter((f) => !columnToHide.includes(f?.key));
};

export const getAppointmetnDefaultTypeColumns = ({
  onEditAppointmentTypeClick,
  allowEdit,
}) => {
  const allLabels = [
    {
      key: "key",
      label: "Type",
      type: "text",
      width: 100,
    },
    {
      key: "title",
      label: "Title",
      type: "text",
      width: 100,
    },
    {
      key: "description",
      label: "Description",
      type: "text",
      width: 140,
    },
    {
      key: "duration",
      label: "Duration",
      type: "text",
      width: 60,
    },

    {
      key: "view",
      label: "Action",
      hide: !allowEdit,
      cellRenderer: (appointmentType) => (
        <>
          <Pencil
            className="text-dark hover-light mx-2"
            onClick={() => onEditAppointmentTypeClick(appointmentType)}
          />
        </>
      ),
    },
  ];
  return allLabels.filter((l) => !l.hide);
};

export const getTagsColumns = ({
  onEditTagClick,
  onDeleteTagClick,
  allowEdit,
  allowDelete,
}) => {
  const allLabels = [
    {
      key: "name",
      label: "Name",
      type: "text",
      width: 100,
    },

    {
      key: "view",
      label: "Action",
      hide: !allowEdit && !allowDelete,
      cellRenderer: (tag) => (
        <>
          {allowEdit && (
            <Pencil
              className="text-dark hover-light mx-2"
              onClick={() => onEditTagClick(tag)}
            />
          )}
          {allowDelete && (
            <Trash
              className="text-dark hover mx-1"
              onClick={() => onDeleteTagClick(tag)}
            />
          )}
        </>
      ),
    },
  ];
  return allLabels.filter((l) => !l.hide);
};

export const getStatusesColumns = ({
  onEditStatusClick,
  onDeleteStatusClick,
  allowEdit,
  allowDelete,
}) => {
  const allLabels = [
    {
      key: "english",
      label: "English",
      type: "text",
      width: 100,
    },
    {
      key: "hebrew",
      label: "Hebrew",
      type: "text",
      width: 100,
    },
    {
      key: "view",
      label: "Action",
      hide: !allowDelete && !allowEdit,
      cellRenderer: (status) => (
        <>
          {allowEdit && (
            <Pencil
              className="text-dark hover-light mx-2"
              onClick={() => onEditStatusClick(status)}
            />
          )}
          {allowDelete && (
            <Trash
              className="text-dark hover mx-1"
              onClick={() => onDeleteStatusClick(status)}
            />
          )}
        </>
      ),
    },
  ];
  return allLabels.filter((l) => !l.hide);
};

export const getDefaultStatusColumns = (onEditDefaultStatusClick) => {
  const allLabels = [
    {
      key: "inboundSource",
      label: "Inbound Source",
      type: "text",
      width: 100,
    },
    {
      key: "status",
      label: "Status",
      type: "text",
      width: 100,
    },
    {
      key: "exceptions",
      label: "Exception",

      cellRenderer: (status) => (
        <div className="" style={{ textAlign: "left" }}>
          {status?.exceptions?.map((eP, index) => (
            <h6 key={index} className="smallFont mb-0">
              - {eP.exception}
            </h6>
          ))}
        </div>
      ),
    },

    {
      key: "view",
      label: "Action",
      cellRenderer: (status) => (
        <>
          <Pencil
            className="text-dark hover-light mx-2"
            onClick={() => onEditDefaultStatusClick(status)}
          />
        </>
      ),
    },
  ];
  return allLabels;
};

export const getDashboardSalesWonColumns = ({ openContactPopup }) => {
  const allLabels = [
    {
      key: "client",
      label: "client",
      cellRenderer: (sale) => (
        <div
          className="underline hover-light"
          onClick={(e) => {
            e.stopPropagation();
            openContactPopup(sale?.contact?._id);
          }}
        >
          {sale?.contact?.firstName + " " + sale?.contact?.lastName}
        </div>
      ),
      width: 100,
    },
    {
      key: "product",
      label: "product",
      valueSelector: (sale) => sale?.product?.description,
      width: 100,
    },
    {
      key: "proposalQuote",
      label: "deal value",
      cellRenderer: (sale) => formatCurrency(sale?.proposalQuote),
      width: 140,
    },
    {
      key: "signedOn",
      label: "deal date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      type: "date",
      width: 60,
    },

    {
      key: "assignedTo",
      label: "Sales person",
      cellRenderer: (meeting) =>
        meeting?.assignedTo?.map((u) => (
          <Badge key={u?._id} className="mx-1" bg="dark">
            {u?.name}{" "}
          </Badge>
        )),
      width: 60,
    },
  ];
  return allLabels;
};
export const getDashboardNewMeetingColumns = ({
  appointmentTypes = [],
  users = [],
}) => {
  const allLabels = [
    {
      key: "appointmentType",
      label: "meeting type",
      type: "text",
      width: 150,
      searchOptions: appointmentTypes.map((t) => ({
        label: t.title,
        value: t.key,
      })),
    },
    {
      key: "title",
      label: "meeting title",
      cellRenderer: (meeting) => {
        const videoUrl = getAppointmentVideo(meeting);
        return (
          <div className="d-flex justify-content-between">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip" style={{ zIndex: 9999999999 }}>
                  {meeting?.title}
                </Tooltip>
              }
            >
              <h6 className="mb-0 smallFont truncate" style={{ maxWidth: 200 }}>
                {meeting?.title}
              </h6>
            </OverlayTrigger>

            {videoUrl && (
              <PlayCircleFill
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(videoUrl, "_blank");
                }}
              />
            )}
          </div>
        );
      },
      type: "text",
      width: 150,
    },
    {
      key: "createdAt",
      label: "meeting setting date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      type: "date",
      width: 140,
    },
    {
      key: "startDateTime",
      label: "meeting schedule date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      valueSelector: (meeting) => meeting?.startDateTime,
      type: "date",
      width: 60,
    },

    {
      key: "participants",
      label: "meeting participants users",
      cellRenderer: (meeting) =>
        meeting?.participants?.map((u) => (
          <Badge className="mx-1" bg="dark">
            {u?.name}{" "}
          </Badge>
        )),
      width: 60,
      searchOptions: users.map((u) => ({ label: u.name, value: u._id })),
    },
    {
      key: "meetingParticipantsClients",
      label: "meeting participants clients",
      valueSelector: (meeting) => meeting?.invitees?.join(", "),
      width: 60,
      disableSearch: true,
    },
    {
      key: "description",
      label: "meeting description",
      truncate: true,
      type: "text",
      width: 200,
    },
  ];
  return allLabels;
};
export const getDashboardIncomeColumns = ({ openContactPopup }) => {
  const allLabels = [
    {
      key: "client",
      label: "client",
      cellRenderer: (income) => (
        <div
          className="underline hover-light"
          onClick={(e) => {
            e.stopPropagation();
            openContactPopup(income?.contract?.contact?._id);
          }}
        >
          {income?.contract?.contact?.firstName +
            " " +
            income?.contract?.contact?.lastName}
        </div>
      ),
      width: 100,
    },
    {
      key: "amount",
      label: "value",
      cellRenderer: (income) => formatCurrency(income?.amount),
      width: 100,
    },
    {
      key: "date",
      label: "date",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      width: 160,
    },
    {
      key: "product",
      label: "product",
      valueSelector: (income) => income?.contract?.product?.description,
      width: 100,
    },
    {
      key: "assignedTo",
      label: "assigned to",
      cellRenderer: (income) =>
        income?.contract?.assignedTo?.map((u) => (
          <Badge key={u?._id} className="mx-1" bg="dark">
            {u?.name}{" "}
          </Badge>
        )),
      width: 60,
    },
  ];
  return allLabels;
};

export const getDashboardNewClientColumns = ({ openContactPopup }) => {
  const allLabels = [
    {
      key: "client",
      label: "client",
      cellRenderer: (client) => (
        <div
          className="underline hover-light"
          onClick={(e) => {
            e.stopPropagation();
            openContactPopup(client?._id);
          }}
        >
          {client?.firstName + " " + client?.lastName}
        </div>
      ),
      width: 100,
    },
    {
      key: "product",
      label: "product",
      valueSelector: (client) => client?.contract?.product?.description,
      width: 100,
    },
    {
      key: "dealValue",
      label: "deal value",
      cellRenderer: (client) => formatCurrency(client?.contract?.proposalQuote),
      width: 140,
    },
    {
      key: "dealDate",
      label: "deal date",
      valueSelector: (client) => client?.contract?.signedOn,
      type: "date",
      width: 60,
    },
    {
      key: "assignedTo",
      label: "assigned to",
      cellRenderer: (client) =>
        client?.contract?.assignedTo?.map((u) => (
          <Badge key={u?._id} className="mx-1" bg="dark">
            {u?.name}{" "}
          </Badge>
        )),
      width: 60,
    },
  ];
  return allLabels;
};

export const getDashboardTotalCommissionsFields = () => {
  const allLabels = [
    {
      key: "client",
      label: "client",
      cellRenderer: (commission) =>
        `${commission?.contract?.contact?.firstName || ""} ${
          commission?.contract?.contact?.lastName || ""
        }`,
      width: 100,
    },
    {
      key: "product",
      label: "product",
      cellRenderer: (commission) =>
        `${commission?.contract?.product?.description}`,
      width: 100,
    },
    {
      key: "dealValue",
      label: "deal value",
      cellRenderer: (commission) =>
        formatCurrency(commission?.contract?.proposalQuote),
      width: 140,
    },
    {
      key: "commissionValue",
      label: "commission value",
      valueSelector: (commission) => commission?.commission,
      width: 60,
    },
    {
      key: "commissionPaymentData",
      label: "commission payment data",
      valueSelector: (commission) => commission?.commissionPaidDate,
      type: "date",
      width: 60,
    },

    {
      key: "assignedTo",
      label: "assigned to",
      cellRenderer: (commission) =>
        commission?.contract?.assignedTo?.map((u) => (
          <Badge key={u?._id} className="mx-1" bg="dark">
            {u?.name}{" "}
          </Badge>
        )),
      width: 60,
    },
  ];
  return allLabels;
};

const FunnelIcon = ({
  icon: Icon,
  isSelected,
  isActive,
  onClick,
  tooltipText,
}) => (
  <OverlayTrigger placement="top" overlay={<Tooltip>{tooltipText}</Tooltip>}>
    <Icon
      className={`${
        isSelected && isActive ? "text-primary" : "text-muted"
      } hover-light-text mx-2`}
      onClick={onClick}
      style={{ fontSize: "1.2rem" }}
    />
  </OverlayTrigger>
);

export const getEventMarketing = (
  onShowEventPartialsFunnelClick,
  onShowEventFunnelClick,
  selectedEvent,
  funnelType,
  translate
) => {
  const allLabels = [
    {
      key: "name",
      label: "Name",
      type: "text",
      width: 100,
    },
    {
      key: "date",
      label: "Event Date",
      type: "date",
      width: 140,
    },
    {
      key: "view",
      cellRenderer: (event) => {
        const isSelected = selectedEvent?._id === event._id;
        const isCompleteFunnel = funnelType === "complete_funnel";
        const isFractionalFunnel = funnelType === "fractional_funnel";

        return (
          <>
            <FunnelIcon
              icon={Funnel}
              isSelected={isSelected}
              isActive={isFractionalFunnel}
              onClick={() => onShowEventPartialsFunnelClick(event)}
              tooltipText={translate("fractional_funnel")}
            />
            <FunnelIcon
              icon={FunnelFill}
              isSelected={isSelected}
              isActive={isCompleteFunnel}
              onClick={() => onShowEventFunnelClick(event)}
              tooltipText={translate("complete_funnel")}
            />
          </>
        );
      },
    },
  ];

  return allLabels;
};

export const getReportTemplatesColumns = ({
  translate,
  onUpdateClick,
  onDeleteClick,
  allowEdit,
  allowDelete,
}) => {
  const allLabels = [
    {
      key: "name",
      label: "Name",
      width: 100,
    },
    {
      label: "Actions",
      key: "view",
      width: 100,
      disableSearch: true,
      disableSort: true,
      cellRenderer: (template) => (
        <div className="d-flex justify-content-center align-items-center gap-2">
          {allowEdit && (
            <Pencil
              className="text-success hover"
              size={15}
              onClick={() => onUpdateClick(template)}
            />
          )}
          {allowDelete && (
            <Trash
              className="text-danger hover"
              size={15}
              onClick={() => onDeleteClick(template)}
            />
          )}
        </div>
      ),
    },
  ];
  return allLabels;
};

export const getEmailTemplatesColumns = ({
  translate,
  onUpdateClick,
  onDeleteClick,
  onViewTemplate,
  allowEdit,
  allowDelete,
}) => {
  const allLabels = [
    {
      key: "name",
      label: "Name",
      width: 100,
    },
    {
      key: "subject",
      label: "subject",
    },
    {
      label: "Actions",
      key: "view",
      width: 100,
      disableSearch: true,
      disableSort: true,
      cellRenderer: (template) => (
        <div className="d-flex justify-content-center align-items-center gap-2">
          <Eye
            className="text-info hover"
            size={15}
            onClick={() => onViewTemplate(template)}
          />
          {allowEdit && (
            <Pencil
              className="text-success hover"
              size={15}
              onClick={() => onUpdateClick(template)}
            />
          )}
          {allowDelete && (
            <Trash
              className="text-danger hover"
              size={15}
              onClick={() => onDeleteClick(template)}
            />
          )}
        </div>
      ),
    },
  ];
  return allLabels;
};

export const getProductsOnDealsTableColumns = ({
  user,
  columnToHide = [],
  users,
  products,
  units,
  translate,
  showAllFields,
  onToggleSelectAll,
  onToggleSelectItem,
  isItemSelected,
  selectAllMode,
  productProductionStages,
}) => {
  const allLabels = [
    {
      key: "view",
      labelRenderer: () => (
        <input
          type="checkbox"
          className="hover"
          checked={selectAllMode}
          onChange={(e) => {
            e.stopPropagation();
            onToggleSelectAll(e.target.checked);
          }}
        />
      ),
      cellRenderer: (row) => (
        <input
          type="checkbox"
          className="hover"
          checked={isItemSelected(row.unit)}
          onChange={(e) => {
            e.stopPropagation();
            onToggleSelectItem(row.unit);
          }}
        />
      ),
      onCellClick: (row) => onToggleSelectItem(row.unit),
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "status",
      label: "Status",
      cellRenderer: (row) => {
        const { status } = row.contract;
        const color = dealStatusToColor[status] || "primary";
        return <Badge bg={color}>{translate(snakeCase(status))}</Badge>;
      },
      searchOptions: DEAL_STATUSES?.map((s) => ({
        label: translate(snakeCase(s)) || s,
        value: s,
      })),
      width: 180,
      isMultiSelect: true,
    },
    {
      key: "product",
      label: "Contract",
      cellRenderer: (row) => {
        const { product } = row.contract;

        if (!product) return "N/A";
        return product?.prosperoProposalMeta?.link ? (
          <a
            href={contract?.prosperoProposalMeta?.link}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {product?.description}{" "}
          </a>
        ) : (
          <span className="truncate">{product?.description}</span>
        );
      },
      disableSort: true,
      searchOptions: products.map((product) => ({
        label: product?.description,
        value: product?._id,
      })),
      width: 80,
    },
    {
      key: "client",
      label: "Client",
      width: 80,
      cellRenderer: (row) => {
        const { contact } = row.contract;
        if (!contact) return "N/A";
        return <span>{`${contact?.firstName} ${contact?.lastName}`} </span>;
      },
      disableSort: true,
    },
    {
      key: "description",
      label: "Product Name",
      type: "text",
      width: 100,
      cellRenderer: (row) => {
        const { description } = row.unit.unit;

        if (!description) return "N/A";
        return <span className="truncate">{description}</span>;
      },
      searchOptions: units.map((unit) => ({
        label: unit?.description,
        value: unit?.description,
      })),
      isMultiSelect: true,
    },
    {
      key: "payment",
      label: "Payment",
      hide: !isAdminOrManager(user?.role),
      width: 80,
      cellRenderer: (row) => {
        const { payment } = row.unit;
        return <span dir="ltr">{formatCurrency(payment)}</span>;
      },
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "total",
      label: "Value",
      type: "number",
      hide: !isAdminOrManager(user?.role),
      width: 60,
      valueSelector: (row) => formatCurrency(row.unit?.total),
      disableSearch: true,
    },
    {
      key: "assignedTo",
      label: "Assigned To",
      cellRenderer: (row) => {
        return row.contract?.assignedTo?.map((user) => (
          <Badge className="me-1 px-1 py-1">{user?.name}</Badge>
        ));
      },
      searchOptions: users
        .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
        .map((user) => ({
          label: user.name,
          value: user._id,
        })),
      disableSort: true,
      disableSearch: true,
    },
    {
      key: "tags",
      label: "Tags",
      width: 160,
      disableSort: true,
      cellRenderer: (row) => (
        <div>
          {row.contract?.tags?.map((tag) => (
            <Badge className="ms-1" key={tag}>
              {tag}
            </Badge>
          ))}
        </div>
      ),
    },
    {
      key: "signedOn",
      label: "Contract Signed On",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      cellRenderer: (row) =>
        formatDateTime(row.contract?.signedOn, DEFAULT_DATE_TIME_FORMAT) ||
        "N/A",
    },
    {
      key: "createdAt",
      label: "Contract Sent On",
      type: "date",
      dateFormat: DEFAULT_DATE_TIME_FORMAT,
      cellRenderer: (row) =>
        formatDateTime(row.contract?.createdAt, DEFAULT_DATE_TIME_FORMAT) ||
        "N/A",
    },
    {
      key: "productionStage",
      label: "Production Stage",
      hide: !showAllFields,
      cellRenderer: (row) => {
        const step = row.unit?.productionStage?.step || translate("no_stage");
        const color = "dark";
        return <Badge bg={color}>{step}</Badge>;
      },
      width: 180,
      searchOptions: productProductionStages.map((productProductionStage) => ({
        label:
          translate(productProductionStage.step) || productProductionStage.step,
        value: productProductionStage.step,
      })),
      isMultiSelect: true,
    },
    {
      key: "price",
      label: "Price",
      hide: !isAdminOrManager(user?.role),
      width: 80,
      cellRenderer: (row) => {
        const { price } = row.unit;
        return <span dir="ltr">{formatCurrency(price)}</span>;
      },
      disableSort: true,
      disableSearch: true,
      hide: !showAllFields,
    },
    {
      key: "discount",
      label: "Discount",
      hide: !isAdminOrManager(user?.role),
      width: 80,
      cellRenderer: (row) => {
        const { discount } = row.unit;
        return <span dir="ltr">{formatCurrency(discount)}</span>;
      },
      disableSort: true,
      disableSearch: true,
      hide: !showAllFields,
    },
  ];

  return allLabels.filter((l) => !l?.hide);
};
