import { Input } from "antd";
import { snakeCase } from "lodash";
import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { updateMultipleContactField } from "../../../helpers/constants";
import useAppChoices from "../../../hooks/useAppChoices";
import useAuth from "../../../hooks/useAuth";
import useLocalization from "../../../hooks/useLocalization";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import CircularProgressBar from "../../common/circular-progress";
import useContactStatus from "../../../hooks/useContactStatus";
import UserDropdown from "../../common/UserDropdown";

let defaultValues = {
  status: undefined,
  assignedTo: undefined,
};

const UpdateMultipleContactCard = ({ onSaveFieldValue, updatingField }) => {
  const users = useAppChoices("users");
  const tags = useAppChoices("tags");
  const groups = useAppChoices("groups");
  const { translate } = useLocalization();
  const { user } = useAuth();
  const [contactUpdates, setContactUpdates] = useState(defaultValues);
  const { GROUPPED_STATUS } = useContactStatus();
  const SHOW_ARCHIVE_USERS = useAppChoices("SHOW_ARCHIVE_USERS");
  const updateMultipleConactField = useMemo(
    () =>
      updateMultipleContactField({
        users,
        user,
        tags,
        translate,
        GROUPPED_STATUS,
        showArchivedUsers: SHOW_ARCHIVE_USERS?.[0]?.value,
        groups,
      }),
    [users, user, tags, translate, GROUPPED_STATUS, SHOW_ARCHIVE_USERS, groups]
  );

  return (
    <div>
      <div className="mid m-0 px-1 py-2">
        {updateMultipleConactField?.map(
          ({
            label,
            valueModifierForServer,
            key,
            options,
            placeholder,
            isMultiSelect = true,
            isGroupped = false,
            type,
            showAddOption,
          }) => {
            const [disabled, setDisabled] = useState(true);
            return (
              <div className="px-1 d-flex flex-column mb-3">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h6 className="mb-0 smallFont">
                    {translate(snakeCase(label)) || label}
                  </h6>
                  <div className="d-flex align-items-center gap-2">
                    {updatingField === key && <CircularProgressBar size={15} />}
                    {showAddOption && (
                      <>
                        <Button
                          className=" d-flex justify-content-between align-items-center py-0 text-white"
                          variant="primary"
                          size="sm"
                          onClick={() => {
                            setDisabled(true);
                            onSaveFieldValue({
                              contactUpdates: {
                                [key]: valueModifierForServer
                                  ? valueModifierForServer(contactUpdates[key])
                                  : contactUpdates[key],
                              },
                              fieldsToBeAdded: [key],
                            });
                          }}
                          disabled={disabled}
                        >
                          <span className="text-white tiny">
                            {translate("add")}
                          </span>
                        </Button>

                        <Button
                          className=" d-flex justify-content-between align-items-center py-0 text-white"
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            setDisabled(true);
                            onSaveFieldValue({
                              contactUpdates: {
                                [key]: valueModifierForServer
                                  ? valueModifierForServer(contactUpdates[key])
                                  : contactUpdates[key],
                              },
                              fieldsToBeRemoved: [key],
                            });
                          }}
                          disabled={disabled}
                        >
                          <span className="text-white tiny">
                            {translate("remove")}
                          </span>
                        </Button>
                      </>
                    )}

                    <Button
                      className="d-flex justify-content-between align-items-center  py-0 text-white"
                      variant="success"
                      size="sm"
                      onClick={() => {
                        setDisabled(true);
                        onSaveFieldValue({
                          contactUpdates: {
                            [key]: valueModifierForServer
                              ? valueModifierForServer(contactUpdates[key])
                              : contactUpdates[key],
                          },
                        });
                      }}
                      disabled={disabled}
                    >
                      {" "}
                      <span className="text-white tiny">
                        {translate("replace")}
                      </span>
                    </Button>
                  </div>
                </div>
                {type === "grouped-dropdown" && (
                  <CustomMultiSelect
                    isClearable
                    items={options}
                    selectedItems={contactUpdates?.[key]}
                    onChange={(option) => {
                      if (disabled) {
                        setDisabled(false);
                      }
                      setContactUpdates({
                        ...contactUpdates,
                        [key]: option,
                      });
                    }}
                    isMulti={false}
                    isGroupped
                    placeholder={placeholder || "Select"}
                    closeMenuOnSelect
                    fieldColors={options
                      .flatMap((o) => o?.options)
                      ?.find(
                        (option) => option?.value === contactUpdates?.[key]
                      )}
                  />
                )}
                {type === "multi-select" && (
                  <CustomMultiSelect
                    isClearable
                    items={options}
                    isMulti={isMultiSelect}
                    selectedItems={contactUpdates?.[key]}
                    onChange={(option) => {
                      if (disabled) {
                        setDisabled(false);
                      }

                      setContactUpdates({
                        ...contactUpdates,
                        [key]: option?.includes("unassign")
                          ? ["unassign"]
                          : option,
                      });
                    }}
                    isGroupped={isGroupped}
                    placeholder={placeholder}
                  />
                )}
                {type === "input_text" && (
                  <Input
                    size="sm"
                    style={{
                      fontSize: "12px",
                    }}
                    value={contactUpdates?.[key]}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (disabled) {
                        setDisabled(false);
                      }
                      setContactUpdates({
                        ...contactUpdates,
                        [key]: value,
                      });
                    }}
                  ></Input>
                )}
              </div>
            );
          }
        )}
      </div>

      {/* <div className="d-flex justify-content-end align-items-center bg-gray border-top border-secondary-dark px-1 py-2">
        <div className="d-flex">
          <Button
            variant="danger"
            className="text-white px-2 py-0 mx-1"
            size="sm"
            disabled={editingMultipleContact}
            onClick={onHideEditMultipleContact}
          >
            <span className="">
              {translate("cancel")} <XLg size={12} />
            </span>
          </Button>
        </div>
      </div> */}
    </div>
  );
};

export default UpdateMultipleContactCard;
