import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import * as Yup from "yup";
import { WHATSAPP_SOURCE_AUTOMATIONS } from "../../helpers/constants";
import { PERMISSIONS } from "../../helpers/permissions";
import { WEBSITE } from "../../helpers/regex";
import useActiveTrailAndFacebookCampaign from "../../hooks/useActiveTrailAndFacebookCampaign";
import useLocalization from "../../hooks/useLocalization";
import useWhatsappCampaigns from "../../hooks/useWhatsappCampaigns";
import ActiveTrailDropdown from "../automation-events/common/ActiveTrailDropdown";
import FacebookCampaignDropdown from "../automation-events/common/FacebookCampaignDropdown";
import LeadSourceDropdown from "../automation-events/common/LeadSourceDropdown";
import UserDropdown from "../common/UserDropdown";
import StatusDropdown from "../automation-events/common/StatusDropdown";
import WhatsappTemplateTable from "../automation-events/common/WhatsappTemplateTable";
import EditableTagsInput from "../common/EditableTagsInput";
import HorizontalProgress from "../common/HorizontalProgress";
import Loader from "../common/Loader";
import useAuth from "../../hooks/useAuth";

// Yup validation schema updated with new requirements
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please provide the automation name"),
  facebookCampaignIds: Yup.array().of(Yup.string()),
  assignedTo: Yup.array().of(Yup.string()),

  urls: Yup.array()
    .of(Yup.string().matches(WEBSITE, "Invalid website"))
    .nullable()
    .min(0, "Urls can be an empty array"), // Allow empty array
  status: Yup.string().required("Status is required"),
  whatsappCampaigns: Yup.array().of(
    Yup.object().shape({
      template: Yup.string().required("Template is required"),
    })
  ),
});

const defaultValues = {
  name: "",
  facebookCampaignIds: [],
  activeTrailLists: [],
  urls: [],
  status: "",
  assignedTo: [],
};

const AddEditAutomationsForm = ({
  initialValues,
  showProgress,
  editMode,
  onSubmit,
  whatsappTemplates = [],
}) => {
  const { checkNestedPermission } = useAuth();
  const { translate } = useLocalization();
  const { activeTrailLists } = useActiveTrailAndFacebookCampaign();

  const [formValues, setFormValues] = useState(null);
  const { campaigns = [], isLoadingWhatsappCampaign } = useWhatsappCampaigns({
    source: WHATSAPP_SOURCE_AUTOMATIONS,
    sourceId: initialValues?._id,
  });

  useEffect(() => {
    if (initialValues) {
      setFormValues({ ...initialValues, whatsappCampaigns: campaigns });
    }
  }, [campaigns, initialValues]);

  return isLoadingWhatsappCampaign ? (
    <Loader />
  ) : (
    <Formik
      enableReinitialize
      initialValues={formValues ?? defaultValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        // Custom validation logic
        const hasActiveTrail = values.activeTrailLists.length > 0;

        const hasValidCampaignsOrUrls =
          values.facebookCampaignIds?.length > 0 ||
          values.urls?.filter((url) => url !== "").length > 0;

        setSubmitting(false);
        if (!hasValidCampaignsOrUrls) {
          setFieldError(
            "urls",
            "You must provide at least one Facebook campaign or one URL."
          );
          return;
        }
        if (!hasActiveTrail) {
          setFieldError(
            "activeTrailLists",
            "You must provide at least one Active trail"
          );
          return;
        }

        onSubmit(values);
      }}
    >
      {({ setFieldValue, values, submitForm, errors }) => {
        return (
          <Form className="p-0">
            <Row className="m-0">
              <Col xs={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("name")}
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("facebook_campaign")}
                  </FormLabel>
                  <FacebookCampaignDropdown
                    value={values.facebookCampaignIds}
                    onChange={(selectedOptions) =>
                      setFieldValue("facebookCampaignIds", selectedOptions)
                    }
                  />
                  <ErrorMessage
                    name="facebookCampaignIds"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("websites")}
                  </FormLabel>
                  <EditableTagsInput
                    tags={values.urls}
                    onTagsChange={(newUrls) => setFieldValue("urls", newUrls)}
                  />{" "}
                  <ErrorMessage
                    name="urls"
                    component="div"
                    className="text-danger smallFont"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("active_trail_list")}
                  </FormLabel>{" "}
                  <ActiveTrailDropdown
                    fieldInfo={"automation_activeTrailLists"}
                    value={values?.activeTrailLists?.map((l) => Number(l.id))}
                    onChange={(aT) => {
                      setFieldValue(
                        "activeTrailLists",
                        aT.map((a) => ({
                          name: activeTrailLists.find((o) => o.id == a)?.name,
                          id: a,
                        }))
                      );
                    }}
                  />
                  <ErrorMessage
                    name="activeTrailLists"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("lead_source")}
                  </FormLabel>
                  <LeadSourceDropdown
                    value={values.leadSource}
                    onChange={(leadSource) =>
                      setFieldValue("leadSource", leadSource)
                    }
                  />
                  {errors["leadSource"] && (
                    <div className="text-danger smallFont">
                      {errors["leadSource"]}
                    </div>
                  )}
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("status")}
                  </FormLabel>
                  <StatusDropdown
                    value={values.status}
                    onChange={(status) => setFieldValue("status", status)}
                  />

                  <ErrorMessage
                    name="status"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("assigned_to")}
                  </FormLabel>
                  <UserDropdown
                    value={values.assignedTo}
                    onChange={(selectedOptions) =>
                      setFieldValue(`assignedTo`, selectedOptions)
                    }
                  />

                  <ErrorMessage
                    name="assignedTo"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              {checkNestedPermission([
                PERMISSIONS.whatsapp_campaigns.VIEW_ALL_CAMPAIGNS,
              ]) && (
                <Col xs={12}>
                  {" "}
                  <FormLabel className="mid mb-1">
                    {translate("whatsapp_campaigns")}
                  </FormLabel>
                  <WhatsappTemplateTable
                    errors={errors.whatsappCampaigns || []}
                    whatsappTemplates={whatsappTemplates}
                    values={values.whatsappCampaigns}
                    onChange={(whatsappCampaigns) =>
                      setFieldValue(`whatsappCampaigns`, whatsappCampaigns)
                    }
                  />
                </Col>
              )}
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end">
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate(!editMode ? "save" : "update")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditAutomationsForm;
