import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import InputCell from "./InputCell";
import Title from "./Title";
import { InfoCircle, BoxArrowUpRight } from "react-bootstrap-icons";

const OverViewColumns = ({
  data = {},
  onDataChange,
  fields,
  parentContainerClassName = "",
  cellContainerClassName = "",
  titleClassName = "large",
  inputCellClassName = "",
  parentContainerStyle,
  cellContainerStyle,
  titleStyle = { fontSize: "14px" },
  inputCellStyle,
  cellSizeXS,
  cellSizeSM,
  cellSizeMD,
  cellSizeLG,
  isUpdating = false,
  editable: editModeFromParent,
  alwaysInEditMode = false,
  warningMessage,
}) => {
  const editMode = useMemo(
    () => alwaysInEditMode || editModeFromParent,
    [editModeFromParent, alwaysInEditMode]
  );
  const onEditingObjectChange = (field, newValue) => {
    onDataChange({ ...data, [field.key]: newValue });
  };

  return (
    <>
      <Row className={parentContainerClassName} style={parentContainerStyle}>
        {fields.map((field) => {
          return (
            <Col
              key={field.key}
              xs={field.cellSizeXS || cellSizeXS}
              sm={field.cellSizeSM || cellSizeSM}
              md={field.cellSizeMD || cellSizeMD}
              lg={field.cellSizeLG || cellSizeLG}
              className={`px-1 d-flex flex-column  ${
                field.className || "align-self-end " + cellContainerClassName
              }`}
              style={{ marginBottom: 2, ...cellContainerStyle }}
            >
              {!field?.hideTitle && (
                <Title
                  field={field}
                  editMode={editMode}
                  className={titleClassName}
                  style={titleStyle}
                />
              )}
              <InputCell
                data={data}
                onChange={onEditingObjectChange}
                disabled={field.isDisabled || isUpdating || !editMode}
                field={field}
                className={`flex-grow-1 ${field.className}`}
                style={inputCellStyle}
              />

              {Boolean(field.showsWarningMessage && warningMessage) && (
                <span
                  className="text-danger d-flex align-items-center gap-1"
                  style={{
                    fontSize: "10px",
                    visibility: Boolean(
                      warningMessage?.fields?.[field.label]?.message
                    )
                      ? "visible"
                      : "hidden",
                  }}
                >
                  <InfoCircle size={9} />
                  {`${warningMessage?.fields?.[field.label]?.message}`}
                  {warningMessage?.fields?.[field.label]?.onClick && (
                    <BoxArrowUpRight
                      onClick={warningMessage?.fields?.[field.label]?.onClick}
                      size={9}
                    />
                  )}
                </span>
              )}
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default OverViewColumns;
``;
