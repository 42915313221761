import React, { useEffect, useMemo, useRef, useState } from "react";
import { Badge, Form, ListGroup } from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";

const statusColors = {
  Draft: "secondary",
  "Pending Approval": "info",
  Approved: "success",
  Rejected: "danger",
};

const TemplatesList = ({
  searchQuery,
  setSearchQuery,
  templates = [],
  activeTemplate,
  onTemplateSelect,
  translate,
  setShouldScroll,
}) => {
  const [showArchivedTemplates, setShowArchivedTemplates] = useState(false);
  const [alreadyScrolled, setAlreadyScrolled] = useState(false);
  const filteredArchivedTemplates = useMemo(() => {
    return templates.filter((template) => template.archived);
  }, [templates]);

  const filteredTemplates = useMemo(() => {
    return templates.filter((template) => !template.archived);
  }, [templates]);

  useEffect(() => {
    if (activeTemplate && setShouldScroll && !alreadyScrolled) {
      setShouldScroll(true);
      setAlreadyScrolled(true);
      if (
        filteredArchivedTemplates
          ?.map((item) => item._id)
          ?.includes(activeTemplate?._id)
      ) {
        setShowArchivedTemplates(true);
      }
    }
  }, [
    activeTemplate,
    setShouldScroll,
    alreadyScrolled,
    filteredArchivedTemplates,
  ]);

  return (
    <div className="">
      <Form.Control
        type="text"
        placeholder={translate("quick_search")}
        className="form-control form-control-sm mb-1"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        size="sm"
      />

      <div className="d-flex mb-1 mx-1 align-items-center">
        <div>
          {showArchivedTemplates ? (
            <ChevronUp size={8} />
          ) : (
            <ChevronDown size={8} />
          )}
        </div>
        <div className="flex-grow-1 mx-1">
          <p
            className="mb-0 tiny text-primary hover-light"
            onClick={() => setShowArchivedTemplates(!showArchivedTemplates)}
          >
            {showArchivedTemplates
              ? "Hide archived templates"
              : "Show archived templates"}{" "}
            <b>({filteredArchivedTemplates.length})</b>
          </p>
        </div>
      </div>
      <ListGroup className="mb-2">
        {showArchivedTemplates &&
          filteredArchivedTemplates.map((item, index) => (
            <ListGroup.Item
              style={{ fontSize: 14 }}
              className={`py-1 ${
                item._id === activeTemplate?._id ? "" : "bg-gray"
              }`}
              action
              active={item._id === activeTemplate?._id}
              onClick={() => onTemplateSelect && onTemplateSelect(item)}
              key={item["_id"]}
              data-template-id={item._id}
            >
              <h6
                className={`smallFont fw-bold text-${
                  item._id === activeTemplate?._id ? "white" : "dark"
                } mb-0`}
              >
                {item.templateName}
              </h6>
              <Badge
                bg={statusColors[item.status]}
                className="smallest"
                style={{ fontSize: 10 }}
              >
                {item.status}
              </Badge>
            </ListGroup.Item>
          ))}
      </ListGroup>
      <ListGroup>
        {filteredTemplates.map((item, index) => (
          <ListGroup.Item
            style={{ fontSize: 14 }}
            className={`py-1  `}
            action
            active={item._id === activeTemplate?._id}
            onClick={() => onTemplateSelect && onTemplateSelect(item)}
            key={item["_id"]}
            data-template-id={item._id}
          >
            <h6
              className={`smallFont fw-bold text-${
                item._id === activeTemplate?._id ? "white" : "dark"
              } mb-0`}
            >
              {item.templateName}
            </h6>
            <Badge
              bg={statusColors[item.status]}
              className="smallest"
              style={{ fontSize: 10 }}
            >
              {item.status}
            </Badge>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default TemplatesList;
