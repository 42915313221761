import { snakeCase } from "lodash";
import React, { useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FiletypeCsv, Kanban, Stars, Table } from "react-bootstrap-icons";
import Switch from "react-switch";
import { DEAL_STATUSES, DEAL_VIEWS } from "../../helpers/constants";
import useLocalization from "../../hooks/useLocalization";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import CircularProgressBar from "../common/circular-progress";
import CustomMultiSelect from "../common/CustomMultiSelect";
import UserDropdown from "../common/UserDropdown";
import { GROUPPED_PRODUCTS } from "../../helpers/global";

const DealsTabAndFilter = ({
  activeView,
  setActiveView,
  products,
  boardViewFilters,
  onBoardFiltersChange,
  showFloatingContract,
  setShowFloatingContract,
  showDealsWithRemainingPayment,
  toggleRemainingPaymentDealFilter,
  isSearchBoxDisabled,
  isDownloadingDeals,
  downloadContractedBuyersCSV,
  isDownloadingProducts,
  downloadContractProductsCSV,
  showAllFields,
  setShowAllFields,
  showLastAndFirstStage,
  setShowLastAndFirstStage,
  toggleAiChatBot,
  showQuittedContract,
  setShowQuittedContract,
}) => {
  let { translate } = useLocalization();
  const { screenWidth } = useScreenWidth();
  const mobileView = useMemo(() => screenWidth <= 800, [screenWidth]);

  return (
    <Row className="align-items-center mx-0 mb-1 border-bottom py-2">
      <Row className="align-items-center mx-0 py-1">
        <Col
          lg={5}
          md={4}
          xs={12}
          sm={5}
          className="p-0 m-0 d-flex align-items-center gap-2"
        >
          {DEAL_VIEWS?.map((view) => (
            <Button
              key={view?.name}
              className={`fw-bold px-2 py-1 d-flex align-items-center`}
              size="sm"
              style={{ fontSize: 13 }}
              variant={activeView === view?.name ? "dark" : "outline-dark"}
              onClick={() => {
                setActiveView(view?.name);
              }}
            >
              {view.name === "table" ? (
                <Table className="mx-1" />
              ) : (
                <Kanban className="mx-1" />
              )}
              {translate(snakeCase(view?.label)) || view?.label}
            </Button>
          ))}
          <Button
            className="py-1 px-1 d-flex align-items-center "
            variant="dark"
            size="sm"
            disabled={isSearchBoxDisabled}
            onClick={toggleAiChatBot}
            style={{ boxSizing: "border-box" }}
          >
            <Stars size={20} className="mx-1 custom-shining-gold" />{" "}
          </Button>
          <Button
            className="py-1 d-flex align-items-center"
            variant="outline-success"
            size="sm"
            disabled={isSearchBoxDisabled || isDownloadingDeals}
            onClick={downloadContractedBuyersCSV}
          >
            {isDownloadingDeals ? (
              <CircularProgressBar size={16} className="mx-2" />
            ) : (
              <>
                <span>{translate("deals")}</span>
                <FiletypeCsv className="ms-1" size={15} />
              </>
            )}
          </Button>
          <Button
            className="py-1 d-flex align-items-center"
            variant="outline-success"
            size="sm"
            disabled={isSearchBoxDisabled || isDownloadingProducts}
            onClick={downloadContractProductsCSV}
          >
            {isDownloadingProducts ? (
              <CircularProgressBar size={16} className="mx-2" />
            ) : (
              <>
                <span>{translate("products")}</span>
                <FiletypeCsv className="ms-1" size={15} />
              </>
            )}
          </Button>
        </Col>
        <Col
          xs={4}
          sm={2}
          md={2}
          lg={1}
          className="m-0 d-flex align-items-start justify-content-end gap-4"
        >
          {" "}
          <div>
            <h6 className="smallFont fw-bold mb-0">
              {translate("show_floating")}
            </h6>{" "}
            <Switch
              checked={showFloatingContract}
              onChange={(checked) => setShowFloatingContract(checked)}
              height={15}
              width={40}
              offHandleColor="#d1e6cc"
              onHandleColor="#d1e6cc"
            />{" "}
          </div>
          <div>
            <h6 className="smallFont fw-bold mb-0">{translate("quitted")}</h6>{" "}
            <Switch
              checked={showQuittedContract}
              onChange={(checked) => setShowQuittedContract(checked)}
              height={15}
              width={40}
              offHandleColor="#d1e6cc"
              onHandleColor="#d1e6cc"
            />{" "}
          </div>
        </Col>
        <Col xs={8} sm={5} md={4} lg={2} className="m-0">
          <h6 className="smallFont fw-bold mb-0">{translate("products")}</h6>
          <CustomMultiSelect
            items={GROUPPED_PRODUCTS({ products, translate })}
            selectedItems={boardViewFilters?.product}
            onChange={(product) =>
              onBoardFiltersChange({ ...boardViewFilters, product })
            }
            height="26px"
            maxItemCustomMessage={(length) =>
              `+ ${length} ${translate("more")}`
            }
            placeholder={translate("select_product")}
            maxToShow={mobileView ? 0 : 5}
            isGroupped={true}
          />
        </Col>{" "}
        <Col xs={6} sm={5} md={4} lg={2} className="m-0">
          <h6 className="smallFont fw-bold mb-0">{translate("assigned_to")}</h6>
          <UserDropdown
            value={boardViewFilters?.assignedTo}
            onChange={(assignedTo) =>
              onBoardFiltersChange({ ...boardViewFilters, assignedTo })
            }
            maxItemCustomMessage={(length) => `+ ${length} more`}
            height="26px"
            maxToShow={mobileView ? 0 : 5}
            isMultiSelect
            closeMenuOnSelect={false}
          />
        </Col>
        <Col xs={6} sm={5} md={4} lg={2} className="m-0">
          <h6 className="smallFont fw-bold mb-0">{translate("status")}</h6>
          <CustomMultiSelect
            items={DEAL_STATUSES?.map((status) => ({
              label: translate(snakeCase(status)),
              value: status,
            }))}
            selectedItems={boardViewFilters?.status}
            onChange={(status) => {
              onBoardFiltersChange({ ...boardViewFilters, status });
            }}
            maxItemCustomMessage={(length) => `+ ${length} more`}
            height="26px"
            placeholder={translate("select_status")}
            maxToShow={mobileView ? 1 : 5}
          />
        </Col>
      </Row>

      {Boolean(activeView === "table" || activeView === "product") && (
        <Row className="align-items-center mx-0">
          <Col
            xs={8}
            sm={5}
            md={6}
            lg={6}
            className="px-0 m-0 d-flex align-items-start justify-content-start gap-2 "
          >
            {activeView === "product" && (
              <Form.Check
                id={"show-all-fields"}
                size={"sm"}
                checked={showAllFields}
                onChange={(e) => setShowAllFields(e.target.checked)}
                className=""
                label={
                  <h6 id="show-all-fields" className="smallFont mb-0">
                    {translate("show_all_fields")}
                  </h6>
                }
              />
            )}

            <Form.Check
              id="show_deals_with_remaining_payment"
              checked={showDealsWithRemainingPayment}
              onChange={(e) =>
                toggleRemainingPaymentDealFilter(e.target.checked)
              }
              label={
                <h6
                  id="show_deals_with_remaining_payment"
                  className="smallFont mb-0"
                >
                  {translate("show_deals_with_remaining_payment")}
                </h6>
              }
            />
          </Col>{" "}
        </Row>
      )}

      {activeView === "board" && (
        <Row className="d-flex align-items-end justify-content-end mx-0">
          <Col
            xs={6}
            sm={3}
            md={3}
            lg={2}
            className="m-0 d-flex align-items-end justify-content-end gap-2 py-1"
          >
            <h1 className="smallFont fw-bold mb-0">
              {translate("show_last_n_first_stage")}
            </h1>
            <Switch
              checked={showLastAndFirstStage}
              onChange={(checked) => setShowLastAndFirstStage(checked)}
              height={15}
              width={40}
              offHandleColor="#d1e6cc"
              onHandleColor="#d1e6cc"
            />
          </Col>
        </Row>
      )}
    </Row>
  );
};

export default DealsTabAndFilter;
