import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  FormLabel,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { Plus, Trash } from "react-bootstrap-icons";
import * as Yup from "yup";
import { WHATSAPP_SOURCE_INBOUNDS } from "../../helpers/constants";
import { WEBSITE } from "../../helpers/regex";
import useActiveTrailAndFacebookCampaign from "../../hooks/useActiveTrailAndFacebookCampaign";
import useLocalization from "../../hooks/useLocalization";
import useWhatsappCampaigns from "../../hooks/useWhatsappCampaigns";
import ActiveTrailDropdown from "../automation-events/common/ActiveTrailDropdown";
import AddEditWhatsappTemplateModal from "../automation-events/common/AddEditWhatsappTemplateModal";
import FacebookCampaignDropdown from "../automation-events/common/FacebookCampaignDropdown";
import LeadSourceDropdown from "../automation-events/common/LeadSourceDropdown";
import StatusDropdown from "../automation-events/common/StatusDropdown";
import WhatsappTemplateTable from "../automation-events/common/WhatsappTemplateTable";
import Loader from "../common/Loader";
import UserDropdown from "../common/UserDropdown";

// Yup validation schema updated with new requirements
const validationSchema = Yup.object().shape({
  key: Yup.string().required("Key is required"),
  status: Yup.string().required("Status is required"),
  whatsappCampaigns: Yup.array().of(
    Yup.object().shape({
      template: Yup.string().required("Template is required"),
    })
  ),
  exceptions: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().when("key", {
        is: "Website",
        then: (schema) =>
          schema
            .matches(WEBSITE, "URL is not valid")
            .required("url is required"),
        otherwise: (schema) => schema.required("Value is required"),
      }),
      status: Yup.string().required("Status is required"),
    })
  ),
});

const defaultValues = {
  status: "",
  exceptions: [
    {
      value: "",
      status: "",
      assignedTo: [],
    },
  ],
};

const EditDefaultStatusForm = ({
  initialValues,
  showProgress = false,
  onSubmit,
  whatsappTemplates,
  allowEdit,
  canViewWhatsappCampaigns,
}) => {
  const { activeTrailLists } = useActiveTrailAndFacebookCampaign();

  const { translate } = useLocalization();
  const [addEditWhatsappCampaignMeta, setAddEditWhatsappCampaignMeta] =
    useState(null);
  const [formValues, setFormValues] = useState(null);

  const { campaigns = [], isLoadingWhatsappCampaign } = useWhatsappCampaigns({
    source: WHATSAPP_SOURCE_INBOUNDS,
    sourceId: initialValues?._id,
    refetch: initialValues,
  });

  useEffect(() => {
    if (initialValues) {
      setFormValues({
        ...initialValues,
        ...(campaigns.length > 0 ? { whatsappCampaigns: campaigns } : {}),
      });
    }
  }, [campaigns, initialValues]);

  return isLoadingWhatsappCampaign ? (
    <Loader />
  ) : (
    <Formik
      enableReinitialize
      initialValues={formValues ?? defaultValues}
      validationSchema={validationSchema}
      onSubmit={(value) => {
        const valueToSubmit = {
          ...value,
        };
        onSubmit(valueToSubmit);
      }}
    >
      {({ setFieldValue, values, submitForm, errors }) => {
        return (
          <Form className="p-2">
            <h6 className="xxlarge " style={{ fontWeight: 600 }}>
              {values?.key}{" "}
              {`(${values.contactCount} ${translate("contact_signed_up")})`}
            </h6>
            <hr className="my-1" />
            <Row>
              <Col xs={12} md={3}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("status")}
                  </FormLabel>
                  <StatusDropdown
                    value={values.status}
                    onChange={(status) => setFieldValue("status", status)}
                  />
                  {errors["status"] && (
                    <div className="text-danger smallFont">
                      {errors["status"]}
                    </div>
                  )}
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={3}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("lead_source")}
                  </FormLabel>
                  <LeadSourceDropdown
                    value={values.leadSource}
                    onChange={(leadSource) =>
                      setFieldValue("leadSource", leadSource)
                    }
                  />
                  {errors["leadSource"] && (
                    <div className="text-danger smallFont">
                      {errors["leadSource"]}
                    </div>
                  )}
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={3}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("active_trail_groups")}
                  </FormLabel>{" "}
                  <ActiveTrailDropdown
                    fieldInfo={"inbound_source_activeTrailLists"}
                    value={values?.activeTrailLists?.map((l) => Number(l.id))}
                    onChange={(aT) => {
                      setFieldValue(
                        "activeTrailLists",
                        aT.map((a) => ({
                          name: activeTrailLists.find((o) => o.id == a)?.name,
                          id: a,
                        }))
                      );
                    }}
                  />
                  <ErrorMessage
                    name="activeTrailLists"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={3}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("assigned_to")}
                  </FormLabel>
                  <UserDropdown
                    value={values?.assignedTo}
                    onChange={(selectedOptions) =>
                      setFieldValue(`assignedTo`, selectedOptions)
                    }
                  />

                  <ErrorMessage
                    name="assignedTo"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              {canViewWhatsappCampaigns && (
                <Col xs={12} md={6}>
                  <FormGroup className="mb-2">
                    <FormLabel className="mid mb-1">
                      {translate("whatsapp_campaigns")}
                    </FormLabel>
                    <WhatsappTemplateTable
                      errors={errors.whatsappCampaigns || []}
                      whatsappTemplates={whatsappTemplates}
                      values={values.whatsappCampaigns}
                      onChange={(whatsappCampaigns) =>
                        setFieldValue(`whatsappCampaigns`, whatsappCampaigns)
                      }
                    />
                  </FormGroup>
                </Col>
              )}
              <Col xs={12} md={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("exceptions")}
                  </FormLabel>
                  <Table bordered striped className="mb-0">
                    <thead>
                      <tr className="mid">
                        <th>{translate("exception")}</th>
                        <th style={{ width: 30 }}>{translate("contacts")}</th>
                        <th style={{ width: 120 }}>{translate("status")}</th>
                        <th>{translate("lead_source")}</th>
                        <th>{translate("assigned_to")}</th>
                        <th>{translate("active_trail_groups")}</th>
                        {canViewWhatsappCampaigns && allowEdit && (
                          <th>{translate("whatsapp_campaigns")}</th>
                        )}
                        {allowEdit && <th>{translate("action")}</th>}
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray
                        name="exceptions"
                        render={(arrayHelpers) => (
                          <>
                            {values.exceptions &&
                              values.exceptions.length > 0 &&
                              values.exceptions.map((e, index) => (
                                <tr key={`${index}`}>
                                  <td>
                                    {" "}
                                    {initialValues?.key ===
                                    "Facebook Leadform" ? (
                                      <FacebookCampaignDropdown
                                        value={
                                          values?.exceptions?.[index]?.value
                                        }
                                        onChange={(selectedOptions) =>
                                          setFieldValue(
                                            `exceptions.${index}.value`,
                                            selectedOptions
                                          )
                                        }
                                        isMulti={false}
                                      />
                                    ) : (
                                      <Field
                                        name={`exceptions.${index}.value`}
                                        type="text"
                                        className="form-control form-control-sm"
                                        size="sm"
                                      />
                                    )}
                                    <ErrorMessage
                                      name={`exceptions.${index}.value`}
                                      component="div"
                                      className="text-danger smallFont"
                                    />
                                  </td>
                                  <td align="center">
                                    {" "}
                                    <h6 className="mb-0 mid mt-2">
                                      {values?.exceptions?.[index]
                                        ?.contactCount || 0}
                                    </h6>
                                  </td>
                                  <td>
                                    {" "}
                                    <StatusDropdown
                                      value={
                                        values?.exceptions?.[index]?.status
                                      }
                                      onChange={(status) =>
                                        setFieldValue(
                                          `exceptions.${index}.status`,
                                          status
                                        )
                                      }
                                    />
                                    <ErrorMessage
                                      name={`exceptions.${index}.status`}
                                      component="div"
                                      className="text-danger smallFont"
                                    />
                                  </td>
                                  <td>
                                    <LeadSourceDropdown
                                      value={
                                        values?.exceptions?.[index]?.leadSource
                                      }
                                      onChange={(leadSource) =>
                                        setFieldValue(
                                          `exceptions.${index}.leadSource`,
                                          leadSource
                                        )
                                      }
                                    />
                                    <ErrorMessage
                                      name={`exceptions.${index}.leadSource`}
                                      component="div"
                                      className="text-danger smallFont"
                                    />
                                  </td>
                                  <td>
                                    {" "}
                                    <UserDropdown
                                      value={
                                        values?.exceptions?.[index]?.assignedTo
                                      }
                                      onChange={(selectedOptions) =>
                                        setFieldValue(
                                          `exceptions.${index}.assignedTo`,
                                          selectedOptions
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <ActiveTrailDropdown
                                      fieldInfo={`inbound_source_exceptions_${index}_activeTrailLists`}
                                      value={values?.exceptions?.[
                                        index
                                      ]?.activeTrailLists?.map((l) =>
                                        Number(l.id)
                                      )}
                                      onChange={(aT) => {
                                        setFieldValue(
                                          `exceptions.${index}.activeTrailLists`,
                                          aT.map((a) => ({
                                            name: activeTrailLists.find(
                                              (o) => o.id == a
                                            )?.name,
                                            id: a,
                                          }))
                                        );
                                      }}
                                    />
                                  </td>
                                  {canViewWhatsappCampaigns && allowEdit && (
                                    <td>
                                      <Button
                                        size="sm"
                                        variant="outline-dark"
                                        className="mt-1 d-flex align-items-center"
                                        onClick={() =>
                                          setAddEditWhatsappCampaignMeta({
                                            index,
                                            updatingException:
                                              values?.exceptions?.[index],
                                          })
                                        }
                                      >
                                        <h6 className="mb-0 smallFont">
                                          {translate("manage_campaigns")}
                                        </h6>
                                      </Button>
                                      {!!addEditWhatsappCampaignMeta &&
                                        addEditWhatsappCampaignMeta.index ===
                                          index && (
                                          <AddEditWhatsappTemplateModal
                                            show={
                                              !!addEditWhatsappCampaignMeta &&
                                              addEditWhatsappCampaignMeta.index ===
                                                index
                                            }
                                            onHide={() =>
                                              setAddEditWhatsappCampaignMeta(
                                                null
                                              )
                                            }
                                            whatsappTemplates={
                                              whatsappTemplates
                                            }
                                            updatingException={
                                              addEditWhatsappCampaignMeta?.updatingException
                                            }
                                            inbound={values}
                                          />
                                        )}
                                    </td>
                                  )}

                                  {allowEdit && (
                                    <td align="center">
                                      <Button
                                        size="sm"
                                        variant="outline-danger"
                                        className="px-1 py-0"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <Trash />
                                      </Button>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            {allowEdit && (
                              <tr>
                                <td colSpan={8} align="right">
                                  <Button
                                    size="sm"
                                    variant="primary"
                                    className="d-flex align-items-center gap-1 text-white"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        value: "",
                                        status: "",
                                        whatsappCampaigns: [],
                                      })
                                    }
                                    disabled={showProgress}
                                  >
                                    <Plus />{" "}
                                    <h6 className="mb-0 smallFont">
                                      {translate("add")}
                                    </h6>
                                  </Button>{" "}
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      />
                    </tbody>
                  </Table>
                </FormGroup>
              </Col>
            </Row>
            {allowEdit && (
              <div className="text-end">
                <hr className="mt-1 mb-2" />
                <Button
                  className=""
                  variant="success text-white"
                  onClick={submitForm}
                  size="sm"
                  disabled={showProgress}
                >
                  {showProgress ? <Spinner size="sm" /> : translate("save")}
                </Button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditDefaultStatusForm;
