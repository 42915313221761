import { snakeCase } from "lodash";
import React from "react";
import ContentLoader from "react-content-loader";
import useLocalization from "../../hooks/useLocalization";
import { formatCurrency } from "../../helpers/global";

const StatCard = ({
  className,
  style = {},
  active,
  stat,
  fetching,
  onClick,
}) => {
  let { name, data, variant, icon: Icon } = stat;
  const { translate } = useLocalization();
  return (
    <div
      style={{
        backgroundColor: !fetching && active ? "#e5e7eb" : "",
        ...style,
      }}
      className={`custom-card h-100 d-flex hover flex-column p-0 ${className}`}
      onClick={() => {
        if (onClick) {
          onClick(stat);
        }
      }}
    >
      {" "}
      <div
        className={`flex-grow-1 d-flex ${
          fetching ? "justify-content-center" : "justify-content-between"
        } align-items-center p-2`}
      >
        {fetching ? (
          <ContentLoader className="" viewBox="0 0 380 70">
            <rect x="0" y="0" rx="4" ry="4" width="250" height="20" />
            <rect x="0" y="30" rx="3" ry="3" width="200" height="15" />
            <rect x="260" y="0" rx="5" ry="5" width="50" height="50" />
          </ContentLoader>
        ) : (
          <>
            <div className="h-100 w-100 d-flex  align-items-center justify-content-between">
              <div
                className={`h-100 d-flex flex-column justify-content-${
                  data ? "between" : "center"
                }`}
              >
                <h6 className="xxlarge text-muted mb-0">
                  {translate(snakeCase(name)) || name}
                </h6>
                {data && (
                  <h6 style={{ fontSize: "32px" }} className=" fw-bold">
                    {formatCurrency(data?.value || data, true) || "0"}
                  </h6>
                )}
              </div>
              <div
                style={{ borderRadius: 50 }}
                className={`d-flex justify-content-center align-items-center p-2 bg-${variant}`}
              >
                <Icon size={30} className="text-white" />
              </div>
            </div>
          </>
        )}
      </div>
      {/* <h6 className="xxlarge text-muted ">{data}</h6> */}
      <div
        className={`w-100 ${!fetching && active && "bg-dark"}`}
        style={{ height: 3 }}
      />
    </div>
  );
};

export default StatCard;
