import { ErrorMessage, Field, Form, Formik } from "formik";
import { snakeCase } from "lodash";
import React from "react";
import {
  Badge,
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";
import { Eye, EyeSlash, Trash } from "react-bootstrap-icons";
import CustomMultiSelect from "../common/CustomMultiSelect";
import {
  DEFAULT_DATE_TIME_FORMAT_NON_MOMENT,
  LEAD_SOURCE_OPTIONS,
} from "../../helpers/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditableTagsInput from "../common/EditableTagsInput";

// Yup validation schema for inbound fields
const validationSchema = Yup.object().shape({
  source: Yup.string().required("Source is required"),
  date: Yup.date().required("Date is required"),
  leadSource: Yup.string().required("Lead source is required"),
  sourceDetails: Yup.object().shape({
    campaignName: Yup.string().required("Campaign is required"),
  }),
});

const defaultValues = {
  source: "Manually Added",
  date: "",
  leadSource: "",
  sourceDetails: {
    campaignName: "",
  },
};

const AddEditContactInboundForm = ({
  initialValues,
  showProgress,
  onSubmit,
  editMode,
  allowDelete,
  onDeleteInboundClick,
}) => {
  const { translate, isRTL } = useLocalization();

  return (
    <Formik
      initialValues={
        initialValues
          ? {
              _id: initialValues?._id,
              source: initialValues?.source,
              sourceDetails: {
                ...initialValues.sourceDetails,
                campaignName: initialValues?.sourceDetails?.campaignName || "",
              },
              date: initialValues?.date,
              leadSource: initialValues?.leadSource,
            }
          : defaultValues
      }
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, { setFieldError }) => {
        const formattedValues = {
          ...values,
          date: values.date ? new Date(values.date).toISOString() : null,
        };
        onSubmit(formattedValues);
      }}
    >
      {({ values, setFieldValue, errors, submitForm }) => {
        return (
          <Form className="">
            <Row className="m-0">
              {[
                {
                  name: "leadSource",
                  type: "select",
                  colSize: 6,
                  label: "Lead Source",
                  options: LEAD_SOURCE_OPTIONS,
                  value: values.leadSource,
                  onChange: (value) => setFieldValue("leadSource", value),
                  isMulti: false,
                },
                {
                  name: "date",
                  type: "date",
                  colSize: 6,
                  label: "Date",
                  onChange: (value) =>
                    setFieldValue("date", value?.toISOString()),
                  value: values.date ? new Date(values.date) : null,
                },
                {
                  name: "sourceDetails.campaignName",
                  type: "text",
                  label: "campaign_url",
                  colSize: 12,
                  onChange: (e) =>
                    setFieldValue("sourceDetails.campaignName", e.target.value),
                  placeholder: "https://ethica/sample.com",
                },
              ].map((field) => (
                <Col key={field.name} xs={12} md={field.colSize || 6}>
                  <FormGroup key={field.name} className="mb-2">
                    <FormLabel className="mid mb-1">
                      {translate(snakeCase(field.label))}
                    </FormLabel>
                    {field.type === "select" ? (
                      <CustomMultiSelect
                        items={field.options.map((l) => ({
                          label: translate(l),
                          value: l,
                        }))}
                        selectedItems={field.value}
                        isMulti={field.isMulti}
                        height="30px"
                        onChange={field.onChange}
                        placeholder="Select"
                        closeMenuOnSelect
                      />
                    ) : field.type === "date" ? (
                      <DatePicker
                        wrapperClassName="d-block"
                        className={
                          "px-4 mb-0 text-dark px-2 py-1 form-control form-control-sm lh-lg"
                        }
                        selected={field.value}
                        dateFormat={DEFAULT_DATE_TIME_FORMAT_NON_MOMENT}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        showYearDropdown
                        showMonthDropdown
                        showTimeInput
                        timeIntervals={15}
                        dropdownMode="scroll"
                        timeFormat="HH:mm"
                        clearButtonClassName="text-dark"
                        portalId="root"
                        popperClassName="datepickerPopperClassName"
                        placeholderText={translate("select_date")}
                        isClearable
                      />
                    ) : field.type === "multi-tag" ? (
                      <EditableTagsInput
                        tags={field.urls}
                        onTagsChange={field.onChange}
                      />
                    ) : (
                      <Field
                        name={field.name}
                        type={field.type}
                        className="form-control form-control-sm lh-lg"
                        size="sm"
                        onChange={field.onChange}
                        placeholder={field.placeholder}
                      />
                    )}
                    <ErrorMessage
                      name={field.name}
                      component="div"
                      className="text-danger mid"
                    />
                  </FormGroup>
                </Col>
              ))}
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}

            <hr className="my-2" />

            <div className="d-flex flex-end align-items-center justify-content-end">
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate(!editMode ? "add" : "update")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditContactInboundForm;
