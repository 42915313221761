import React, { useEffect, useRef, useState } from "react";
import useLocalization from "../../../hooks/useLocalization";
import { ENDPOINTS } from "../../../helpers/constants";
import { Overlay, Popover } from "react-bootstrap";

import DraggableModal from "../../common/DraggableModal";
import { ChevronDown } from "react-bootstrap-icons";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";

const playbackRates = [
  0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 2.5, 3.0, 3.5, 4.0,
];

const CallDetailModal = ({ show, onHide, callInfo, offSet = null }) => {
  const { translate, isRTL } = useLocalization();

  const audioRef = useRef(null);
  const [playbackRate, setPlaybackRate] = useState(1.75);
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [customInputValue, setCustomInputValue] = useState(playbackRate);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownTarget = useRef(null);

  useEffect(() => {
    if (audioRef.current && audioRef.current.playbackRate) {
      audioRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  const handleCustomInputUpdate = () => {
    const value = parseFloat(customInputValue);
    if (!isNaN(value)) {
      setPlaybackRate(value);
    }
    setShowCustomInput(false);
  };

  const handleCustomInputKeyDown = (e) => {
    if (e.key === "Enter") {
      handleCustomInputUpdate();
    }
  };

  // Handle selection from the dropdown.
  const handleDropdownSelect = (rate) => {
    setPlaybackRate(rate);
    setShowDropdown(false);
    setCustomInputValue(rate);
  };

  return (
    <DraggableModal
      show={show}
      onHide={onHide}
      title={translate("call_recording")}
      height={120}
      width={{
        sm: "90%",
        md: 600,
        lg: 600,
      }}
      popupOffset={offSet}
    >
      {callInfo && (
        <div className="d-flex justify-content-center align-items-center px-2">
          <div className="position-relative w-100">
            <audio
              className={`rounded ${isRTL ? "float-start" : ""}`}
              controls
              ref={audioRef}
              autoPlay
              style={{ height: "60px", width: "95%" }}
            >
              <source
                src={`${process.env.REACT_APP_API_URL}${ENDPOINTS.GET_CALL_RECORDING_STREAM}?audioLink=${callInfo?.recordingLink}`}
                phoneType="audio/mpeg"
                style={{ size: "10px" }}
              />
              Your browser does not support the audio element.
            </audio>

            <div
              className={`position-absolute d-flex align-items-center rounded-end-5 px-1 ${
                isRTL ? "pe-2" : ""
              }`}
              style={{
                right: "0px",
                top: "5px",
                height: "55px",
                minWidth: "100px",
                overflow: "hidden",
                background: "#f1f3f4",
                boxSizing: "border-box",
              }}
            >
              <button
                className="d-flex justify-content-center align-items-center border-0 mx-1"
                style={{
                  cursor: "pointer",
                  background: "#f1f3f4",
                  border: "none",
                  padding: "0",
                }}
                onClick={() =>
                  setPlaybackRate(parseFloat((playbackRate - 0.1).toFixed(2)))
                }
              >
                <MinusCircleFilled size={16} />
              </button>

              <div
                style={{ cursor: "pointer" }}
              >
                {showCustomInput ? (
                  <input
                    type="numeric"
                    className="border-0 text-center"
                    style={{
                      background: "	#F8F8F8",
                      width: "40px",
                      margin: "0",
                      padding: "0",
                      border: "none",
                      outlineColor: "	#989898",
                    }}
                    value={customInputValue}
                    onChange={(e) => setCustomInputValue(e.target.value)}
                    onKeyDown={handleCustomInputKeyDown}
                    onBlur={handleCustomInputUpdate}
                    autoFocus
                    onFocus={(e) => e.target.select()}
                  />
                ) : (
                  <button
                    className="border-0 text-center px-0"
                    style={{
                      background: "#f1f3f4",
                      height: "40px",
                      width: "40px",
                      outline: "none",
                    }}
                    onClick={() => setShowCustomInput(true)}
                  >
                    {playbackRate + "x"}
                  </button>
                )}
              </div>

              <button
                className="d-flex justify-content-center align-items-center border-0 mx-1"
                style={{
                  cursor: "pointer",
                  background: "#f1f3f4",
                  border: "none",
                  padding: "0",
                }}
                onClick={() =>
                  setPlaybackRate(parseFloat((playbackRate + 0.1).toFixed(2)))
                }
              >
                <PlusCircleFilled size={16} />
              </button>

              {/* Dropdown trigger */}
              <div>
                <button
                  ref={dropdownTarget}
                  className="border-0"
                  style={{
                    background: "#f1f3f4",
                    height: "40px",
                    width: "20px",
                    outline: "none",
                    padding: "0",
                  }}
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <ChevronDown size={12} />
                </button>
                <Overlay
                  target={dropdownTarget.current}
                  show={showDropdown}
                  placement={`${!isRTL ? "left" : "right"}`}
                  container={document.body}
                  rootClose
                  onHide={() => setShowDropdown(false)}
                >
                  <Popover id="playback-rate-popover">
                    <Popover.Body>
                      {playbackRates.map((rate) => (
                        <div
                          key={rate}
                          style={{
                            padding: "5px 5px",
                            cursor: "pointer",
                            background:
                              playbackRate === rate ? "#f1f3f4" : "#fff",
                          }}
                          onClick={() => handleDropdownSelect(rate)}
                        >
                          {rate + "x"}
                        </div>
                      ))}
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </div>
          </div>
        </div>
      )}
    </DraggableModal>
  );
};

export default CallDetailModal;
